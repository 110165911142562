import { Router } from '@angular/router';
import { Globals } from '@theme/utils/globals';
import { environment } from '@env/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OwnerService } from '@service/owner/owner.service';
import {FilterService} from '@service/filter/filter.service';
import {MandateService} from '@service/mandate/mandate.service';
import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MandateShowComponent} from '@proprietaire/mandate/mandate-show/mandate-show.component';

@Component({
  selector: 'app-dash-proprietaire',
  templateUrl: './dash-proprietaire.component.html',
  styleUrls: ['./dash-proprietaire.component.scss']
})
export class DashProprietaireComponent implements OnInit {

  publicUrl = environment.publicUrl;
  pieLocativeO = 0;
  pieLocativeR = 0;
  pieLocativeD = 0;
  dtOptions: any = {};
  etat: boolean = false
  widget : any
  listing : any
  type: string = 'TOUT';
  global = {country: Globals.country, device: Globals.device}
  typeRow = [{label: 'TOUT', value: 'TOUT'}];
  event = {
    categorie: null,
    code: null,
    count: 10,
    create: null,
    dateD: null,
    dateF: null,
    etat: null,
    max: null,
    min: null,
    name: null,
    ordre: "ASC",
    type: "TOUT",
    uuid: null
  }

  public pieChartData: any;
  @ViewChild('doughnutChart', {static: false}) doughnutChart: ElementRef; // doughnut
  public pieChartTag: CanvasRenderingContext2D;

  constructor(
    public router: Router,
    private modalService: NgbModal,
    private ownerService: OwnerService,
    private filterService: FilterService,
    private mandateService: MandateService,
    private _changeDetectorRef: ChangeDetectorRef
  ) {
    this.onFilter(this.event);
  }

  ngOnInit(): void {
    this.dtOptions = Globals.dataTable;
    this.filterService.dashboard(this.event, 'owner', null).subscribe(
      res => {
        this.onSet(res);
        this.getPie();
        this._changeDetectorRef.markForCheck();
    }, err => { })
  }

  onFilter($event) {
    this.type = $event.type
    this.filterService.dashboard($event, 'owner', null).subscribe(
      res => {
        this.etat = res ? true : false;
        this.widget = res?.widget;
        this.listing = res?.listing;
        this.onSet(res);
        this.getPie();
        this._changeDetectorRef.markForCheck();
    }, err => { })
  }
  onSet(res){
    if (res) {
      this.pieLocativeO = res?.pieLocative.nbrO;
      this.pieLocativeR = res?.pieLocative.nbrR;
      this.pieLocativeD = res?.pieLocative.nbrD;
    }
  }
  onChangeLoad($event) {
    this.type = $event
    if($event === 'TOUT'){
    }
  }
  getPie(){
    setTimeout(() => {
      /* pie cart */
      const pieTag = (((this.doughnutChart.nativeElement as HTMLCanvasElement).children));
      this.pieChartTag = ((pieTag['doughnut_chart']).lastChild).getContext('2d'); // doughnut_chart
      this.pieChartData = {
        labels: ["Occupée", "Reservée", 'Disponible'],
        datasets: [{
          data: [this.pieLocativeO, this.pieLocativeR, this.pieLocativeD],
          backgroundColor: ['#0e9e4a', '#4680ff', '#ff5252'],
          hoverBackgroundColor: ['#0e9e4a', '#4680ff', '#ff5252']
        }]
      };
    });
  }
  showOwner(row) {
    this.ownerService.setOwner(row);
    this.router.navigate(['/admin/proprietaire/show/' + row.uuid]);
  }
  showMandate(row) {
    this.mandateService.setMandate(row);
    this.modal(MandateShowComponent, 'modal-basic-title', 'lg', true, 'static');
  }
  modal(component, type, size, center, backdrop): void {
    this.modalService.open(component, {
      ariaLabelledBy: type,
      size: size,
      centered: center,
      backdrop: backdrop
    }).result.then((result) => {}, (reason) => {});
  }
}
