import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-assemblee-list',
  templateUrl: './assemblee-list.component.html',
  styleUrls: ['./assemblee-list.component.scss']
})
export class AssembleeListComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
