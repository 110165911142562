<div class="modal-header">
  <h5 class="modal-title">{{title}}</h5>
  <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
    (click)="modale.close('ferme')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="col-md-12">
    <div class="d-flex">
      <!-- COL GAUCHE -->
      <div class="flex-fill">
        <p>
          <span class="title-show">Référence : </span>
          <span class="title-result-show">{{ product?.code }}</span>
        </p>
        <p>
          <span class="title-show">Libellé : </span>
          <span class="title-result-show">{{ product?.libelle }}</span>
        </p>
        <p>
          <span class="title-show">Prix : </span>
          <span class="title-result-show text-warning">{{ product?.prix|number}} {{ global.device }}</span>
        </p>
      </div>
      <!-- COL DROITE -->
      <div class="flex-fill">
        <p>
          <span class="title-show">Modifié le : </span>
          <span class="title-result-show">{{ product?.updatedAt| date: "d MMMM y 'à' h:mm:ss" : '' : 'fr-FR' }}</span>
          <span class="title-show ml-5"> Par : </span>
          <span class="title-result-show">{{ product?.update }}</span>
        </p>
        <p>
          <span class="title-show">Crée le : </span>
          <span class="title-result-show">{{ product?.createdAt| date: "d MMMM y 'à' h:mm:ss" : '' : 'fr-FR'}}</span>
          <span class="title-show ml-5"> Par : </span>
          <span class="title-result-show">{{ product?.create }}</span>
        </p>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button (click)="modale.close('ferme')" type="button" class="btn btn-secondary text-left" data-dismiss="modal">
    Fermer <i class="feather icon-x-circle"></i>
  </button>
  <button *ngxPermissionsOnly="'PRODUCT:EDIT'" (click)="editProduct(product)" class="btn btn-primary">
    Modifier <i class="feather icon-edit-2"></i>
  </button>
  <button *ngxPermissionsOnly="'PRODUCT:PRINTER:SHOW'" (click)="printerProduct(product)" class="btn btn-warning">
    Imprimer <i class="feather icon-printer"></i>
  </button>
</div>
