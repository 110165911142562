import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dash-suivi',
  templateUrl: './dash-suivi.component.html',
  styleUrls: ['./dash-suivi.component.scss']
})
export class DashSuiviComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
