import {ValidatorsEnums} from '@theme/enums/validators.enums';
import {HouseService} from '@service/house/house.service';
import {House} from '@model/house';
import {Owner} from '@model/owner';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Mandate} from '@model/mandate';
import {MandateService} from '@service/mandate/mandate.service';
import {FormGroup, FormBuilder, Validators, AbstractControl} from '@angular/forms';
import {Component, OnInit} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {EmitterService} from '@service/emitter/emitter.service';
import {DateHelperService} from '@theme/utils/date-helper.service';
import { Globals } from '@theme/utils/globals';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { SyndicService } from '@service/syndic/syndic.service';
import { Syndic } from '@model/syndic/syndic';

@Component({
  selector: 'app-mandate-add',
  templateUrl: './mandate-add.component.html',
  styleUrls: ['./mandate-add.component.scss']
})
export class MandateAddComponent implements OnInit {
  syndic: Syndic;
  title: string = '';
  form: FormGroup;
  submit: boolean = false;
  edit: boolean = false;
  mandate: Mandate;
  owners ?: Owner[];
  isLoadingHouse = false;
  owner: Owner;
  ownerUuid ?: null;
  houses?: House[];
  house: House;
  ownerSelected?: any;
  required = Globals.required;

  booleanRow = [
    {label: 'NON', value: false},
    {label: 'OUI', value: true}
  ];

  constructor(
    public modal: NgbActiveModal,
    private mandateService: MandateService,
    private houseService: HouseService,
    public toastr: ToastrService,
    private formBuild: FormBuilder,
    private emitter: EmitterService,
    private syndicService: SyndicService
  ) {
    this.edit = this.mandateService.edit;
    this.mandate = this.mandateService.getMandate();
    this.syndic = this.syndicService.getSyndic();
    console.log('syndic', this.syndic);
    this.title = (!this.edit) ? 'Ajouter un mandat' : 'Modifier le mandat ' + this.mandate.type;
    this.newForm();
  }

  ngOnInit(): void {
    this.editForm();
  }

  newForm() {
    this.form = this.formBuild.group({
      uuid: [null],
      id: [null],
      syndic: [this.syndic.uuid],
      agency: [this.syndic.agency],
      isSigned: [false],
      type: ['SYNDIC', [Validators.required]],
      date: [null],
      dateD: [null],
      dateF: [null],
      honoraire: [0, [Validators.required]],
      intermediaire: [null],
      contactInter: [null]
    });
  }
  editForm() {
    if (this.edit) {
      const data = {...this.mandateService.getMandate()};
      data.date = DateHelperService.fromJsonDate(data.date);
      data.dateD = DateHelperService.fromJsonDate(data.dateD);
      data.dateF = DateHelperService.fromJsonDate(data.dateF);
      //this.setOwnerUuid(data?.house?.owner?.uuid);

      /*this.ownerSelected = {
        photoSrc: data?.house?.owner?.photoSrc,
        title: data?.house?.owner?.searchableTitle,
        detail: data?.house?.owner?.searchableDetail
      };*/
      this.form.patchValue(data);
    }
  }

  setRequirements() {

  }

  updateValididties() {

  }

  onSubmit() {
    this.submit = true;
    if (this.form.valid) {
      this.mandateService.addMandate(this.syndic.uuid, this.form.value).subscribe(
        res => {
          if (res?.status === 'success') {
            this.modal.dismiss();
            this.modal.close('ferme');
            this.emitter.emit({action: this.edit ? 'MANDATE_UPDATED' : 'MANDATE_ADD', payload: res?.data});
          }
          this.emitter.stopLoading();
        },
        error => {
          this.toast(error.message, 'Une erreur a été rencontrée', error.status);
        }
      );
    } else {
      return;
    }
  }
  onConfirme() {
    Swal.fire({
      title: '',
      text: 'Confirmez-vous l\'enregistrement ?',
      icon: 'warning',
      showCancelButton: true,
      showCloseButton: true,
      confirmButtonText: 'Confirmer <i class="fas fa-check"></i>',
      cancelButtonText: 'Annuler <i class="feather icon-x-circle"></i>',
      confirmButtonColor: '#1bc943',
      reverseButtons: true
    }).then((willDelete) => {
      if (!willDelete.dismiss) {
        this.onSubmit();
      }
    });
  }


  onClose(){
    this.form.reset()
    this.modal.close('ferme');
  }
  toast(msg, title, type) {
    if (type == 'info') {
      this.toastr.info(msg, title);
    } else if (type == 'success') {
      this.toastr.success(msg, title);
    } else if (type == 'warning') {
      this.toastr.warning(msg, title);
    } else if (type == 'error') {
      this.toastr.error(msg, title);
    }
  }
  get f() {return this.form.controls;}
}
