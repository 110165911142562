import { Terminate } from '@model/terminate';
import { Globals } from '@theme/utils/globals';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, Input, OnInit } from '@angular/core';
import { VALIDATION } from '@theme/utils/functions';
import { EmitterService } from '@service/emitter/emitter.service';
import { TerminateService } from '@service/terminate/terminate.service';
import { TerminateAddComponent } from '@locataire/terminate/terminate-add/terminate-add.component';
import { TerminateShowComponent } from '@locataire/terminate/terminate-show/terminate-show.component';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
  selector: 'app-terminate-list',
  templateUrl: './terminate-list.component.html',
  styleUrls: ['./terminate-list.component.scss']
})
export class TerminateListComponent implements OnInit {
  @Input() terminates: Terminate[];
  @Input() locataire = true;
  dtOptions: any = {};
  etat: boolean = false
  global = {country: Globals.country, device: Globals.device}
  userSession = Globals.user
  validation = VALIDATION
  total: number = 0;

  constructor(
    private modalService: NgbModal,
    private emitter: EmitterService,
    private terminateService: TerminateService,
    private permissionsService: NgxPermissionsService
  ) {
  }

  ngOnInit(): void {
    this.etat = this.terminates ? true : false;
    if(this.etat){
      this.terminates.forEach(item => {
        this.total += item?.invoice.montant
        return
      })
    }
    this.dtOptions = Globals.dataTable;
    this.emitter.event.subscribe((data) => {
      if (data.action === 'TERMINATE_ADD') {
        this.appendToList(data.payload);
      }
      if (data.action === 'TERMINATE_UPDATED') {
        this.update(data.payload);
      }
    });
  }

  appendToList(rent): void {
    this.terminates.unshift(rent);
  }
  update(row): void {
    const index = this.terminates.findIndex(x => x.uuid === row.uuid);
    if (index !== -1) {
      this.terminates[index] = row;
    }
  }
  editTerminate(row): void {
    this.terminateService.setTerminate(row);
    this.terminateService.edit = true;
    this.modal(TerminateAddComponent, 'modal-basic-title', 'xl', true, 'static');
  }
  showTerminate(row): void {
    this.terminateService.setTerminate(row);
    this.modal(TerminateShowComponent, 'modal-basic-title', 'xl', true, 'static')
  }
  printerTerminate(row): void {
    this.terminateService.getPrinter('SHOW', this.userSession?.agencyKey, this.userSession?.uuid, row?.uuid);
  }
  activateTerminate(row) {
    Swal.fire({
      title: '',
      text: 'Voulez-vous vraiment valider cette résiliation ?',
      icon: '',
      showCancelButton: true,
      showCloseButton: true,
      cancelButtonText: 'Annuler',
      confirmButtonText: 'Valider <i class="fas fa-check"></i>',
      confirmButtonColor: 'green',
      timer: 2500,
      reverseButtons: true
    }).then((willDelete) => {
      if (willDelete.dismiss) {
      } else {
          this.terminateService.activate(row).subscribe((res) => {
          const index = this.terminates.findIndex((x) => {
            return x.uuid === res?.data.uuid;
          });
          if (index !== -1) {
            this.terminates[index] = res?.data;
          }
        });
      }
    });
  }
  delete(terminate) {
    Swal.fire({
      title: '',
      text: 'Voulez-vous vraiment supprimer cet enrégistrement ?',
      icon: '',
      showCancelButton: true,
      showCloseButton: true,
      cancelButtonText: 'Annuler',
      confirmButtonText: 'Supprimer <i class="feather icon-trash"></i>',
      confirmButtonColor: '#d33',
      timer: 2500,
      reverseButtons: true
    }).then((willDelete) => {
      if (willDelete.dismiss) {
      } else {
        this.terminateService.getDelete(terminate.uuid).subscribe((res: any) => {
          if (res?.status === 'success') {
            const index = this.terminates.findIndex(x => x.id === terminate.id);
            if (index !== -1) {
              this.terminates.splice(index, 1);
            }
            Swal.fire('', 'La suppression a été éffectuée avec succès !', 'success');
          }
        });
      }
    });
  }
  modal(component, type, size, center, backdrop): void {
    this.modalService.open(component, {
      ariaLabelledBy: type,
      size: size,
      centered: center,
      backdrop: backdrop
    }).result.then((result) => {

    }, (reason) => {

    });
  }
}
