import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EmitterService } from '@service/emitter/emitter.service';
import { InvoiceCoService } from '@service/invoice-co/invoice-co.service';
import { InvoicePaymentService } from '@service/invoice-payment/invoice-payment.service';
import { ToastrService } from 'ngx-toastr';
import { UploaderService } from '@service/uploader/uploader.service';
import { InvoicePayment } from '@model/prestataire/invoice-payment';
import { DateHelperService } from '@theme/utils/date-helper.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { InvoiceCo } from '@model/prestataire/invoice-co';

@Component({
  selector: 'app-invoice-payment-add',
  templateUrl: './invoice-payment-add.component.html',
  styleUrls: ['./invoice-payment-add.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvoicePaymentAddComponent implements OnInit {
  edit: boolean;
  invoicePayment: InvoicePayment;
  title: string;
  form: any;
  invoice: InvoiceCo;
  currentInvoice: { title: string; detail: number; };
  file: any;
  folder: any;
  submit: boolean;

  constructor(
    public modal: NgbActiveModal,
    private formBuild: FormBuilder,
    private uploadService: UploaderService,
    private invoiceCoService: InvoiceCoService,
    private invoicePaymentService: InvoicePaymentService,

    private emitter: EmitterService,
    public toastr: ToastrService
  ) {
    this.edit = this.invoicePaymentService.edit
    this.invoicePayment = this.invoicePaymentService.getInvoicePayment()
    this.title = (!this.edit) ? "Ajouter un paiement" : "Modifier le paiement" + this.invoicePayment.code
    this.newForm()
  }

  ngOnInit(): void {
    this.editForm()
  }

  newForm() {
    this.form = this.formBuild.group({
      uuid: [null],
      id: [null],
      facture: [null, [Validators.required]],
      libelle: [null, [Validators.required]],
      date: [null, [Validators.required]],
      methode: [null, [Validators.required]],
      montant: [0],
      description: [null],
    });
  }
  editForm() {
    if (this.edit) {
      const data = { ...this.invoicePaymentService.getInvoicePayment() }
      this.setInvoiceUuid(data.invoice.uuid);
      data.date = DateHelperService.fromJsonDate(data?.date);
      this.invoice = data.invoice
      this.currentInvoice = {
        title: data?.invoice?.libelle,
        detail: data?.invoice?.montant
      };

      this.form.patchValue(data)
    }
  }
  setInvoiceUuid(uuid) {
    if (uuid) {
      this.f.facture.setValue(uuid);
    } else {
      this.f.facture.setValue(null);
    }
  }

  loadfile(data) {
    if (data && data !== null) {
      const file = data.todo.file
      this.file.push(
        this.formBuild.group({
          uniqId: [data.todo.uniqId, [Validators.required]],
          fileName: [file.name, [Validators.required]],
          fileSize: [file.size, [Validators.required]],
          fileType: [file.type, [Validators.required]],
          loaded: [data.todo.loaded, [Validators.required]],
          chunk: [data.chunk, [Validators.required]],
        })
      );
    }
  }
  files(data) {
    if (data && data !== null) {
      data.forEach(item => {
        this.folder.push(
          this.formBuild.group({
            uuid: [item?.uuid, [Validators.required]],
            name: [item?.name],
            path: [item?.path]
          })
        );
      });
    }
  }
  upload(files) {
    for (const file of files) {
      this.uploadService.upload(file);
    }
  }
  setParam(property, value) {
    if (value) {
      if (this.form.value.hasOwnProperty(property)) {
        Object.assign(this.form.value, { [property]: value });
      }
      if (this.form.controls.hasOwnProperty(property)) {
        this.form.controls[property].setValue(value);
      }
    }
  }
  onSubmit() {
    this.submit = true;
    if (!this.form.invalid) {
      const data = this.form.getRawValue();
      this.invoicePaymentService.add(data).subscribe(res => {
        if (res?.status === 'success') {
          this.modal.close('ferme');
          if (this.form.value.uuid) {
            this.emitter.emit({ action: 'INVOICE_CO_UPDATED', payload: res?.data });
          } else {
            this.emitter.emit({ action: 'INVOICE_CO_ADD', payload: res?.data });
          }
        }
      });
    } else { return; }
  }
  onConfirme() {
    Swal.fire({
      title: '',
      text: 'Confirmez-vous l\'enregistrement ?',
      icon: 'warning',
      showCancelButton: true,
      showCloseButton: true,
      confirmButtonText: 'Confirmer <i class="fas fa-check"></i>',
      cancelButtonText: 'Annuler <i class="feather icon-x-circle"></i>',
      confirmButtonColor: '#1bc943',
      reverseButtons: true
    }).then((willDelete) => {
      if (!willDelete.dismiss) {
        this.onSubmit();
      }
    });
  }
  onClose() {
    this.form.reset()
    this.modal.close('ferme');
  }
  toast(msg, title, type) {
    if (type == 'info') {
      this.toastr.info(msg, title)
    } else if (type == 'success') {
      this.toastr.success(msg, title)
    } else if (type == 'warning') {
      this.toastr.warning(msg, title)
    } else if (type == 'error') {
      this.toastr.error(msg, title)
    }
  }
  timelapse(dateD, dateF): string { return DateHelperService.getTimeLapse(dateD, dateF, false, 'dmy'); }
  get f() { return this.form.controls; }
}
