import { ProductionAddComponent } from '@chantier/production/production-add/production-add.component';
import { Component, OnInit } from '@angular/core';
import { Production } from '@model/production';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Globals } from '@theme/utils/globals';
import { VALIDATION } from '@theme/utils/functions';
import { ProductionService } from '@service/production/production.service';

@Component({
  selector: 'app-production-show',
  templateUrl: './production-show.component.html',
  styleUrls: ['./production-show.component.scss']
})
export class ProductionShowComponent implements OnInit {
  title: string = ""
  production: Production
  global = {country: Globals.country, device: Globals.device}
  userSession = Globals.user;
  validation = VALIDATION

  constructor(
    public modale: NgbActiveModal,
    private modalService: NgbModal,
    private productionService: ProductionService
  ) {
    this.production = this.productionService.getProduction()
    this.title = "Détails sur la realistaion du devis " + this.production?.code
  }

  ngOnInit(): void {
  }

  editProduction(row) {
    this.modalService.dismissAll()
    this.productionService.setProduction(row)
    this.productionService.edit = true
    this.modal(ProductionAddComponent, 'modal-basic-title', 'xl', true, 'static')
  }
  printerProduction(row): void {
    this.productionService.getPrinter('SHOW', this.userSession?.agencyKey, this.userSession?.uuid, row?.uuid);
  }
  modal(component, type, size, center, backdrop) {
    this.modalService.open(component, {
      ariaLabelledBy: type,
      size: size,
      centered: center,
      backdrop: backdrop
    }).result.then((result) => { }, (reason) => { });
  }
}
