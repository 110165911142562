// import { SelectModule } from 'ng-select';
import { ToastyModule } from 'ng2-toasty';
import { ChartModule } from 'angular2-chartjs';
import { CommonModule } from '@angular/common';
import { TextMaskModule } from 'angular2-text-mask';
import { ArchwizardModule } from 'angular-archwizard';
import { ClientModule } from '@client/client.module';
import { SharedModule } from '@shared/shared.module';
import { DataTablesModule } from 'angular-datatables';
import { HttpClientModule } from "@angular/common/http";
import { FileUploadModule } from '@iplab/ngx-file-upload';
import { LocataireModule } from '@locataire/locataire.module';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { DemandeModule } from '@agence/demande/demande.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChantierModule } from '@agence/chantier/chantier.module';
import { AngularHighchartsChartModule } from 'angular-highcharts-chart';
import { ProprietaireModule } from '@proprietaire/proprietaire.module';
import { SpentAddComponent } from './spent/spent-add/spent-add.component';
import { SpentListComponent } from './spent/spent-list/spent-list.component';
import { SpentShowComponent } from './spent/spent-show/spent-show.component';
import { TresorerieRoutingModule } from '@tresorerie/tresorerie-routing.module';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { DayAddComponent } from '@agence/tresorerie/day/day-add/day-add.component';
import { DayEndComponent } from '@agence/tresorerie/day/day-end/day-end.component';
import { DayListComponent } from '@agence/tresorerie/day/day-list/day-list.component';
import { DayShowComponent } from '@agence/tresorerie/day/day-show/day-show.component';
import { SupplyAddComponent } from '@agence/tresorerie/supply/supply-add/supply-add.component';
import { SupplyListComponent } from '@agence/tresorerie/supply/supply-list/supply-list.component';
import { SupplyShowComponent } from '@agence/tresorerie/supply/supply-show/supply-show.component';
import { TreasuryAddComponent } from '@agence/tresorerie/treasury/treasury-add/treasury-add.component';
import { TreasuryListComponent } from '@agence/tresorerie/treasury/treasury-list/treasury-list.component';
import { TreasuryShowComponent } from '@agence/tresorerie/treasury/treasury-show/treasury-show.component';
import { ConfirmationAddComponent } from '@agence/tresorerie/confirmation/confirmation-add/confirmation-add.component';
import { NgbTooltipModule, NgbDatepickerModule, NgbDropdownModule, NgbCarouselModule, NgbTabsetModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxPermissionsModule } from 'ngx-permissions';
import { AngularDualListBoxModule } from 'angular-dual-listbox';
import { TransactionListComponent } from '@agence/tresorerie/transaction/transaction-list/transaction-list.component';
import { TransactionShowComponent } from '@agence/tresorerie/transaction/transaction-show/transaction-show.component';
import { TrusteeSpentAddComponent } from './spent/trustee-spent-add/trustee-spent-add.component';
import { TrusteeSpentListComponent } from './spent/trustee-spent-list/trustee-spent-list.component';
import { TrusteeSpentShowComponent } from './spent/trustee-spent-show/trustee-spent-show.component';
import { TrusteeTreasuryShowComponent } from '@agence/tresorerie/treasury/trustee-treasury-show/trustee-treasury-show.component';
import { TrusteeSupplyAddComponent } from '@agence/tresorerie/supply/trustee-supply-add/trustee-supply-add.component';
import { TrusteeSupplyListComponent } from '@agence/tresorerie/supply/trustee-supply-list/trustee-supply-list.component';
import { TrusteeFundsPaymentListComponent } from './trustee-funds-payment/trustee-funds-payment-list/trustee-funds-payment-list.component'
import { TrusteeFundsPaymentShowComponent } from './trustee-funds-payment/trustee-funds-payment-show/trustee-funds-payment-show.component'

@NgModule({
  declarations: [
    SupplyAddComponent,
    SupplyListComponent,
    SupplyShowComponent,
    ConfirmationAddComponent,
    DayAddComponent,
    DayListComponent,
    DayEndComponent,
    DayShowComponent,
    SpentAddComponent,
    SpentListComponent,
    SpentShowComponent,
    TreasuryAddComponent,
    TreasuryListComponent,
    TreasuryShowComponent,
    TransactionListComponent,
    TransactionShowComponent,
    TrusteeSpentAddComponent,
    TrusteeSpentListComponent,
    TrusteeSpentShowComponent,
    TrusteeTreasuryShowComponent,
    TrusteeSupplyAddComponent,
    TrusteeSupplyListComponent,
    TrusteeFundsPaymentListComponent,
    TrusteeFundsPaymentShowComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    NgbTooltipModule,
    NgbDatepickerModule,
    NgbDropdownModule,
    ReactiveFormsModule,
    AngularHighchartsChartModule,
    FormsModule,
    FileUploadModule,
    TextMaskModule,
    ToastyModule,
    NgbTabsetModule,
    NgbCarouselModule,
    TresorerieRoutingModule,
    SharedModule,
    FormsModule,
    DataTablesModule,
    ArchwizardModule,
    // SelectModule,
    LocataireModule,
    ProprietaireModule,
    ClientModule,
    DemandeModule,
    ChantierModule,
    ChartModule,
    AngularDualListBoxModule,
    BsDatepickerModule.forRoot(),
    NgxPermissionsModule.forRoot()
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ]
})
export class TresorerieModule { }
