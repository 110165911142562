<div class="modal-header">
    <h5 class="modal-title">{{title}}</h5>
    <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
        (click)="modal.close('ferme')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<form autocomplete="off" [formGroup]="form" (ngSubmit)="onConfirme()">
    <div class class="modal-body">
        <div class="col-md-12">
            <!-- DETAILS SUR LES OPTIONS -->
            <div class="row">
                <span class="badge badge-primary my-2 f-14 width">DETAILS SUR LE PAIEMENT</span>
            </div>
            <div class="form-group">
                <div class="row mb-2">
                    <div class="col-md-3">
                        <label for="libelle">Libellé <span class="asterix">*</span></label>
                        <input formControlName="libelle" class="form-control" id="libelle" [ngClass]="{
                  'is-invalid': submit && f.libelle.errors,
                  'is-valid': submit && f.libelle .valid
                }" placeholder="Libellé">
                        <div class="invalid-feedback" *ngIf="submit && f.libelle.errors">
                            <div *ngIf="f.libelle.errors.required">{{required.novide}}</div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <label>Facture <span class="asterix">*</span></label>
                        <app-entity-finder [class]="'InvoiceCo'" [namespace]="'Provider'" [groups]="['invoiceCo']" [required]="false"
                            [selected]="currentInvoice" [disabled]="this.edit" (uuid)="setInvoiceUuid($event)">
                        </app-entity-finder>
                    </div>
                    <div class="col-md-3">
                        <label for="montant">Montant<span class="asterix">*</span></label>
                        <input type="number" class="form-control" formControlName="montant" id="montant" [ngClass]="{
                  'is-invalid': submit && f.montant.errors,
                  'is-valid': submit && f.montant.valid
                   }" placeholder="Budget estimative">
                        <div class="invalid-feedback" *ngIf="submit && f.montant.errors">
                            <div *ngIf="f.montant.errors.required">{{required.novide}}</div>
                            <div *ngIf="f.montant.errors.pattern">{{required.nolettre}}</div>
                            <div *ngIf="f.montant.errors.pattern">{{required.positive}}</div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <label for="date ">Date <span class="asterix">*</span></label>
                        <input formControlName="date" class="form-control" id="date" type="date" [ngClass]="{
                'is-invalid': submit && f.date.errors,
                'is-valid': submit && f.date .valid
              }">
                        <div class="invalid-feedback" *ngIf="submit && f.date.errors">
                            <div *ngIf="f.date.errors.required">{{required.novide}}</div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <label for="date ">Methode de payement <span class="asterix">*</span></label>
                        <select formControlName="methode" class="form-control" id="methode" [ngClass]="{
                  'is-invalid': submit && f.methode.errors,
                  'is-valid': submit && f.methode.valid
                }">
                            <option [value]="'ESPECE'">ESPECE</option>
                            <option [value]="'CHEQUE'">CHEQUE</option>
                            <option [value]="'VIREMENT'">VIREMENT</option>
                            <option [value]="'MOBILE_MONEY'">MOBILE MONEY</option>
                            <option [value]="'WAVE'">WAVE</option>
                            <option [value]="'CARTE'">CARTE</option>
                            <option [value]="'ORANGE'">ORANGE</option>
                            <option [value]="'MTN'">MTN</option>
                            <option [value]="'MTN'">MOOV</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row">
                <span class="badge badge-primary my-2 f-14 width">AUTRE</span>
            </div>
            <div class="form-group">
                <div class="col-md-12">
                    <label for="description">Description </label>
                    <textarea class="form-control" name="description" id="description" formControlName="description"
                        placeholder="Description"></textarea>
                </div>
            </div>
            <div class="row">
                <div class="col-md">
                    <label>Pièces et documents à joindre</label>
                    <app-folder-uploader (filesd)="files($event)" (filesUploaded)="setParam('folderUuid',$event)"
                        [path]="'invoice'" [etat]="edit ? 'edit': 'add'"
                        [folder]="edit && invoicePayment ? invoicePayment?.folder : null">
                    </app-folder-uploader>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button (click)="modal.close('ferme')" type="button" class="btn btn-secondary text-left" data-dismiss="modal">
            Fermer <i class="feather icon-x-circle"></i>
        </button>
        <button (click)="form.reset()" type="button" class="btn btn-warning">
            Vider <i class="fas fa-broom"></i>
        </button>
        <button [disabled]="form.invalid" type="submit" class="btn btn-primary">
            Enregistrer <i class="feather icon-save"></i>
        </button>
    </div>
</form>