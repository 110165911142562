import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EmitterService } from '@service/emitter/emitter.service';
import {SendService} from '@service/send/send.service';

@Component({
  selector: 'app-send',
  templateUrl: './send.component.html',
  styleUrls: ['./send.component.scss']
})
export class SendComponent implements OnInit {

  form: FormGroup;
  submit: boolean = false;
  sends: any[]
  dataShow: any
  public isCollapsed: boolean = false;
  public isMail: string = 'inbox' ;
  public isSubMail: string = 'primary';
  public basicContent: string = '<p>Put your things hear...</p>';

  typeRow = [
    {value: "MAIL", label: "MAIL"},
    {value: "SMS", label: "SMS"},
  ];

  constructor(
    private formBuild: FormBuilder,
    private emitter: EmitterService,
    private sendService: SendService
  ) {
    this.sendService.getList('MAIL', 'ENVOYE').subscribe(res => {
     this.sends = res
     return this.sends
    })
    
   this.newForm()
  }
  
  ngOnInit(): void {
  }

  newForm(){
    this.form = this.formBuild.group({
      id: [null],
      uuid: [null],
      type: ['MAIL', [Validators.required]],
      destinataire: [null, [Validators.required]],
      objet: [null],
      content: [null, [Validators.required]]
    })
  }
  onChange(type: string, option: string, etat: string){
    this.isSubMail = option
    this.sendService.getList(type, etat).subscribe(res => {
      this.sends = res
      return this.sends
    })
  }
  onChanging(type: string, option: string, etat: string){
    this.isMail = option
    if(type === 'MAIL'){
      this.isSubMail = 'primary'
    }
    this.sendService.getList(type, etat).subscribe(res => {
      this.sends = res
      return this.sends
    })
  }
  onCorbeille(item, type){
    const data = {uuid: item.uuid, type: type}
    this.sendService.corbeille(data).subscribe(res => {
      if (res.status === 'success') {
          const index = this.sends.findIndex((x) => {
            return x.uuid === item.uuid;
          });
          if (index !== -1) {
            this.sends.splice(index, 1);
          }
        }
    })
  }
  delete(row){
    this.sendService.getDelete(row.uuid).subscribe(res => {
      if (res.status === 'success') {
        const index = this.sends.findIndex((x) => {
          return x.uuid === row.uuid;
        });
        if (index !== -1) {
          this.sends.splice(index, 1);
        }
      }
    })
  }
  onSend(item){
    this.sendService.sendBack(item).subscribe(res => {
      if (res.status === 'success') {
        const index = this.sends.findIndex((x) => {
          return x.uuid === item.uuid;
        });
        if (index !== -1) {
          this.sends.splice(index, 1);
        }
      }
    })
  }
  onShow(item){
    this.isMail = 'show'
    this.dataShow = item
  }

  onSubmit(){
    this.submit = true;
    if (this.form.valid) {
      this.sendService.add(this.form.getRawValue()).subscribe(res => {
        if (res?.status === 'success'){
          this.emitter.emit({action: 'SEND_ADD', payload: res?.data});
          this.isMail = 'sent'
          this.sendService.getList('MAIL', 'ENVOYE').subscribe(res => {
            this.sends = res
            return this.sends
           })
        }
      }, error => {});
    } else {
      return;
    }
  }

  onSubStringLongName(str: string): any {
    if (str !== null) {
      if (str.length > 50) {
        return str.substring(0, 50) + ' ...';
      } else {
        return str;
      }
    } else {
      return '';
    }
  }

  get f() {return this.form.controls;}

}
