import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MandateSyndicService } from '@service/syndic/mandate-syndic.service';
import { Globals } from '@theme/utils/globals';
import { MandateAddComponent } from '../mandate-add/mandate-add.component';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { MandateShowComponent } from '../mandate-show/mandate-show.component';
import { EmitterService } from '@service/emitter/emitter.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-mandate-list',
  templateUrl: './mandate-list.component.html',
  styleUrls: ['./mandate-list.component.scss']
})
export class MandateListComponent implements OnInit {

  @Input() mandats: any[] = []
  dtOptions: any
  userSession = Globals.user

  constructor(
    private modalService: NgbModal,
    private router: Router,
    public route: ActivatedRoute,
    private mandateService: MandateSyndicService,
    private emitter: EmitterService
  ) { }

  ngOnInit(): void {
    this.dtOptions = Globals.dataTable
  }

  showMandate(item){
    this.mandateService.setMandat(item)
    this.mandateService.edit = false
    this.modal(MandateShowComponent, 'modal-basic-title', 'xl', true, 'static')
  }

  editMandate(item){
    this.mandateService.setMandat(item)
    this.mandateService.edit = true
    this.modal(MandateAddComponent, 'modal-basic-title', 'xl', true, 'static')
  }

  printMandate(item){
    this.mandateService.getPrinter('SHOW', this.userSession?.agencyKey, this.userSession?.uuid, null, item?.trustee?.uuid, item.uuid);
  }

  delete(item) {
    Swal.fire({
      title: '',
      text: 'Voulez-vous vraiment supprimer cet enrégistrement ?',
      icon: '',
      showCancelButton: true,
      showCloseButton: true,
      cancelButtonText: 'Annuler',
      confirmButtonText: 'Supprimer <i class="feather icon-trash"></i>',
      confirmButtonColor: '#d33',
      timer: 2500,
      reverseButtons: true
    }).then((willDelete) => {
      if (willDelete.dismiss) {
      } else {
        this.mandateService.getDelete(item?.uuid).subscribe((res: any) => {
          if (res?.status === 'success') {
            const index = this.mandats.findIndex(x => x.id === item.id);
            if (index !== -1) { this.mandats.splice(index, 1); }
            Swal.fire('', 'La suppression a été éffectuée avec succès !', 'success');
          }
        });
      }
    });
  }

  onValidate(item) {
    Swal.fire({
      title: '',
      text: 'Voulez-vous valider ce mandat ?',
      icon: 'warning',
      showCancelButton: true,
      showCloseButton: true,
      confirmButtonText: 'Confirmer <i class="fas fa-check"></i>',
      cancelButtonText: 'Annuler <i class="feather icon-x-circle"></i>',
      confirmButtonColor: '#1bc943',
      reverseButtons: true
    }).then((willDelete) => {
      if (!willDelete.dismiss) {
        this.mandateService.validate(item.uuid).subscribe((res: any) => {
          this.emitter.loading()
          if (res?.status === 'success') {
            this.mandateService.getList(item.trustee.uuid, null).subscribe((res: any) => {
              return this.mandats = res
            })
            this.emitter.stopLoading()
          }
        })
      }
    });
  }

  modal(component, type, size, center, backdrop) {
    this.modalService.open(component, {
      ariaLabelledBy: type,
      size: size,
      centered: center,
      backdrop: backdrop
    }).result.then((result) => {
      if(result == 'MANDAT'){
        this.mandateService.getList(null).subscribe((res: any) => {
          return this.mandats = res
        })
      }
   }, (reason) => { });
  }

  onClose(){
    this.modalService.dismissAll();
  }


}
