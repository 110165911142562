import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-compte-tva-show',
  templateUrl: './compte-tva-show.component.html',
  styleUrls: ['./compte-tva-show.component.scss']
})
export class CompteTvaShowComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
