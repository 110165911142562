<div class="user-about-block align-center row">
    <div class="col-md-1"></div>
    <div class="col-md-10 py-4">
      <app-alert type="secondary">
        <div class="text-center item-image-box col-lg-12">
          <div class="item-image ml-2">
            <i class="fas fa-lock" id="image-oupps"></i>
          </div>
        </div>
        <div class="text-center item-info col-lg-12">
          <div class="ad-info">
            <h1>Aucune autorisation </h1>
            <h3>Désolé vous n'avez pas accès à l'interface {{ title }}</h3>
          </div>
        </div>
      </app-alert>
    </div>
    <div class="col-md-1"></div>
</div>
