import { House } from '@model/house';
import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Globals } from '@theme/utils/globals';
import { ActivatedRoute, Router } from '@angular/router';
import { HouseService } from '@service/house/house.service';
import { environment } from '@env/environment';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { HouseAddComponent } from '@proprietaire/house/house-add/house-add.component';
import { PaymentCustomer } from '@model/payment-customer';
import { HouseCo } from '@model/syndic/house-co';

@Component({
  selector: 'app-house-show',
  templateUrl: './house-show.component.html',
  styleUrls: ['./house-show.component.scss']
})
export class HouseShowComponent implements OnInit {
  public viewImage: number;
  house: House;
  files = [];
  payments: PaymentCustomer[]
  publicUrl = environment.publicUrl;
  global = {country: Globals.country, device: Globals.device}
  userSession = Globals.user;
  total = 0;

  constructor(
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private houseService: HouseService,
    private router: Router,
  ) {
    this.viewImage = 1;
    this.houseService.getSingle(this.route.snapshot.params.id).subscribe((res: any) => {
      if(res){
        if(res?.folder?.files.length > 0){
          res?.folder?.files.forEach((file, index) =>{
            if(index < 5){
              this.files.push(file);
            }
          });
        }
        this.house = res;
        this.payments = this.house?.folderCustomer?.invoice?.payments;
        this.payments.forEach(item => { return this.total = this.total + item?.montant })
        return this.house;
      }
    });
  }

  ngOnInit(): void {
  }

  editHouse(row) {
    this.houseService.setHouse(row);
    this.houseService.edit = true;
    this.houseService.disponible = row?.disponible;
    this.modal(HouseAddComponent, 'modal-basic-title', 'xl', true, 'static');
  }
  printerHouse(row): void {
    this.houseService.getPrinter('SHOW', this.userSession?.agencyKey, this.userSession?.uuid, row?.uuid);
  }
  back(){ this.router.navigate(['/admin/proprietaire']) }
  delete(item) {
    Swal.fire({
      title: '',
      text: 'Voulez-vous vraiment supprimer cet enrégistrement ?',
      icon: '',
      showCancelButton: true,
      showCloseButton: true,
      cancelButtonText: 'Annuler',
      confirmButtonText: 'Supprimer <i class="feather icon-trash"></i>',
      confirmButtonColor: '#d33',
      timer: 2500,
      reverseButtons: true
    }).then((willDelete) => {
      if (willDelete.dismiss) { }
      else {
        this.houseService.getDelete(item.uuid).subscribe((res: any) => {
          if (res?.status === 'success') {this.router.navigate(['/admin/proprietaire'])}
        });}
    });
  }
  modal(component, type, size, center, backdrop) {
    this.modalService.open(component, {
      ariaLabelledBy: type,
      size: size,
      centered: center,
      backdrop: backdrop
    }).result.then((result) => { }, (reason) => { });
  }
}
