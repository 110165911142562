<div class="row">
    <div class="col-sm-12">
      <div class="card email-card">
        <div class="card-header">
            <div class="mail-header">
              <div class="row align-items-center">
                <!-- [ inbox-left section ] start -->
                <div class="col-xl-2 col-md-3">
                  <a href="javascript:" class="b-brand">
                    <div class="b-bg">
                        <i class="fas fa-mailbox"></i> <i class="fas fa-message-sms"></i>
                    </div>
                    <span class="b-title text-muted">SMS / MAIL</span>
                  </a>
                </div>
                <!-- [ inbox-left section ] end -->
                <!-- [ inbox-right section ] start -->
                <div class="col-xl-10 col-md-9">
                  <div class="input-group mb-0"></div>
                </div>
                <!-- [ inbox-right section ] end -->
              </div>
            </div>
        </div>
        <div class="card-body">
            <div class="mail-body">
                <div class="row">
                    <div class="col-xl-3 col-md-3">
                        <div class="mb-3">
                          <a [ngClass]="{'active': isMail === 'new'}" (click)="isMail = 'new'" class="btn btn-primary rounded p-2"><i class="fas fa-pen font-size-xxl"></i> Nouveau message</a>
                        </div>
                        <ul class="mb-2 nav nav-tab flex-column nav-pills" role="tablist" aria-orientation="vertical">
                          <li class="nav-item mail-section">
                            <a class="nav-link text-left" [ngClass]="{'active': isMail === 'sent'}" (click)="onChanging('MAIL', 'sent', 'ENVOYE')" data-toggle="pill" href="javascript:" role="tab">
                              <span><i class="feather icon-navigation"></i> Messages envoyés</span>
                            </a>
                          </li>
                          <li class="nav-item mail-section">
                            <a class="nav-link text-left" [ngClass]="{'active': isMail === 'waiting'}" (click)="onChanging('TOUT', 'waiting', 'ATTENTE')" data-toggle="pill" href="javascript:" role="tab">
                              <span><i class="fas fa-clock"></i> En attente</span>
                            </a>
                          </li>
                          <li class="nav-item mail-section">
                            <a class="nav-link text-left" [ngClass]="{'active': isMail === 'trash'}" (click)="onChanging('TOUT', 'trash', 'CORBEILLE')" data-toggle="pill" href="javascript:" role="tab">
                              <span><i class="feather icon-trash-2"></i> Corbeille</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div class="col-xl-9 col-md-9 inbox-right content-body">
                        <div class="tab-content p-0">
                            <!-- BLOC D'ENVOI SMS OU MAIL -->
                            <div class="tab-pane fade" [ngClass]="{'show active': isMail === 'new'}" role="tabpanel">
                                <div class="tab-content" id="v-pills-tabContent">
                                    <div class="mail-body-content">
                                        <form [formGroup]="form" (ngSubmit)="onSubmit()">
                                            <div class="form-material">
                                                <div class="form-group">
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <label for="type">Type d'envoi<span class="asterix mr-2">*</span></label>
                                                            <select class="form-control" id="type" formControlName="type" 
                                                                [ngClass]="{ 'is-invalid': submit && f.type.errors,'is-valid': submit && f.type.valid}">
                                                                <option *ngFor="let item of typeRow" [value]="item?.value">{{item?.label}}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <div class="form-group">
                                                                <label for="destinataire">{{f.type.value === 'MAIL' ? 'À' : 'Numéro'}} <span class="asterix mr-2">*</span></label>
                                                                <input type="email" class="form-control" formControlName="destinataire" id="destinataire" placeholder="{{f.type.value === 'MAIL' ? 'Email destinataire' : 'Numéro destinataires'}}"
                                                                [ngClass]="{ 'is-invalid': submit && f.destinataire.errors, 'is-valid': submit && f.destinataire.valid }">
                                                                <div class="invalid-feedback" *ngIf="submit && f.destinataire.errors">
                                                                    <div *ngIf="f.destinataire.errors.required">{{required.novide}}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <label for="objet">Objet</label>
                                                    <input type="text" class="form-control" formControlName="objet" id="objet" placeholder="Objet"
                                                    [ngClass]="{ 'is-invalid': submit && f.objet.errors, 'is-valid': submit && f.objet.valid }">
                                                    <div class="invalid-feedback" *ngIf="submit && f.objet.errors">
                                                        <div *ngIf="f.objet.errors.required">{{required.novide}}</div>
                                                    </div>
                                                </div>
                                                <app-tinymce formControlName="content" id="content" #tinymce='tinymce' aria-placeholder="kkkkk"></app-tinymce>
                                                <div class="float-right mt-3">
                                                    <button [disabled]="form.invalid" type="submit" class="btn waves-effect waves-light btn-primary">Envoyer <i class="ion-md-send"></i></button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <!-- BLOC ATTENTE DE SMS ET MAIL  -->
                            <div class="tab-pane fade" [ngClass]="{'show active': isMail === 'waiting'}" role="tabpanel">
                                <p class="mb-0">
                                <ul class="nav nav-pills nav-fill mb-0" role="tablist">
                                    <li class="nav-item">
                                        <a class="nav-link" [ngClass]="{'active': isSubMail === 'primary'}" (click)="onChange('TOUT', 'primary', 'ATTENTE')" data-toggle="pill" href="javascript:" role="tab" aria-selected="true">
                                            <span><i class="fas fa-clock"></i>En attente</span>
                                        </a>
                                    </li>
                                </ul>
                                <div class="tab-pane fade show active" id="pills-starred" role="tabpanel">
                                <div class="mail-body-content table-responsive">

                                    <div *ngIf="sends && sends.length === 0">
                                        <span class="d-flex justify-content-center align-items-center mt-2 bold">Aucun message en attente.</span>
                                        <hr class="my-1">
                                    </div>
                                    <table *ngIf="sends && sends.length > 0" class="table">
                                        <tbody>
                                            <tr *ngFor="let send of sends" class="unread" (click)="onShow(send)">
                                                <td>
                                                    <div class="check-star">
                                                        <div class="form-group d-inline">
                                                            <div class="checkbox checkbox-primary checkbox-fill d-inline">
                                                                <input type="checkbox" name="checkbox-s-in-49" (change)="onSelect($event.target)">
                                                                <label for="checkbox-s-infill-49" class="cr"></label>
                                                            </div>
                                                        </div>
                                                        <a href="javascript:"><i class="feather icon-star-on text-c-yellow ml-2"></i></a>
                                                    </div>
                                                </td>
                                                <td (click)="onShow(send)"><a [routerLink]="" class="email-name waves-effect">{{send?.destinataire}}</a></td>
                                                <td (click)="onShow(send)" [innerHTML]=onSubStringLongName(send?.content) ></td>
                                                <td (click)="onShow(send)" class="email-time">{{send?.createdAt| date: "d MMMM y 'à' H:mm:ss " : '' : 'fr-FR'}}</td>
                                                <td class="btn waves-light btn-icon btn-rounded btn-outline-secondary" tooltip="Placer dans la corbeille" (click)="onCorbeille(send, 'CORBEILLE')">
                                                    <i class="feather icon-trash-2"></i>
                                                </td>
                                                <td class="btn waves-light btn-icon btn-rounded btn-outline-secondary" tooltip="Renvoyer" (click)="onSend(send)">
                                                    <i class="feather icon-navigation"></i>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                </div>
                            </div>
                            <!-- BLOC SMS ET MAIL ENVOYES  -->
                            <div class="tab-pane fade" [ngClass]="{'show active': isMail === 'sent'}" role="tabpanel">
                                <div class="mail-body-content table-responsive">
                                    <ul class="nav nav-pills nav-fill mb-0" role="tablist">
                                        <li class="nav-item">
                                            <a class="nav-link" [ngClass]="{'active': isSubMail === 'primary'}" (click)="onChange('MAIL', 'primary', 'ENVOYE')" id="pills-primary-tab" data-toggle="pill" href="javascript:" role="tab" aria-controls="pills-primary" aria-selected="true">
                                            <span><i class="feather icon-navigation"></i> Mail envoyés</span>
                                            </a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" [ngClass]="{'active': isSubMail === 'secondary'}" (click)="onChange('SMS', 'secondary', 'ENVOYE')" id="pills-primary-tab" data-toggle="pill" href="javascript:" role="tab" aria-controls="pills-primary" aria-selected="true">
                                            <span><i class="feather icon-navigation"></i> SMS envoyés</span>
                                            </a>
                                        </li>
                                    </ul>
                                    <div *ngIf="sends && sends.length === 0">
                                        <span *ngIf="isSubMail === 'primary'" class="d-flex justify-content-center align-items-center mt-2 bold">Aucun mail envoyé pour l'instant.</span>
                                        <span *ngIf="isSubMail === 'secondary'" class="d-flex justify-content-center align-items-center mt-2 bold">Aucun sms envoyé pour l'instant.</span>
                                    </div>
                                    <table *ngIf="sends && sends.length > 0" class="table">
                                        <tbody>
                                            <tr *ngFor="let send of sends" class="unread" (click)="onShow(send)">
                                                <td>
                                                    <div class="check-star">
                                                        <div class="form-group d-inline">
                                                            <div class="checkbox checkbox-primary checkbox-fill d-inline">
                                                                <input type="checkbox" name="checkbox-s-in-49">
                                                                <label for="checkbox-s-infill-49" class="cr"></label>
                                                            </div>
                                                        </div>
                                                        <a href="javascript:"><i class="feather icon-star-on text-c-yellow ml-2"></i></a>
                                                    </div>
                                                </td>
                                                <td (click)="onShow(send)"><a class="email-name waves-effect">{{send?.destinataire}}</a></td>
                                                <td (click)="onShow(send)" [innerHTML]=onSubStringLongName(send?.content) ></td>
                                                <td (click)="onShow(send)" class="email-time">{{send?.createdAt| date: "d MMMM y 'à' H:mm:ss " : '' : 'fr-FR'}}</td>
                                                <td class="btn waves-light btn-icon btn-rounded btn-outline-secondary" tooltip="Placer dans la corbeille" (click)="onCorbeille(send, 'CORBEILLE')">
                                                    <i class="feather icon-trash-2"></i>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <!-- BLOC CORBEILLE  -->
                            <div class="tab-pane fade" [ngClass]="{'show active': isMail === 'trash'}" role="tabpanel">
                                <div class="mail-body-content table-responsive">
                                    <ul class="nav nav-pills nav-fill mb-0" id="pills-tab" role="tablist">
                                    <li class="nav-item">
                                        <a class="nav-link" [ngClass]="{'active': isSubMail === 'primary'}" (click)="onChange('TOUT', 'primary', 'CORBEILLE')" id="pills-primary-tab" data-toggle="pill" href="javascript:" role="tab" aria-controls="pills-primary" aria-selected="true">
                                        <span><i class="feather icon-trash-2"></i> Corbeille</span>
                                        </a>
                                    </li>
                                    </ul>
                                    <div *ngIf="sends && sends.length === 0">
                                        <span class="d-flex justify-content-center align-items-center mt-2 bold">Aucun massage dans la corbeille.</span>
                                        <hr class="my-1">
                                    </div>
                                    <table *ngIf="sends && sends.length > 0" class="table">
                                        <tbody>
                                            <tr *ngFor="let send of sends" class="unread">
                                                <td>
                                                    <div class="check-star">
                                                        <div class="form-group d-inline">
                                                            <div class="checkbox checkbox-primary checkbox-fill d-inline">
                                                                <input type="checkbox" name="checkbox-s-in-49">
                                                                <label for="checkbox-s-infill-49" class="cr"></label>
                                                            </div>
                                                        </div>
                                                        <a href="javascript:"><i class="feather icon-star-on text-c-yellow ml-2"></i></a>
                                                    </div>
                                                </td>
                                                <td (click)="onShow(send)"><a class="email-name waves-effect">{{send?.destinataire}}</a></td>
                                                <td (click)="onShow(send)" [innerHTML]=onSubStringLongName(send?.content)></td>
                                                <td (click)="onShow(send)" class="email-time">{{send?.createdAt| date: "d MMMM y 'à' H:mm:ss " : '' : 'fr-FR'}}</td>
                                                <td class="btn waves-light btn-icon btn-rounded btn-outline-secondary" tooltip="Restaurer depuis la corbeille" (click)="onCorbeille(send, 'RESTAURER')">
                                                    <i class="fas fa-recycle"></i>
                                                </td>
                                                <td class="btn waves-light btn-icon btn-rounded btn-outline-secondary" tooltip="Placer dans la corbeille" (click)="delete(send)">
                                                    <i class="feather icon-trash-2"></i>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <!--BLOC DETAIL DU MAIL ET SMS-->
                            <div class="tab-pane fade" [ngClass]="{'show active': isMail === 'show'}" role="tabpanel">
                                <div class="m-2 border-1 rounded m-1 p-1">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <span>Type d'envoi </span>
                                                </div>
                                                <div class="col-md-6">
                                                    <span class="bold">
                                                        {{dataShow?.type === 'MAIL' ? 'Mail' : 'Sms'}}
                                                    </span>
                                                </div>
                                            </div>
                                            <hr class="my-1">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <span>Etat de l'envoi </span>
                                                </div>
                                                <div class="col-md-6">
                                                    <span class="bold">
                                                        {{dataShow?.etat === 'ENVOYE' ? 'Envoyé' : 'En attente'}}
                                                    </span>
                                                </div>
                                            </div>
                                            <hr class="my-1">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <span>Destinataire</span>
                                                </div>
                                                <div class="col-md-6">
                                                    <span class="bold">
                                                        {{dataShow?.destinataire}}
                                                    </span>
                                                </div>
                                            </div>
                                            <hr class="my-1">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <span>Date d'envoi</span>
                                                </div>
                                                <div class="col-md-6">
                                                    <span class="bold">
                                                        {{dataShow?.createdAt| date: "d MMMM y 'à' H:mm:ss " : '' : 'fr-FR'}}
                                                    </span>
                                                </div>
                                            </div>
                                            <hr class="my-1">
                                        </div>
                                        <div class="col-md-6"></div>
                                    </div>
                                    <h4 *ngIf="dataShow?.type === 'MAIL'">Objet : {{dataShow?.objet}}</h4>
                                    <h5>Contexte du message : </h5>
                                    <div [innerHTML]=dataShow?.content></div>
                                </div>
                            </div>
                        </div>
                      </div>
                </div>
            </div>
        </div>
      </div>
    </div>
</div>