<div class="modal-header">
    <h5 class="modal-title">{{title}}</h5>
    <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
      (click)="modale.close('ferme')">
      <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
  <span class="badge badge-primary mb-4 f-14 width"> DETAIL SUR L'AVIS D'ECHEANCE </span>
  <div class="col-md-12">
      <div class="row">
          <div class="col-md-3">
              <span>Code :</span>
          </div>
          <div class="col-md-9">
              <span class="bold">
                  {{fundsNotice?.code}}
              </span>
          </div>
      </div>
      <hr class="my-2">
  </div>
</div>
<div class="modal-footer">
    <button (click)="modale.close('ferme')" type="button" class="btn btn-secondary text-left" data-dismiss="modal">
      Fermer <i class="feather icon-x-circle"></i>
    </button>
</div>