<div class="col-md-12" *ngIf="buildings.length > 0">
  <span class="badge badge-info mb-4 f-14 width">LISTE DES BÂTIMENT</span>

  <div class="row">
    <div class="col-lg-4 col-md-4" *ngFor="let item of buildings">
      <app-card [customHeader]="true" headerClass="border-0 p-2 pb-0" [options]="false" cardClass="user-card user-card-1" blockClass="pt-0 users-contact">
        <div class="app-card-header">
          <div class="cover-img-block">
            <img [src]="item?.photoSrc ? publicUrl+'/'+item.photoSrc : 'assets/images/building.png'" alt="{{ item?.libelle }}"
              title="{{ item?.libelle }}" class="img-fluid size-img-house">
          </div>
        </div>
        <div class="text-center">
          <h5 class="mb-1 mt-3">Nom: {{ item?.libelle }} - {{ item?.niveau }} Étage{{ item?.niveau > 1 ? 's' : '' }}</h5>
          <h6 class="mb-1 mt-3">Promotion: {{ item?.promotion?.libelle }} </h6>
        </div>
        <div class="row align-items-end mb-2">
          <div class="col text-left">
            <p class="mb-1 text-muted">
              Superficie : {{ item?.superficie }} m²
            </p>
          </div>
          <div class="col text-right">
            <p class="mb-1 text-muted text-warning font-weight-bold">
              Nbr d'appartement : {{ item?.homes.length}}
            </p>
          </div>
        </div>
        <div class="row align-content">
          <div class="col text-right">
            <button (click)="showBuilding(item)" type="button" class="btn btn-icon btn-secondary"
              ngbTooltip="Détails">
              <i class="fas fa-eye"></i>
            </button>
            <button *ngxPermissionsOnly="'HOME:EDIT'" (click)="editBuilding(item)" type="button" class="btn btn-icon btn-primary ml-1" ngbTooltip="Modifier">
              <i class="feather icon-edit-2"></i>
            </button>
            <button *ngxPermissionsOnly="'HOME:PRINTER:SHOW'" (click)="printerBuilding(item)" type="button" class="btn btn-icon btn-warning ml-1"
              ngbTooltip="Imprimer">
              <i class="feather icon-printer"></i>
            </button>
            <ng-template ngxPermissionsOnly="HOME:DELETE">
              <button *ngIf="item?.etat === 'DISPONIBLE'" (click)="delete(item)" type="button" class="btn btn-icon btn-danger ml-1" ngbTooltip="Supprimer">
                <i class="feather icon-trash"></i>
              </button>
            </ng-template>
          </div>
        </div>
      </app-card>
    </div>
  </div>
</div>
