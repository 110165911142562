import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { VALIDATION } from '@theme/utils/functions';
import {Inventory} from '@model/inventory';
import {InventoryService} from '@service/inventory/inventory.service';
import {DateHelperService} from '@theme/utils/date-helper.service';
import { Globals } from '@theme/utils/globals';
import { UploaderService } from '@service/uploader/uploader.service';
import { environment } from '@env/environment';

@Component({
  selector: 'app-inventory-show',
  templateUrl: './inventory-show.component.html',
  styleUrls: ['./inventory-show.component.scss']
})
export class InventoryShowComponent implements OnInit {
  title: string = '';
  public inventory: Inventory;
  public currentTenantSelected ?: any;
  publicUrl = environment.publicUrl;
  global = {country: Globals.country, device: Globals.device};
  userSession = Globals.user;
  validation = VALIDATION;
  file: any;

  constructor(
    public modale: NgbActiveModal,
    private inventoryService: InventoryService,
    private uploader: UploaderService
  ) { }

  ngOnInit(): void {
    this.inventory = this.inventoryService.inventory;
    console.log(this.inventory)
    this.title = 'Détail de l\'etat des lieux '+this.inventory.contract.rental.libelle,
    this.currentTenantSelected = {
      photoSrc: this.inventory.contract.tenant.photoSrc,
      title: this.inventory.contract.tenant.nom,
      detail: this.inventory.contract.tenant.searchableDetail
    };
  }
  printerInventory(row): void {
    this.inventoryService.getPrinter('SHOW', this.userSession?.agencyKey, this.userSession?.uuid, row?.uuid);
  }
  formatDate(date) {
    return DateHelperService.fromJsonDate(date);
  }
  showFile(item) {
    const fileByFolder = this.uploader.getDataFileByFolder();
    this.file = fileByFolder?.path ? this.publicUrl + '/' + JSON.parse(localStorage.getItem('token-zen-data'))?.path + 'signe/etat_lieu/' + fileByFolder?.path : null;
  }
  closeViewer() {
    this.file = '';
    this.uploader.setDataFileByFolder('');
  }
}
