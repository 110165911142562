import { Component, Input, OnInit } from '@angular/core';
import { Equipment } from '@model/equipment';

@Component({
  selector: 'app-equipment-list',
  templateUrl: './equipment-list.component.html',
  styleUrls: ['./equipment-list.component.scss']
})
export class EquipmentListComponent implements OnInit {
  @Input() equipment: Equipment[] = [];

  constructor() { }

  ngOnInit(): void {
  }

}
