import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Home } from '@model/home';
import { Tasks } from '@model/tasks';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EmitterService } from '@service/emitter/emitter.service';
import { HomeService } from '@service/home/home.service';
import { Globals } from '@theme/utils/globals';

@Component({
  selector: 'app-task-list',
  templateUrl: './task-list.component.html',
  styleUrls: ['./task-list.component.scss']
})
export class TaskListComponent implements OnInit {
  @Input() homes: Home[] = []
  dtOptions: any = {};
  etat: boolean = false

  constructor(
    private router: Router,
    private emitter: EmitterService,
    private homeService: HomeService    
  ) { }


  ngOnInit(): void {
    this.dtOptions = Globals.dataTable;
    this.etat = this.homes ? true : false;
    this.emitter.event.subscribe((data) => {
      if (data.action === 'TASK_ADD') {
        this.appendToList(data.payload);
      }
      // if (data.action === 'WORKSITE_UPDATE') {
      //   this.update(data.payload);
      // }
    });
  }

  show(row,type) {
    this.router.navigate(['/outils/gantt/' + row.uuid + '/'+type]);
  }
  appendToList(item): void {
    this.homes.unshift(...item);
  }

}
