<div class="row">
  <ng-template [ngxPermissionsOnly]="['SETTING:AGENCY']">
    <div class="col-md-3">
      <div class="card mb-3">
        <div class="card-header">
          <h4>Paramètres</h4>
        </div>
        <div class="card-body p-3">
          <div class="cat-list">
            <div *ngxPermissionsOnly="'SETTING:AGENCY'" class="border-bottom pb-3 ">
              <a class="btn btn-light width" (click)="onDisplay('GENERAL')">
                <i class="fa fa-cog"></i> Général
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="card mb-3">
        <div class="card-header">
          <h4>Autres paramètres</h4>
        </div>
        <div class="card-body p-3">
          <div class="cat-list">
            <div class="border-bottom pb-3 pt-3">
              <a class="btn btn-light width" (click)="onDisplay('TANTIEME')">
                <i class="fas fa-calendar"></i> Millièmes
              </a>
            </div>
            <div class="border-bottom pb-3 pt-3">
              <a class="btn btn-light width" (click)="onDisplay('CATEGORY')">
                <i class="fas fa-cash-register"></i> Catégorie de charges
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="card mb-3">
        <div class="card-header">
            <h4>Comptabilité</h4>
        </div>
        <div class="card-body p-3">
            <div class="cat-list">
              <div class="border-bottom pb-3 pt-3">
                  <a class="btn btn-light width" (click)="onChange('JOURNAUX')">
                      <i class="fas fa-book"></i> Journaux comptables
                  </a>
              </div>
              <div class="border-bottom pb-3 pt-3">
                  <a class="btn btn-light width" (click)="onChange('COMPTE_MODELE')">
                      <i class="fa-sharp fas fa-bookmark"></i> Modèle de plan comptable
                  </a>
              </div>
              <div class="border-bottom pb-3 pt-3">
                  <a class="btn btn-light width" (click)="onChange('PLAN_COMPTABLE')">
                      <i class="fas fa-compress"></i> Plan comptable
                  </a>
              </div>
              <div class="border-bottom pb-3 pt-3">
                <a class="btn btn-light width" (click)="onChange('PLAN_AUXILIAIRE')">
                    <i class="fa-sharp fas fa-sort"></i> Plans des comptes auxiliaires
                </a>
              </div>
              <div class="border-bottom pb-3 pt-3">
                <a class="btn btn-light width" (click)="onChange('COMPTE_DEFAUT')">
                    <i class="fas fa-pallet"></i> Comptes par défaut
                </a>
              </div>
              <div class="border-bottom pb-3 pt-3">
                <a class="btn btn-light width" (click)="onChange('TRESORERIE')">
                    <i class="fas fa-pallet"></i> Trésorerie
                </a>
              </div>
              <!-- <div class="border-bottom pb-3 pt-3">
                  <a class="btn btn-light width" (click)="onChange('COMPTE_TVA')">
                      <i class="fas fa-money-bill-wave"></i> Comptes taxes
                  </a>
              </div> -->
              <!-- <div class="border-bottom pb-3 pt-3">
                <a class="btn btn-light width" (click)="onChange('CHARGE')">
                    <i class="fas fa-money-bill-wave"></i> Comptes charges soc/fisc
                </a>
              </div> -->
              <!-- <div class="border-bottom pb-3 pt-3">
                <a class="btn btn-light width" (click)="onChange('FRAIS')">
                    <i class="fas fa-money-bill-wave"></i> Notes de frais
                </a>
              </div> -->
              <!-- <div class="border-bottom pb-3 pt-3">
                <a class="btn btn-light width" (click)="onChange('PRODUIT')">
                    <i class="fas fa-money-bill-wave"></i> Produits
                </a>
              </div> -->
              <div class="border-bottom pb-3 pt-3">
                <a class="btn btn-light width" (click)="onChange('LENGTH_CODE_COMPTABLE')">
                  <i class="fas fa-reel"></i> Longueur des codes comptables
                </a>
              </div>
            </div>
        </div>
      </div>
    </div>

    <div class="col-md-9">
      <!-- LISTE DES PARAMETRES GENERAUX -->
      <ng-template ngxPermissionsOnly="SETTING:AGENCY">
      <app-general-add *ngIf="type === 'GENERAL' && general" [general]="general" class="width"></app-general-add>
      </ng-template>
      <ng-template ngxPermissionsExcept="SETTING:AGENCY">
        <div class="col-md-12">
          <app-no-droit *ngIf="type === 'GENERAL'" [title]="'liste template'"></app-no-droit>
        </div>
      </ng-template>

       <!-- LISTE DES ANNEES -->
      <div class="col-md-12" *ngIf="type === 'YEAR'">
        <app-year-list *ngIf="years" [years]="years" class="width"></app-year-list>
      </div>

       <!-- LISTE DES TANTIEMES -->
       <div class="col-md-12" *ngIf="type === 'TANTIEME'">
        <app-tantieme-list class="width"></app-tantieme-list>
      </div>

       <!-- LISTE DES CATEGORIES DE CHARGES -->
       <div class="col-md-12" *ngIf="type === 'CATEGORY'">
        <app-category-list class="width" [loadCategory]="loadCategory" ></app-category-list>
      </div>


      
      <!-- DEFINIR LA TAILLE DES COMPTE COMPTABLE -->
      <div class="col-md-12" *ngIf="type ===  'LENGTH_CODE_COMPTABLE'">
        <app-length-code-comptable></app-length-code-comptable>
      </div>

      <!-- LISTE DES COMPTES TVA -->
      <div class="col-md-12" *ngIf="type ===  'COMPTE_TVA'">
        <app-compte-tva-list class="width"></app-compte-tva-list>
      </div>

      <!-- LISTE DES COMPTE AUXILIAIRE -->
      <div class="col-md-12" *ngIf="type === 'PLAN_AUXILIAIRE'">
        <app-plan-auxiliaire class="width"></app-plan-auxiliaire>
      </div>

      <!-- LISTE DES COMPTE PAS DEFAUT -->
      <div class="col-md-12" *ngIf="type === 'COMPTE_DEFAUT'">
        <app-compte-defaut class="width"></app-compte-defaut>
      </div>

      <!-- LISTE DES COMPTES COMPTABLES -->
      <div class="col-md-12" *ngIf="type === 'PLAN_COMPTABLE'">
        <app-plan-comptable-list class="width"></app-plan-comptable-list>
      </div>

      <!-- LISTE DES JOURNAUX -->
      <div class="col-md-12" *ngIf="type === 'JOURNAUX'">
        <app-journaux-list class="width"></app-journaux-list>
      </div>

      <!-- LISTE DES MODELES DE COMPTE COMPTABLES -->
      <div class="col-md-12" *ngIf="type === 'COMPTE_MODELE'">
        <app-plan-modele-list class="width"></app-plan-modele-list>
      </div>

      <!-- LISTE DES COMPTES TRESORERIE -->
      <div class="col-md-12" *ngIf="type === 'TRESORERIE'">
        <app-tresorerie class="width"></app-tresorerie>
      </div>

      <!-- LISTE DES COMPTES CHARGES -->
      <div class="col-md-12" *ngIf="type === 'CHARGE'">
        <app-charge class="width"></app-charge>
      </div>

      <!-- LISTE DES NOTES DE FRAIS -->
      <div class="col-md-12" *ngIf="type === 'FRAIS'">
        <app-note-frais class="width"></app-note-frais>
      </div>

      <!-- LISTE DES NOTES DE FRAIS -->
      <div class="col-md-12" *ngIf="type === 'PRODUIT'">
        <app-produit class="width"></app-produit>
      </div>

    </div>
  </ng-template>
</div>
<ng-template ngxPermissionsExcept="SETTING:AGENCY" [ngxPermissionsOnly]="['SETTING:AGENCY']">
  <div class="col-md-12">
    <app-no-droit [title]="'Paramètre client'"></app-no-droit>
  </div>
</ng-template>
