<div class="modal-header">
  <h5 class="modal-title">{{title}}</h5>
  <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
    (click)="modal.close('ferme')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form autocomplete="off" [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="modal-body">
    <div class="col-md-12">
      <div class="row"> <span class="badge badge-primary my-2 f-14 width"> INFORMATION SUR LA DEMANDE </span> </div>
      <div class="form-group">
        <div class="row mb-2">
          <div class="col-md-4">
            <label>Motif </label>
            <input type="text" readonly [value]="fund?.motif" class="form-control bold p-2">
          </div>
          <div class="col-md-4">
            <label>Date </label>
            <input type="text" readonly [value]="fund?.date| date: 'd MMMM y' : '' : 'fr-FR' " class="form-control bold p-2">
          </div>
          <div class="col-md-4">
            <label>Montant</label>
            <input type="text" readonly [value]="fund?.montant" class="form-control bold p-2">
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <div class="row">
        <span class="badge badge-primary my-2 f-14 width"> INFORMATION SUR LE DECAISSEMENT </span>
      </div>
      <div class="form-group">
        <div class="row mb-2">
          <div class="col-md-5">
            <label>Ligne budgétaire <span class="asterix">*</span></label>
            <app-entity-finder [class]="'TypeLoad'" [namespace]="'Budget'" [groups]="['load']"
            [required]="true" [selected]="currentLigne"
            (uuid)="setLigneUuid($event)">
            </app-entity-finder>
          </div>
          <div class="col-md-5">
            <label>Ligne budgétaire <span class="asterix">*</span></label>
              <ng-select [multiple]="false" id="ligne" formControlName="ligne" [(ngModel)]="currentLigne" >
                  <ng-option *ngFor="let item of lignes" [value]="item.uuid">{{item?.libelle}}</ng-option>
              </ng-select>
              <div class="invalid-feedback" *ngIf="submit && f.ligne.errors">
                  <div *ngIf="f.ligne.errors.required">{{required.novide}}</div>
              </div>
          </div>
          <div class="col-md-3">
            <label for="date">Date <span class="asterix">*</span></label>
            <input type="date" formControlName="date" class="form-control" id="date" [ngClass]="{
              'is-invalid': submit && f.date.errors,
              'is-valid': submit && f.date.valid
            }">
            <div class="invalid-feedback" *ngIf="submit && f.date.errors">
              <div *ngIf="f.date.errors.required">{{required.novide}}</div>
            </div>
          </div>
          <div class="col-md-4">
            <label for="montant">Montant Decaisse <span class="asterix">*</span></label>
            <input type="number" formControlName="montant" [min]="0" step="1000" class="form-control" id="montant" [ngClass]="{
              'is-invalid': submit && f.montant.errors,
              'is-valid': submit && f.montant.valid
            }" readonly>
            <div class="invalid-feedback" *ngIf="submit && f.montant.errors">
              <div *ngIf="f.montant.errors.required">{{required.novide}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button (click)="modal.close('ferme')" type="button" class="btn btn-secondary text-left" data-dismiss="modal">
      Fermer <i class="feather icon-x-circle"></i>
    </button>
    <button (click)="form.reset()" type="button" class="btn btn-warning">
      Vider <i class="fas fa-broom"></i>
    </button>
    <button [disabled]="form.invalid" type="submit" class="btn btn-primary">
      Decaisser <i class="feather icon-save"></i>
    </button>
  </div>
</form>
