<div class="modal-header">
    <h5 class="modal-title">{{title}}</h5>
    <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
      (click)="modal.close('ferme')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <form autocomplete="off" [formGroup]="form" (ngSubmit)="onConfirme()">
    <div class="modal-body">
      <div class="col-md-12">
        <div class="row">
          <span class="badge badge-primary my-2 f-14 formBadge width">
            INFORMATIONS SUR LA L'OPÉRATION
          </span>
        </div>
        <div class="form-group">
          <div class="row mb-2">
            <div class="col-md-4">
              <label for="libelle">Libéllé de l'opération <span class="asterix">*</span></label>
              <input type="text" formControlName="libelle" class="form-control" id="libelle" [ngClass]="{
                'is-invalid': submit && f.libelle.errors,
                'is-valid': submit && f.libelle.valid
              }" placeholder="Libéllé de l'opération" [readOnly]="true">
              <div class="invalid-feedback" *ngIf="submit && f.libelle.errors">
                <div *ngIf="f.libelle.errors.required">{{required.novide}}</div>
              </div>
            </div>
            <div class="col-md-4">
              <label for="dateOp">Date de l'opération<span class="asterix">*</span></label>
              <input class="form-control" type="date"formControlName="dateOp"  id="dateOp" [readOnly]="true" />
              <div class="invalid-feedback" *ngIf="submit && f.dateOp.errors">
                <div *ngIf="f.dateOp.errors.required">{{required.novide}}</div>
              </div>
            </div>
            <div class="col-md-4">
              <label for="montant">Montant de l'opération <span class="asterix">*</span></label>
              <input type="text" formControlName="montant" class="form-control" id="montant" [ngClass]="{
                'is-invalid': submit && f.montant.errors,
                'is-valid': submit && f.montant.valid
              }" placeholder="Montant de l'opération" [readOnly]="true">
              <div class="invalid-feedback" *ngIf="submit && f.montant.errors">
                <div *ngIf="f.montant.errors.required">{{required.novide}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <span class="badge badge-primary my-2 f-14 formBadge width">
            INFORMATIONS COMPTABLE
          </span>
        </div>
        <div class="form-group">
          <div class="row mb-2">
              <ng-container *ngIf="operation.isCredited">
                <div class="col-md-6">
                  <label for="lot">Compte comptable | Crédit</label>
                  <ng-select formControlName="creditedAccount" class="mb-2">
                      <ng-option *ngFor="let account of accounts" [value]="account?.uuid"> {{ account?.numero }} - {{ account?.libelle }} </ng-option>
                  </ng-select>
                </div>
                <!-- <div class="col-md-6" *ngIf="operation.auxiliaryC">
                  <label for="libelle">Compte auxilliare | Crédit </label>
                  <ng-select formControlName="auxiliaryC" class="mb-2">
                      <ng-option *ngFor="let account of accounts" [value]="account?.uuid"> {{ account?.numero }} - {{ account?.libelle }} </ng-option>
                  </ng-select>
                </div> -->
                <!-- <div class="col-md-6" *ngIf="!operation.auxiliaryC"> -->
                <div class="col-md-6">
                  <label for="auxiliaryC">Compte auxilliare | Crédit </label>
                  <input formControlName="auxiliaryC" class="form-control" id="auxiliaryC" [ngClass]="{
                      'is-invalid': submit && f.auxiliaryC.errors,
                      'is-valid': submit && f.auxiliaryC.valid
                    }" [placeholder]="'Numéro compte auxiliaire'" [readOnly]="true" />
                  <div class="invalid-feedback" *ngIf="submit && f.auxiliaryC.errors">
                    <div *ngIf="f.auxiliaryC.errors.required">{{ required.novide }}</div>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="operation.isDebited">
                <div class="col-md-6">
                  <label for="lot">Compte comptable | Débit</label>
                  <ng-select formControlName="debitedAccount" class="mb-2">
                      <ng-option *ngFor="let account of accounts" [value]="account?.uuid"> {{ account?.numero }} - {{ account?.libelle }} </ng-option>
                  </ng-select>
                </div>
                <!-- <div class="col-md-6">
                  <label for="auxiliaryD">Compte auxilliare | Débit </label>
                  <ng-select formControlName="auxiliaryD" class="mb-2">
                      <ng-option *ngFor="let account of accounts" [value]="account?.uuid"> {{ account?.numero }} - {{ account?.libelle }} </ng-option>
                  </ng-select>
                </div> -->
                <div class="col-md-6">
                  <label for="auxiliaryD">Compte auxilliare | Débit </label>
                  <input formControlName="auxiliaryD" class="form-control" id="auxiliaryD" [ngClass]="{
                      'is-invalid': submit && f.auxiliaryD.errors,
                      'is-valid': submit && f.auxiliaryD.valid
                    }" [placeholder]="'Numéro compte auxiliaire'" [readOnly]="true" />
                  <div class="invalid-feedback" *ngIf="submit && f.auxiliaryD.errors">
                    <div *ngIf="f.auxiliaryD.errors.required">{{ required.novide }}</div>
                  </div>
                </div>
              </ng-container>
              <div class="col-md-6">
                <label for="lot">Journal comptable</label>
                <ng-select formControlName="log" class="mb-2">
                    <ng-option *ngFor="let log of logs" [value]="log?.uuid"> {{ log.libelle }} </ng-option>
                </ng-select>
              </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button (click)="modal.close('ferme')" type="button" class="btn btn-secondary text-left" data-dismiss="modal">
        Fermer <i class="feather icon-x-circle"></i>
      </button>
      <button (click)="form.reset()" type="button" class="btn btn-warning">
        Vider <i class="fas fa-broom"></i>
      </button>
      <button [disabled]="form.invalid" type="submit" class="btn btn-primary">
        Enregistrer <i class="feather icon-save"></i>
      </button>
    </div>
</form>
