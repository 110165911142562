import { OwnerAddComponent } from '@agence/proprietaire/owner/owner-add/owner-add.component';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OwnerService } from '@service/owner/owner.service';

@Component({
  selector: 'app-pop',
  templateUrl: './pop.component.html',
  styleUrls: ['./pop.component.scss']
})
export class PopComponent implements OnInit {

  constructor(
    public modale: NgbActiveModal,
    private modalService: NgbModal,
    private ownerService: OwnerService
  ) { }

  ngOnInit(): void {
  }

  add(type){
    this.modale.close('ferme')
    this.modalService.dismissAll();
    this.ownerService.edit = false;
    this.ownerService.type = type;
    this.ownerService.typeSyndic = 'SYNDIC'
    this.modal(OwnerAddComponent, 'modal-basic-title', 'xl', true, 'static');
  }

  modal(component, type, size, center, backdrop) {
    this.modalService.open(component, {
      ariaLabelledBy: type,
      size: size,
      centered: center,
      backdrop: backdrop
    }).result.then((result) => {
     
    }, (reason) => { });
  }

}
