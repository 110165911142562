import { Spent } from '@model/spent';
import { Treasury } from '@model/treasury';
import { ToastrService } from 'ngx-toastr';
import { Globals } from '@theme/utils/globals';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SpentService } from '@service/spent/spent.service';
import {ValidatorsEnums} from '@theme/enums/validators.enums';
import { EmitterService } from '@service/emitter/emitter.service';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TreasuryService } from '@service/treasury/treasury.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-spent-add',
  templateUrl: './spent-add.component.html',
  styleUrls: ['./spent-add.component.scss']
})
export class SpentAddComponent implements OnInit {
  title: string = ""
  edit: boolean = false
  form: FormGroup
  submit: boolean = false
  spent: Spent
  treasury: Treasury
  totalHT = 0;
  totalTva = 0;
  totalTTC = 0;
  totalRemise = 0;
  required = Globals.required;
  prioriteRow = [
    {label: 'NON PRIORITAIRE', value: 'NON'},
    {label: 'PRIORITE MOYEN', value: 'MOYEN'},
    {label: 'URGENT', value: 'URGENT'}
  ]


  constructor(
    public modal: NgbActiveModal,
    private formBuild: FormBuilder,
    public spentService: SpentService,
    public treasuryService: TreasuryService,
    public toastr: ToastrService,
    private route: ActivatedRoute,
    private emitter: EmitterService
  ) {
    this.edit = this.spentService.edit
    this.spent = this.spentService.getSpent()
    this.title = (!this.edit) ? "Ajouter une depense" : "Modifier la depense de " + this.spent?.code
    this.newForm()
    this.f.treasury.setValue(this.spentService.treasury)
    this.treasuryService.getSingle(this.spentService.treasury).subscribe((res: any) => {
      if (res) { this.treasury = res; }
    });
  }

  ngOnInit(): void {
    this.editForm();
  }

  newForm() {
    this.form = this.formBuild.group({
      uuid: [null],
      id: [null],
      treasury: [null, Validators.required],
      montant: [null, Validators.required],
      date: [null, Validators.required],
      motif: [null, Validators.required],
      priorite: [null, Validators.required],
      description: [null],
      montantHt: [0],
      montantTva: [0],
      montantRemise: [0],
      options: this.formBuild.array([
        this.formBuild.group({
          uuid: [null],
          id: [null],
          libelle: [null, [Validators.required]],
          prix: [0, [Validators.required, Validators.pattern(ValidatorsEnums.number), Validators.min(0)]],
          qte: [1, [Validators.required, Validators.pattern(ValidatorsEnums.number), Validators.min(1)]],
          tva: [0, [Validators.required, Validators.pattern(ValidatorsEnums.number), Validators.min(0)]],
          remise: [0, [Validators.required, Validators.pattern(ValidatorsEnums.number), Validators.min(0)]],
          total: [{value: 0, disabled: true}, [Validators.required, Validators.pattern(ValidatorsEnums.number), Validators.min(0)]]
        })
      ]),
      concern: ['AGENCY', Validators.required]
    });
  }
  editForm() {
    if (this.edit) {
    }
  }
  onChangeTotal() {
    let totalOptionRemise = 0;
    let totalOptionHT = 0;
    let totalOptionTVA = 0;
    let totalOptionTTC = 0;
    this.option.controls.forEach(elem => {
      var remise = elem.value.remise >= 0 ? elem.value.remise : 0
      var totalHt = (elem.value.prix * elem.value.qte) - remise
      var totalTva = elem.value.tva >= 0 ? totalHt * (elem.value.tva / 100) : 0
      var totalTtc = totalHt + totalTva
      elem.get('total').setValue(totalTtc);
      totalOptionRemise += remise;
      totalOptionHT += (elem.value.qte >= 1 && (remise <= totalHt)) ? totalHt - remise : 0;
      totalOptionTVA += totalTva;
      totalOptionTTC += totalTtc
    });

    this.totalHT = totalOptionHT;
    this.totalTva = totalOptionTVA;
    this.totalRemise = totalOptionRemise;
    this.totalTTC = totalOptionHT + totalOptionTVA + totalOptionRemise
    this.f.montantHt.setValue(totalOptionHT);
    this.f.montantTva.setValue(totalOptionTVA);
    this.f.montantRemise.setValue(totalOptionRemise);
    this.f.montant.setValue(totalOptionHT + totalOptionTVA + totalOptionRemise);
  }
  addOption() {
    this.option.push(
      this.formBuild.group({
        uuid: [null],
        id: [null],
        libelle: [null, [Validators.required]],
        prix: [0, [Validators.required, Validators.pattern(ValidatorsEnums.number), Validators.min(0)]],
        qte: [1, [Validators.required, Validators.pattern(ValidatorsEnums.number), Validators.min(1)]],
        tva: [0, [Validators.required, Validators.pattern(ValidatorsEnums.number), Validators.min(0)]],
        remise: [0, [Validators.required, Validators.pattern(ValidatorsEnums.number), Validators.min(0)]],
        total: [{value: 0, disabled: true}, [Validators.required, Validators.pattern(ValidatorsEnums.number), Validators.min(0)]],
      })
    );
  }
  onDelete(row) {
    var index = this.option.controls.indexOf(row);
    this.option.controls.splice(index, 1);
    this.onChangeTotal();
  }
  onSubmit() {
    this.submit = true;
    if (!this.form.invalid) {
      const data = this.form.getRawValue();
        this.spentService.add(data).subscribe(res => {
          if (res?.status === 'success') {
            this.modal.close('ferme');
            if (data?.uuid) {
              this.emitter.emit({action: 'SPENT_UPDATED', payload: res?.data});
            } else {
              this.emitter.emit({action: 'SPENT_ADD', payload: res?.data});
            }
          }
        });
    } else { return; }
  }
  onConfirme() {
    Swal.fire({
      title: '',
      text: 'Confirmez-vous l\'enregistrement ?',
      icon: 'warning',
      showCancelButton: true,
      showCloseButton: true,
      confirmButtonText: 'Confirmer <i class="fas fa-check"></i>',
      cancelButtonText: 'Annuler <i class="feather icon-x-circle"></i>',
      confirmButtonColor: '#1bc943',
      reverseButtons: true
    }).then((willDelete) => {
      if (!willDelete.dismiss) {
        this.onSubmit();
      }
    });
  }
  onClose(){
    this.form.reset()
    this.modal.close('ferme');
  }
  toast(msg, title, type) {
    if (type == 'info') {
      this.toastr.info(msg, title);
    } else if (type == 'success') {
      this.toastr.success(msg, title);
    } else if (type == 'warning') {
      this.toastr.warning(msg, title);
    } else if (type == 'error') {
      this.toastr.error(msg, title);
    }
  }
  get f() { return this.form.controls; }
  get option() { return this.form.get('options') as FormArray; }
}
