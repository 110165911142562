<div class="card mb-3">
  <div class="card-header d-flex">
    <div class="p-2">
      <h4><i class="fa fa-cog"></i> Paramètres généraux</h4>
    </div>
    <div class="ml-auto p-2">
      <ng-template ngxPermissionsOnly="SETTING:AGENCY">
        <button (click)="editGeneral(true)" *ngIf="!edit" type="button"
          class="btn btn-icon btn-primary ml-1" ngbTooltip="Modifier">
          <i class="feather icon-edit-2"></i>
        </button>
        <button (click)="editGeneral(false)" *ngIf="edit" type="button"
          class="btn btn-icon btn-danger ml-1" ngbTooltip="Fermer">
          <i class="feather icon-x-circle"></i>
        </button>
      </ng-template>
    </div>
  </div>
  <div class="card-body p-3">
    <div class="col-md-12">
      <div class="row"><span class="badge badge-primary my-2 f-14 width">{{title}}</span></div>
    </div>
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="modal-body">
        <div class="col-md-12">
          <div class="form-group">
            <div class="row mb-2">
              <div class="col-md-4">
                <label for="nom">Type d'usage</label>
                <select [attr.disabled]="!edit ? edit : null" nom="type" id="type" formControlName="type"
                  class="form-control">
                  <option [value]="'ENTREPRISE'">ENTREPRISE</option>
                  <option [value]="'PARTICULIER'">PARTICULIER</option>
                </select>
              </div>
              <div class="col-md-4">
                <label for="responsable">
                  {{ f.type.value === 'PARTICULIER' ? 'Nom et prénoms' : 'Responsable' }}
                </label>
                <input [attr.disabled]="!edit ? edit : null" type="text" formControlName="responsable"
                  [class]="!edit ? 'form-control p-2' : 'form-control'" id="responsable"
                  placeholder="{{ f.type.value === 'PARTICULIER' ? 'Nom et prénoms' : 'Responsable' }}">
              </div>
              <div class="col-md-4">
                <label for="nom">
                  {{ f.type.value === 'PARTICULIER' ? 'Nom à afficher' : 'Agence' }}
                </label>
                <input [attr.disabled]="!edit ? edit : null" type="text" formControlName="nom" [class]="!edit ? 'form-control p-2' : 'form-control'"
                  id="nom" placeholder="{{ f.type.value === 'PARTICULIER' ? 'Nom à afficher' : 'Agence' }}">
              </div>
              <div class="col-md-4">
                <label for="nom">Agrée ?</label>
                <select [attr.disabled]="!edit ? edit : null" nom="agree" id="agree" formControlName="agree"
                  class="form-control">
                  <option [value]="false">NON</option>
                  <option [value]="true">OUI</option>
                </select>
              </div>
              <div class="col-md-4">
                <label for="contact">Contact</label>
                <input [attr.disabled]="!edit ? edit : null" type="text" formControlName="contact"
                  id="contact" placeholder="Contact" [class]="!edit ? 'form-control p-2' : 'form-control'">
              </div>
              <div class="col-md-4">
                <label for="email">E-mail</label>
                <input [attr.disabled]="!edit ? edit : null" type="email" formControlName="email"
                  id="email" placeholder="E-mail" [class]="!edit ? 'form-control p-2' : 'form-control'">
              </div>
              <div class="col-md-4">
                <label for="nrc">Numéro registre de commerce</label>
                <input [attr.disabled]="!edit ? edit : null" type="text" formControlName="nrc"
                  id="nrc" placeholder="N°NRC" [class]="!edit ? 'form-control p-2' : 'form-control'">
              </div>
              <div class="col-md-4">
                <label for="ncc">Numéro compte contribuable</label>
                <input [attr.disabled]="!edit ? edit : null" type="text" formControlName="ncc"
                  id="ncc" placeholder="N°NCC" [class]="!edit ? 'form-control p-2' : 'form-control'">
              </div>
              <div class="col-md-4">
                <label for="adresse">Adresse postale</label>
                <input [attr.disabled]="!edit ? edit : null" type="text" formControlName="adresse"
                  id="adresse" placeholder="Adresse postale" [class]="!edit ? 'form-control p-2' : 'form-control'">
              </div>
              <div class="col-md-4">
                <label for="faxe">Fax</label>
                <input [attr.disabled]="!edit ? edit : null" type="text" formControlName="faxe"
                  id="faxe" placeholder="Fax" [class]="!edit ? 'form-control p-2' : 'form-control'">
              </div>
              <div class="col-md-4">
                <label for="domicile">
                  {{ f.type.value === 'PARTICULIER' ? 'Domicile' : 'Siège' }}
                </label>
                <input [attr.disabled]="!edit ? edit : null" type="text" formControlName="domicile" [class]="!edit ? 'form-control p-2' : 'form-control'"
                  id="domicile" placeholder="{{ f.type.value === 'PARTICULIER' ? 'Domicile' : 'Siège' }}">
              </div>
              <div class="col-md-4">
                <label for="nom">Pays</label>
                <app-entity-finder [class]="'Country'" [namespace]="'Extra'" [groups]="['country']"
                 [selected]="countrySelected" [disabled]="!edit" (uuid)="setCountryUuid($event)">
                </app-entity-finder>
              </div>
              <div class="col-md-4">
                <label for="ville">Ville</label>
                <input [attr.disabled]="!edit ? edit : null" type="text" formControlName="ville"
                  id="ville" placeholder="Ville" [class]="!edit ? 'form-control p-2' : 'form-control'">
              </div>
              <div class="col-md-4">
                <label for="commune">Commune</label>
                <input [attr.disabled]="!edit ? edit : null" type="text" formControlName="commune"
                  id="commune" placeholder="Commune" [class]="!edit ? 'form-control p-2' : 'form-control'">
              </div>
              <div class="col-md-4">
                <label for="quartier">Quartier</label>
                <input [attr.disabled]="!edit ? edit : null" type="text" formControlName="quartier"
                  id="quartier" placeholder="Quartier" [class]="!edit ? 'form-control p-2' : 'form-control'">
              </div>
              <div class="col-md-4">
                <label for="capital">Capital</label>
                <input [attr.disabled]="!edit ? edit : null" type="number" formControlName="capital"
                  id="capital" placeholder="Capital" [class]="!edit ? 'form-control p-2' : 'form-control'">
              </div>
              <div class="col-md-4">
                <label for="sender">Sender</label>
                <input [attr.disabled]="!edit ? edit : null" type="text" formControlName="sender"
                  id="sender" placeholder="Sender" [class]="!edit ? 'form-control p-2' : 'form-control'">
              </div>
              <div class="col-md-4">
                <label for="impot">Impot foncier</label>
                <input [attr.disabled]="!edit ? edit : null" type="number" formControlName="impot"
                  id="impot" placeholder="Impot foncier" [class]="!edit ? 'form-control p-2' : 'form-control'">
              </div>
              <div class="col-md-4">
                <label for="dateG">Date génération avis écheance</label>
                <select [attr.disabled]="!edit ? edit : null" name="dateG" id="dateG" formControlName="dateG"
                  class="form-control">
                  <option *ngFor="let item of dateGRow" [value]="item.value">{{ item.label }}</option>
                </select>
              </div>
              <div class="col-md-4">
                <label for="etat">Notification</label>
                <select [attr.disabled]="!edit ? edit : null" name="etat" id="etat" formControlName="etat"
                  class="form-control">
                  <option [value]="'ACTIVE'">ACTIVE</option>
                  <option [value]="'DESACTIVE'">DESACTIVE</option>
                </select>
              </div>
              <div class="col-md-4">
                <label for="paiement">Autoriser les paiements via Mobile</label>
                <select [attr.disabled]="!edit ? edit : null" name="paiement" id="paiement" formControlName="paiement"
                  class="form-control">
                  <option *ngFor="let item of paiementRow" [value]="item.value">{{ item.label }}</option>
                </select>
              </div>
              <div class="col-md-4">
                <label for="tva">TVA (%)</label>
                <input [attr.disabled]="!edit ? edit : null" type="number" formControlName="tva"
                  id="tva" placeholder="Tva" [class]="!edit ? 'form-control p-2' : 'form-control'">
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-md-6">
                <label for="entete">ENTETE DE DOCUMENT</label>
                <textarea [attr.disabled]="!edit ? edit : null" class="form-control border border p-2" id="entete"
                  formControlName="entete" rows="3"></textarea>
              </div>
              <div class="col-md-6">
                <label for="piedPage">PIEDS DE DOCUMENT</label>
                <textarea [attr.disabled]="!edit ? edit : null" class="form-control border border p-2" id="piedPage"
                  formControlName="piedPage" rows="3"></textarea>
              </div>
            </div>
            <div class="form-group">
              <div class="row mb-2">
                <div *ngIf="edit" class="flex-grow-0" style="min-width: 30%">
                  <app-image-uploader (filesd)="loadfile($event)" (imageUploaded)="setParam('photoUuid',$event)" [photo]="general ? general?.photo : null"
                  [message]="'Selectionner un logo'"></app-image-uploader>
                </div>
                <div *ngIf="!edit" class="card-img">
                  <img class="card-img-top" [src]="f.photoSrc.value ? publicUrl+'/'+f.photoSrc.value : 'assets/images/agency.png'">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer" *ngIf="edit">
        <button *ngxPermissionsOnly="'SETTING:AGENCY'" type="submit" class="btn btn-primary">
          Enregistrer <i class="feather icon-save"></i>
        </button>
      </div>
    </form>
  </div>
</div>
