import { NgxPermissionsService } from 'ngx-permissions';
import { RentalAddComponent } from '@proprietaire/rental/rental-add/rental-add.component';
import { Component, OnInit } from '@angular/core';
import { Rental } from '@model/rental';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RentalService } from '@service/rental/rental.service';
import { Globals } from '@theme/utils/globals';
import { UploaderService } from '@service/uploader/uploader.service';
import { environment } from '@env/environment';

@Component({
  selector: 'app-rental-show',
  templateUrl: './rental-show.component.html',
  styleUrls: ['./rental-show.component.scss']
})
export class RentalShowComponent implements OnInit {
  title: string = ""
  rental: Rental
  global = {country: Globals.country, device: Globals.device}
  userSession = Globals.user;
  file: any;
  publicUrl = environment.publicUrl;

  constructor(
    public modale: NgbActiveModal,
    private modalService: NgbModal,
    private rentalService: RentalService,
    private permissionsService: NgxPermissionsService,
    private uploader: UploaderService
  ) {
    this.rental = this.rentalService.getRental()
    this.title = "Détails locative N° " + this.rental.libelle
    const permission = JSON.parse(localStorage.getItem('permission-zen')) ? JSON.parse(localStorage.getItem('permission-zen')) : [];
    this.permissionsService.loadPermissions(permission);
  }

  ngOnInit(): void {
  }

  editRental(row) {
    this.modalService.dismissAll()
    this.rentalService.setRental(row)
    this.rentalService.edit = true
    this.modal(RentalAddComponent, 'modal-basic-title', 'xl', true, 'static')
  }
  printerRental(row): void {
    this.rentalService.getPrinter('SHOW', this.userSession?.agencyKey, this.userSession?.uuid, row?.uuid);
  }
  showFile(item) {
    const fileByFolder = this.uploader.getDataFileByFolder();
    this.file = fileByFolder?.path ? this.publicUrl + '/' + fileByFolder?.path : null;
  }
  closeViewer() {
    this.file = '';
    this.uploader.setDataFileByFolder('');
  }
  modal(component, type, size, center, backdrop) {
    this.modalService.open(component, {
      ariaLabelledBy: type,
      size: size,
      centered: center,
      backdrop: backdrop
    }).result.then((result) => {}, (reason) => {});
  }
}
