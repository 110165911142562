import { Injectable } from '@angular/core';

export interface NavigationItem {
  id: string;
  title: string;
  type: 'item' | 'collapse' | 'group';
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  function?: any;
  badge?: {
    title?: string;
    type?: string;
  };
  children?: Navigation[];
}

export interface Navigation extends NavigationItem {
  children?: NavigationItem[];
}

const NavigationItems = [
  {
    id: 'dashboard',
    title: 'Tableau de bord',
    type: 'item',
    url: '/admin/dashboard/syndic',
    icon: 'feather icon-activity',
    classes: 'nav-item',
  },
  {
    id: 'gestion',
    title: 'MODULE SYNDIC',
    type: 'group',
    icon: 'feather icon-monitor',
    children: [
      {
        id: 'syndic',
        title: 'Syndic',
        type: 'item',
        url: '/admin/syndic',
        icon: 'feather icon-box',
        classes: 'nav-item',
      },
      {
        id: 'proprietaires', 
        title: 'Copropriétaire',
        type: 'item',
        url: '/admin/proprietaire',
        icon: 'feather icon-users',
        classes: 'nav-item',
      },
      {
        id: 'budget',
        title: 'Budget',
        type: 'item',
        icon: 'feather icon-layers',
        url: '/admin/budget',
        classes: 'nav-item',
      },
      // {
      //   id: 'generale',
      //   title: 'Assemblée générale',
      //   type: 'item',
      //   icon: 'feather icon-compass',
      //   url: '/admin/assemblee',
      //   classes: 'nav-item',
      // },
      // {
      //   id: 'comptabilite',
      //   title: 'Comptabilité',
      //   type: 'collapse',
      //   icon: 'fas fa-receipt',
      //   children: [
      //     { id: 'ventilation', title: 'Ventilation', type: 'item', url: '/admin/comptabilite/ventilation', breadcrumbs: true },
      //     // { id: 'achat', title: 'Enregistrement compta...', type: 'item', url: '/admin/comptabilite/achat', breadcrumbs: true },
      //     // { id: 'journaux', title: 'Journaux', type: 'item', url: '/admin/comptabilite/journaux', breadcrumbs: true }
      //   ]
      // },
      {
        id: 'tresorerie',
        title: 'Trésorerie',
        type: 'item',
        icon: 'feather icon-server',
        url: '/admin/tresorerie',
        classes: 'nav-item',
      },
      {
        id: 'moyen',
        title: 'Moyens généraux',
        type: 'collapse',
        icon: 'fas fa-paint-roller',
        breadcrumbs: true,
        children: [
          { id: 'prestataire', title: 'Fournisseur et Prestataire', type: 'item', url: '/admin/prestataire', breadcrumbs: true },
          { id: 'construction', title: 'Intervention', type: 'item', url: '/admin/intervention', breadcrumbs: true },
        ]
      },
    ]
  },
  {
    id: 'extra',
    title: 'EXTRA',
    type: 'group',
    icon: 'feather icon-monitor',
    children: [
      {
        id: 'rapport',
        title: 'Rapport',
        type: 'collapse',
        icon: 'fas fa-file-alt',
        breadcrumbs: true,
        children: [
          // { id: 'proprietaire', title: 'Propriétaire', type: 'item', url: '/admin/rapport/proprietaire', breadcrumbs: true },
          // { id: 'locataire', title: 'Locataire', type: 'item', url: '/admin/rapport/locataire', breadcrumbs: true },
          { id: 'syndic', title: 'Syndic', type: 'item', url: '/admin/rapport/syndic', breadcrumbs: true }
        ]
      }
    ]
  },
  {
    id: 'securites',
    title: 'SECURITE',
    type: 'group',
    icon: 'feather icon-monitor',
    children: [
      {
        id: 'utilisateur',
        title: 'Utilisateur',
        type: 'collapse',
        icon: 'fas fa-user-check',
        children: [
          { id: 'user', title: 'Utilisateur', type: 'item', url: '/admin/user', breadcrumbs: true },
        ]
      }
    ]
  },
];

@Injectable()
export class NavigationItem {
  public get() {
    return NavigationItems;
  }
}
