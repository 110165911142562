<div class="col-md-12">
  <span class="badge badge-success my-2 f-14 formBadge width"> LISTE DES PAIEMENTS </span>
</div>
<div class="col-sm-12">
  <app-card [hidHeader]="true" cardClass="card-datatable">
    <div class="table-responsive">
      <table *ngIf="etat" datatable [dtOptions]="dtOptions" class="table table-sm table-striped table-bordered nowrap table-hover">
        <thead class="thead-light">
          <tr>
            <th *ngIf="construction !== 'SHOW'">Intervention</th>
            <th>Facture</th>
            <th>Financeur</th>
            <th>Mode</th>
            <th>Etat</th>
            <th>Montant</th>
            <th>Date de création</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of payments">
            <td *ngIf="construction !== 'SHOW'">
              <p class="m-0 d-inline-block align-middle font-16">
                <span class="text-warning">{{item?.invoice?.funding?.construction?.searchableTitle}}</span> <br />
                Bien : {{item?.invoice?.funding?.construction?.rental ? item?.invoice?.funding?.construction?.rental?.libelle :
                  item?.invoice?.funding?.construction?.house?.searchableTitle}} <br />
                Proriétaire : {{item?.invoice?.funding?.construction?.house?.owner?.searchableTitle}}
              </p>
            </td>
            <td class="align-middle">
              <p class="m-0 d-inline-block align-middle font-16">
                {{item?.invoice?.libelle}}
              </p>
            </td>
            <td>{{item.invoice.funding.financeur}}</td>
            <td>{{item.mode}}</td>
            <td>
              <span class="badge" [ngClass]="{
              'badge-success' : item.etat === 'VALIDE',
              'badge-danger' : item.etat === 'INVALIDE'
              }">{{item.etat}}</span> <br />
              <span class="badge badge-pill" [ngClass]="{
              'badge-light-success' : item?.status === 'CONFIRMER',
              'badge-light-danger' : item?.status === 'ATTENTE'
              }"><small>{{item?.status === 'ATTENTE' ? 'EN ATTENTE DE CONFIRMATION' : item?.status}}</small></span>
            </td>
            <td><p class="m-0 d-inline-block align-middle font-16"><span class="text-warning">{{item.montant|number}} {{global.device}}</span> <br /></p></td>
            <td>{{item.date|date: "d MMMM y" : '' : 'fr-FR'}}</td>
            <td class="table-action">
              <div class="overlay-edit">
                <button (click)="showPayment(item)" type="button" class="btn btn-icon btn-secondary ml-1"
                  ngbTooltip="Détails">
                  <i class="fas fa-eye"></i>
                </button>
                <ng-template ngxPermissionsOnly="PAYMENT:FUNDING:EDIT">
                  <button *ngIf="item.etat ==='INVALIDE'" (click)="editPayment(item)" type="button" class="btn btn-icon btn-primary ml-1"
                    ngbTooltip="Modifier">
                    <i class="feather icon-edit-2"></i>
                  </button>
                </ng-template>
                <ng-template ngxPermissionsOnly="PAYMENT:FUNDING:VALIDATE">
                  <button *ngIf="construction === 'TREASURY' && item.etat ==='INVALIDE'" (click)="validatePayment(item)" type="button" class="btn btn-icon  btn-success" ngbTooltip="Valider">
                    <i class="fas fa-check"></i>
                  </button>
                </ng-template>
                <ng-template ngxPermissionsOnly="PAYMENT:FUNDING:PRINTER:SHOW">
                  <button *ngIf="item.etat === 'VALIDE'" (click)="printerPayment(item)" type="button" class="btn btn-icon btn-warning ml-1"
                    ngbTooltip="Imprimer">
                    <i class="feather icon-printer"></i>
                  </button>
                </ng-template>
                <button *ngxPermissionsOnly="'PAYMENT:FUNDING:DELETE'" type="button" (click)="delete(item)" class="btn btn-icon btn-danger ml-1" ngbTooltip="Supprimer">
                  <i class="feather icon-trash"></i>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td [attr.colspan]="construction !== 'SHOW' ? 5 : 4" class="font-weight-bold font-size-40 text-right">TOTAL</td>
            <td class="font-weight-bold font-size-40 text-success"> {{total | number}} {{global.device}}</td>
            <td *ngIf="action"></td>
          </tr>
        </tfoot>
      </table>
    </div>
  </app-card>
</div>
