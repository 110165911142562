import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-plan-modele-show',
  templateUrl: './plan-modele-show.component.html',
  styleUrls: ['./plan-modele-show.component.scss']
})
export class PlanModeleShowComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
