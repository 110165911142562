<ng-container *ngIf="!item.hidden &&
  (item?.id != 'proprietaire' || autorisation?.CONTRAT) &&
  (item?.id != 'locataire' || autorisation?.CONTRAT) &&
  (item?.id != 'client' || autorisation?.DOSSIER) &&
  (item?.id != 'promotion' || autorisation?.DOSSIER) &&
  (item?.id != 'lotissement' || autorisation?.DOSSIER) &&
  (item?.id != 'dashboard' || isPermissionExists('MENU:DASHBOARD:TRUSTEE')) &&
  (item?.id != 'syndic' || isPermissionExists('MENU:TRUSTEE')) &&
  (item?.id != 'proprietaires' || isPermissionExists('MENU:OWNER')) &&
  (item?.id != 'budget' || isPermissionExists('MENU:BUDGET')) &&
  (item?.id != 'tresorerie' || isPermissionExists('MENU:TREASURY')) &&

  (item?.id != 'prestataire' || isPermissionExists('MENU:GR:TIERS')) &&
  (item?.id != 'construction' || isPermissionExists('MENU:GR:CONSTRUCTION')) &&
  
  (item?.id != 'rapport' || isPermissionExists('MENU:REPORT')) &&
  (item?.id != 'syndic' || isPermissionExists('MENU:REPORT:TRUSTEE')) &&
  
  (item?.id != 'utilisateur' || isPermissionExists('MENU:SECURITE:USER'))">
  <li [ngClass]="item.classes" *ngIf="item.url && !item.external" [routerLinkActive]="['active']">
    <a class="nav-link" [target]="item.target ? '_blank' : '_self'" [routerLink]="[item.url]" (click)="closeOtherMenu($event)">
      <ng-container *ngTemplateOutlet="itemIcon"></ng-container>
      <span class="pcoded-mtext" *ngIf="item.icon; else directTitle">{{ item.title }}</span>
      <ng-template #directTitle>
        {{item.title}}
      </ng-template>
      <ng-container *ngTemplateOutlet="itemBadge"></ng-container>
    </a>
  </li>
  <li [ngClass]="item.classes" *ngIf="item.url && item.external">
    <a [target]="item.target ? '_blank' : '_self'" [href]="item.url">
      <ng-container *ngTemplateOutlet="itemIcon"></ng-container>
      <span class="pcoded-mtext" *ngIf="item.icon; else directTitle">{{ item.title }}</span>
      <ng-template #directTitle>
        {{item.title}}
      </ng-template>
      <ng-container *ngTemplateOutlet="itemBadge"></ng-container>
    </a>
  </li>
  <ng-template #itemIcon>
    <span *ngIf="item.icon" class="pcoded-micon"><i class="feather" [ngClass]="item.icon"></i></span>
  </ng-template>
  <ng-template #itemBadge>
    <span *ngIf="item.badge && themeLayout === 'vertical'" class="pcoded-badge badge" [ngClass]="item.badge.type">
      {{item.badge.title}}
    </span>
    <span *ngIf="item.badge && themeLayout === 'horizontal'" class="badge label" [ngClass]="item.badge.type">
      {{item.badge.title}}
    </span>
  </ng-template>
</ng-container>
