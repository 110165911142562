<div class="user-profile user-card mb-4" *ngIf="provider">
  <div class="card-body py-0">
    <div class="user-about-block m-0">
      <div class="row">
        <div class="col-md-3 text-center mt-n5">
          <div class="change-profile text-center">
            <div class="dropdown w-auto d-inline-block" ngbDropdown>
              <a class="dropdown-toggle" data-toggle="dropdown" ngbDropdownToggle aria-haspopup="true"
                aria-expanded="false">
                <div class="profile-dp">
                  <div class="position-relative d-inline-block">
                    <img class="img-radius img-fluid wid-100 hei-100"
                      [src]="provider.photoSrc ? publicUrl+'/'+provider.photoSrc : 'assets/images/avatar-default.png'"
                      alt="">
                  </div>
                  <div class="overlay">
                    <span>change</span>
                  </div>
                </div>
                <div class="certificated-badge">
                  <i class="fas fa-certificate text-c-blue bg-icon"></i>
                  <i class="fas fa-check front-icon text-white"></i>
                </div>
              </a>
            </div>
          </div>
          <h5 class="mb-1">{{ provider?.nom }}</h5>
          <p class="mb-2 text-muted text-uppercase">{{ provider?.type }}</p>
        </div>
        <div class="col-md-9 mt-md-4">
          <div class="row">
            <div class="col-md-6">
              <h5 class="mb-1 mt-3">Solde <span class="badge badge-warning">
                  {{ provider?.solde | number }} XOF
                </span></h5>
              <p class="mb-1 text-muted d-flex align-items-end text-h-primary">
                <i class="fas fa-business-time mr-2"></i>{{provider?.profession}}
              </p>
              <div class="clearfix"></div>
              <p class="mb-1 text-muted d-flex align-items-end text-h-primary">
                <i class="feather icon-phone mr-2 f-18"></i>{{provider?.telephone}}
              </p>
            </div>
            <div class="col-md-6">
              <div class="media">
                <i class="feather icon-map-pin mr-2 mt-1 f-18"></i>
                <div class="media-body">
                  <p class="mb-0 text-muted">{{provider?.domicile}}</p>
                </div>
              </div>
              <div class="media">
                <i class="feather icon-mail mr-2 f-18"></i>
                <div class="media-body">
                  <p class="mb-0 text-muted">{{provider?.email}}</p>
                </div>
              </div>
            </div>
          </div>
          <!-- MENU TABS PRESTATAIRE -->
          <ul class="nav nav-tabs profile-tabs nav-fill" id="tabShowProvider" role="tablist">
            <li class="nav-item">
              <a class="nav-link text-reset" [ngClass]="{'active': this.activeTab === 'PRESTATAIRE'}"
                (click)="onChangeLoad('PRESTATAIRE')" id="profil-tab" data-toggle="tab" href="javascript:" role="tab"
                aria-controls="profil" aria-selected="true">
                <i class="far fa-user"></i> Profil
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link text-reset" [ngClass]="{'active': activeTab === 'DEVIS'}"
                (click)="onChangeLoad('DEVIS')" id="bien-tab" data-toggle="tab" href="javascript:" role="tab"
                aria-controls="bien" aria-selected="true">
                <i class="fas fa-clipboard"></i> Devis
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link text-reset" [ngClass]="{'active': activeTab === 'BON'}" (click)="onChangeLoad('BON')"
                id="bien-tab" data-toggle="tab" href="javascript:" role="tab" aria-controls="bien" aria-selected="true">
                <i class="fas fa-clipboard"></i> Bons de commandes
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link text-reset" [ngClass]="{'active': activeTab === 'FACTURE'}"
                (click)="onChangeLoad('FACTURE')" id="bien-tab" data-toggle="tab" href="javascript:" role="tab"
                aria-controls="bien" aria-selected="true">
                <i class="fas fa-clipboard"></i> Factures
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link text-reset" [ngClass]="{'active': activeTab === 'PAYMENT'}"
                (click)="onChangeLoad('PAYMENT')" id="bien-tab" data-toggle="tab" href="javascript:" role="tab"
                aria-controls="bien" aria-selected="true">
                <i class="fas fa-clipboard"></i> Paiements
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link text-reset" [ngClass]="{'active': activeTab === 'CONTRAT'}"
                (click)="onChangeLoad('CONTRAT')" id="bien-tab" data-toggle="tab" href="javascript:" role="tab"
                aria-controls="bien" aria-selected="true">
                <i class="fas fa-clipboard"></i> Contrats
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- RETOUR -->
<div class="row">
  <div class="col-sm-12 mb-2">
    <button (click)="back()" type="button" class="btn btn-secondary m-1">
      <i class="fa fa-arrow-alt-circle-left"></i> Retour
    </button>
  </div>
</div>

<!-- FILTRE DE RECHERCHE -->
<div class="row" *ngIf="activeTab !== 'PRESTATAIRE'">
  <ng-template [ngxPermissionsOnly]="['PRODUCT:LIST']">
    <app-filter class="width" [name]="true" [nameTitle]="nameTitle" [user]="true" [userTitle]="userTitle" [etat]="true"
      [etatRow]="etatRow" [etatTitle]="etatTitle" [categorie]="true" [categorieRow]="false"
      [categorieTitle]="categorieTitle" [dateD]="true" [dateF]="true" [create]="true" [min]="true" [minTitle]="minTitle"
      [max]="true" [maxTitle]="maxTitle" [ordre]="true" [code]="true" [count]="true" [type]="true" [typeRow]="typeRow"
      [uuid]="provider.uuid" (filterEvent)="onFilter($event)">
    </app-filter>
  </ng-template>
  <ng-template [ngxPermissionsExcept]="['PRODUCT:LIST']">
    <div class="col-md-12">
      <app-no-droit [title]="'prestataire details'"></app-no-droit>
    </div>
  </ng-template>
</div>

<!-- AFFICHAGE DU TABS -->
<div class="row">
  <div class="col-md-12 order-md-2">
    <div class="tab-content" id="myTabContent">
      <ng-template ngxPermissionsOnly="PROVIDER:LIST">
        <div class="tab-pane fade" [ngClass]="{'show active': activeTab === 'PRESTATAIRE'}" id="profil" role="tabpanel"
          aria-labelledby="profil-tab">
          <div class="card">
            <div class="col-md-12 mt-2 text center">
              <div class="d-flex">
                <!-- COL GAUCHE -->
                <div class="flex-fill">
                  <p>
                    <span class="title-show">
                      {{ provider?.type === 'ENTREPRISE' ? 'Raison sociale' : 'Nom et prénoms' }} :
                    </span>
                    <span class="title-result-show">
                      {{ provider?.type === 'ENTREPRISE' ? provider?.nom : provider?.civilite + ' ' + provider?.nom }}
                    </span>
                  </p>
                  <p>
                    <span class="title-show">Type : </span>
                    <span class="title-result-show">{{ provider?.type }}</span>
                  </p>
                  <p *ngIf="provider?.type === 'ENTREPRISE'">
                    <span class="title-show">Forme juridique: </span>
                    <span class="title-result-show">{{ provider?.juridique }}</span>
                  </p>
                  <p>
                    <span class="title-show">Groupe : </span>
                    <span class="title-result-show">{{ provider?.groupe }}</span>
                  </p>
                  <p>
                    <span class="title-show">Coprs de metier : </span>
                    <span class="title-result-show">{{ provider?.job?.libelle }}</span>
                  </p>
                  <p>
                    <span class="title-show">Email : </span>
                    <span class="title-result-show">{{ provider?.email }}</span>
                  </p>
                  <p>
                    <span class="title-show">Service fournis : </span>
                    <span class="title-result-show">{{ provider?.service }}</span>
                  </p>
                </div>
                <!-- COL MILLIEU -->
                <div class="flex-fill">
                  <p *ngIf="provider?.type === 'ENTREPRISE'">
                    <span class="title-show">N° Compte contribualbe: </span>
                    <span class="title-result-show">{{ provider?.ncc }}</span>
                  </p>
                  <p *ngIf="provider?.type === 'ENTREPRISE'">
                    <span class="title-show">N° Registre de commerce: </span>
                    <span class="title-result-show">{{ provider?.siegeSocial }}</span>
                  </p>
                  <p>
                    <span class="title-show">Code comptable: </span>
                    <span class="title-result-show">{{ provider?.auxiliary?.libelle }} {{ provider?.auxiliary?.numero
                      }}</span>
                  </p>
                  <p>
                    <span class="title-show">Numéro compte auxiliaire: </span>
                    <span class="title-result-show">{{ provider?.compte?.libelle }} {{ provider?.compte?.numero
                      }}</span>
                  </p>
                  <p>
                    <span class="title-show">Telephone : </span>
                    <span class="title-result-show">{{ provider?.telephone }}</span>
                  </p>
                  <p>
                    <span class="title-show">Code postal : </span>
                    <span class="title-result-show">{{ provider?.codePostal }}</span>
                  </p>
                  <p>
                    <span class="title-show">Nature de la pièce: </span>
                    <span class="title-result-show">{{ provider?.naturePiece }}</span>
                  </p>
                  <p *ngIf="provider?.type === 'PARTICULIER'">
                    <span class="title-show">Numéro de la pièce: </span>
                    <span class="title-result-show">{{ provider?.numPiece }}</span>
                  </p>
                </div>
                <!-- COL DROITE -->
                <div class="flex-fill">
                  <p>
                    <span class="title-show">Pays: </span>
                    <span class="title-result-show">{{ provider?.country?.nom }}</span>
                  </p>
                  <p>
                    <span class="title-show">Ville: </span>
                    <span class="title-result-show">{{ provider?.ville }}</span>
                  </p>
                  <p>
                    <span class="title-show">Quartier: </span>
                    <span class="title-result-show">{{ provider?.quartier }}</span>
                  </p>
                  <p>
                    <span class="title-show">Crée le : </span>
                    <span class="title-result-show">{{ provider?.createdAt | date: "d MMMM y 'à' h:mm:ss" : '' : 'fr-FR'
                      }}</span>
                    <span class="title-show ml-5"> Par : </span>
                    <span class="title-result-show">{{ provider?.create }}</span>
                  </p>
                  <p>
                    <span class="title-show">Modifié le : </span>
                    <span class="title-result-show">{{ provider?.updatedAt | date: "d MMMM y 'à' h:mm:ss" : '' : 'fr-FR'
                      }}</span>
                    <span class="title-show ml-5"> Par : </span>
                    <span class="title-result-show">{{ provider?.update }}</span>
                  </p>
                </div>
              </div>
              <div class="modal-footer">
                <button *ngxPermissionsOnly="'PROVIDER:EDIT'" (click)="editProvider(provider)" type="button"
                  class="btn btn-primary" ngbTooltip="Modifier">
                  Modifier <i class="feather icon-edit-2"></i>
                </button>
                <button *ngxPermissionsOnly="'PROVIDER:PRINTER:SHOW'" (click)="printerProvider(provider)"
                  class="btn btn-warning">
                  Imprimer <i class="feather icon-printer"></i>
                </button>
                <button *ngxPermissionsOnly="'PROVIDER:DELETE'" (click)="delete(provider)" type="button"
                  class="btn btn-danger text-left" data-dismiss="modal" ngbTooltip="Supprimer">
                  Supprimer <i class="feather icon-trash"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template ngxPermissionsExcept="PROVIDER:LIST" [ngxPermissionsOnly]="['PRODUCT:LIST']">
        <div class="col-md-12">
          <app-no-droit *ngIf="activeTab === 'PRESTATAIRE'" [title]="'liste prestataire'"></app-no-droit>
        </div>
      </ng-template>

      <div class="tab-pane fade" [ngClass]="{'show active': activeTab === 'DEVIS'}" id="bien" role="tabpanel"
        aria-labelledby="bien-tab">
        <div class="row">
          <div class="col-sm-12 mb-4 btn-all-construction">
            <div class="btn-group float-right m-1 btn-outil-construction" ngbDropdown>
              <button *ngxPermissionsOnly="['CONSTRUCTION:NEW']" (click)="addQuote()" type="button"
                class="btn btn-primary m-1" ngbTooltip="Cliquez ici pour ajouter un devis">
                Créer un devis <i class="fas fa-clipboard"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="row">
          <!-- LISTE DES DEVIS-->
          <div class="col-md-12" *ngIf="quotes && quotes.length === 0">
            <span class="badge badge-secondary mb-4 f-14 width">
              LISTE DES DEVIS
            </span>
            <app-no-data [title]="'Aucun devis trouvé'" *ngIf="quotes && quotes.length === 0"></app-no-data>
          </div>
          <app-quote-list *ngIf="quotes && quotes.length > 0" [quotes]="quotes" [prestataire]="prestataire"
            class="width"></app-quote-list>
        </div>
      </div>
      <div class="tab-pane fade" [ngClass]="{'show active': activeTab === 'BON'}" id="bien" role="tabpanel"
        aria-labelledby="bien-tab">
        <div class="row">
          <div class="col-sm-12 mb-4 btn-all-construction">
            <div class="btn-group float-right m-1 btn-outil-construction" ngbDropdown>
              <button *ngxPermissionsOnly="['CONSTRUCTION:NEW']" (click)="addBon()" type="button"
                class="btn btn-primary m-1" ngbTooltip="Cliquez ici pour ajouter un bon de commande">
                Créer un bon de commande <i class="fas fa-clipboard"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="row">
          <!-- LISTE DES BONS DE COMMANDE-->
          <div class="col-md-12" *ngIf="bons && bons.length === 0">
            <span class="badge badge-secondary mb-4 f-14 width">
              LISTE DES BONS DE COMMANDE
            </span>
            <app-no-data [title]="'Aucun bon de commande trouvé'" *ngIf="bons && bons.length === 0"></app-no-data>
          </div>
          <app-quote-list *ngIf="bons && bons.length > 0" [quotes]="bons" [isBon]="true" [prestataire]="prestataire"
            class="width"></app-quote-list>
        </div>
      </div>
      <div class="tab-pane fade" [ngClass]="{'show active': activeTab === 'FACTURE'}" id="bien" role="tabpanel"
        aria-labelledby="bien-tab">
        <div class="row">
          <div class="col-sm-12 mb-4 btn-all-construction">
            <div class="btn-group float-right m-1 btn-outil-construction" ngbDropdown>
              <div class="btn-InvoiceCo">
                <button *ngxPermissionsOnly="['CONSTRUCTION:NEW']" (click)="addInvoiceCo()" type="button"
                  class="btn btn-primary m-1" ngbTooltip="Cliquez ici pour ajouter une facture">
                  Créer une Facture <i class="fas fa-clipboard"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <!-- LISTE DES FACTURE-->
          <div class="col-md-12" *ngIf="invoices && invoices.length === 0">
            <span class="badge badge-secondary mb-4 f-14 width">
              LISTE DES FACTURES
            </span>
            <app-no-data [title]="'Aucune facture trouvée'" *ngIf="invoices && invoices.length === 0"></app-no-data>
          </div>
          <app-invoice-co-list *ngIf="invoices && invoices.length > 0" [invoiceCos]="invoices"
            [prestataire]="prestataire" class="width"></app-invoice-co-list>
        </div>
      </div>
      <div class="tab-pane fade" [ngClass]="{'show active': activeTab === 'PAYMENT'}" id="bien" role="tabpanel"
        aria-labelledby="bien-tab">
        <div class="row">
          <!-- LISTE DES PAIEMENTS-->
          <div class="col-md-12" *ngIf="payments && payments.length === 0">
            <span class="badge badge-secondary mb-4 f-14 width">
              LISTE DES PAIEMENTS
            </span>
            <div class="col-md-12" *ngIf="payments && payments.length === 0">
              <app-no-data [title]="'Aucun paiement trouvé'" *ngIf="payments && payments.length === 0"></app-no-data>
            </div>
          </div>
          <app-trustee-provider-payment-list class="width" *ngIf="payments && payments.length > 0"
            [payments]="payments"></app-trustee-provider-payment-list>
        </div>
      </div>
      <div class="tab-pane fade" [ngClass]="{'show active': activeTab === 'CONTRAT'}" id="bien" role="tabpanel"
        aria-labelledby="bien-tab">
        <div class="row">
          <div class="col-sm-12 mb-4 btn-all-construction">
            <div class="btn-group float-right m-1 btn-outil-construction" ngbDropdown>
              
              <button *ngxPermissionsOnly="['CONSTRUCTION:NEW']" (click)="addProviderContract()" type="button"
                class="btn btn-primary m-1" ngbTooltip="Cliquez ici pour ajouter un contrat">
                Créer un contrat<i class="fas fa-clipboard"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="row">
          <!-- LISTE DES CONTRATS-->
          <div class="col-md-12" *ngIf="contrats && contrats.length === 0">
            <span class="badge badge-secondary mb-4 f-14 width">
              LISTE DES CONTRATS
            </span>
            <div class="col-md-12" *ngIf="contrats && contrats.length === 0">
              <app-no-data [title]="'Aucun contrat trouvé'" *ngIf="contrats && contrats.length === 0"></app-no-data>
            </div>
          </div>
          <app-provider-contract-list class="width" *ngIf="contrats && contrats.length > 0"
            [providerContracts]="contrats"></app-provider-contract-list>
        </div>
      </div>
    </div>
  </div>
</div>