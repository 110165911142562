import { NgModule } from '@angular/core';
import { LOCALE_ID } from '@angular/core';
import { ChartModule } from 'angular2-chartjs';
import { CommonModule } from '@angular/common';
import { SharedModule } from "@theme/shared/shared.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AngularHighchartsChartModule } from 'angular-highcharts-chart';
import { DashboardRoutingModule } from '@dashboard/dashboard-routing.module';
import { DashDefaultComponent } from '@dashboard/dash-default/dash-default.component';
import { DashLocataireComponent } from '@dashboard/dash-locataire/dash-locataire.component';
import { DashProprietaireComponent } from '@dashboard/dash-proprietaire/dash-proprietaire.component';
import { DashPromotionComponent } from '@dashboard/dash-promotion/dash-promotion.component';
import { DashLotissementComponent } from '@dashboard/dash-lotissement/dash-lotissement.component';
import { DashSuiviComponent } from './dash-suivi/dash-suivi.component';
import { DashSyndicComponent } from './dash-syndic/dash-syndic.component';
import { DashBudgetComponent } from './dash-budget/dash-budget.component';
import { AgmCoreModule } from '@agm/core';
import { ExtraModule } from '@agence/extra/extra.module';



@NgModule({
  declarations: [
    DashDefaultComponent,
    DashLocataireComponent,
    DashProprietaireComponent,
    DashPromotionComponent,
    DashLotissementComponent,
    DashSuiviComponent,
    DashSyndicComponent,
    DashBudgetComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    ExtraModule,
    ReactiveFormsModule,
    FormsModule,
    DashboardRoutingModule,
    SharedModule,
    ChartModule,
    AngularHighchartsChartModule,
    AgmCoreModule.forRoot({ apiKey: 'AIzaSyCR7wdbPJRZh49cZ5lq_LCw-Eb0CFNSCdI' }),
  ],
  providers: [{ provide: LOCALE_ID, useValue: "fr-FR" }],
})
export class DashboardModule { }
