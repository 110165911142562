
import {House} from '@model/house';
import {Folder} from '@model/folder';
import {Funding} from '@model/funding';
import {ToastrService} from 'ngx-toastr';
import {Repayment} from '@model/repayment';
import { Globals } from '@theme/utils/globals';
import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { HouseService } from '@service/house/house.service';
import {ValidatorsEnums} from '@theme/enums/validators.enums';
import { FolderService } from '@service/folder/folder.service';
import {InvoiceService} from '@service/invoice/invoice.service';
import {FundingService} from '@service/funding/funding.service';
import { EmitterService } from '@service/emitter/emitter.service';
import {DateHelperService} from '@theme/utils/date-helper.service';
import {RepaymentService} from '@service/repayment/repayment.service';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-repayment-add',
  templateUrl: './repayment-add.component.html',
  styleUrls: ['./repayment-add.component.scss']
})
export class RepaymentAddComponent implements OnInit {
  title: string = '';
  form: FormGroup;
  submit: boolean = false;
  edit: boolean = false;
  type: string = 'VENTE';
  ownerSelected?: any;
  customerSelected?: any;
  isLoadingFolder = false;
  isLoadingHouse = false;
  repayment: Repayment;
  montant: number = 0
  required = Globals.required;
  montantV: number = 0
  commission: number = 0
  houses: House[];
  folders: Folder[];
  folder: Folder;
  house: House;
  fundings: Funding[];
  row?: any;
  montantFunding: number = 0;
  totalAverser: number = 0;
  totalOptionTVA: any = 0;
  totalOptionHT: any = 0;
  totalOptionTTC: any = 0;
  totalOptionRemise: any = 0;
  typeRow = [
    {label: 'BIEN EN VENTE', value: 'VENTE'},
    {label: 'BIEN EN LOCATION', value: 'LOCATION'}
  ];
  filtreRow = [
    {label: 'Date de loyer', value: 'LOYER'},
    {label: 'Date de reglement', value: 'PAIEMENT'}
  ];
  global = {country: Globals.country, device: Globals.device}
  userSEssion = Globals.user;

  constructor(
    public modal: NgbActiveModal,
    public toastr: ToastrService,
    private formBuild: FormBuilder,
    private emitter: EmitterService,
    private folderService: FolderService,
    private houseService: HouseService,
    private invoiceService: InvoiceService,
    private fundingService: FundingService,
    private repaymentService: RepaymentService
  ) {
    this.edit = this.repaymentService.edit;
    this.type = this.repaymentService.type;
    this.repayment = this.repaymentService.getRepayment();
    this.title = (!this.edit) ? 'Ajouter un reversement' : 'Modifier le reversement de type ' + this.repayment?.type + ' N°' +this.repayment?.code;
    this.newForm();
  }

  ngOnInit(): void {
    this.editForm();
  }

  newForm() {
    const defaults = {
      uuid: [null],
      selectAll: [false],
      id: [null],
      owner: [null, [Validators.required]],
      type: [this.type, [Validators.required]],
      montant: [0, [Validators.pattern(ValidatorsEnums.decimal), Validators.min(0)]],
      montantRemise: [0],
      montantFunding: [0],
      montantTva: [0],
      montantHt: [0],
      options: this.formBuild.array([]),
      optionsFunding: this.formBuild.array([]),
    };
    switch (this.type) {
      case 'VENTE': {
        Object.assign(defaults, {
          folder: [null, [Validators.required]],
          customer: [null, [Validators.required]],
          montantVerse: [0, [Validators.pattern(ValidatorsEnums.decimal)]],
          prcCom: [0, [Validators.pattern(ValidatorsEnums.decimal)]],
          montantPaye: [0, [Validators.pattern(ValidatorsEnums.decimal)]],
          montantRCal: [0, [Validators.pattern(ValidatorsEnums.decimal)]],
          montantImpot: [0, [Validators.pattern(ValidatorsEnums.decimal)]],
          montantImpaye: [0, [Validators.pattern(ValidatorsEnums.decimal)]],
          commission: [{value: 0, disabled: true}, [Validators.pattern(ValidatorsEnums.decimal)]],
        });
        break;
      }
      case 'LOCATION': {
        Object.assign(defaults, {
          house: [null, [Validators.required]],
          dateD: [null, [Validators.required]],
          dateF: [null, [Validators.required]],
          typeFiltre: [null, [Validators.required]],
          tva: [0, [Validators.pattern(ValidatorsEnums.decimal)]],
          impot: [0, [Validators.pattern(ValidatorsEnums.decimal)]],
          verse: [0, [Validators.pattern(ValidatorsEnums.decimal)]],
          prcImpot: [0, [Validators.pattern(ValidatorsEnums.decimal)]],
          prcTva: [0, [Validators.pattern(ValidatorsEnums.decimal)]],
          commission: [0, [Validators.pattern(ValidatorsEnums.decimal)]],
          loyer: [0, [Validators.pattern(ValidatorsEnums.decimal)]],
          optionsPayment: this.formBuild.array([]),
        });
        break;
      }
    }
    this.form = this.formBuild.group(defaults);
  }
  editForm() {
    if (this.edit) {
      const data = {...this.repaymentService.getRepayment()};
      this.ownerSelected = {
        photoSrc: data.owner?.photoSrc,
        title: data.owner?.searchableTitle,
        detail: data.owner?.searchableDetail
      };
      this.customerSelected = {
        photoSrc: data.folder?.customer?.photoSrc,
        title: data.folder?.customer?.searchableTitle,
        detail: data.folder?.customer?.searchableDetail
      };
      if(data?.type === 'VENTE'){
        this.folder = data?.folder;
        this.montant = data?.aReverser;
        this.f.prcCom.setValue(data?.prcCom);
        this.f.owner.setValue(data?.owner.uuid);
        this.f.customer.setValue(data?.folder?.customer?.uuid);
        this.f.folder.setValue(data?.folder.uuid);
        this.f.commission.setValue(data?.commission);
        this.f.montantRCal.setValue(this.montant);
      }
      if(data?.type === 'LOCATION'){
        this.house = data?.house;
        this.f.house.setValue(data?.house?.uuid);
        this.f.typeFiltre.setValue(data?.filtre);
        data?.optionInvoiceRepayments.forEach((item) => {
          this.optionsP.push(
            this.formBuild.group({
              uuid: [item?.uuid],
              checked: [{value: true, disabled: false}, [Validators.required]],
              locataire: [{value: item?.invoice?.contract?.tenant?.searchableTitle, disabled: true}, [Validators.required]],
              locative: [{value: item?.invoice?.contract?.rental?.libelle, disabled: true}, [Validators.required]],
              libelle: [{value: item?.invoice?.libelle, disabled: true}, [Validators.required]],
              loyer: [{value: item?.loyer, disabled: true}, [Validators.required]],
              commission: [{value: item?.commission, disabled: true}, [Validators.required]],
              tvaCom: [{value: item?.tva, disabled: true}, [Validators.required]],
              impot: [{value: item?.impot, disabled: true}, [Validators.required]],
              verse: [{value: item?.verse, disabled: true}, [Validators.required]],
              montant: [{value: item?.montant, disabled: true}, [Validators.required]],
            })
          );
        });
      }
      //@ts-ignore
      data.house = data.house?.uuid;
      //@ts-ignore
      data.owner = data.owner?.uuid;
      this.f.montantHt.setValue(data?.montantHt);
      this.f.montantRemise.setValue(data?.montantRemise);
      this.f.montantTva.setValue(data?.montantTva);
      this.f.montantFunding.setValue(data?.montantFunding);
      this.montantFunding = data?.montantFunding;
      this.form.patchValue(data);
      this.f.dateF.setValue(DateHelperService.fromJsonDate(data?.dateF));
      this.f.dateD.setValue(DateHelperService.fromJsonDate(data?.dateD));
      data?.optionFungings.forEach((item) => {
        this.optionsF.push(
          this.formBuild.group({
            uuid: [item.uuid],
            checked: [{value: true}, [Validators.required]],
            periode: [{value: item?.periode, disabled: true}, [Validators.required]],
            montant: [{value: item?.mensualite, disabled: true}, [Validators.required]],
            libelle: [{value: 'Financement N° '+ item?.funding?.construction?.code, disabled: true}, [Validators.required]]
          })
        );
      });
      data?.options.forEach((item) => {
        this.options.push(
          this.formBuild.group({
            uuid: [item.uuid],
            id: [item.id],
            libelle: [item.libelle, [Validators.required]],
            prix: [item.prix, [Validators.required, Validators.pattern(ValidatorsEnums.number), Validators.min(0)]],
            qte: [item.qte, [Validators.required, Validators.pattern(ValidatorsEnums.number), Validators.min(1)]],
            tva: [item.tva, [Validators.required, Validators.pattern(ValidatorsEnums.number), Validators.min(0)]],
            remise: [item.remise, [Validators.required, Validators.pattern(ValidatorsEnums.number), Validators.min(0)]],
            total: [item.total, [Validators.required, Validators.pattern(ValidatorsEnums.number), Validators.min(0)]],
          })
        );
      });
      this.totalAverser = data?.montantLoyer;
      this.onChangeTotalCalcul();
    }
  }
  setOwnerUuid(uuid) {
    if (uuid) {
      this.f.owner.setValue(uuid);
      if (this.f.type.value == "VENTE") {
        this.loadFolders();
      }
      if (this.f.type.value == "LOCATION") {
        this.loadHouses();
      }
    } else {
      if (this.f.type.value == "VENTE") {
        this.folders = [];
        this.f.folder.setValue(null);
      }
      if (this.f.type.value == "LOCATION") {
        this.houses = [];
        this.f.house.setValue(null);
      }
    }
  }
  setCustomerUuid(uuid){
    if (uuid) {
      this.f.customer.setValue(uuid);
      this.loadFolders();
    } else {
      this.houses = [];
      this.f.folder.setValue(null);
    }
  }
  onLoadData(){
    this.f.selectAll.setValue(false)

    this.f.tva.setValue(0)
    this.f.loyer.setValue(0)
    this.f.verse.setValue(0)
    this.f.impot.setValue(0)
    this.f.montant.setValue(0)

    if(!this.edit){
      this.invoiceService.getList(null,null,null,null,this.f.house.value, this.f.dateD.value, this.f.dateF.value, null, this.f.typeFiltre.value).subscribe(res =>{
        if (res) {
          this.optionsP.controls = this.itemOptionPayment(res)
        }
      })
    }
    this.loadFunding()
  }
  loadFolders() {
    this.folders = [];
    this.f.folder.setValue(null)
    if (!this.f.customer.value && !this.f.owner.value) {
      this.isLoadingFolder = false;
      return;
    }
    if (this.f.customer.value && this.f.owner.value) {
      this.isLoadingFolder = true;
      this.folderService.getList(this.f.customer.value, 'VALIDE', this.f.owner.value).subscribe(
        res => {
          this.isLoadingFolder = false;
          this.folders = res;
          return this.folders;
        }, error => {
        this.isLoadingFolder = false;
      });
    }
  }
  loadHouses() {
    this.houses = [];
    this.f.house.setValue(null)
    if (this.f.owner.value) {
      this.isLoadingHouse = true;
      this.houseService.getList(this.f.owner.value, 'LOCATION', 'OCCUPE', null).subscribe(
        res => {
          this.isLoadingHouse = false;
          this.houses = res;
          return this.houses;
        }, error => {
        this.isLoadingHouse = false;
      });
    }
  }
  setFolderUuid(value){
    if (!this.edit) {
      this.folder = this.folders.find(item => {
        if (item.uuid === value) {
          this.f.folder.setValue(item.uuid);
          return item;
        }
      });
    }
    this.f.folder.setValue(value);
    this.onCalculVente()
  }
  setHouseUuid(value){
    if (!this.edit) {
      this.house = this.houses.find(item => {
        if (item.uuid === value) {
          this.f.house.setValue(item.uuid);
          return item;
        }
      });
    }
    this.f.house.setValue(value);
  }
  itemOptionFunding(): FormGroup[] {
    var arr: any[] = []
    if(this.fundings && this.fundings.length > 0){
      this.fundings.forEach((funding) =>{
        funding?.options.forEach((item) =>{
          arr.push(
            this.formBuild.group({
              uuid: [item.uuid],
              checked: [{value: false, disabled: item?.reverse === 'OUI' ? true : false}, [Validators.required]],
              periode: [{value: item?.periode, disabled: true}, [Validators.required]],
              montant: [{value: item?.mensualite, disabled: true}, [Validators.required]],
              libelle: [{value: 'Financement N° '+funding?.construction?.code, disabled: true}, [Validators.required]]
            })
          )
        })
      })
    }
    return arr;
  }
  itemOptionPayment(row): FormGroup[] {
    var arr: any[] = []
    if(row && row.length > 0){
      row.forEach((item) =>{
        this.f.prcTva.setValue(item?.prcTva)
        this.f.prcImpot.setValue(item?.prcImpot)
        arr.push(
          this.formBuild.group({
            uuid: [item.facture],
            checked: [{value: false, disabled: false}, [Validators.required]],
            locataire: [{value: item?.locataire, disabled: true}, [Validators.required]],
            locative: [{value: item?.locative, disabled: true}, [Validators.required]],
            libelle: [{value: item?.libelle, disabled: true}, [Validators.required]],
            loyer: [{value: item?.loyer, disabled: true}, [Validators.required]],
            commission: [{value: item?.commission, disabled: true}, [Validators.required]],
            tvaCom: [{value: item?.tvaCom, disabled: true}, [Validators.required]],
            impot: [{value: item?.impot, disabled: true}, [Validators.required]],
            verse: [{value: item?.verse, disabled: true}, [Validators.required]],
            montant: [{value: item?.montant, disabled: true}, [Validators.required]],
          })
        )
      })
    }
    return arr;
  }
  onCalculVente() {
    this.montantV = this.folder?.montant - this.folder?.frais
    if (!this.edit) {
      this.montant = this.folder?.invoice?.montantNonReverse
      if (this.folder?.repayments?.length <= 0) {
        // calcule des frais a retranche au premier reversement
        this.montant =  this.montant - this.folder?.frais > 0 ? this.montant - this.folder?.frais : 0
        this.f.montantRCal.setValue(this.montant)
      } else {
        this.f.montantRCal.setValue(this.montant)
      }
    }

    if (this.folder?.houses.length <= 1) {
      var house = this.folder?.houses[0].house
      if (house.mandate?.verseCom === 'TOTALITE') {
        this.commission = house.mandate?.montantCom
      }else{
        this.commission = (this.f.montantRCal.value * house.mandate?.montantCom) / house.mandate?.valeur
      }
      this.f.prcCom.setValue(house.mandate?.commission)
    } else {
      this.commission = (this.f.prcCom.value * this.f.montantRCal.value) / 100
    }

    this.f.commission.setValue(this.commission)
    var mtnR = this.f.montantRCal.value - this.commission
    this.f.montant.setValue(mtnR)
    this.loadFunding()
  }
  onSelectAll($event) {
    var montant = 0;
    var commission = 0;
    var tva = 0;
    var impot = 0;
    var loyer = 0;
    var verse = 0;
    if (this.type === 'LOCATION') {
      this.optionsP.controls.forEach(item => {
        // @ts-ignore
        var ligne = item.getRawValue();
        tva += ligne.tvaCom;
        impot += ligne.impot;
        verse += ligne.verse;
        loyer += ligne.loyer;
        montant += ligne.montant;
        commission += ligne.commission;
        item.get('checked').setValue($event.target.checked);
      })
      this.f.tva.setValue(($event.target.checked) ? tva : 0);
      this.f.loyer.setValue(($event.target.checked) ? loyer : 0);
      this.f.verse.setValue(($event.target.checked) ? verse : 0);
      this.f.impot.setValue(($event.target.checked) ? impot : 0);
      this.f.commission.setValue(($event.target.checked) ? commission : 0);
      montant = ($event.target.checked) ? montant: 0;
      this.totalAverser = montant;
      this.onChangeTotalCalcul()
    }
  }
  onSelect(index, row) {
    var montant = 0
    var commission = 0
    var tva = 0
    var impot = 0
    var loyer = 0
    var verse = 0
    if (this.type === 'LOCATION') {
      tva = (row.controls.checked.value) ? this.f.tva.value + row.controls.tvaCom.value : this.f.tva.value - row.controls.tvaCom.value;
      impot = (row.controls.checked.value) ? this.f.impot.value + row.controls.impot.value : this.f.impot.value - row.controls.impot.value;
      loyer = (row.controls.checked.value) ? this.f.loyer.value + row.controls.loyer.value : this.f.loyer.value - row.controls.loyer.value;
      verse = (row.controls.checked.value) ? this.f.verse.value + row.controls.verse.value : this.f.verse.value - row.controls.verse.value;
      montant = (row.controls.checked.value) ? this.totalAverser + row.controls.montant.value : this.totalAverser - row.controls.montant.value;
      commission = (row.controls.checked.value) ? this.f.commission.value + row.controls.commission.value : this.f.commission.value - row.controls.commission.value;

      /*
        //LAISSE EN CAS D'ERREUR CE CODE SERA UTILISE
        let comm = 0;
        let select = 0;
        let noSelect = 0;
        this.optionsP.controls.forEach(item => {
          // @ts-ignore
          comm += item.getRawValue().commission;
        })
        this.optionsP.controls.forEach(item => {
          // @ts-ignore
          var ligne = item.getRawValue()
          if(ligne.checked === true) {
            select += ligne.commission;
          } else if(ligne.checked === false) {
            noSelect += ligne.commission;
          }
        })
      */
      this.f.tva.setValue(tva)
      this.f.loyer.setValue(loyer)
      this.f.verse.setValue(verse)
      this.f.impot.setValue(impot)
      this.f.commission.setValue(commission)
    }
    if (this.type === 'VENTE') {
      montant = (row.controls.checked.value) ? this.f.montant.value - row.controls.montant.value : this.f.montant.value + row.controls.montant.value;
    }
    this.totalAverser = montant;
    this.onChangeTotalCalcul();
  }
  onSelectFunding(index, row) {
    var total = 0;
    this.optionsF.controls.forEach(item => {
      // @ts-ignore
      var ligne = item.getRawValue();
      if(ligne.checked === true){
        total = ligne.checked === true ? total + ligne.montant : total - ligne.montant;
      }
    })
    this.montantFunding = total;
    this.f.montantFunding.setValue(this.montantFunding);
  }
  onSelectAllFunding($event){
    let total = 0;
    this.optionsF.controls.forEach(item => {
      // @ts-ignore
      var ligne = item.getRawValue();
      total += ligne.montant;
      item.get('checked').setValue($event.target.checked);
    })
    this.montantFunding = $event.target.checked === true ? total : 0;
    this.f.montantFunding.setValue(this.montantFunding);
  }
  loadFunding(){
    // selection des financement lie au proprietaire
    if (this.f.owner.value && !this.edit) {
      this.fundingService.getList(null, this.f.owner.value).subscribe(res =>{
        this.fundings = res;
        this.optionsF.controls = this.itemOptionFunding();
        return this.fundings;
      })
    }
  }
  onChangeTotalOption(row) {
    var total = ((row.value.prix * row.value.qte) - (row.value.remise >= 0 ? row.value.remise : 0)) + (row.value.tva >= 0 ? ((row.value.prix * row.value.qte) - row.value.remise) * (row.value.tva/100) : 0);
    row.controls.total.setValue(total);
    this.onChangeTotalCalcul();
  }
  onChangeTotalCalcul() {
    this.totalOptionHT = 0;
    this.totalOptionTVA = 0;
    this.totalOptionTTC = 0;
    this.totalOptionRemise = 0;
    var montant = 0;
    this.options.controls.forEach(elem => {
      var remise = elem.value.remise >= 0 ? elem.value.remise : 0;
      var totalHt = (elem.value.qte > 0) ? elem.value.prix * elem.value.qte : 0;
      var totalTva = (elem.value.tva >= 0 && (remise <= totalHt)) ? (totalHt - remise) * (elem.value.tva/100) : 0;
      var totalTtc = (totalHt - remise) + totalTva;
      this.totalOptionRemise += remise;
      this.totalOptionHT += totalHt;
      this.totalOptionTVA += totalTva;
      this.totalOptionTTC += totalTtc;
    });
    this.f.montantRemise.setValue(this.totalOptionRemise);
    this.f.montantTva.setValue(this.totalOptionTVA);
    this.f.montantHt.setValue(this.totalOptionHT);
    montant = (this.totalOptionTTC <= this.totalAverser) ? this.totalAverser - this.totalOptionTTC : 0;
    montant = montant - this.montantFunding;
    this.f.montant.setValue(montant);
  }
  onAddOption() {
    return this.options.push(
      this.formBuild.group({
        uuid: [null],
        id: [null],
        libelle: [null, [Validators.required]],
        prix: [0, [Validators.required, Validators.pattern(ValidatorsEnums.number), Validators.min(0)]],
        qte: [1, [Validators.required, Validators.pattern(ValidatorsEnums.number), Validators.min(1)]],
        tva: [0, [Validators.pattern(ValidatorsEnums.number), Validators.min(0)]],
        remise: [0, [Validators.pattern(ValidatorsEnums.number), Validators.min(0)]],
        total: [0, [Validators.required, Validators.pattern(ValidatorsEnums.number), Validators.min(0)]]
      })
    );
  }
  onDeleteOption(i) {
    this.options.removeAt(i);
    this.onChangeTotalCalcul();
  }
  onSubmit() {
    this.submit = true;
    if (this.f.montant.value <= 0) {
      this.toast('Le montant de la commission ne peut être supérieur au montant à reverser pour un versement en totalité.', 'Montant erroné', 'danger');
      return
    }
    if (!this.form.invalid) {
      const data = this.form.getRawValue();
      this.repaymentService.add(data).subscribe(res => {
        if (res?.status === 'success') {
          this.modal.close('ferme');
          if (data?.uuid) {
            this.emitter.emit({action: 'REPAYMENT_UPDATED', payload: res?.data});
          } else {
            this.emitter.emit({action: 'REPAYMENT_ADD', payload: res?.data});
          }
        }
      });
    } else { return; }
  }
  onConfirme() {
    Swal.fire({
      title: '',
      text: 'Confirmez-vous l\'enregistrement ?',
      icon: 'warning',
      showCancelButton: true,
      showCloseButton: true,
      confirmButtonText: 'Confirmer <i class="fas fa-check"></i>',
      cancelButtonText: 'Annuler <i class="feather icon-x-circle"></i>',
      confirmButtonColor: '#1bc943',
      reverseButtons: true
    }).then((willDelete) => {
      if (!willDelete.dismiss) {
        this.onSubmit();
      }
    });
  }
  onClose(){
    this.form.reset();
    this.modal.close('ferme');
  }
  toast(msg, title, type) {
    if (type == 'info') {
      this.toastr.info(msg, title);
    } else if (type == 'success') {
      this.toastr.success(msg, title);
    } else if (type == 'warning') {
      this.toastr.warning(msg, title);
    } else if (type == 'error') {
      this.toastr.error(msg, title);
    }
  }
  get f() { return this.form.controls; }
  get options() { return this.form.get('options') as FormArray; }
  get optionsF() { return this.form.get('optionsFunding') as FormArray; }
  get optionsP() { return this.form.get('optionsPayment') as FormArray; }
}
