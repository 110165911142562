<div class="card card-box rounded border-0 p-2">
    <div class="py-0">
        <h3 class="text-center">Comptes produits</h3>
    </div>
    <hr class="border-2">
    <!-- <app-no-data *ngIf="produits && produits.length === 0" [title]="'Aucun compte charge soc./fisc. trouvé'"></app-no-data> -->
    <!-- <div *ngIf="produits && produits.length > 0"> -->
        <span class="badge badge-primary my-2 f-14 width">LISTE DES COMPTES PRODUITS </span>
        <div class="table-responsive m-0">
            <table datatable [dtOptions]="dtOptions" class="table table-striped m-0">
                <thead class="thead-light">
                <tr>
                    <th>Réf</th>
                    <th>Libellé</th>
                    <th>Taux TVA</th>
                    <th>Compte dédié courant</th>
                    <th>Nouveau compte à assigner</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of produits">
                        <!-- <td>
                            <span>
                                {{item?.code}}
                            </span>
                        </td>
                        <td>
                            <span>
                                {{item?.libelle}}
                            </span>
                        </td>
                        <td>
                            <span class="badge badge-dark">
                                {{item?.type}}
                            </span>
                        </td>
                        <td>
                            <button type="button" (click)="show(item)" class="btn btn-icon btn-secondary" ngbTooltip="Détails">
                                <i class="fas fa-eye"></i>
                            </button>
                        </td> -->
                    </tr>
                </tbody>
            </table>
        </div>
    <!-- </div> -->
</div>