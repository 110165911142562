<div class="col-md-12">
  <span class="badge badge-warning my-2 f-14 formBadge width"> LISTE DES TYPES DE MAISON </span>
</div>
<div class="col-sm-12">
  <app-card [hidHeader]="true" cardClass="card-datatable">
    <div class="table-responsive">
      <table *ngIf="etat" datatable [dtOptions]="dtOptions" class="table table-sm table-striped table-bordered nowrap table-hover">
        <thead class="thead-light">
          <tr>
            <th>Libellé</th>
            <th>Description</th>
            <th>Crée le</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of homeTypes">
            <td>{{item?.libelle}}</td>
            <td>{{item?.description}}</td>
            <td>{{item.createdAt|date: "d MMMM y 'à' h:mm:ss" : '' : 'fr-FR'}}</td>
            <td class="table-action">
              <div class="overlay-edit">
                <button (click)="showHomeType(item)" type="button" class="btn btn-icon btn-secondary ml-1"
                  ngbTooltip="Détails">
                  <i class="fas fa-eye"></i>
                </button>
                <button *ngxPermissionsOnly="'HOME:TYPE:EDIT'" (click)="editHomeType(item)" type="button" class="btn btn-icon btn-primary ml-1"
                  ngbTooltip="Modifier">
                  <i class="feather icon-edit-2"></i>
                </button>
                <ng-template ngxPermissionsOnly="HOME:TYPE:DELETE">
                  <button *ngIf="item?.isDelete" type="button" (click)="delete(item)" class="btn btn-icon btn-danger ml-1" ngbTooltip="Supprimer">
                    <i class="feather icon-trash"></i>
                  </button>
                </ng-template>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </app-card>
</div>
