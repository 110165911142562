import { Building } from '@model/building';
import { Promotion } from '@model/promotion';
import { Globals } from '@theme/utils/globals';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ValidatorsEnums } from '@theme/enums/validators.enums';
import { EmitterService } from '@service/emitter/emitter.service';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BuildingService } from '@service/building/building.service';
import { PromotionService } from '@service/promotion/promotion.service';

@Component({
  selector: 'app-building-add',
  templateUrl: './building-add.component.html',
  styleUrls: ['./building-add.component.scss']
})
export class BuildingAddComponent implements OnInit {
  title: string = '';
  edit: boolean = false;
  form: FormGroup;
  submit: boolean = false;
  building: Building;
  buildings: Building[] = [];
  promotion: Promotion;
  promotionSelected: any;
  required = Globals.required;
  typeRow = [
    { label: 'BÂTIMENT INEXISTANT', value: 'ADD' },
    { label: 'BÂTIMENT EXISTANT', value: 'UPDATE' }
  ];
  etageRow = [];

  constructor(
    public modal: NgbActiveModal,
    private formBuild: FormBuilder,
    private emitter: EmitterService,
    private buildingService: BuildingService,
    private _changeDetector: ChangeDetectorRef,
    private promotionService: PromotionService
  ) {
    this.edit = this.buildingService.edit;
    this.building = this.buildingService.getBuilding();
    this.title = (!this.edit) ? 'Ajouter un bâtiment' : 'Modifier le bâtiment ' + this.building?.libelle + ' de la promotion ' + this.building?.promotion?.libelle;
    this.newForm();
  }

  ngOnInit() {
    this.editForm();
  }

  newForm() {
    this.form = this.formBuild.group({
      uuid: [null],
      id: [null],
      type: ['ADD', [Validators.required]],
      promotion: [null, [Validators.required]],
      niveau: [null, [Validators.pattern(ValidatorsEnums.number), Validators.min(0)]],
      libelle: [null, [Validators.required]],
      nbr: [0, [Validators.required, Validators.pattern(ValidatorsEnums.number), Validators.min(0)]],
      montant: [0, [Validators.required, Validators.pattern(ValidatorsEnums.number), Validators.min(0)]],
      superficie: [0, [Validators.required, Validators.pattern(ValidatorsEnums.number), Validators.min(0)]],
      appartements: this.formBuild.array([]),
    });

    this.form.get('type').valueChanges.subscribe(res => {
      this.form.get('uuid').setValue(null);
      this.form.get('niveau').setValue(null);
      this.form.get('libelle').setValue(null);
      this.form.get('superficie').setValue(null);
      if(!this.edit && res === 'ADD') {
        this.form.get('uuid').clearValidators();
        this.form.get('niveau').setValidators(Validators.required);
        this.form.get('libelle').setValidators(Validators.required);
        this.form.get('superficie').setValidators(Validators.required);

        this.form.get('niveau').enable();
        this.form.get('libelle').enable();
        this.form.get('superficie').enable();
      } else if(!this.edit && res === 'UPDATE')  {
        this.loadBuilding();
        this.form.get('uuid').setValidators(Validators.required);
        this.form.get('niveau').clearValidators();
        this.form.get('libelle').clearValidators();
        this.form.get('superficie').clearValidators();

        this.form.get('niveau').disable();
        this.form.get('libelle').disable();
        this.form.get('superficie').disable();
      }
      this.form.get('niveau').updateValueAndValidity();
      this.form.get('libelle').updateValueAndValidity();
      this.form.get('superficie').updateValueAndValidity();
    });
    this.form.get('uuid').valueChanges.subscribe(res => {
      if(!this.edit && res) {
        this.building = this.buildings.find(build => build.uuid = res);
        this.form.get('libelle').setValue(this.building?.libelle);
        this.form.get('niveau').setValue(this.building?.niveau);
        this.form.get('superficie').setValue(this.building?.superficie);
      }
    });
    this.form.get('niveau').valueChanges.subscribe(res => {
      this.etageRow = [];
      if (!this.edit && res > 0) {
        this.etageRow.push({label: "Rez-de-chaussée", value: "Rez-de-chaussée"});
        for (let index = 1; index <= res; index++) {
          this.etageRow.push({label: index +"e étage", value: index});
        }
      }
    });
  }
  editForm() {
    if (this.edit) {
      const data = {...this.buildingService.getBuilding()};
      this.form.get('uuid').setValue(data.uuid);
      this.form.get('nbr').setValue(data.homes.length);
      this.form.get('nbr').disable();
      this.form.get('type').setValue('UPDATE');
      this.setCurrentPromotion(data?.promotion);
      this.form.patchValue(data);
      this.f.promotion.setValue(data?.promotion?.uuid);
    }
  }
  setCurrentPromotion(promotion){
    this.setPromotionUuid(promotion?.uuid);
    this.promotionSelected = {
      photoSrc: promotion?.photoSrc,
      title: promotion?.searchableTitle,
      detail: promotion?.searchableDetail
    };
  }
  setPromotionUuid(uuid) {
    if(uuid) {
      this.f.promotion.setValue(uuid);
      this.loadPromotion();
      this.loadBuilding();
    }
  }
  setHomeTypeUuid(uuid, row){
    if(uuid){
      row.get('type').setValue(uuid);
    } else {
      row.get('type').setValue(null);
    }
  }
  loadBuilding() {
    this.buildings = [];
    if (this.f.promotion.value && this.f.type.value === 'UPDATE') {
      this.buildingService.getList(this.f.promotion.value).subscribe((res) => {
        this.buildings = res;
        this._changeDetector.markForCheck();
      })
    }
  }
  loadPromotion() {
    this.emitter.disallowLoading();
    this.promotionService.getSingle(this.f.promotion.value).subscribe((res) => {
      this.promotion = res;
      if (!this.edit) {
        this.onChangeMaison();
      }
      if(res?.type === 'TYPE_A') {
        Swal.fire({
          title: 'ATTENTION',
          text: 'Il est impossible d\'ajouter des bâtiments/immeuble sur des promotions de type A.',
          icon: 'error',
          showCancelButton: false,
          showCloseButton: false
        }).then((willDelete) => {
          this.promotion = null;
          this.promotionSelected = null;
        });
      }
    });
  }
  onSubmit() {
    this.submit = true;
    if (this.form.valid) {
      this.buildingService.add(this.form.getRawValue()).subscribe(res => {
        if (res?.status === 'success') {
          this.modal.close('ferme');
          if (this.form.getRawValue().uuid) {
            this.emitter.emit({action: 'BUILDING_UPDATED', payload: res?.data});
          } else {
            this.emitter.emit({action: 'BUILDING_ADD', payload: res?.data});
          }
        }
      }, error => {});
    } else {
      return;
    }
  }
  onConfirme() {
    Swal.fire({
      title: '',
      text: 'Confirmez-vous l\'enregistrement ?',
      icon: 'warning',
      showCancelButton: true,
      showCloseButton: true,
      confirmButtonText: 'Confirmer <i class="fas fa-check"></i>',
      cancelButtonText: 'Annuler <i class="feather icon-x-circle"></i>',
      confirmButtonColor: '#1bc943',
      reverseButtons: true
    }).then((willDelete) => {
      if (!willDelete.dismiss) {
        this.onSubmit();
      }
    });
  }
  onChangeMaison() {
    this.appartement.controls.length = 0;
    var nbr = (this.f.nbr.value >= 0) ? this.f.nbr.value : 0;
    if (this.appartement.controls.length < nbr) {
      for (let i = 0; i < nbr; i++) {
        this.appartement.push(
          this.formBuild.group({
            uuid: [null],
            id: [null],
            type: [null, [Validators.required]],
            etage: [null, [Validators.required]],
            porte: [null, [Validators.required]],
            montant: [null, [Validators.required, Validators.pattern(ValidatorsEnums.number), Validators.min(0)]],
            superficie: [null, [Validators.required, Validators.pattern(ValidatorsEnums.number), Validators.min(0)]],
          })
        );
      }
      return this.appartement;
    } else {
      this.appartement.clear();
    }
  }
  get f() { return this.form.controls; }
  get appartement() { return this.form.get('appartements') as FormArray; }
}
