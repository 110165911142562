<div class="modal-header">
  <h5 class="modal-title">{{title}}</h5>
  <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close" (click)="onClose()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form autocomplete="off" [formGroup]="form" (ngSubmit)="onConfirme()">
  <div class="modal-body">
    <div class="col-md-12">
      <aw-wizard #wizard class="arc-wizard">
        <aw-wizard-step *ngIf="type === 'VENTE'" stepTitle="Réversement">
          <!-- DETAILS SUR LE BIEN CONCERNE -->
          <div class="row">
            <span class="badge badge-success my-2 f-14 width">
              DETAILS SUR LE BIEN CONCERNE
            </span>
          </div>
          <div class="form-group">
            <div class="row mb-2">
              <div class="col-md-3">
                <label for="type">Type <span class="asterix">*</span></label>
                <select formControlName="type" class="form-control" id="type" [attr.disabled]="edit" [ngClass]="{
                    'is-invalid': submit && f.type.errors,
                    'is-valid': submit && f.type.valid
                  }">
                  <option *ngFor="let item of typeRow" [value]="item.value">{{item.label}}</option>
                </select>
                <div class="invalid-feedback" *ngIf="submit && f.type.errors">
                  <div *ngIf="f.type.errors.required">{{required.novide}}</div>
                </div>
              </div>
              <div class="col-md-3">
                <app-entity-finder [class]="'Owner'" [groups]="['owner']" [required]="true" [label]="'Proprietaire'"
                  (uuid)="setOwnerUuid($event)" [selected]="ownerSelected" [disabled]="edit"
                  [placeholder]="'Selectionez un proprietaire'">
                </app-entity-finder>
                <div class="invalid-feedback" *ngIf="submit && f.owner.errors">
                  <div *ngIf="f.owner.errors.required">{{required.novide}}</div>
                </div>
              </div>
              <div class="col-md-3">
                <app-entity-finder [class]="'Customer'" [groups]="['customer']" [required]="true" [label]="'Client'"
                  (uuid)="setCustomerUuid($event)" [selected]="customerSelected" [disabled]="edit"
                  [placeholder]="'Selectionez un client'">
                </app-entity-finder>
                <div class="invalid-feedback" *ngIf="submit && f.customer.errors">
                  <div *ngIf="f.customer.errors.required">{{required.novide}}</div>
                </div>
              </div>
              <div class="col-md-3">
                <div *ngIf="!edit && isLoadingFolder" class="spinner-container">
                  <div class="spinner"></div>
                </div>

                <label for="folder">Dossier <span class="asterix">*</span></label>
                <select *ngIf="!edit" (change)="setFolderUuid($event.target.value)" formControlName="folder"
                  class="form-control" id="folder" [attr.disabled]="f.customer.value ? null : 'true'"
                  [ngClass]="{'is-invalid': submit && f.folder.errors,'is-valid': submit && f.folder.valid}">
                  <option *ngFor="let item of folders" [value]="item?.uuid">{{item?.libelle}}</option>
                </select>
                <input *ngIf="edit" type="text" class="form-control p-2 text-uppercase font-weight-bold text-secondary"
                  [value]="folder?.libelle" readonly>
                <div class="invalid-feedback" *ngIf="submit && f.folder.errors">
                  <div *ngIf="f.folder.errors.required">{{required.novide}}</div>
                </div>
              </div>
            </div>
            <div *ngIf="folder?.houses.length > 1" class="row mb-2">
              <div class="col-md-4">
                <label for="prcCom">Commission (%) <span class="asterix">*</span></label>
                <input (change)="onCalculVente()" formControlName="prcCom" id="prcCom" type="number"
                  class="form-control" [ngClass]="{
                  'is-invalid': submit && f.prcCom.errors,
                  'is-valid': submit && f.prcCom.valid
                  }" placeholder="Commission (%)">
                <div class="invalid-feedback" *ngIf="submit && f.prcCom.errors">
                  <div *ngIf="f.prcCom.errors.required">{{required.novide}}</div>
                  <div *ngIf="f.prcCom.errors.pattern">{{required.positive}}</div>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="folder?.houses.length <= 1">
            <!-- ALERT SUR LE MODE DE PAIEMENT DE LA COMMISSION -->
            <app-alert *ngIf="f.owner.value && f.customer.value && f.folder.value" type="warning">
              Selon le mandat de gestion du bien <span class="alert-link">{{house?.nom}}</span>, le montant de la
              commission doit être
              <span class="alert-link">
                {{ house?.mandate?.verseCom === 'TOTALITE' ? " versé en TOTALITÉ " : " versé au PRORATA " }}
              </span>
              {{ house?.mandate?.verseCom === 'TOTALITE' ? " lors du premier paiement." : " lors des paiements successif." }}
            </app-alert>
            <div *ngIf="folder?.houses.length <= 1">
              <div
                *ngIf="f.owner.value && f.customer.value && f.folder.value && house?.mandate?.verseCom === 'TOTALITE'">
                <app-alert type="danger" dismiss="true" *ngIf="house?.mandate?.montantCom > montant">
                  <i class="fas fa-exclamation-triangle mr-2"></i>
                  Le montant de la commission ne peut être supérieur au montant à reverser pour un versement en
                  totalité.
                </app-alert>
              </div>
            </div>
          </div>
          <div *ngIf="folder?.houses.length > 1">
            <app-alert type="danger" dismiss="true">
              <i class="fas fa-exclamation-triangle mr-2"></i>
              Le montant de la commission sera calculer en fonction de la nouvelle valeur de commission renseigné et
              sera reversé au prorata.
            </app-alert>
          </div>

          <!-- RECAPITULATIF DES MONTANTS VENTE -->
          <div *ngIf="f.owner.value && f.customer.value && f.folder.value" class="row mb-2">
            <div class="col-sm-4">
              <span class="badge badge-danger text-center width">
                <h5 class="text-white py-2">TOTAL : {{ montantV | number }} {{global.device}}</h5>
              </span>
            </div>
            <div class="col-sm-4">
              <span class="badge badge-primary text-center width">
                <h5 class="text-white py-2">COMMISSION : {{ f.commission.value | number }} {{global.device}}</h5>
              </span>
            </div>
            <div class="col-sm-4">
              <span class="badge badge-success text-center width">
                <h5 class="text-white py-2">A REVERSER : {{ totalAverser | number }} {{global.device}}</h5>
              </span>
            </div>
          </div>

          <!-- DETAILS CONCERNANT LA VENTE -->
          <div *ngIf="f.owner.value && f.customer.value && f.folder.value" class="form-group">
            <div class="row">
              <div class="col-md-3">
                <label for="house">Montant de la vente ({{global.device}}) </label>
                <input type="text" class="form-control p-2 text-uppercase font-weight-bold text-success"
                  [value]="montantV| number" readonly>
              </div>
              <div class="col-md-3">
                <label for="house">Montant déjà reversé ({{global.device}}) </label>
                <input type="text" class="form-control p-2 text-uppercase font-weight-bold text-success"
                  [value]="house?.folderCustomer?.invoice?.montantReverse | number" readonly>
              </div>
              <div class="col-md-3">
                <label for="house">Montant à reverser ({{global.device}}) </label>
                <input type="text" class="form-control p-2 text-uppercase font-weight-bold text-success"
                  [value]="montant | number" readonly>
              </div>
              <div class="col-md-3">
                <label for="house">Montant commission ({{global.device}}) </label>
                <input class="form-control p-2 text-uppercase font-weight-bold text-success" type="text"
                  [value]="commission | number" readonly>
              </div>
            </div>
          </div>
          <div class="col-sm-12 centered-content" *ngIf="f.owner.value && f.customer.value && f.folder.value">
            <div class="btn-group mt-10">
              <button type="button" class="btn btn-primary" awNextStep>
                Suivant <i class="feather icon-arrow-right"></i>
              </button>
            </div>
          </div>
        </aw-wizard-step>
        <aw-wizard-step *ngIf="type === 'LOCATION'" stepTitle="Réversement">
          <!-- DETAILS SUR LE BIEN CONCERNE -->
          <div class="row">
            <span class="badge badge-success my-2 f-14 width">
              DETAILS SUR LES BIEN CONCERNE
            </span>
          </div>
          <div class="form-group">
            <div class="row mb-2">
              <div class="col-md-3">
                <app-entity-finder [class]="'Owner'" [groups]="['owner']" [required]="true" [label]="'Proprietaire'"
                  (uuid)="setOwnerUuid($event)" [selected]="ownerSelected" [disabled]="edit"
                  [placeholder]="'Selectionez un proprietaire'">
                </app-entity-finder>
                <div class="invalid-feedback" *ngIf="submit && f.owner.errors">
                  <div *ngIf="f.owner.errors.required">{{required.novide}}</div>
                </div>
              </div>
              <div class="col-md-2">
                <label for="house">Bien <span class="asterix">*</span></label>
                <select *ngIf="!edit" formControlName="house" (change)="setHouseUuid($event.target.value)"
                  class="form-control" id="house" [ngClass]="{
                    'is-invalid': submit && f.house.errors,
                    'is-valid': submit && f.house.valid
                  }">
                  <option *ngFor="let item of houses" [value]="item.uuid">{{ item.nom }}</option>
                </select>
                <input *ngIf="edit" type="text" class="form-control p-2 text-uppercase font-weight-bold text-secondary"
                  [value]="house?.nom" readonly>
                <div class="invalid-feedback" *ngIf="submit && f.house.errors">
                  <div *ngIf="f.house.errors.required">{{required.novide}}</div>
                </div>
                <div class="invalid-feedback" *ngIf="submit && f.house.errors">
                  <div *ngIf="f.house.errors.required">{{required.novide}}</div>
                </div>
              </div>
              <div class="col-md-2">
                <label for="typeFiltre">Filtrer par <span class="asterix">*</span></label>
                <select formControlName="typeFiltre" class="form-control" id="typeFiltre" [ngClass]="{
                    'is-invalid': submit && f.typeFiltre.errors,
                    'is-valid': submit && f.typeFiltre.valid
                  }">
                  <option *ngFor="let item of filtreRow" [value]="item.value">{{item.label}}</option>
                </select>
                <div class="invalid-feedback" *ngIf="submit && f.typeFiltre.errors">
                  <div *ngIf="f.typeFiltre.errors.required">{{required.novide}}</div>
                </div>
              </div>
              <div class="col-md-2">
                <label for="dateD">Date debut</label>
                <input class="form-control" type="date" formControlName="dateD" id="dateD"
                  [readonly]="edit || f.typeFiltre.value === null || f.owner.value === null" />
              </div>
              <div class="col-md-2">
                <label for="dateF">Date Fin</label>
                <input class="form-control" type="date" formControlName="dateF" id="dateF"
                  [readonly]="edit || f.typeFiltre.value === null || f.owner.value === null" />
              </div>
              <div class="col-md-1 mt-4">
                <button type="button" (click)="onLoadData()" class="btn btn-primary"
                  [disabled]="edit || f.dateF.value === null || f.dateD.value === null || f.typeFiltre.value === null || f.owner.value === null">
                  <i class="feather icon-filter"></i>
                </button>
              </div>
            </div>
          </div>
          <!-- DETAILS CONCERNANT LES FINANCEMENT DE BIEN -->
          <div class="form-group">
            <div class="col-md-12" formArrayName="optionsPayment">
              <div class="row" *ngIf="optionsP.controls.length > 0">
                <div class="table-responsive">
                  <table class="table table-sm table-striped table-bordered nowrap table-hover">
                    <thead class="thead-light">
                      <tr>
                        <th *ngIf="!edit">
                          <div class="form-group">
                            <div class="form-check">
                              <input id="selectAll" class="form-check-input" type="checkbox"
                                (change)="onSelectAll($event)">
                            </div>
                          </div>
                        </th>
                        <th *ngIf="edit">#</th>
                        <th>Locataire</th>
                        <th>Locative</th>
                        <th>Libelle</th>
                        <th>Montant net</th>
                        <th>Comission</th>
                        <th>TVA honoraire</th>
                        <th>Impot foncier</th>
                        <th>Loyer net déja reverser</th>
                        <th>Loyer à reverser</th>
                      </tr>
                    </thead>
                    <tbody class="task-page">
                      <tr *ngFor="let item of optionsP.controls; let i = index" [formGroupName]="i">
                        <td>
                          <div class="form-group">
                            <div class="form-check">
                              <input formControlName="checked" class="form-check-input" type="checkbox"
                                id="checked{{i}}" (change)="onSelect(i, item)">
                            </div>
                          </div>
                        </td>
                        <td class="px-2"><input formControlName="locataire" type="text" class="form-control p-2 bold"
                            id="locataire{{i}}" style="width: 14em;"></td>
                        <td class="px-2"><input formControlName="locative" type="text" class="form-control p-2 bold"
                            id="locative{{i}}" style="width: 17em;"></td>
                        <td class="px-2"><input formControlName="libelle" type="text" class="form-control p-2 bold"
                            id="libelle{{i}}" style="width: 17em;"></td>
                        <td class="px-2"><input formControlName="loyer" type="text" class="form-control p-2 bold"
                            id="loyer{{i}}"></td>
                        <td class="px-2"><input formControlName="commission" type="text" class="form-control p-2 bold"
                            id="commission{{i}}"></td>
                        <td class="px-2"><input formControlName="tvaCom" type="text" class="form-control p-2 bold"
                            id="tvaCom{{i}}"></td>
                        <td class="px-2"><input formControlName="impot" type="text" class="form-control p-2 bold"
                            id="impot{{i}}"></td>
                        <td class="px-2"><input formControlName="verse" type="text" class="form-control p-2 bold"
                            id="verse{{i}}"></td>
                        <td class="px-2"><input formControlName="montant" type="text" class="form-control p-2 bold"
                            id="montant{{i}}"></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="optionsP.controls.length > 0">
            <!-- RECAPITULATIF DES MONTANTS -->
            <div class="row mb-2">
              <div class="col-sm-4">
                <span class="badge badge-danger text-center width">
                  <h5 class="text-white py-2">TOTAL DU LOYER NET: {{ f.loyer.value | number }} {{global.device}}</h5>
                </span>
              </div>
              <div class="col-sm-4">
                <span class="badge badge-primary text-center width">
                  <h5 class="text-white py-2">TOTAL IMPOT : {{ f.impot.value | number }} {{global.device}}</h5>
                </span>
              </div>
              <div class="col-sm-4">
                <span class="badge badge-success text-center width">
                  <h5 class="text-white py-2">TOTAL VERSE : {{ f.verse.value | number }} {{global.device}}</h5>
                </span>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-sm-4">
                <span class="badge badge-danger text-center width">
                  <h5 class="text-white py-2">TOTAL COMMISSION : {{ f.commission.value | number }} {{global.device}}
                  </h5>
                </span>
              </div>
              <div class="col-sm-4">
                <span class="badge badge-primary text-center width">
                  <h5 class="text-white py-2">TOTAL TVA HONORAIRE : {{ f.tva.value | number }} {{global.device}}</h5>
                </span>
              </div>
              <div class="col-sm-4">
                <span class="badge badge-success text-center width">
                  <h5 class="text-white py-2">MONTANT A REVERSER : {{ totalAverser | number }} {{global.device}}</h5>
                </span>
              </div>
            </div>
          </div>
          <div class="col-sm-12 centered-content">
            <div class="btn-group mt-10">
              <button *ngIf="optionsP.controls.length > 0" type="button" class="btn btn-primary" awNextStep>
                Suivant <i class="feather icon-arrow-right"></i>
              </button>
            </div>
          </div>
        </aw-wizard-step>
        <aw-wizard-step stepTitle="Financement" *ngIf="optionsF.controls.length > 0">
          <div class="row">
            <div class="col-sm-12">
              <!-- DETAILS CONCERNANT LES FINANCEMENT DE BIEN -->
              <div class="form-group">
                <div class="col-md-12" formArrayName="optionsFunding">
                  <div class="row" *ngIf="optionsF.controls.length > 0">
                    <table class="table table-sm table-striped table-bordered nowrap table-hover">
                      <thead class="thead-light">
                        <tr>
                          <th *ngIf="!edit">
                            <div class="form-group">
                              <div class="form-check">
                                <input class="form-check-input" id="selectAll" type="checkbox"
                                  (change)='onSelectAllFunding($event)'>
                              </div>
                            </div>
                          </th>
                          <th *ngIf="edit">#</th>
                          <th>Periode</th>
                          <th>Montant</th>
                          <th>Libelle</th>
                        </tr>
                      </thead>
                      <tbody class="task-page">
                        <tr *ngFor="let item of optionsF.controls; let i = index" [formGroupName]="i">
                          <td>
                            <div class="form-group">
                              <div class="form-check">
                                <input formControlName="checked" class="form-check-input" type="checkbox"
                                  id="checked{{i}}" (change)='onSelectFunding(i, item)'>
                              </div>
                            </div>
                          </td>
                          <td><input formControlName="periode" type="text" class="form-control p-2 bold"
                              id="periode{{i}}"></td>
                          <td><input formControlName="montant" type="text" class="form-control p-2 bold"
                              id="montant{{i}}"></td>
                          <td><input formControlName="libelle" type="text" class="form-control p-2 bold"
                              id="libelle{{i}}"></td>
                        </tr>
                      </tbody>
                    </table>

                    <table class="width">
                      <tbody>
                        <tr class="border-bottom-success">
                          <td></td>
                          <td colspan="5" class="text-right font-weight-bold"></td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr class="border border-success">
                          <td colspan="5" class="font-weight-bold font-size-default">TOTAL </td>
                          <td class="font-weight-bold font-size-default text-right">{{ montantFunding | number }}
                            {{global.device}}</td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-12 centered-content">
            <div class="btn-group mt-10">
              <button type="button" class="btn btn-warning" awPreviousStep>
                <i class="feather icon-arrow-left"></i> Précédent
              </button>
              <button (click)="onChangeTotalCalcul()" type="button" class="ml-1 btn btn-primary" awNextStep>
                Suivant <i class="feather icon-arrow-right"></i>
              </button>
            </div>
          </div>
        </aw-wizard-step>
        <aw-wizard-step stepTitle="Supplément">
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <div formArrayName="options">
                  <!-- LABEL DU TABLEAU DES OPTIONS -->
                  <div class="form-group" *ngIf="options.length > 0">
                    <div class="row">
                      <div class="col-md-3">
                        <label>Désignation <span class="asterix">*</span></label>
                      </div>
                      <div class="col-md-2">
                        <label>Prix unitaire <span class="asterix">*</span></label>
                      </div>
                      <div class="col-md-1">
                        <label>Qté <span class="asterix">*</span></label>
                      </div>
                      <div class="col-md-1">
                        <label>TVA</label>
                      </div>
                      <div class="col-md-2">
                        <label>Remise</label>
                      </div>
                      <div class="col-md-2">
                        <label>Total</label>
                      </div>
                    </div>
                  </div>
                  <div class="form-group" *ngFor="let opt of options.controls; let j=index" [formGroupName]="j">
                    <div class="row">
                      <div class="col-md-3">
                        <input type="text" formControlName="libelle" class="form-control p-2" id="libelle{{j}}"
                          [ngClass]="{
                            'is-invalid': submit && opt.get('libelle').errors,
                            'is-valid': submit && opt.get('libelle').valid
                          }" placeholder="Désignation">
                        <div class="invalid-feedback" *ngIf="submit && opt.get('libelle').errors">
                          <div *ngIf="opt.get('libelle').errors.required">{{required.novide}}</div>
                        </div>
                      </div>
                      <div class="col-md-2">
                        <input (change)="onChangeTotalOption(opt, true)" type="number" formControlName="prix"
                          class="form-control" id="prix{{j}}" [ngClass]="{
                            'is-invalid': submit && opt.get('prix').errors,
                            'is-valid': submit && opt.get('prix').valid
                          }" placeholder="Prix unitaire">
                        <div class="invalid-feedback" *ngIf="submit && opt.get('prix').errors">
                          <div *ngIf="opt.get('prix').errors.required">{{required.novide}}</div>
                        </div>
                        <div class="invalid-feedback" *ngIf="submit && opt.get('prix').errors">
                          <div *ngIf="opt.get('prix').errors.required">{{required.novide}}</div>
                          <div *ngIf="opt.get('prix').errors.pattern">{{required.nolettre}}</div>
                        </div>
                      </div>
                      <div class="col-md-1">
                        <input (change)="onChangeTotalOption(opt, true)" type="number" formControlName="qte"
                          class="form-control" id="qte{{j}}" [ngClass]="{
                            'is-invalid': submit && opt.get('qte').errors,
                            'is-valid': submit && opt.get('qte').valid
                          }" placeholder="Quantité">
                        <div class="invalid-feedback" *ngIf="submit && opt.get('qte').errors">
                          <div *ngIf="opt.get('qte').errors.required">{{required.novide}}</div>
                          <div *ngIf="opt.get('qte').errors.pattern">{{required.nolettre}}</div>
                          <div *ngIf="opt.get('qte').errors.pattern">{{required.positive}}</div>
                        </div>
                      </div>
                      <div class="col-md-1">
                        <input (change)="onChangeTotalOption(opt, true)" type="number" formControlName="tva"
                          class="form-control p-2" id="tva{{j}}" [ngClass]="{
                            'is-invalid': submit && opt.get('tva').errors,
                            'is-valid': submit && opt.get('tva').valid
                          }" placeholder="TVA">
                        <div class="invalid-feedback" *ngIf="submit && opt.get('tva').errors">
                          <div *ngIf="opt.get('tva').errors.pattern">{{required.nolettre}}</div>
                          <div *ngIf="opt.get('tva').errors.pattern">{{required.positive}}</div>
                        </div>
                      </div>
                      <div class="col-md-2">
                        <input (change)="onChangeTotalOption(opt, true)" type="number" formControlName="remise"
                          class="form-control" id="remise{{j}}">
                      </div>
                      <div class="col-md-2">
                        <input type="number" formControlName="total" class="form-control p-2" id="total{{j}}"
                          step="0.01" placeholder="TOTAL" readonly>
                        <div class="invalid-feedback" *ngIf="submit && opt.get('total').errors">
                          <div *ngIf="opt.get('total').errors.pattern">{{required.nolettre}}</div>
                        </div>
                      </div>
                      <div class="col-md-1">
                        <button (click)="onDeleteOption(j)" type="button" class="btn btn-icon btn-danger">
                          <i class="feather icon-trash"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mb-2 d-flex flex-row-reverse">
                  <button (click)="onAddOption()" type="button" class="btn btn-primary">
                    Ajouter <i class="feather icon-plus"></i>
                  </button>
                </div>

                <div class="row">
                  <div class="col-sm-12">
                    <table class="table table-responsive invoice-table invoice-total">
                      <tbody>
                        <tr>
                          <th>TOTAL HT :</th>
                          <td>{{totalOptionHT | number}}</td>
                        </tr>
                        <tr>
                          <th>TOTAL REMISE :</th>
                          <td>{{totalOptionRemise | number}}</td>
                        </tr>
                        <tr>
                          <th>TOTAL TVA :</th>
                          <td>{{totalOptionTVA | number}}</td>
                        </tr>
                        <tr>
                          <th>TOTAL TTC :</th>
                          <td>{{totalOptionTTC | number}}</td>
                        </tr>
                        <tr class="text-info">
                          <td>
                            <hr />
                            <h2 class="text-primary m-r-10">TOTAL A REVERSER :</h2>
                          </td>
                          <td>
                            <hr />
                            <h2 class="text-success">{{f.montant.value|number}}</h2>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-12 centered-content">
            <div class="btn-group mt-10">
              <button type="button" class="btn btn-warning" awPreviousStep>
                <i class="feather icon-arrow-left"></i> Précédent
              </button>
            </div>
          </div>
        </aw-wizard-step>
      </aw-wizard>
    </div>
  </div>
  <div class="modal-footer">
    <button (click)="onClose()" type="button" class="btn btn-secondary text-left" data-dismiss="modal">
      Fermer <i class="feather icon-x-circle"></i>
    </button>
    <button (click)="form.reset()" type="button" class="btn btn-warning">
      Vider <i class="fas fa-broom"></i>
    </button>
    <button [disabled]="form.invalid" type="submit" class="btn btn-primary">
      Enregistrer <i class="feather icon-save"></i>
    </button>
  </div>
</form>
