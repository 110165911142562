<div class="auth-wrapper align-items-stretch aut-bg-img">
  <div class="flex-grow-1">
    <!-- SLIDE AUTH -->
    <div class="h-auto d-md-flex align-items-center auth-side-img">
      <div class="col-sm-10 auth-content w-auto">
        <h1 class="text-white my-4">Changez en toute sécurité !</h1>
        <h4 class="text-white font-weight-normal">
          La plateforme numéro un (N˚1) dans la Gestion Immobilière, Recouvrement, Vente<br/>(Promotion Immobilière, Lotissement)
        </h4>
      </div>
    </div>

    <!-- FORM AUTH -->
    <div class="auth-side-form">
      <div class="auth-content">
        <img src="assets/images/logo.png" alt="" class="img-fluid mb-4 img-center">
        <h4 class="mb-3 f-w-400 text-center">Mot de passe oublié</h4>
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
          <div class="input-group mb-3">
            <input type="email" formControlName="email" id="email" class="form-control" [ngClass]="{'is-invalid': f.email.invalid && (f.email.dirty || f.email.touched)}" placeholder="Adresse email">
            <label *ngIf="!f.email.valid && (f.email.dirty || f.email.touched)" id="email-error" class="error jquery-validation-error small form-text invalid-feedback">
              L'adresse email est incorrect.
            </label>
          </div>

          <button type="submit" [disabled]="!form.valid || loading" class="btn btn-block btn-primary text-white mb-4">
            Envoyer
            <ng-container *ngIf="loading">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              <span class="sr-only">Chargement...</span>
            </ng-container>
          </button>
          <div class="text-center">
            <p class="mb-2 text-muted"><a routerLink="/auth/login" class="f-w-400">Connexion ?</a></p>
            <a target="_blank" href="https://www.facebook.com/ZenAPImmo" class="btn text-white bg-facebook mb-2 mr-2  wid-40 px-0 hei-40 rounded-circle"><i class="fab fa-facebook-f"></i></a>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
