import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TenantService } from '@service/tenant/tenant.service';

@Component({
  selector: 'app-update',
  templateUrl: './update.component.html',
  styleUrls: ['./update.component.scss']
})
export class UpdateComponent implements OnInit {
  @Input() data: any;
  title: string = null;
  submit: boolean = false;
  form: FormGroup;

  constructor(
    public modal: NgbActiveModal,
    private formBuild: FormBuilder,
    private tenantService: TenantService
  ) {
    this.title = "Modifier les données du locataire";
  }

  ngOnInit() {
    this.newForm();
    this.tenantService.getSingle(this.data.uuid).subscribe(res => {
      console.log(this.data, res);
    });
  }

  newForm() {
    this.form = this.formBuild.group({
      uuid: [null, [Validators.required]],
      type: [this.data.type, [Validators.required]],
      contact: [null, [Validators.required]],
      email: [null, [Validators.required]]
    });
  }
  onConfirme() {

  }
  onSubmit() {
    this.submit = true;
    if (!this.form.invalid) {
      const data = this.form.getRawValue();
      this.tenantService.add(data).subscribe(res => {
        if (res?.status === 'success') {
          this.modal.close('ferme');
        }
      });
    } else { return; }
  }

  onReset() {

  }

  onClose() {

  }
}
