import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '@theme/shared/shared.module';
import { ParametreRoutingModule } from '@parametre/parametre-routing.module';
import { TemplateAddComponent } from '@parametre/template/template-add/template-add.component';
import { SmsAddComponent } from '@parametre/sms/sms-add/sms-add.component';
import { MailAddComponent } from '@parametre/mail/mail-add/mail-add.component';
import { GeneralAddComponent } from '@parametre/general/general-add/general-add.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SettingListComponent } from '@parametre/setting/setting-list/setting-list.component';
import { FileUploadModule } from '@iplab/ngx-file-upload';
import { TinymceModule } from 'angular2-tinymce';
import { NgxPermissionsModule } from 'ngx-permissions';
import { CompteDefautComponent } from './configuration-syndic/compte-defaut/compte-defaut.component';
import { CompteTvaAddComponent } from './configuration-syndic/compte-tva/compte-tva-add/compte-tva-add.component';
import { CompteTvaListComponent } from './configuration-syndic/compte-tva/compte-tva-list/compte-tva-list.component';
import { CompteTvaShowComponent } from './configuration-syndic/compte-tva/compte-tva-show/compte-tva-show.component';
import { LengthCodeComptableComponent } from './configuration-syndic/length-code-comptable/length-code-comptable.component';
import { JournauxAddComponent } from './configuration-syndic/journaux/journaux-add/journaux-add.component';
import { JournauxListComponent } from './configuration-syndic/journaux/journaux-list/journaux-list.component';
import { JournauxShowComponent } from './configuration-syndic/journaux/journaux-show/journaux-show.component';
import { PlanAuxiliaireComponent } from './configuration-syndic/plan-auxiliaire/plan-auxiliaire.component';
import { PlanComptableAddComponent } from './configuration-syndic/plan-comptable/plan-comptable-add/plan-comptable-add.component';
import { PlanComptableListComponent } from './configuration-syndic/plan-comptable/plan-comptable-list/plan-comptable-list.component';
import { PlanComptableShowComponent } from './configuration-syndic/plan-comptable/plan-comptable-show/plan-comptable-show.component';
import { PlanModeleAddComponent } from './configuration-syndic/plan-modele/plan-modele-add/plan-modele-add.component';
import { PlanModeleListComponent } from './configuration-syndic/plan-modele/plan-modele-list/plan-modele-list.component';
import { PlanModeleShowComponent } from './configuration-syndic/plan-modele/plan-modele-show/plan-modele-show.component';
import { DataTablesModule } from 'angular-datatables';
import { TresorerieComponent } from './configuration-syndic/tresorerie/tresorerie.component';
import { ChargeComponent } from './configuration-syndic/charge/charge.component';
import { NoteFraisComponent } from './configuration-syndic/note-frais/note-frais.component';
import { ProduitComponent } from './configuration-syndic/produit/produit.component';
import { TantiemeAddComponent } from './tantieme/tantieme-add/tantieme-add.component';
import { TantiemeListComponent } from './tantieme/tantieme-list/tantieme-list.component';
import { TantiemeShowComponent } from './tantieme/tantieme-show/tantieme-show.component';
import { CategoryAddComponent } from './categorie/category-add/category-add.component';
import { CategoryListComponent } from './categorie/category-list/category-list.component';
import { CategoryShowComponent } from './categorie/category-show/category-show.component';


@NgModule({
  declarations: [
    TemplateAddComponent,
    SmsAddComponent,
    MailAddComponent,
    GeneralAddComponent,
    SettingListComponent,
    CompteDefautComponent,
    CompteTvaAddComponent,
    CompteTvaListComponent,
    CompteTvaShowComponent,
    LengthCodeComptableComponent,
    JournauxAddComponent,
    JournauxListComponent,
    JournauxShowComponent,
    PlanAuxiliaireComponent,
    PlanComptableAddComponent,
    PlanComptableListComponent,
    PlanComptableShowComponent,
    PlanModeleAddComponent,
    PlanModeleListComponent,
    PlanModeleShowComponent,
    TresorerieComponent,
    ChargeComponent,
    NoteFraisComponent,
    ProduitComponent,
    TantiemeAddComponent,
    TantiemeListComponent,
    TantiemeShowComponent,
    CategoryAddComponent,
    CategoryListComponent,
    CategoryShowComponent
  ],
  imports: [
    CommonModule,
    ParametreRoutingModule,
    NgbModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    FileUploadModule,
    TinymceModule,
    NgxPermissionsModule.forRoot(),
    DataTablesModule,
    NgbTooltipModule,
  ],
  exports: [
    TemplateAddComponent,
    SmsAddComponent,
    MailAddComponent,
    GeneralAddComponent,
    SettingListComponent,
    CompteDefautComponent,
    CompteTvaAddComponent,
    CompteTvaListComponent,
    CompteTvaShowComponent,
    LengthCodeComptableComponent,
    JournauxListComponent,
    JournauxShowComponent,
    PlanAuxiliaireComponent,
    PlanComptableAddComponent,
    PlanComptableListComponent,
    PlanComptableShowComponent,
    PlanModeleAddComponent,
    PlanModeleListComponent,
    PlanModeleShowComponent,
    TresorerieComponent,
    ChargeComponent,
    NoteFraisComponent,
    ProduitComponent,
    TantiemeListComponent,
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ]
})
export class ParametreModule { }
