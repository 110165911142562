<div class="row justify-content-center">
    <!-- FILTRE DE RECHERCHE -->
    <div class="width btn-filter-achat">
        <app-filter class="width"
            [name]="true"
            [nameTitle]="nameTitle"
            [user]="true"
            [userTitle]="userTitle"
            [etat]="true"
            [etatRow]="etatRow"
            [etatTitle]="etatTitle" [categorie]="true" [categorieRow]="categorieRow" [categorieTitle]="categorieTitle" [dateD]="true" [dateF]="true" [create]="true"
            [min]="true" [minTitle]="minTitle" [max]="true" [maxTitle]="maxTitle" [ordre]="true" [code]="true" [count]="true" [type]="true" [typeRow]="typeRow" (typeEvent)="onChangeLoad($event)" (filterEvent)="onFilter($event)">
        </app-filter>
    </div>


    <div class="width list-journaux">
        <div  *ngIf="type === 'JOURNAUX'" class="col-md-12">
            <span class="badge badge-primary mb-4 f-14 width">
                OPERATIONS - JOURNAUX
            </span>
            <div>
                <app-card [hidHeader]="true" cardClass="card-datatable">
                    <div class="table-responsive m-0">
                        <table datatable [dtOptions]="dtOptions" class="table table-striped m-0">
                            <thead class="thead-light">
                            <tr>
                                <th>Num. Transaction</th>
                                <th>Journal</th>
                                <th>Date</th>
                                <th>Pièce</th>
                                <th>Compte</th>
                                <th>Compte Auxiliaire</th>
                                <th>Libellé</th>
                                <th>Débit</th>
                                <th>Crédit</th>
                            </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of journaux">
                                    <!-- <td>
                                        <span>
                                            {{item?.code}}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {{item?.libelle}}
                                        </span>
                                    </td>
                                    <td>
                                        <span class="badge badge-dark">
                                            {{item?.type}}
                                        </span>
                                    </td>
                                    <td>
                                        <button type="button" (click)="show(item)" class="btn btn-icon btn-secondary" ngbTooltip="Détails">
                                            <i class="fas fa-eye"></i>
                                        </button>
                                    </td> -->
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </app-card>
            </div>
        </div>
    </div>

    <div class="width list-livre">
        <div  *ngIf="type === 'LIVRE'" class="col-md-12">
            <span class="badge badge-primary mb-4 f-14 width">
                OPERATIONS - VUE PAR COMPTES COMPTABLES (GRAND LIVRE)
            </span>
            <div>
                <app-card [hidHeader]="true" cardClass="card-datatable">
                    <app-grand-livre-list></app-grand-livre-list>
                </app-card>
            </div>
        </div>
    </div>

    <div class="width list-livre">
        <div  *ngIf="type === 'BALANCE'" class="col-md-12">
            <span class="badge badge-primary mb-4 f-14 width">
                BALANCE DES COMPTES
            </span>
            <div>
                <app-card [hidHeader]="true" cardClass="card-datatable">
                    <app-balance-list></app-balance-list>
                </app-card>
            </div>
        </div>
    </div>

</div>