<div class="user-profile user-card mb-4" *ngIf="owner">
  <div class="card-body py-0">
    <div class="user-about-block m-0">
      <div class="row">
        <div class="col-md-3 text-center mt-n5">
          <div class="change-profile text-center">
            <div class="dropdown w-auto d-inline-block" ngbDropdown>
              <a class="dropdown-toggle" data-toggle="dropdown" ngbDropdownToggle aria-haspopup="true"
                aria-expanded="false">
                <div class="profile-dp">
                  <div class="position-relative d-inline-block">
                    <img *ngIf="owner?.type === 'PARTICULIER' && owner?.sexe === 'Masculin'"
                      class="img-radius img-fluid wid-80 hei-80"
                      [src]="owner.photoSrc ? publicUrl+'/'+owner.photoSrc : 'assets/images/avatar-default.png'"
                      onerror="this.onerror=null; this.src='assets/images/avatar-default.png'" />
                    <img *ngIf="owner?.type === 'PARTICULIER' && owner?.sexe === 'Féminin'"
                      class="img-radius img-fluid wid-80 hei-80"
                      [src]="owner.photoSrc ? publicUrl+'/'+owner.photoSrc : 'assets/images/avatar-mlle.jpeg'"
                      onerror="this.onerror=null; this.src='assets/images/avatar-mlle.jpeg'" />
                    <img *ngIf="owner?.type === 'ENTREPRISE' && owner?.photoSrc"
                      class="img-radius img-fluid wid-80 hei-80"
                      [src]="owner.photoSrc ? publicUrl+'/'+owner.photoSrc : 'assets/images/avatar-default.png'"
                      onerror="this.onerror=null; this.src='assets/images/avatar-default.png'" />
                    <i *ngIf="owner?.type === 'ENTREPRISE' && !owner?.photoSrc" class="fas fa-building fa-5x"></i>
                  </div>
                  <div class="overlay">
                    <span>change</span>
                  </div>
                </div>
                <div class="certificated-badge">
                  <i class="fas fa-certificate text-c-blue bg-icon"></i>
                  <i class="fas fa-check front-icon text-white"></i>
                </div>
              </a>
            </div>
          </div>
          <h5 class="mb-1">{{ owner?.nom }}</h5>
          <p class="mb-2 text-muted text-uppercase">{{ owner?.type }}</p>
        </div>
        <div class="col-md-9 mt-md-4">
          <!-- MENU TABS PROPRIETAIRE -->
          <ul class="nav nav-tabs profile-tabs nav-fill" id="tabShowOwner" role="tablist">
            <li class="nav-item">
              <a class="nav-link text-reset" [ngClass]="{'active': this.activeTab === 'PROPRIETAIRE'}"
                (click)="onChangeLoad(false,'PROPRIETAIRE')" id="profil-tab" data-toggle="tab" href="javascript:" role="tab"
                aria-controls="profil" aria-selected="true">
                <i class="far fa-user"></i> Profil
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link text-reset" [ngClass]="{'active': this.activeTab === 'LOT'}"
                (click)="onChangeLoad(true,'LOT')" id="copropriete-tab" data-toggle="tab" href="javascript:"
                role="tab" aria-controls="copropriete" aria-selected="false">
                <i class="feather icon-home"></i> Lots <span class="bold text-primary">{{coproprietes?.length ? coproprietes?.length : ''}}</span>
              </a>
            </li>
            <li class="nav-item" >
              <a class="nav-link text-reset" [ngClass]="{'active': this.activeTab === 'APPEL'}"
                (click)="onChangeLoad(true,'APPEL')" id="appel-tab" data-toggle="tab" href="javascript:"
                role="tab" aria-controls="appel" aria-selected="false">
                <i class="fas fa-money-bill-wave"></i> Appels de charge <span class="bold text-primary">{{fundsapeals?.length ? fundsapeals?.length : ''}}</span>
              </a>
            </li>

            <li class="nav-item" >
              <a class="nav-link text-reset" [ngClass]="{'active': this.activeTab === 'PROVISION'}"
                (click)="onChangeLoad(true,'PROVISION')" id="provision-tab" data-toggle="tab" href="javascript:"
                role="tab" aria-controls="provision" aria-selected="false">
                <i class="fas fa-donate"></i> Règlement <span class="bold text-primary">{{fundsPayments?.length ? fundsPayments?.length : ''}}</span>
              </a>
            </li>

            <!-- <li class="nav-item" >
              <a class="nav-link text-reset" [ngClass]="{'active': this.activeTab === 'TRAVAUX'}"
                (click)="onChangeLoad(true,'TRAVAUX')" id="travaux-tab" data-toggle="tab" href="javascript:"
                role="tab" aria-controls="travaux" aria-selected="false">
                <i class="fas fa-paint-roller"></i> Interventions <span class="bold text-primary">{{interventions?.length ? interventions?.length : ''}}</span>
              </a>
            </li> -->

          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- RETOUR -->
<div class="row">
  <div class="col-sm-12 mb-2">
    <button (click)="back()" type="button" class="btn btn-secondary m-1">
      <i class="fa fa-arrow-alt-circle-left"></i> Retour
    </button>
    <div class="btn-group float-right m-1 btn-outil-syndic" ngbDropdown>
      <button class="btn btn-secondary" ngbDropdownToggle type="button">
          Outils <i class="fas fa-tools"></i>
      </button>
      <div class="dropdown-menu-right" ngbDropdownMenu>
          <a class="dropdown-item" ngbTooltip="Cliquez ici pour imprimer le listing" (click)="onPrinter()">
              Imprimer <i class="feather icon-printer"></i>
          </a>
         <!--  <a class="dropdown-item" ngbTooltip="Cliquez ici pour exporter le listing" (click)="onExport()">
              Exporter <i class="fa fa-file-excel"></i>
          </a> -->
      </div>
  </div>
  </div>
</div>

<!-- FILTRE DE RECHERCHE -->
<div class="row">
    <app-filter class="width" *ngIf="view" [name]="name" [nameTitle]="nameTitle" [bien]="bien" [bienTitle]="bienTitle" [user]="true" [userTitle]="userTitle" [etat]="etat"
      [etatRow]="etatRow" [etatTitle]="etatTitle" [categorie]="categorie" [categorieRow]="categorieRow"
      [categorieTitle]="categorieTitle" [dateD]="true" [dateF]="true" [create]="true" [min]="false" [minTitle]="minTitle"
      [max]="false" [maxTitle]="maxTitle" [ordre]="true" [code]="false" [count]="true" [type]="true" [typeRow]="typeRow"
      [uuid]="owner.uuid" (typeEvent)="onChangeLoad(true, $event)" (filterEvent)="onFilter($event)">
    </app-filter>
</div>

<!-- AFFICHAGE DU TABS -->
<div class="row">
  <div class="col-md-12 order-md-2">
    <div class="tab-content" id="myTabContent">

      <div class="tab-pane fade" [ngClass]="{'show active': activeTab === 'PROPRIETAIRE'}" id="profil" role="tabpanel"
        aria-labelledby="profil-tab">
        <div class="card">
          <div class="col-md-12 mt-2 text center">
            <div class="row col-md">
              <div class="col-md-6">
                <div class="row py-2 item-condominium">
                  <div class="col-md-5">
                      <span>
                          Réference :
                      </span>
                  </div>
                  <div class="col-md-7">
                      <span class="bold">
                          {{ owner?.code }}
                      </span>
                  </div>
                </div>
                <div class="row py-2">
                    <div class="col-md-5">
                        <span>
                            {{owner?.type === 'PARTICULIER' ? 'Nom & prénoms' : 'Raison social'}}
                        </span>
                    </div>
                    <div class="col-md-7">
                        <span class="bold">
                            {{owner?.nom }}
                        </span>
                    </div>
                </div>
                <div class="row py-2 item-condominium">
                    <div class="col-md-5">
                        <span>
                            Type
                        </span>
                    </div>
                    <div class="col-md-7">
                        <span class="bold">
                            {{ owner?.type === 'PARTICULIER' ? 'Particulier' : 'Enreprise' }}
                        </span>
                    </div>
                </div>
                <div class="row py-2">
                    <div class="col-md-5">
                        <span>
                            {{owner?.type === 'PARTICULIER' ? 'Contact' : 'Tel. bureau'}}
                        </span>
                    </div>
                    <div class="col-md-7">
                        <span class="bold">
                            {{owner?.telephone}}
                        </span>
                    </div>
                </div>
                <div class="row py-2 item-condominium">
                    <div class="col-md-5">
                        <span>
                            Autorisation de notifier
                        </span>
                    </div>
                    <div class="col-md-7">
                        <span class="bold">
                            <span *ngIf="owner?.autorisationNotif === 'NON'">Aucune</span>
                            <span *ngIf="owner?.autorisationNotif === 'SMS'">SMS</span>
                            <span *ngIf="owner?.autorisationNotif === 'EMAIL'">EMAIL</span>
                            <span *ngIf="owner?.autorisationNotif === 'SMS & EMAIL'">SMS & MAIL</span>
                        </span>
                    </div>
                </div>
              </div>
              <div class="col-md-6">
                <!--
                  <div class="row py-2" *ngIf="owner?.type === 'ENTREPRISE'">
                  <div class="col-md-5">
                      <span>
                          N° Compte contribuable
                      </span>
                  </div>
                  <div class="col-md-7">
                      <span class="bold">
                          {{ owner?.ncc}}
                      </span>
                  </div>
                </div>
                <div class="row py-2 item-condominium"  *ngIf="owner?.type === 'ENTREPRISE'">
                    <div class="col-md-5">
                        <span>
                            N° Registre de commerce
                        </span>
                    </div>
                    <div class="col-md-7">
                        <span class="bold">
                            {{ owner?.nrc}}
                        </span>
                    </div>
                </div>
                -->
                <div class="row py-2"  *ngIf="owner?.type === 'ENTREPRISE'">
                    <div class="col-md-5">
                        <span>
                            Boîte postal
                        </span>
                    </div>
                    <div class="col-md-7">
                        <span class="bold">
                            {{ owner?.codePostal}}
                        </span>
                    </div>
                </div>
                <div class="row py-2 ">
                  <div class="col-md-5">
                      <span>
                        Date de création : <br />
                      </span>
                  </div>
                  <div class="col-md-7">
                      <span class="bold">
                        {{owner?.createdAt| date: "d MMMM y 'à' H:mm:ss " : '' : 'fr-FR'}}<br />
                      </span>
                  </div>
                </div>
                <div class="row py-2 item-condominium">
                  <div class="col-md-5">
                      <span>
                        Créer par
                      </span>
                  </div>
                  <div class="col-md-7">
                      <span class="bold">
                        {{owner?.create}}
                      </span>
                  </div>
                </div>
                <div class="row py-2 ">
                  <div class="col-md-5">
                      <span>
                        Date de modification : <br />
                      </span>
                  </div>
                  <div class="col-md-7">
                      <span class="bold">
                        {{owner?.updatedAt| date: "d MMMM y 'à' H:mm:ss " : '' : 'fr-FR'}}<br />
                      </span>
                  </div>
                </div>
                <div class="row py-2 item-condominium">
                  <div class="col-md-5">
                      <span>
                        Modifier par
                      </span>
                  </div>
                  <div class="col-md-7">
                      <span class="bold">
                        {{owner?.update}}
                      </span>
                  </div>
                </div>
              </div>
            </div>
            <hr class="my-2">
            <div class="col-md" *ngIf="owner">
              <span class="badge badge-primary mb-4 f-14 width">FICHE DE DOSSIER</span>
              <div class="row">
                <div [ngClass]="file ? 'col-md-4' : 'col-md-12'">
                  <app-folder-uploader (click)="showFile(owner?.folder)" [folder]="owner?.folder" [etat]="'show'"
                    [allowFileUpload]="false" [allowDownload]="true">
                  </app-folder-uploader>
                </div>
                <div [ngClass]="file ? 'col-md-8' : ''" *ngIf="file">
                  <div class="row">
                    <div class="col-sm-12 mb-2">
                      <button (click)="closeViewer()" type="button" class="btn btn-secondary m-1">
                        <i class="fa fa-arrow-alt-circle-left"></i> Fermer
                      </button>
                    </div>
                  </div>
                  <ngx-doc-viewer [url]="file" viewer="url" style="width:100%;height: 64vh;">
                  </ngx-doc-viewer>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button *ngxPermissionsOnly="'OWNER:EDIT'" (click)="editOwner(owner)" type="button"
                class="btn btn-primary" ngbTooltip="Modifier">
                Modifier <i class="feather icon-edit-2"></i>
              </button>
              <button *ngxPermissionsOnly="'OWNER:PRINTER:SHOW'" (click)="printerOwner(owner)" type="submit"
                class="btn btn-warning" ngbTooltip="Imprimer">
                Imprimer <i class="feather icon-printer"></i>
              </button>
              <ng-template ngxPermissionsOnly="OWNER:DELETE">
                <button *ngIf="owner?.isDelete" (click)="delete(owner)" type="button"
                  class="btn btn-danger text-left" data-dismiss="modal" ngbTooltip="Supprimer">
                  Supprimer <i class="feather icon-trash"></i>
                </button>
              </ng-template>
            </div>
          </div>
        </div>
      </div>

      <div class="tab-pane fade" [ngClass]="{'show active': activeTab === 'LOT'}" id="copropriete" role="tabpanel"
        aria-labelledby="bien-tab">
        <div class="row">
          <div class="col-md-12">
            <span class="badge badge-secondary mb-4 f-14 width">
              LISTE DES LOTS
            </span>
            <app-no-data [title]="'Aucune copropriété trouvée'" *ngIf="coproprietes && coproprietes.length === 0"></app-no-data>
            <div *ngIf="coproprietes && coproprietes.length > 0" class="row">
              <div class="col-lg-4 col-md-4" *ngFor="let item of coproprietes">
                <app-card [customHeader]="true" headerClass="border-0 p-2 pb-0" [options]="false" cardClass="user-card user-card-1" blockClass="pt-0 users-contact">
                  <div class="app-card-header" (click)="showHouse(item)">
                    <div class="row align-items-end">
                      <div *ngIf="item?.type !== 'VERTICAL'" class="col text-left pb-3">
                        <span>{{item?.owner?.nom}}</span> <br />
                      </div>
                      <div class="col text-right pb-3">
                        <span class="badge badge-light-warning ml-1">
                          <span *ngIf="item?.categorie">
                            {{item?.categorie === 'APPARTEMENT' ? 'Appartement ' : item?.categorie === 'BUREAU' ? 'Bureau' : item?.categorie === 'MAGASIN' ? 'Magasin' : item?.categorie === 'SURFACE' ? 'Surface' : item?.categorie === 'RESTAURANT' ? 'Restaurant' : item?.categorie === 'HALL' ? 'Hall' : item?.categorie === 'SALLE CONFERENCE' ? 'Salle de conférence' : item?.categorie === 'PARKING' ? 'Parking' : item?.categorie === 'VILLA' ? 'Villa' : item?.categorie === 'STUDIO' ? 'Studio' : ''}}
                          </span>
                          <span *ngIf="item?.type === 'VERTICAL'">
                            Vertical
                          </span>
                          <span *ngIf="item?.type === 'HORIZONTAL' && item?.categorie === 'HORIZONTAL'">Horizontal</span>
                        </span>
                      </div>
                    </div>
                    <div class="cover-img-block condominium-pic">
                      <img [src]="item?.photoSrc ? publicUrl+'/'+item.photoSrc : 'assets/images/house-default.png'" alt="{{ item?.nom }}"
                       title="{{ item?.nom }}" class="img-fluid">
                    </div>
                  </div>
                  <div class="text-center" (click)="showHouse(item)">
                    <div class="row align-items-end">
                    </div>
                    <div class="alert-info mb-1">
                      <div class="col-md-12 mt-2 text-center p-2">
                        <h5 class="bold">
                          Lot : {{item?.nom}}
                        </h5>
                      </div>
                    </div>
                    <h6 class="mb-1 mt-3">
                      Syndic : {{item?.trustee?.nom}}
                    </h6>
                    <h6 class="mb-1 mt-3">
                      <span>
                        Superficie : {{item?.superficie}} (m²)
                      </span>
                      <span *ngIf="item?.type !== 'VERTICAL'">
                         - Numéro de porte : {{item?.numPorte}}
                      </span>
                    </h6>
                  </div>
                  <hr>
                  <div class="row mb-2" (click)="showHouse(item)">
                    <div class="col text-left">
                      <p class="mb-0">Code : {{item?.code}}</p>
                    </div>
                  </div>
                  <div class="row align-content">
                    <div class="col text-right">
                        <button (click)="showHouse(item)" type="button" class="btn btn-icon btn-secondary" ngbTooltip="Détails">
                          <i class="fas fa-eye"></i>
                        </button>
                        <button (click)="editHouse(item)" type="button" class="btn btn-icon btn-primary ml-1" ngbTooltip="Modifier">
                          <i class="feather icon-edit-2"></i>
                        </button>
                        <button (click)="printHouse(item)" type="button" class="btn btn-icon btn-warning ml-1" ngbTooltip="Imprimer">
                          <i class="feather icon-printer"></i>
                        </button>
                        <button (click)="deleteOwner(item)" type="button" class="btn btn-icon btn-danger ml-1" ngbTooltip="Supprimer">
                          <i class="feather icon-trash"></i>
                        </button>
                    </div>
                  </div>
                </app-card>
              </div>
          </div>
          </div>
        </div>
      </div>

      <div class="tab-pane fade" [ngClass]="{'show active': activeTab === 'MANDAT_SYNDIC'}" id="mandate_syndic" role="tabpanel"
        aria-labelledby="bien-tab">
        <div class="row">
          <div class="col-md-12">
            <span class="badge badge-secondary mb-4 f-14 width">
              LISTE DES MANDATS
            </span>
            <app-no-data [title]="'Aucun mandat syndic trouvé'" *ngIf="mandateSyndics && mandateSyndics.length === 0"></app-no-data>
            <div *ngIf="mandateSyndics && mandateSyndics.length > 0">
              <app-card [hidHeader]="true" cardClass="card-datatable">
                  <div class="table-responsive m-0">
                      <table datatable [dtOptions]="dtOptions" class="table table-striped m-0">
                          <thead class="thead-light">
                            <tr>
                                <th>Code</th>
                                <th>Syndic</th>
                                <th>Honoraires/Periodicité</th>
                                <th>Date de signature</th>
                                <th>Date</th>
                                <th>État</th>
                                <th>Crée le</th>
                                <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                              <tr *ngFor="let item of mandateSyndics">
                                  <td>
                                      <span>
                                          {{item?.code}}
                                      </span>
                                  </td>
                                  <td>
                                  <span class="text-black">
                                      <div class="d-flex align-items-center">
                                      <div>
                                          <span class="text-black d-block">
                                              Code : {{item?.trustee?.code}}
                                          </span>
                                          <span class="text-black d-block">
                                              Syndic : {{item?.trustee?.nom}}
                                          </span>
                                      </div>
                                      </div>
                                  </span>
                                  </td>
                                  <td>
                                      <span class="text-black">
                                      <div class="d-flex align-items-center">
                                      <div>
                                          <span class="text-black d-block">
                                              Honoraires : {{item?.honoraires | number}} <span *ngIf="item?.typeHonoraire === 'BUDGET'">% du budget</span>
                                          </span>
                                          <span class="text-black d-block">
                                              Périodicité :
                                              <span *ngIf="item?.periodicite === 'MENSUEL'" class="badge badge-primary">Mensuel</span>
                                              <span *ngIf="item?.periodicite === 'TRIMESTRIEL'" class="badge badge-primary">Trimestriel</span>
                                              <span *ngIf="item?.periodicite === 'ANNUEL'" class="badge badge-primary">Annuel</span>
                                          </span>
                                      </div>
                                      </div>
                                  </span>
                                  </td>
                                  <td>
                                      <span>
                                          {{item?.date | date: "d MMMM y" : '' : 'fr-FR'}}
                                      </span>
                                  </td>
                                  <td>
                                      <span class="text-black">
                                          <div class="d-flex align-items-center">
                                              <div>
                                                  <span class="text-black d-block">
                                                      Début : {{item?.dateD| date: "d MMMM y" : '' : 'fr-FR'}}
                                                  </span>
                                                  <span class="text-black d-block">
                                                      Fin : {{item?.dateF| date: "d MMMM y" : '' : 'fr-FR'}}
                                                  </span>
                                              </div>
                                          </div>
                                      </span>
                                  </td>
                                  <td>
                                    <span *ngIf="item?.etat === 'VALIDE'" class="badge badge-success">Validé</span>
                                    <span *ngIf="item?.etat === 'ATTENTE'" class="badge badge-success">En attente</span>
                                    <span *ngIf="item?.etat === 'EN COURS'" class="badge badge-success">En cours</span>
                                  </td>
                                  <td>{{item?.createdAt| date: "d MMMM y 'à' H:mm:ss " : '' : 'fr-FR'}}</td>
                                  <td>
                                    <button (click)="showMandate(item)" type="button" class="btn btn-icon btn-secondary" ngbTooltip="Détails">
                                          <i class="fas fa-eye"></i>
                                      </button>
                                      <button (click)="editMandate(item)" type="button"class="btn btn-icon btn-primary ml-1" ngbTooltip="Modifier">
                                          <i class="feather icon-edit-2"></i>
                                      </button>
                                      <button (click)="printMandate(item)" type="button"class="btn btn-icon btn-warning ml-1" ngbTooltip="Imprimer">
                                          <i class="feather icon-printer"></i>
                                      </button>
                                      <button (click)="deleteMandate(item)" type="button" class="btn btn-icon btn-danger ml-1" ngbTooltip="Supprimer">
                                          <i class="feather icon-trash"></i>
                                      </button>
                                  </td>
                              </tr>
                          </tbody>
                      </table>
                  </div>
              </app-card>
            </div>
          </div>
        </div>
      </div>

      <div class="tab-pane fade" [ngClass]="{'show active': activeTab === 'APPEL'}" id="appel" role="tabpanel"
        aria-labelledby="bien-tab">
        <div class="row">
          <div class="col-md-12">
            <span class="badge badge-secondary mb-4 f-14 width">
              LISTE DES APPELS DE CHARGES
            </span>
            <app-no-data [title]="'Aucun appel de charge trouvé'" *ngIf="fundsapeals && fundsapeals.length === 0"></app-no-data>
            <div *ngIf="fundsapeals && fundsapeals.length > 0">
              <app-card [hidHeader]="true" cardClass="card-datatable">
                <div class="table-responsive m-0">
                  <table datatable [dtOptions]="dtOptions" class="table table-hover text-nowrap table-bordered m-0">
                    <thead class="thead-light">
                      <tr>
                          <th rowspan="2" class="text-center justify-content-center align-items-center">Lot/Numéro porte</th>
                          <th rowspan="2" class="text-center justify-content-center align-items-center">Copropriétaire</th>
                          <th rowspan="2" class="text-center justify-content-center align-items-center">Libellé</th>
                          <th rowspan="2" class="text-center justify-content-center align-items-center">Période</th>
                          <th rowspan="2" class="text-center justify-content-center align-items-center">Statut</th>
                          <th colspan="3" class="text-center justify-content-center align-items-center">Valeur financiére</th>
                          <th rowspan="2" class="text-center justify-content-center align-items-center">Crée le</th>
                          <th rowspan="2" class="text-center justify-content-center align-items-center">Actions</th>
                      </tr>
                      <tr>
                        <th>Montant Total</th>
                        <th>Payé</th>
                        <th>Reste</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of fundsapeals">
                        <td>
                          <span class="text-black">
                            <div class="d-flex align-items-center">
                              <div>
                                <span class="text-black d-block">
                                    Lot : {{item?.numLot}}
                                </span>
                                <span class="text-black d-block">
                                    Porte : {{item?.numPorte}}
                                </span>
                              </div>
                            </div>
                          </span>
                        </td>
                        <td>
                          <span class="text-black">
                            <div class="d-flex align-items-center">
                              <div>
                                <span class="text-black d-block">
                                    Nom : {{item?.owner?.nom}}
                                </span>
                                <span class="text-black d-block">
                                    Type : <sapn class="badge badge-{{ item.type === 'ENTREPRISE' ? 'info': 'secondary' }}">{{item?.owner?.type}}</sapn>
                                </span>
                              </div>
                            </div>
                          </span>
                        </td>
                        <td><span>{{item?.libelle}}</span></td>
                        <td><span>{{item?.periode}}</span></td>
                        <td>
                          <span *ngIf="item?.etat === 'PAYER'" class="badge badge-success">Payé</span>
                          <span *ngIf="item?.etat === 'IMPAYER'" class="badge badge-danger">Impayé</span>
                          <span *ngIf="item?.etat === 'ATTENTE'" class="badge badge-warning">En attente</span>
                          <span *ngIf="item?.etat === 'EN COURS'" class="badge badge-info">En cours</span>
                        </td>
                        <td class="text-right"><span class="text-primary bold">{{item?.montant | number}} XOF</span></td>
                        <td class="text-right"><span class="text-success bold">{{item?.payer | number}} XOF</span></td>
                        <td class="text-right"><span class="text-danger bold">{{item?.reste | number}} XOF</span></td>
                        <td>{{item?.createdAt| date: "d MMMM y 'à' H:mm:ss " : '' : 'fr-FR'}}</td>
                        <td class="text-center">
                          <button (click)="showFundsApeal(item)" type="button" class="btn btn-icon btn-secondary" ngbTooltip="Détails">
                            <i class="fas fa-eye"></i>
                          </button>
                          <button (click)="deleteFundsApeal(item)" type="button" class="btn btn-icon btn-danger ml-1" ngbTooltip="Supprimer">
                            <i class="feather icon-trash"></i>
                          </button>
                          <button (click)="printerFundsApeal(item)" type="button"class="btn btn-icon btn-warning ml-1" ngbTooltip="Imprimer">
                            <i class="feather icon-printer"></i>
                        </button>
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <th colspan="5"></th>
                        <th class="text-right"><span class="text-primary bold">{{montant | number}} XOF</span></th>
                        <th class="text-right"><span class="text-success bold">{{paye | number}} XOF</span></th>
                        <th class="text-right"><span class="text-danger bold">{{reste | number}} XOF</span></th>
                        <th></th>
                        <th></th>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </app-card>
            </div>
          </div>
        </div>
      </div>

      <div class="tab-pane fade" [ngClass]="{'show active': activeTab === 'AVIS'}" id="avis" role="tabpanel"
        aria-labelledby="bien-tab">
        <div class="row">
          <div class="col-md-12">
            <span class="badge badge-secondary mb-4 f-14 width">
              LISTE DES AVIS ECHEANCE
            </span>
            <app-no-data [title]="'Aucun avis d\'écheance trouvé'" *ngIf="fundsNotices && fundsNotices.length === 0"></app-no-data>
            <div *ngIf="fundsNotices && fundsNotices.length > 0">
              <app-card [hidHeader]="true" cardClass="card-datatable">
                <div class="table-responsive m-0">
                    <table datatable [dtOptions]="dtOptions" class="table table-striped m-0">
                        <thead class="thead-light">
                          <tr>
                              <th>Code</th>
                              <th>Libélle</th>
                              <th>Montant</th>
                              <th>Payé</th>
                              <th>Impayé</th>
                              <th>Délai</th>
                              <th>Etat</th>
                              <th>Crée le</th>
                              <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let item of fundsNotices">
                            <td><span>{{item?.code}}</span></td>
                            <td><span>{{item?.libelle}}</span></td>
                            <td><span>{{item?.montant | number}} xof</span></td>
                            <td><span>{{item?.paye | number}} xof</span></td>
                            <td><span>{{item?.impaye | number}} xof</span></td>
                            <td><span>{{item?.delai | number}}</span></td>
                            <td><span>{{item?.etat}}</span></td>
                            <td>{{item?.createdAt| date: "d MMMM y 'à' H:mm:ss " : '' : 'fr-FR'}}</td>
                            <td class="text-center">
                              <button (click)="showFundsNotice(item)" type="button" class="btn btn-icon btn-secondary" ngbTooltip="Détails">
                                <i class="fas fa-eye"></i>
                              </button>
                              <button (click)="deleteFundsNotice(item)" type="button" class="btn btn-icon btn-danger ml-1" ngbTooltip="Supprimer">
                                <i class="feather icon-trash"></i>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                    </table>
                </div>
              </app-card>
            </div>
          </div>
        </div>
      </div>

      <div class="tab-pane fade" [ngClass]="{'show active': activeTab === 'TRAVAUX'}" id="travaux" role="tabpanel"
        aria-labelledby="bien-tab">
        <div class="row">
          <div class="col-md-12">
            <span class="badge badge-secondary mb-4 f-14 width">
              LISTE DES TRAVAUX
            </span>
            <app-no-data [title]="'Aucun travaux trouvé'" *ngIf="interventions && interventions.length === 0">
            </app-no-data>
            <div *ngIf="interventions && interventions.length > 0">
              <app-card [hidHeader]="true" cardClass="card-datatable">
                  <div class="table-responsive">
                      <table datatable [dtOptions]="dtOptions"
                          class="table table-sm table-striped table-bordered nowrap table-hover">
                          <thead class="thead-light">
                            <tr>
                              <th>Bien</th>
                              <th>Libellé</th>
                              <th>Evolution</th>
                              <th>Etat</th>
                              <th>Crée le</th>
                              <th>Montant</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                              <tr *ngFor="let item of interventions">
                                  <td class="align-middle">
                                      <p class="m-0 d-inline-block align-middle font-16">
                                          <span class="text-primary">{{item?.trustee ? item?.trustee?.nom : ''}}</span>
                                          <span *ngIf="item?.rental!=null || item?.house!=null">
                                              <br /> <span>
                                                  Superficie : {{item?.rental
                                                  ? item?.rental?.superficie : item?.house?.superficie}} m² -
                                                  {{item?.rental
                                                  ? item?.rental?.piece : item?.house?.nbrPiece}} pièce(s)
                                              </span>
                                          </span>
                                          <span *ngIf="item?.houseCo!=null">
                                              <br /> <span>Copropriété : {{item?.houseCo?.nom}}</span>
                                          </span>
                                          <span *ngIf="item?.homeCo!=null">
                                              <br /> <span>Villa ou appartement : {{item?.homeCo?.nom}}</span>
                                          </span>
                                          <span *ngIf="item?.infrastructure!=null">
                                              <br /> <span>Infrastructure : {{item?.infrastructure?.nom}}</span>
                                          </span>
                                          <span *ngIf="item?.nature!=null">
                                              <br /> <span>Nature : {{item?.nature?.libelle}}</span>
                                          </span>
                                          <span *ngIf="house!=null">
                                              <br /> <span>Propriétaire
                                                  : {{item?.house?.owner?.searchableTitle}}</span>
                                          </span>
                                      </p>
                                  </td>
                                  <td class="align-middle">
                                      <p class="m-0 d-inline-block align-middle font-16">
                                          <span class="text-primary">{{item?.nom}}</span><br /> Durée:
                                          {{timelapse(item?.dateD, item?.dateF)}} <br /> Début: {{item?.dateD|date: "d MMMM y" : '' : 'fr-FR'}} - Fin: {{item?.dateF|date: "d MMMM y" : '' : 'fr-FR'}}
                                      </p>
                                  </td>
                                  <td>
                                      <circle-progress [percent]="item?.pourcentage" [radius]="25" [toFixed]="1"
                                          [titleFontSize]="10" [outerStrokeWidth]="5" [innerStrokeWidth]="3"
                                          [outerStrokeColor]="item?.pourcentage >= 65 ? '#3FBD0D' : item?.pourcentage > 35 ? '#F0DD35' : '#EC0F0F'"
                                          [innerStrokeColor]="'#95cde4'" [animation]="true" [showSubtitle]="false"
                                          [animationDuration]="300"></circle-progress>
                                  </td>
                                  <td>
                                      <span class="badge" [ngClass]="{
                                          'badge-danger' : item?.etat === 'STOPPER',
                                          'badge-primary' : item?.etat === 'EN COURS',
                                          'badge-warning' : item?.etat === 'PREVU',
                                          'badge-success' : item?.etat === 'TERMINER'
                                          }"> {{ item?.etat === 'EN COURS' ? 'EN COURS DE REALISATION' : item?.etat }}
                                      </span>
                                  </td>
                                  <td>{{item?.createdAt|date: "d MMMM y 'à' h:mm:ss" : '' : 'fr-FR'}}</td>
                                  <td>
                                      <p class="m-0 d-inline-block align-middle font-16">
                                          <span class="text-warning">{{item?.budget|number}} {{global.device}}</span>
                                      </p>
                                  </td>
                                  <td class="table-action">
                                    <button (click)="showConstruction(item)" type="button"
                                      class="btn btn-icon btn-secondary" ngbTooltip="Détails">
                                      <i class="fas fa-eye"></i>
                                    </button>
                                    <button (click)="editConstruction(item)" type="button"
                                        class="btn btn-icon btn-primary ml-1" ngbTooltip="Modifier">
                                        <i class="feather icon-edit-2"></i>
                                    </button>
                                    <button (click)="printerConstruction(item)" type="button"
                                        class="btn btn-icon btn-warning ml-1" ngbTooltip="Imprimer">
                                        <i class="feather icon-printer"></i>
                                    </button>
                                    <button (click)="deleteConstruction(item)" type="button" class="btn btn-icon btn-danger ml-1"
                                        ngbTooltip="Supprimer">
                                        <i class="feather icon-trash"></i>
                                    </button>
                                  </td>
                              </tr>
                          </tbody>
                      </table>
                  </div>
              </app-card>
            </div>
          </div>
        </div>
      </div>

      <div class="tab-pane fade" [ngClass]="{'show active': activeTab === 'PROVISION'}" id="provision" role="tabpanel" aria-labelledby="bien-tab">
        <div class="row">
          <div class="col-md-12">
            <span class="badge badge-secondary mb-4 f-14 width">
              LISTE DES RÈGLEMENTS
            </span>
            <app-no-data [title]="'Aucun règlement trouvé'" *ngIf="fundsPayments && fundsPayments.length === 0"></app-no-data>
            <div *ngIf="fundsPayments && fundsPayments.length > 0">
              <app-card [hidHeader]="true" cardClass="card-datatable">
                <div class="table-responsive m-0">
                  <table datatable [dtOptions]="dtOptions" class="table table-striped m-0">
                    <thead class="thead-light">
                      <tr>
                          <th>Numéro porte / lot</th>
                          <th>Appel de charge</th>
                          <th>Numéro opération</th>
                          <th>Tresorerie</th>
                          <th>Montant</th>
                          <th>Date</th>
                          <th>Moyen</th>
                          <th>Crée le</th>
                          <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of fundsPayments">
                        <td>
                          <span class="text-black">
                          <div class="d-flex align-items-center">
                            <div>
                              <span class="text-black d-block">
                                  Lot : {{item?.numLot | number}}
                              </span>
                              <span class="text-black d-block">
                                  Porte : {{item?.numPorte}}
                              </span>
                            </div>
                          </div>
                        </span></td>
                        <td>
                          {{item?.numOperation}}
                        </td>
                        <td><span class="text-primary">{{item?.fundsApeal?.libelle}}</span></td>
                        <td>
                          <span class="text-black">
                          <div class="d-flex align-items-center">
                            <div>
                              <span class="text-black d-block">
                                  {{item?.treasury?.nom}}
                              </span>
                              <span class="text-black d-block">
                                <span class="badge badge-primary">
                                  {{item?.treasury?.type}}
                                </span>
                              </span>
                            </div>
                          </div>
                          </span>
                        </td>
                        <td><span class="text-primary">{{item?.montant | number}} XOF</span></td>
                        <td>{{item?.date| date: "d MMMM y" : '' : 'fr-FR'}}</td>
                        <td><span class="badge badge-dark">{{item?.moyen}}</span></td>
                        <td>{{item?.createdAt| date: "d MMMM y 'à' H:mm:ss " : '' : 'fr-FR'}}</td>
                        <td class="text-center">
                          <button (click)="showFundsPayment(item)" type="button" class="btn btn-icon btn-secondary" ngbTooltip="Détails">
                            <i class="fas fa-eye"></i>
                          </button>
                          <button (click)="deleteFundsPayment(item)" type="button" class="btn btn-icon btn-danger ml-1" ngbTooltip="Supprimer">
                            <i class="feather icon-trash"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </app-card>
            </div>
          </div>
        </div>
      </div>

      <div class="tab-pane fade" [ngClass]="{'show active': activeTab === 'INFRASTRUCTURE'}" id="provision" role="tabpanel"
        aria-labelledby="bien-tab">
        <div class="row">
          <div class="col-md-12">
            <span class="badge badge-secondary mb-4 f-14 width">
              LISTE DES INFRASTRUCTURES
            </span>
            <app-no-data [title]="'Aucune infrastructure trouvée'" *ngIf="infrastructures && infrastructures.length === 0"></app-no-data>
            <div *ngIf="infrastructures && infrastructures.length > 0">
              <app-card [hidHeader]="true" cardClass="card-datatable">
                <div class="table-responsive m-0">
                  <table datatable [dtOptions]="dtOptions" class="table table-striped m-0">
                    <thead class="thead-light">
                      <tr>
                          <th>Infrastructure</th>
                          <th>Syndic</th>
                          <th>Hauteur/Surface</th>
                          <th>Etat/Type</th>
                          <th>Crée le</th>
                          <th>Actions</th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr *ngFor="let item of infrastructures">
                          <td>
                              <span class="text-black">
                                  <div class="d-flex align-items-center">
                                  <div>
                                      <span class="text-black d-block">
                                          {{item?.code}}
                                      </span>
                                      <span class="text-black d-block">
                                          {{item?.nom}}
                                      </span>
                                  </div>
                                  </div>
                              </span>
                          </td>
                          <td>
                              <span class="text-black">
                                  <div class="d-flex align-items-center">
                                  <div>
                                      <span class="text-black d-block">
                                          {{item?.trustee?.code}}
                                      </span>
                                      <span class="text-black d-block">
                                          {{item?.trustee?.nom}}
                                      </span>
                                  </div>
                                  </div>
                              </span>
                          </td>
                          <td>
                              <span class="text-black">
                                  <div class="d-flex align-items-center">
                                  <div>
                                      <span class="text-black d-block">
                                          Hauteur : {{item?.hauteur | number}} m
                                      </span>
                                      <span class="text-black d-block">
                                          Superficie :{{item?.superficie | number}} m²
                                      </span>
                                  </div>
                                  </div>
                              </span>
                          </td>
                          <td>
                              <span class="text-black">
                                  <div class="d-flex align-items-center">
                                  <div>
                                      <span class="text-black d-block">
                                          Etat :
                                          <span *ngIf="item?.etat === 'EN SERVICE'" class="badge badge-success">En service</span>
                                          <span *ngIf="item?.etat === 'HORS SERVICE'" class="badge badge-danger">Hors service</span>
                                      </span>
                                      <span class="text-black d-block">
                                          Type :{{item?.type}}
                                      </span>
                                  </div>
                                  </div>
                              </span>
                          </td>
                          <td>{{item?.createdAt| date: "d MMMM y 'à' H:mm:ss " : '' : 'fr-FR'}}</td>
                          <td>
                              <button (click)="showInfrastructure(item)" type="button" class="btn btn-icon btn-secondary" ngbTooltip="Détails">
                                  <i class="fas fa-eye"></i>
                              </button>
                              <button (click)="editInfrastructure(item)" type="button"class="btn btn-icon btn-primary ml-1" ngbTooltip="Modifier">
                                  <i class="feather icon-edit-2"></i>
                              </button>
                              <button (click)="printInfrastructure(item)" type="button"class="btn btn-icon btn-warning ml-1" ngbTooltip="Imprimer">
                                  <i class="feather icon-printer"></i>
                              </button>
                              <button (click)="deleteInfrastructure(item)" type="button" class="btn btn-icon btn-danger ml-1" ngbTooltip="Supprimer">
                                  <i class="feather icon-trash"></i>
                              </button>
                          </td>
                      </tr>
                  </tbody>
                </table>
              </div>
              </app-card>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
