import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-grand-livre-show',
  templateUrl: './grand-livre-show.component.html',
  styleUrls: ['./grand-livre-show.component.scss']
})
export class GrandLivreShowComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
