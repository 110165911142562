export const environment = {
  production: false,
  // serverUrl: "http://zenapi-api.simaubenin.com/api",
  // publicUrl: "http://zenapi-api.simaubenin.com",
  // serverUrl: "http://127.0.0.1:8000/api",
  // publicUrl: "http://127.0.0.1:8000",
  serverUrl: "http://api.sci-jasmina.zenapi.immo/api",
  publicUrl: "http://api.sci-jasmina.zenapi.immo",
  // serverUrl: "https://demo.api.zenapi.immo/api",

  // serverUrl: "http://demo-api.simaubenin.com/api",
  // publicUrl: "http//demo-api.simaubenin.com",
  mercureUrl: "https://zenapi-mercure.herokuapp.com/.well-known/mercure"
};
