<div class="modal-header">
    <h5 class="modal-title">{{title}}</h5>
    <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
        (click)="modale.close('ferme')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="col-md-12">
        <div class="d-flex">
            <!-- COL MILLIEU -->
            <div class="flex-fill">
                <p><span class="title-show bold">Code: </span> {{ typeLoad?.loadCategoryCode }}</p>
                <p><span class="title-show bold">Catétorie de charge: </span> {{ typeLoad?.loadCategoryLibelle }}</p>                
            </div>
            <!-- COL DROITE -->
            <div class="flex-fill">
                <p><span class="title-show bold">Budget: </span> <span class="text-primary font-weight-blod"> {{ typeLoad?.budgetValide  | number }} {{global.device}}</span> </p>
            </div>
        </div>
        <div class="row mb-2">          
            <div class="col-sm-4">
              <span class="badge badge-success text-center width">
                <h5 class="text-white py-2">TOTAL PAYÉES: {{ typeLoad?.paye  | number }} {{global.device}}</h5>
              </span>
            </div>
            <div class="col-sm-4">
                <span class="badge badge-danger text-center width">
                  <h5 class="text-white py-2">TOTAL IMPAYÉES: {{ typeLoad?.impaye  | number }} {{global.device}}</h5>
                </span>
              </div>
            <div class="col-sm-4">
              <span class="badge badge-info text-center width">
                <h5 class="text-white py-2">ECART: {{ (typeLoad?.budgetValide - typeLoad?.paye) | number }} {{global.device}}</h5>
              </span>
            </div>
          </div>
        <div class="col-md-12">
            <span class="badge badge-info my-2 f-14 formBadge width"> LISTE DES FACTURES </span>
        </div>
        <app-card [hidHeader]="true" cardClass="card-datatable">
            <div class="table-responsive">
                <table class="table table-sm table-striped table-bordered nowrap table-hover">
                    <thead class="thead-light">
                        <tr>
                            <th>Libellé Facture</th>
                            <th>Type</th>
                            <th>Détails</th>
                            <th>Date de transmission</th>
                            <th>Montant TTC</th>
                            <th>Montant Payé</th>
                            <th>Montant Restant</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of typeInfo?.invoiceDetails">
                            <td>
                                <span>{{ item?.libelle }}</span>
                            </td>
                            <td>
                                <span>
                                    <span>Type: {{item?.type}}</span><br />
                                </span>
                                <p *ngIf="item?.provider" class="m-0 d-inline-block align-middle font-16">
                                    <span class="text-primary">Fournisseur:{{item?.provider?.nom}}</span><br />
                                    Type: {{item?.provider?.type }} <br />
                                </p>
                            </td>
                            <td>
                               <span>
                                    <span>Lot : {{item?.house?.type}} {{item?.house?.nom}}</span><br />
                                    <span *ngIf="item?.home">{{item?.home?.type}}: {{item?.home?.nom}}</span><br />
                                    <span *ngIf="item?.infrastructure">Infrastructure : {{item?.infrastructure?.nom}}</span><br />                                    
                                </span>                                
                            </td>
                            <td>{{ item.date|date: "d MMMM y hh:mm" : '' : 'fr-FR' }}</td>                          
                            <td class="align-middle">
                                <p class="m-0 d-inline-block align-middle font-16">
                                    <span class="text-primary font-weight-blod">{{ item?.montant|number }} {{
                                        global.device
                                        }}</span>
                                </p>
                            </td>
                            <td class="align-middle">
                                <p class="m-0 d-inline-block align-middle font-16">
                                    <span class="text-success font-weight-blod">{{ item?.montantPaye|number }} {{
                                        global.device
                                        }}</span>
                                </p>
                            </td>
                            <td class="align-middle">
                                <p class="m-0 d-inline-block align-middle font-16">
                                    <span class="text-danger font-weight-blod">{{ (item?.montant - item?.montantPaye)
                                        |number }} {{ global.device
                                        }}</span>
                                </p>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                    </tfoot>
                </table>
            </div>
        </app-card>
    </div>
</div>
<div class="modal-footer">
    <button (click)="modale.close('ferme')" type="button" class="btn btn-secondary text-left" data-dismiss="modal">
        Fermer <i class="feather icon-x-circle"></i>
    </button>
    <ng-template *ngxPermissionsOnly="'QUOTE:EDIT'">
        <button *ngIf="invoice?.etat === 'INVALIDE'" (click)="editQuote(invoice)" class="btn btn-primary">
            Modifier <i class="feather icon-edit-2"></i>
        </button>
    </ng-template>
    <button *ngxPermissionsOnly="'QUOTE:PRINTER:SHOW'" (click)="printerQuote(invoice)" class="btn btn-warning">
        Imprimer <i class="feather icon-printer"></i>
    </button>
</div>