<div class="col-md-12">
  <app-card [hidHeader]="true" cardClass="card-datatable">
    <div class="table-responsive">
      <table  datatable [dtOptions]="dtOptions"
        class="table table-sm table-striped table-bordered nowrap table-hover">
        <thead class="thead-light">
          <tr>
            <th>Libéllé</th>
            <th>Date de création</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of categories">
            <td class="align-middle">
              <p class="m-0 d-inline-block align-middle font-16">
                <span class="text-body mb-2">{{ item?.libelle }}</span><br />
              </p>
            </td>
            <td class="align-middle">{{ formatDate(item?.createdAt) }}</td>
            <td class="table-action">
              <button (click)="editCategory(item)" type="button" class="btn btn-icon btn-primary ml-1" ngbTooltip="Modifier">
                <i class="feather icon-edit-2"></i>
              </button>
              <button (click)="delete(item)" type="button" class="btn btn-icon btn-danger ml-1" ngbTooltip="Supprimer">
                <i class="feather icon-trash"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </app-card>
</div>
