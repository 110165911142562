
import { Payment } from '@model/payment';
import { Globals } from '@theme/utils/globals';
import { Component, OnInit } from '@angular/core';
import { CONFIRMATION, VALIDATION} from '@theme/utils/functions';
import { PaymentService } from '@service/payment/payment.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PaymentAddComponent } from '@locataire/payment/payment-add/payment-add.component';
import { UploaderService } from '@service/uploader/uploader.service';
import { environment } from '@env/environment';
import { InvoicePaymentService } from '@service/invoice-payment/invoice-payment.service';
import { TrusteeProviderPaymentAddComponent } from '../trustee-provider-payment-add/trustee-provider-payment-add.component';


@Component({
  selector: 'app-trustee-provider-payment-show',
  templateUrl: './trustee-provider-payment-show.component.html',
  styleUrls: ['./trustee-provider-payment-show.component.scss']
})
export class TrusteeProviderPaymentShowComponent implements OnInit {
  publicUrl = environment.publicUrl;
  title: string = ""
  payment: any
  confirmation = CONFIRMATION
  validation = VALIDATION
  global = {country: Globals.country, device: Globals.device}
  userSession = Globals.user;
  file: any;

  constructor(
    public modale: NgbActiveModal,
    private modalService: NgbModal,
    private paymentService: InvoicePaymentService,
    private uploader: UploaderService
  ) {
    this.payment = this.paymentService.getInvoicePayment()
    console.log(this.payment)
    this.title = "Détails du paiement N°" + this.payment?.code
  }

  ngOnInit(): void {
  }

  editPayment(row) {
    this.modalService.dismissAll()
    this.paymentService.setInvoicePayment(row)
    this.paymentService.edit = true
    this.paymentService.type = row.type
    this.modal(TrusteeProviderPaymentAddComponent, 'modal-basic-title', 'xl', true, 'static')
  }
  printerPayment(row): void {
    // this.paymentService.getPrinter('SHOW', this.userSession?.agencyKey, this.userSession?.uuid, row?.uuid);
  }
  modal(component, type, size, center, backdrop) {
    this.modalService.open(component, {
      ariaLabelledBy: type,
      size: size,
      centered: center,
      backdrop: backdrop
    }).result.then((result) => { }, (reason) => { });
  }
  showFile(item) {
    const fileByFolder = this.uploader.getDataFileByFolder();
    this.file = fileByFolder?.path ? this.publicUrl + '/' + fileByFolder?.path : null;
  }
  closeViewer() {
    this.file = '';
    this.uploader.setDataFileByFolder('');
  }
}
