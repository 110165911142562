<div class="modal-header">
  <h5 class="modal-title">{{title}}</h5>
  <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close" (click)="onClose()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form autocomplete="off" [formGroup]="form" (ngSubmit)="onConfirme()">
  <div class="modal-body">
    <div class="col-md-12">
      <!-- DETAILS SUR LE MANDAT -->
      <div class="row">
        <span class="badge badge-warning my-2 f-14 width">
          DETAILS SUR LE MANDAT
        </span>
      </div>
      <div class="form-group">
        <div class="row mb-2">
          <div class="col-md-4">
            <label for="type">Type de mandat <span class="asterix">*</span></label>
            <select formControlName="type" class="form-control" id="type"
              [ngClass]="{
              'is-invalid': submit && f.type.errors,
              'is-valid': submit && f.type.valid
              }">
              <option [value]="'SYNDIC'">SYNDIC</option>
            </select>
            <div class="invalid-feedback" *ngIf="submit && f.type.errors">
              <div *ngIf="f.type.errors.required">{{required.novide}}</div>
            </div>
          </div>
          <div class="col-md-4">
            <label for="honoraire">Honoraires <span class="asterix">*</span></label>
            <input formControlName="honoraire" id="honoraire" type="number" min="0"
              class="form-control" [ngClass]="{
              'is-invalid': submit && f.honoraire.errors,
              'is-valid': submit && f.honoraire.valid
              }" placeholder="Honoraires">
            <div class="invalid-feedback" *ngIf="submit && f.honoraire.errors">
              <div *ngIf="f.honoraire.errors.required">{{required.novide}}</div>
              <div *ngIf="f.honoraire.errors.pattern">{{required.positive}}</div>
            </div>
          </div>
        </div>
      </div>
      <!-- DETAILS SUR LE MANDAT -->
      <!-- DETAILS SUR LES TERMES DU MANDATS -->
      <div class="row">
        <span class="badge badge-warning my-2 f-14 width">
          DETAILS SUR LES TERMES DU MANDATS
        </span>
      </div>
      <div class="form-group">
        <div class="row mb-2">
          <div class="col-md-4">
            <label for="intermediaire">Intermediaire</label>
            <input type="text" formControlName="intermediaire" class="form-control" id="intermediaire"
              placeholder="Intermediaire" />
          </div>
          <div class="col-md-4">
            <label for="contactInter">Contact de l'intermediaire <span class="asterix">*</span></label>
            <input type="text" formControlName="contactInter" class="form-control" id="contactInter"
              placeholder="Contact de l'intermediaire" [ngClass]="{
                'is-invalid': submit && f.contactInter.errors,
                'is-valid': submit && f.contactInter.valid
              }" />
            <div class="invalid-feedback" *ngIf="submit && f.contactInter.errors">
              <div *ngIf="f.contactInter.errors.required">{{required.novide}}</div>
            </div>
          </div>
          <div class="col-md-4">
            <label for="date">Date de signature <span class="asterix">*</span></label>
            <input formControlName="date" id="date" type="date" class="form-control" [ngClass]="{
                'is-invalid': submit && f.date.errors,
                'is-valid': submit && f.date.valid
              }">
            <div class="invalid-feedback" *ngIf="submit && f.date.errors">
              <div *ngIf="f.date.errors.required">{{required.novide}}</div>
            </div>
          </div>
          <div class="col-md-4">
            <label for="dateD">Date de debut <span class="asterix">*</span></label>
            <input formControlName="dateD" id="dateD" type="date" class="form-control" [ngClass]="{
                'is-invalid': submit && f.dateD.errors,
                'is-valid': submit && f.dateD.valid
              }">
            <div class="invalid-feedback" *ngIf="submit && f.dateD.errors">
              <div *ngIf="f.dateD.errors.required">{{required.novide}}</div>
            </div>
          </div>
          <div class="col-md-4">
            <label for="dateF">Date de fin <span class="asterix">*</span></label>
            <input formControlName="dateF" id="dateF" type="date" class="form-control" [ngClass]="{
                'is-invalid': submit && f.dateF.errors,
                'is-valid': submit && f.dateF.valid
              }">
            <div class="invalid-feedback" *ngIf="submit && f.dateF.errors">
              <div *ngIf="f.dateF.errors.required">{{required.novide}}</div>
            </div>
          </div>
          <div class="col-md-4">
            <label for="isSigned">Signature numérique du mandat ? <span class="asterix">*</span></label>
            <select class="form-control" id="isSigned" formControlName="isSigned"
              [ngClass]="{
                'is-invalid': submit && f.isSigned.errors,
                'is-valid': submit && f.isSigned.valid
              }">
              <option *ngFor="let item of booleanRow" [ngValue]="item.value">{{item.label}}</option>
            </select>
            <div class="invalid-feedback" *ngIf="submit && f.isSigned.errors">
              <div *ngIf="f.isSigned.errors.required">{{required.novide}}</div>
            </div>
          </div>
        </div>
      </div>
      <!-- DETAILS SUR LES TERMES DU MANDATS -->
    </div>
  </div>
  <div class="modal-footer">
    <button (click)="onClose()" type="button" class="btn btn-secondary text-left" data-dismiss="modal">
      Fermer <i class="feather icon-x-circle"></i>
    </button>
    <button (click)="form.reset()" type="button" class="btn btn-warning">
      Vider <i class="fas fa-broom"></i>
    </button>
    <button [disabled]="form.invalid" type="submit" class="btn btn-primary">
      Enregistrer <i class="feather icon-save"></i>
    </button>
  </div>
</form>
