import {Contract} from '@model/contract';
import {ToastrService} from 'ngx-toastr';
import {Inventory} from '@model/inventory';
import { Globals } from '@theme/utils/globals';
import {Component, HostListener, OnInit} from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ValidatorsEnums} from '@theme/enums/validators.enums';
import {EmitterService} from '@service/emitter/emitter.service';
import {DateHelperService} from '@theme/utils/date-helper.service';
import {ContractService} from '@service/contract/contract.service';
import {InventoryService} from '@service/inventory/inventory.service';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import { UploaderService } from '@service/uploader/uploader.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';


@Component({
  selector: 'app-inventory-add',
  templateUrl: './inventory-add.component.html',
  styleUrls: ['./inventory-add.component.scss']
})
export class InventoryAddComponent implements OnInit {

  ESCAPE_KEYCODE = 27;
  title: string = '';
  type = '';
  form: FormGroup;
  submit = false;
  edit = false;
  inventory: Inventory;
  contract: Contract;
  tenant?: string;
  tenantSelected?: any;
  contracts: Array<Contract> = [];
  required = Globals.required;
  typeRow = [
    {label: 'ENTREE', value: 'ENTREE'},
    {label: 'SORTIE', value: 'SORTIE'}
  ];
  equipementRow = [
    {label: 'Portes', value: 'Portes'},
    {label: 'Fenêtres', value: 'Fenetres'},
    {label: 'Étanchéité (humidité, froid)', value: 'Etanchéité'},
    {label: 'Sol', value: 'Sol'},
    {label: 'Murs', value: 'Murs'},
    {label: 'Prises', value: 'Prises'},
    {label: 'Éclairage', value: 'Eclairage'},
    {label: 'Sanitaire', value: 'Sanitaire'},
    {label: 'Douche', value: 'Douche'},
    {label: 'WC', value: 'WC'},
    {label: 'Mirroire', value: 'Mirroire'},
    {label: 'Serviette', value: 'Serviette'},
    {label: 'Ballaie de nettoyage', value: 'Ballaie de nettoyage'},
    {label: 'Chauffe eau', value: 'Chauffe eau'}
  ];
  etatRow = [
    {label: 'Bon état', value: 'BON'},
    {label: 'Intermédiaire', value: 'INTERMEDIAIRE'},
    {label: 'Mauvais état', value: 'MAUVAIS'}
  ];

  constructor(
    public modal: NgbActiveModal,
    public toastr: ToastrService,
    private formBuild: FormBuilder,
    public emitter: EmitterService,
    public uploadService: UploaderService,
    public contractService: ContractService,
    private inventoryService: InventoryService,
    private permissionsService: NgxPermissionsService
  ) {
    const permission = JSON.parse(localStorage.getItem('permission-zen')) ? JSON.parse(localStorage.getItem('permission-zen')) : [];
    this.permissionsService.loadPermissions(permission);
    this.edit = this.inventoryService.edit;
    this.inventory = this.inventoryService.getInventory();
    this.title = (!this.edit) ? 'Ajouter un etat des lieux' : 'Modifier l\'etat des lieux ';
    this.newForm();
  }

  ngOnInit(): void {
    this.editForm();
  }

  newForm() {
    this.inventoryService.edit = false
    this.form = this.formBuild.group({
      id: [null],
      uuid: [null],
      contract: [null, [Validators.required]],
      date: [null, Validators.required],
      tenant: [null, Validators.required],
      type: [null, Validators.required],
      folderUuid: [null],
      observation: [null],
      folders: this.formBuild.array([]),
      pieces: this.formBuild.array([
        this.formBuild.group({
          uuid: [null],
          id: [null],
          libelle: [null, [Validators.required]],
          equipements: this.formBuild.array([
            this.formBuild.group({
              uuid: [null],
              id: [null],
              libelle: [null, [Validators.required]],
              etat: [null, [Validators.required]],
              description: [null]
            })
          ]),
        })
      ]),
    });
  }
  editForm() {
    if (this.edit) {
      const data = {...this.inventoryService.getInventory()};
      this.setCurrentTenant(data?.contract?.tenant);
      data.date = DateHelperService.fromJsonDate(data.date)
      data.contract = data?.contract?.uuid
      data.pieces.forEach((piece) => {
        var equipements = this.formBuild.array([]);
        piece.equipements.forEach((x) => {
          equipements.push(
            this.formBuild.group({
              uuid: [x?.uuid],
              id: [x?.id],
              libelle: [x?.libelle + ' '+ piece.uuid, [Validators.required]],
              etat: [x?.etat, [Validators.required]],
              description: [x?.description],
            })
          )
        })
        this.pieces.push(
          this.formBuild.group({
            uuid: [piece?.uuid],
            id: [piece?.id],
            libelle: [piece?.libelle, [Validators.required]],
            equipements: equipements
          })
        )
      });
      this.form.patchValue(data);
      this.f.folderUuid.setValue(data?.folder?.uuid)
      this.onDeletePiece(0);
    }
  }
  setTenantUuid(uuid) {
    this.tenant = uuid;
    this.f.tenant.setValue(uuid);
    if (!this.edit) { this.loadContracts(); }
  }
  loadContracts() {
    this.contracts = [];
    this.f.contract.setValue(null);
    if (this.f.tenant.value) {
      this.contractService.getList(this.f.tenant.value, 'ACTIF').subscribe((res) => {
        this.contracts = res;
      });
    }
  }
  setContratUuid(event) {
    if (event.target.value !== null) {
      this.contract = this.contracts.find(item => {
        if (item.uuid === event.target.value) {
          this.f.contract.setValue(item.uuid);
          return item;
        } else {
          this.contracts = [];
          this.f.contract.setValue(null);
        }
      });
    }
  }
  setCurrentTenant(tenant) {
    this.setTenantUuid(tenant?.uuid);
    this.tenantSelected = {
      photoSrc: tenant?.photoSrc,
      title: tenant?.searchableTitle,
      detail: tenant?.searchableDetail
    };
  }
  setFolderUuid(uuid) {
    this.form.get('folderUuid').setValue(uuid);
  }
  itemEquipement(count: number): FormGroup[] {
    const arr = [];
    for (let i = 0; i < count; i++) {
      arr.push(
        this.formBuild.group({
          id_: [null],
          id: [null],
          libelle: [null, [Validators.required]],
          etat: [null, [Validators.required]],
          description: [null],
          montant: [0, [Validators.required, Validators.pattern(ValidatorsEnums.number), Validators.min(0)]],
        })
      );
    }
    return arr;
  }
  onSubmit() {
    this.submit = true;
    if (!this.form.invalid) {
      const data = this.form.getRawValue();
      this.inventoryService.add(data).subscribe(res => {
        if (res?.status === 'success') {
          this.modal.close('ferme');
          if (data?.uuid) {
            this.emitter.emit({action: 'INVENTORY_UPDATED', payload: res?.data});
          } else {
            this.emitter.emit({action: 'INVENTORY_ADD', payload: res?.data});
          }
        }
      });
    } else { return; }
  }
  onConfirme() {
    Swal.fire({
      title: '',
      text: 'Confirmez-vous l\'enregistrement ?',
      icon: 'warning',
      showCancelButton: true,
      showCloseButton: true,
      confirmButtonText: 'Confirmer <i class="fas fa-check"></i>',
      cancelButtonText: 'Annuler <i class="feather icon-x-circle"></i>',
      confirmButtonColor: '#1bc943',
      reverseButtons: true
    }).then((willDelete) => {
      if (!willDelete.dismiss) {
        this.onSubmit();
      }
    });
  }
  onAddPiece() {
    return this.pieces.push(
      this.formBuild.group({
        uuid: [null],
        id: [null],
        libelle: [null, [Validators.required]],
        equipements: this.formBuild.array([
          this.formBuild.group({
            uuid: [null],
            id: [null],
            libelle: [null, [Validators.required]],
            etat: [null, [Validators.required]],
            description: [null]
          })
        ]),
      })
    );
  }
  onAddEquipement(piece, count: number) {
    for (let i = 0; i < count; i++) {
      // @ts-ignore
      this.pieces.at(piece).get('equipements').push(
        this.formBuild.group({
          uuid: [null],
          id: [null],
          libelle: [null, [Validators.required]],
          etat: [null, [Validators.required]],
          description: [null]
        })
      );
    }
  }
  onDeletePiece(row) {
    this.pieces.removeAt(row);
  }
  onDelete(room, equipment) {
    // @ts-ignore
    this.pieces.at(room).get('equipements').removeAt(equipment);
  }
  toast(msg, title, type) {
    if (type === 'info') {
      this.toastr.info(msg, title);
    } else if (type === 'success') {
      this.toastr.success(msg, title);
    } else if (type === 'warning') {
      this.toastr.warning(msg, title);
    } else if (type === 'error') {
      this.toastr.error(msg, title);
    }
  }
  files(data) {
    if(data && data !== null){
      data.forEach(item => {
        this.folder.push(
          this.formBuild.group({
            uuid: [item?.uuid, [Validators.required]],
            name: [item?.name],
            path: [item?.path]
          })
        );
      });
    }
  }
  upload(files): void {
    for (const file of files) {
      this.uploadService.upload(file);
    }
  }
  setParam(property, value): void {
    if (value) {
      if (this.form.value.hasOwnProperty(property)) {
        Object.assign(this.form.value, {[property]: value});
      }
      if (this.form.controls.hasOwnProperty(property)) {
        this.form.controls[property].setValue(value);
      }
    }
  }

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.keyCode === this.ESCAPE_KEYCODE) {
      this.onClose()
    }
  }

  onClose(){
    if (!this.edit && this.form.value.folderUuid) {
      var data = {uuid: this.form.value.folderUuid, path: 'etat_lieux'}
      this.uploadService.getDelete(data).subscribe((res: any) => {
        if (res) {
          if (res?.status === 'success') {
            this.form.reset()
            this.modal.close('ferme');
          }
        }
        return res
      });
    }else{
      this.form.reset()
      this.modal.close('ferme');
    }
  }
  onReset(){
    if (this.form.value.folderUuid) {
      this.toast('Impossible de de vider le formulaire lorsque un upload a été éffectué', 'Une erreur a été rencontrée', 'warning');
    }else{
      this.form.reset()
    }
  }
  get f() { return this.form.controls; }
  get pieces() { return this.form.get('pieces') as FormArray; }
  get folder() { return this.form.get('folders') as FormArray; }
}
