import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashDefaultComponent } from './dash-default/dash-default.component';
import { DashLocataireComponent } from './dash-locataire/dash-locataire.component';
import { DashLotissementComponent } from './dash-lotissement/dash-lotissement.component';
import { DashPromotionComponent } from './dash-promotion/dash-promotion.component';
import { DashProprietaireComponent } from './dash-proprietaire/dash-proprietaire.component';
import { DashSuiviComponent } from './dash-suivi/dash-suivi.component';
import { DashboardGuard } from '@dashboard/dashboard.guard';
import { DashSyndicComponent } from './dash-syndic/dash-syndic.component';
import { DashBudgetComponent } from './dash-budget/dash-budget.component';


const routes: Routes = [
  { path: "principal", component: DashDefaultComponent },
  { path: "syndic", component: DashSyndicComponent},
  { path: "locataire", component: DashLocataireComponent, canActivate: [DashboardGuard] },
  { path: "promotion", component: DashPromotionComponent, canActivate: [DashboardGuard] },
  { path: "lotissement", component: DashLotissementComponent, canActivate: [DashboardGuard] },
  { path: "proprietaire", component: DashProprietaireComponent, canActivate: [DashboardGuard] },
  { path: "budget", component: DashBudgetComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }
