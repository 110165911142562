<div class="modal-header">
    <h5 class="modal-title">{{ title }}</h5>
    <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
      (click)="modale.close('ferme')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="d-flex">
      <!-- COL GAUCHE -->
      <div class="flex-fill">
        <p>
          <span class="title-show">Référence  : </span>
          <span class="title-result-show">{{invoice?.code }}</span>
        </p>
        <p>
          <span class="title-show">Client : </span>
          <span class="title-result-show"> {{ invoice?.folder?.customer?.searchableTitle }} </span>
        </p>
        <p>
          <span class="title-show">Type : </span>
          <span class="title-result-show"> {{invoice?.type}} </span>
        </p>
        <p>
          <span class="title-show">Dossier : </span>
          <span class="title-result-show"> {{invoice?.folder?.libelle}} </span>
        </p>
        <p>
          <span class="title-show">Periode : </span>
          <span class="title-result-show">établie le {{invoice?.date | date:'dd/MM/yyyy'}}</span>
        </p>
      </div>
      <!-- COL DROITE -->
      <div class="flex-fill">
        <p>
          <span class="title-show">Etat: </span>
          <span class="title-result-show">
            <span class="badge" [ngClass]="{
              'badge-danger' : invoice?.etat === 'IMPAYE',
              'badge-primary' : invoice?.etat === 'ATTENTE',
              'badge-warning' : invoice?.etat === 'EN COURS',
              'badge-success' : invoice?.etat === 'SOLDE'
              }">{{payment(invoice?.etat)}}</span>
            <span class="ml-1 badge" [ngClass]="{
              'badge-danger' : invoice?.folder?.etat === 'RESILIE',
              'badge-warning' : invoice?.folder?.etat === 'INACTIF',
              'badge-primary' : invoice?.folder?.etat === 'ACTIF'
              }">{{invoice?.folder.statut}}</span>
          </span>
        </p>
        <p>
          <span class="title-show text-warning">Montant  : </span>
          <span class="title-result-show text-warning">{{ invoice?.montant|number }} {{global.device}}</span>
        </p>
        <p>
          <span class="title-show text-warning">Montant avance : </span>
          <span class="title-result-show text-warning">{{ invoice?.folder?.montantAvance|number }} {{global.device}}</span>
        </p>
        <p>
          <span class="title-show text-warning">Montant frais : </span>
          <span class="title-result-show text-warning">{{ invoice?.folder?.montantFrais|number }} {{global.device}}</span>
        </p>
      </div>
      <!-- COL DROITE -->
      <div class="flex-fill">
        <p>
          <span class="title-show text-warning">Payé : </span>
          <span class="title-result-show text-warning">{{ invoice?.paye|number }} {{global.device}}</span>
        </p>
        <p>
          <span class="title-show text-warning">Impayé : </span>
          <span class="title-result-show text-warning">{{ invoice?.impaye|number }} {{global.device}}</span>
        </p>
        <p>
          <span class="title-show">Crée le : </span>
          <span class="title-result-show">{{invoice?.createdAt|date: "d MMMM y 'à' h:mm:ss" : '' : 'fr-FR'}}</span>
          <span class="title-show ml-5"> Par : </span>
          <span class="title-result-show">{{ invoice?.create }}</span>
        </p>
        <p>
          <span class="title-show">Modifié le : </span>
          <span class="title-result-show">{{ invoice?.updatedAt|date: "d MMMM y 'à' h:mm:ss" : '' : 'fr-FR'}}</span>
          <span class="title-show ml-5"> Par : </span>
          <span class="title-result-show">{{invoice?.update }}</span>
        </p>
      </div>
    </div>
    <div *ngIf="invoice?.options && invoice?.options.length > 0" class="table-responsive">
      <table class="table table-sm table-striped table-bordered nowrap table-hover">
        <thead class="thead-light">
          <tr>
            <th>Désigantion</th>
            <th>Prix u.</th>
            <th>Qte</th>
            <th>Tva</th>
            <th>Remise</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of invoice?.options">
            <td><p class="m-0 d-inline-block align-middle font-16">{{item?.libelle}}</p></td>
            <td>
              <p class="m-0 d-inline-block align-middle font-30"><span class="font-weight-blod">{{item?.prix |number}} {{global.device}}</span></p>
            </td>
            <td>{{item?.qte}}</td>
            <td>{{item?.tva}} %</td>
            <td><p class="m-0 d-inline-block align-middle font-30"><span class="font-weight-blod">{{item?.remise |number }} {{global.device}}</span></td>
            <td><p class="m-0 d-inline-block align-middle font-30"><span class="font-weight-blod">{{item?.total |number}} {{global.device}}</span></td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <table class="table table-responsive invoice-table invoice-total">
          <tbody>
          <tr>
            <th>TOTAL HT : </th>
            <td>{{ invoice?.montantHt | number }} {{global.device}}</td>
          </tr>
          <tr>
            <th>TOTAL REMISE : </th>
            <td>{{ invoice?.montantRemise | number }} {{global.device}}</td>
          </tr>
          <tr>
            <th>TOTAL TVA : </th>
            <td>{{ invoice?.montantTva | number }} {{global.device}}</td>
          </tr>
          <tr>
            <th>TOTAL PAYE : </th>
            <td> {{ invoice?.paye | number }} {{global.device}}</td>
          </tr>
          <tr>
            <th>TOTAL IMPAYE : </th>
            <td  class="text-danger"> {{ invoice?.impaye | number }} {{global.device}}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

    <table class="width">
      <tbody>
        <tr class="border-bottom-success">
          <td></td>
          <td colspan="5" class="text-right font-weight-bold"></td>
        </tr>
      </tbody>
      <tfoot>
        <tr class="border border-success">
          <td colspan="5" class="font-weight-bold font-size-default">TOTAL</td>
          <td class="font-weight-bold font-size-default text-right">{{ invoice?.montant | number }} {{global.device}}</td>
        </tr>
      </tfoot>
    </table>
  </div>
  <div class="modal-footer">
    <button (click)="modale.close('ferme')" type="button" class="btn btn-secondary text-left" data-dismiss="modal">
      Fermer <i class="feather icon-x-circle"></i>
    </button>
  </div>
