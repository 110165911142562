import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tantieme-show',
  templateUrl: './tantieme-show.component.html',
  styleUrls: ['./tantieme-show.component.scss']
})
export class TantiemeShowComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
