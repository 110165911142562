<div class="col-md-12" *ngIf="type === 'PRODUIT'">
  <app-card [hidHeader]="true" cardClass="card-datatable">
    <div class="table-responsive">
      <table  *ngIf="etat" datatable [dtOptions]="dtOptions" class="table table-sm table-striped table-bordered nowrap table-hover">
        <thead class="thead-light">
          <tr>
            <th>Type</th>
            <th>Désignation</th>
            <th>prix d'achat HT</th>
            <th>Crée le</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of products">
            <td>
              <span class="text-uppercase cursor-pointer badge badge-primary" (click)="show(item)">
                  {{item?.type}}
              </span>
            </td>
            <td>
              <p class="m-0 d-inline-block align-middle font-16">
                <span class="text-warning">{{item?.libelle}}</span> <br />
                Famille : {{item?.subFamily?.family?.libelle}}<br />
                Sous-famille: {{item?.subFamily?.libelle}}<br />
                Etat : {{item?.etat}}
              </p>
            </td>
            <td>
              <span class="text-warning">{{ item.prix | number }} {{global.device}}</span>
            </td>
            <td>{{ item.createdAt| date: "d MMMM y 'à' h:mm:ss" : '' : 'fr-FR'}}</td>
            <td class="table-action">
              <button *ngxPermissionsOnly="'PRODUCT:SHOW'" (click)="showProduct(item)" type="button" class="btn btn-icon btn-secondary" ngbTooltip="Détails">
                <i class="fas fa-eye"></i>
              </button>
              <button *ngxPermissionsOnly="'PRODUCT:EDIT'" (click)="editProduct(item)" type="button" class="btn btn-icon btn-primary ml-1" ngbTooltip="Modifier">
                <i class="feather icon-edit-2"></i>
              </button>
              <button *ngxPermissionsOnly="'PRODUCT:PRINTER:SHOW'" (click)="printerProduct(item)" type="button" class="btn btn-icon btn-warning ml-1" ngbTooltip="Imprimer">
                <i class="feather icon-printer"></i>
              </button>
              <button *ngIf="isDelete" type="button" (click)="delete(item)" class="btn btn-icon btn-danger ml-1" ngbTooltip="Supprimer">
                <i class="feather icon-trash"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </app-card>
</div>
