import { Component, OnInit } from '@angular/core';
import { Day } from '@model/day';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DayService } from '@service/day/day.service';
import { Globals } from '@theme/utils/globals';

@Component({
  selector: 'app-day-show',
  templateUrl: './day-show.component.html',
  styleUrls: ['./day-show.component.scss']
})
export class DayShowComponent implements OnInit {
  title: string = ""
  soldeF: number = 0
  totalR: number = 0
  totalD: number = 0
  day: Day
  suppliesEntr = []
  suppliesSort = []
  paymentsEntr = []
  paymentsSort = []
  revers = []
  paymentsCustEntr = []
  paymentsCustSort = []
  paymentsF = []
  spents = []
  funds = []
  global = {country: Globals.country, device: Globals.device}
  userSession = Globals.user

  constructor(
    public modale: NgbActiveModal,
    private dayService: DayService
  ) {
    this.day = this.dayService.getDay()
    this.title = "Détails de la " + this.day?.libelle
  }

  ngOnInit(): void {
    this.soldeF = this.day?.soldeI
    this.totalR = this.day?.soldeI
    this.day.supplies.forEach((item) =>{
      if (item.emetteur.uuid === this.day.treasury.uuid) {
        this.suppliesSort.push({
          date: item?.createdAt,
          libelle: item?.libelle,
          montant: item?.montant,
          solde: item?.montant + this.soldeF,
        })
        this.totalD = this.totalD + item?.montant
        this.soldeF = this.soldeF - item?.montant
      }
      if (item.recepteur.uuid === this.day.treasury.uuid) {
        this.suppliesEntr.push({
          date: item?.createdAt,
          libelle: item?.libelle,
          montant: item?.montant,
          solde: item?.montant + this.soldeF,
        })
        this.totalR = this.totalR + item?.montant
        this.soldeF = item?.montant + this.soldeF
      }
    })

    this.day?.payments?.forEach((item) =>{
      if (item?.compte === "CREDIT") {
        this.paymentsEntr?.push({
          date: item?.createdAt,
          libelle: item?.invoice?.libelle,
          montant: item?.montant,
          solde: item?.montant + this.soldeF,
        })
        this.totalR = this.totalR + item?.montant
        this.soldeF = item?.montant + this.soldeF
      }
      if (item?.compte === "DEBIT") {
        this.paymentsSort?.push({
          date: this.day?.date,
          libelle: item?.invoice?.libelle,
          montant: item?.montant,
          solde: item?.montant + this.soldeF,
        })
        this.totalD = this.totalD + item?.montant
        this.soldeF = this.soldeF - item?.montant
      }
    })

    this.day?.paymentsCustomer?.forEach((item) =>{
      if (item?.compte === "CREDIT") {
        this.paymentsCustEntr?.push({
          date: item?.createdAt,
          libelle: item?.invoice?.libelle,
          montant: item?.montant,
          solde: item?.montant + this.soldeF,
        })
        this.totalR = this.totalR + item?.montant
        this.soldeF = item?.montant + this.soldeF
      }
      if (item?.compte === "DEBIT") {
        this.paymentsCustSort?.push({
          date: this.day?.date,
          libelle: item?.invoice?.libelle,
          montant: item?.montant,
          solde: item?.montant + this.soldeF,
        })
        this.totalD = this.totalD + item?.montant
        this.soldeF = this.soldeF - item?.montant
      }
    })

    this.day?.paymentsFunding?.forEach((item) =>{
      this.paymentsF?.push({
        date: item?.createdAt,
        libelle: item?.invoice?.libelle,
        montant: item?.montant,
        solde: this.soldeF - item?.montant,
      })
      this.totalD = this.totalD + item?.montant
      this.soldeF =  this.soldeF - item?.montant
    })

    this.day?.paymentRepayments?.forEach((item) =>{
      this.revers?.push({
        date: item?.createdAt,
        libelle: item?.invoice?.libelle,
        montant: item?.montant,
        solde: this.soldeF - item?.montant,
      })
      this.totalD = this.totalD + item?.montant
      this.soldeF =  this.soldeF - item?.montant
    })

    this.day?.fundRequests?.forEach((item) =>{
      if( item?.etat === 'DECAISSER'){
        this.funds?.push({
          date: item?.createdAt,
          libelle: item?.motif,
          montant: item?.montantD,
          solde: this.soldeF - item?.montantD ,
        })
        this.totalD = this.totalD + item?.montantD
        this.soldeF = this.soldeF - item?.montantD
      }
    })

    this.day?.spents?.forEach((item) =>{
      this.spents?.push({
        date: item?.createdAt,
        libelle: item?.motif,
        montant: item?.montant,
        solde: this.soldeF - item?.montant ,
      })
      this.totalD = this.totalD + item?.montant
      this.soldeF = this.soldeF - item?.montant
    })
  }
  printerDay(row): void {
    this.dayService.getPrinter('SHOW', this.userSession?.agencyKey, this.userSession?.uuid, row?.uuid);
  }

}
