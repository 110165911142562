<div class="modal-header">
  <h5 class="modal-title">{{title}}</h5>
  <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
    (click)="modale.close('ferme')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="col-md-12">
    <div class="d-flex">
      <!-- COL GAUCHE -->
      <div class="flex-fill">
        <p>
          <span class="title-show">Réference : </span>
          <span class="title-result-show">{{ production?.code }}</span>
        </p>
        <p>
          <span class="title-show">Intervention : </span>
          <span class="title-result-show">{{ production?.construction?.searchableTitle }}</span>
        </p>
        <p>
          <span class="title-show">Bien : </span>
          <span class="title-result-show">
            {{ production?.construction?.rental ? production?.construction?.rental?.libelle :
              production?.construction?.house?.searchableTitle }}
            </span>
        </p>
        <p>
          <span class="title-show">Proprietaire : </span>
          <span class="title-result-show">{{ production?.construction?.house?.owner?.searchableTitle}}</span>
        </p>
        <p>
          <span class="title-show">Evolution : </span>
          <span class="title-result-show ">
            <circle-progress
              [percent]="production?.construction?.pourcentage"
              [radius]="25"
              [toFixed]="1"
              [titleFontSize]="10"
              [outerStrokeWidth]="5"
              [innerStrokeWidth]="3"
              [outerStrokeColor]="production?.construction?.pourcentage >= 65 ? '#3FBD0D' : production?.construction?.pourcentage > 35 ? '#F0DD35' : '#EC0F0F'"
              [innerStrokeColor]="'#95cde4'"
              [animation]="true"
              [showSubtitle]="false"
              [animationDuration]="300">
            </circle-progress>
          </span>
        </p>
      </div>
      <!-- COL DROITE -->
      <div class="flex-fill">
        <p>
          <span class="title-show">Budget : </span>
          <span class="title-result-show text-warning">{{ production.construction.budget }} {{global.device}}</span>
        </p>
        <p>
          <span class="title-show">Etat Intervention : </span>
          <span class="title-result-show">
            <span class="badge"
              [ngClass]="{
              'badge-danger' : production?.construction?.etat === 'STOPPER',
              'badge-primary' : production?.construction?.etat === 'EN COURS',
              'badge-warning' : production?.construction?.etat === 'PREVU',
              'badge-success' : production?.construction?.etat === 'TERMINER'
              }"> {{ production?.construction?.etat === 'EN COURS'  ? 'EN COURS DE REALISATION' : production?.construction?.etat }}
            </span>
          </span>
        </p>
        <p>
          <span class="title-show">Etat : </span>
          <span class="title-result-show">
            <span class="badge" [ngClass]="{
              'badge-danger' : production?.etat === 'INVALIDE',
              'badge-success' : production?.etat === 'VALIDE'
              }">{{ validation(production?.etat) }}
            </span>
          </span>
        </p>
        <p>
          <span class="title-show">Crée le : </span>
          <span class="title-result-show">{{ production?.createdAt }}</span>
          <span class="title-show ml-5"> Par : </span>
          <span class="title-result-show">{{ production?.create }}</span>
        </p>
        <p>
          <span class="title-show">Modifié le : </span>
          <span class="title-result-show">{{ production?.updatedAt }}</span>
          <span class="title-show ml-5"> Par : </span>
          <span class="title-result-show">{{ production?.update }}</span>
        </p>
      </div>
    </div>
  </div>

  <div class="table-responsive">
    <table class="table table-sm table-striped table-bordered nowrap table-hover">
      <thead class="thead-light">
        <tr>
          <th>Dévis</th>
          <th>Evolution</th>
          <th>Montant</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of production?.quotes" >
          <td><p class="m-0 d-inline-block align-middle font-16">{{ item?.libelle }}</p></td>
          <td>
            <p class="m-0 d-inline-block align-middle font-30"><span class="font-weight-blod">{{ item?.evolution ? 'TERMINER' : 'EN COURS'}}</span></p>
          </td>
          <td><p class="m-0 d-inline-block align-middle font-30"><span class="font-weight-blod">{{item?.montant|number}} {{global.device}}</span></td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="modal-footer">
  <button (click)="modale.close('ferme')" type="button" class="btn btn-secondary text-left" data-dismiss="modal">
    Fermer <i class="feather icon-x-circle"></i>
  </button>
  <ng-template *ngxPermissionsOnly="'PRODUCTION:EDIT'">
    <button *ngIf="production?.etat === 'INVALIDE'" (click)="editProduction(production)" class="btn btn-primary">
      Modifier <i class="feather icon-edit-2"></i>
    </button>
  </ng-template>
  <button *ngxPermissionsOnly="'PRODUCTION:PRINTER:SHOW'" (click)="printerProduction(production)" class="btn btn-warning">
    Imprimer <i class="feather icon-printer"></i>
  </button>
</div>
