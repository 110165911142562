import * as Highcharts from 'highcharts';
import { Router } from '@angular/router';
import { Globals } from '@theme/utils/globals';
import { environment } from '@env/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import HC_drilldown from 'highcharts/modules/drilldown';
import { RentService } from '@service/rent/rent.service';
import {Component, OnDestroy, OnInit} from '@angular/core';
import { OwnerService } from '@service/owner/owner.service';
import {FilterService} from '@service/filter/filter.service';
import { TenantService } from '@service/tenant/tenant.service';
import { RentShowComponent } from '@locataire/rent/rent-show/rent-show.component';
import {ApexChartService} from "../../theme/shared/components/chart/apex-chart/apex-chart.service";
import { DateHelperService } from '@theme/utils/date-helper.service';
import { SyndicService } from '@service/syndic/syndic.service';
import * as moment from 'moment';
import { BudgetService } from '@service/budget/budget.service';
import { TantiemeService } from '@service/syndic/tantieme.service';
import { InvoiceCoService } from '@service/invoice-co/invoice-co.service';
import  { RapportSyndicService } from '@service/syndic/rapport-syndic.service'


HC_drilldown(Highcharts);
@Component({
  selector: 'app-dash-syndic',
  templateUrl: './dash-syndic.component.html',
  styleUrls: ['./dash-syndic.component.scss']
})
export class DashSyndicComponent implements OnInit {
  public bandeC: any;
  public bandeM: any;

  //variable du graph
  public Highcharts = Highcharts;
  public barBasicChartOptions: any;
  agency = Globals.user.agencyKey;
  public lastDate: number;
  public data: any;
  rapport: boolean = false;
  public intervalSub: any;
  public intervalMain: any;
  type: string = 'SYNDIC';
  typeRow = [    
    {label: 'SYNDIC', value: 'SYNDIC'}
  ];
  categorieRow =  []
  trusteeRow = []

  currentYearValue:number = new Date().getFullYear();

  event = {
    categorie: null,
    code: null,
    count: 10,
    create: null,
    annee: this.currentYearValue,
    trustee: null,
    dateD: null,
    dateF: null,
    etat: null,
    max: null,
    min: null,
    name: null,
    ordre: "ASC",
    type: "SYNDIC",
    uuid: null
  }
  widget : any
  listing : any
  global = {country: Globals.country, device: Globals.device}
  dtOptions: any = {};
  etat: boolean = false
  name: boolean = false
  dateF: boolean = false
  dateD: boolean = false
  nameTitle: string = 'Budget';
  publicUrl = environment.publicUrl;
  totalTenant = 0;
  totalRentS = 0;
  totalRentA = 0;
  total = [];
  totalI = [];
  totalP = [];
  mois = [];
  rapportRow = [
    { label: 'DETAIL SUR LE SYNDIC', value: 'SYNDIC' },
    { label: 'COMPARATIF DU BUDGET PAR RAPPORT AUX CHARGES REELLES', value: 'CBRCR' },
    { label: 'COMPARATIF DES CHARGES REELLES D\'UN EXERCICE A L\'AUTRE', value: 'CCREA' },
    { label: 'APPELS DE CHARGES EMIS & ENCAISSES', value: 'AF_EMIS' },
    { label: 'CHARGES REELLES REPARTIES PAR NATURE AUX COPROPRIETAIRES', value: 'CRRNC' },
    // { label: 'PRODUITS & CHARGES EXEPTIONNELLES', value: 'PRODUIT_CHARGE_EXEP' },
    { label: 'SITUATION DES COPROPRIETAIRES', value: 'SITUATION_COPROPRIETAIRE' },
    { label: 'SITUATION DES COPROPRIETAIRES RESERVE FINANCIERE', value: 'SITUATION_CO_RESERVE_FINAN' },
    // { label: 'FONDS DE RESERVE FINANCIERE EN COMPTE', value: 'FOND_RESERVE_FINANIERE' },
    { label: 'CHARGES', value: 'CHARGES' },
    { label: 'DETAIL DU POSTE TRAVAUX IMMEUBLE ET DIVERS', value: 'DETAIL' },
  ]

  syndic: any
  syndicUuid: any
  selectYear: any
  lat = Globals.lat;
  lng = Globals.lng;
  zoom = Globals.zoom;
  map?: any;

  syndic_owners: any[] = []
  syndic_type_tantiemes: any[] = []
  syndic_data: any[] = []

  cbrcr_annee: any
  cbrcr_data: any[] = []
  cbrcr_totalBudget = 0
  cbrcr_totalCR = 0
  cbrcr_totalEcart = 0

  ccrea_annees = []
  ccrea_data: any[] = []
  ccrea_data_n: any[] = []
  ccrea_data_n_1: any[] = []
  ccrea_totalCR_n = 0
  ccrea_totalCR_n_1 = 0
  ccrea_totalEcart = 0

  af_data: any[] = []
  af_totalE = 0
  af_totalEnc = 0
  af_totalE_t1 = 0
  af_totalEnc_t1 = 0
  af_totalE_t2 = 0
  af_totalEnc_t2 = 0
  af_totalE_t3 = 0
  af_totalEnc_t3 = 0
  af_totalE_t4 = 0
  af_totalEnc_t4 = 0


  stats_data: any[]
  periode: any

  crrnc_owners: any[] = []
  crrnc_data: any[] = []
  crrnc_total_tantiemes = 0
  crrnc_totalChargeR = 0

  sc_data: any[] = []
  sc_totalFundsapeals = 0
  sc_totalChargesR = 0
  sc_totalPayments = 0
  sc_totalChargesI = 0
  sc_totalSolde = 0
  sc_date = new Date()

  scrf_totalFundsapeals_n = 0
  scrf_totalFundsapeals_n_1 = 0
  scrf_totalPayments_n = 0
  scrf_totalPayments_n_1 = 0
  scrf_totalSolde = 0
  scrf_data:  any[] = []
  scrf_date_n: any
  scrf_date_n_1: any

  detail_annee: any
  detail_data: any[] = []
  detail_total_janvier = 0
  detail_total_fevrier = 0
  detail_total_mars = 0
  detail_total_avril = 0
  detail_total_mai = 0
  detail_total_juin = 0
  detail_total_juillet = 0
  detail_total_aout = 0
  detail_total_septembre = 0
  detail_total_octobre = 0
  detail_total_novembre = 0
  detail_total_decembre = 0
  detail_total = 0

  charges_data: any[] = []
  charges_annee: any
  charges_total_janvier = 0
  charges_total_fevrier = 0
  charges_total_mars = 0
  charges_total_avril = 0
  charges_total_mai = 0
  charges_total_juin = 0
  charges_total_juillet = 0
  charges_total_aout = 0
  charges_total_septembre = 0
  charges_total_octobre = 0
  charges_total_novembre = 0
  charges_total_decembre = 0
  charges_total = 0

  constructor(
      public apexEvent: ApexChartService,
      private modalService: NgbModal,
      private filterService: FilterService,
      private tenantService: TenantService,
      private rentService: RentService,
      private ownerService: OwnerService,
      private syndicService: SyndicService,

      private budgetService: BudgetService,
      private tantiemeService: TantiemeService,
      private invoiceCoService: InvoiceCoService,
      private rapportSyndicService: RapportSyndicService
    ) {
      this.rapport = true
      this.getfristInfo()
      // this.onApercu(this.event)
  }

  ngOnInit(): void {
    this.dtOptions = Globals.dataTable;
  }

  onFilter($event) {
    this.filterService.dashboard($event, 'default', null).subscribe(
      res => {
        this.rapport = true
        $event.uuid = $event.trustee
        this.selectYear = $event.annee
        this.etat = res ? true : false
        this.widget = res?.widget
        this.total = []
        this.totalP = []
        this.totalI = []
        this.mois = []
        this.listing = res?.listing
        res?.depense.forEach(el => {
          this.total.push(el?.nbr?.total)
          this.totalP.push(el?.nbr?.paye)
          this.totalI.push(el?.nbr?.impaye)
          this.mois.push(el?.date)
        });
        this.getGraph();
        this.listing?.tenant.forEach(el => { this.totalTenant = this.totalTenant + el.impaye});
        this.listing?.rentS.forEach(el => { this.totalRentS = this.totalRentS + el.montant});
        this.listing?.rentA.forEach(el => { this.totalRentA = this.totalRentA + el.montant});
    }, err => { })
  }

  onChangeLoad($event) {
    this.type = $event
    this.onFilter($event)
  }
  
  getGraph() {
    this.barBasicChartOptions = {
      chart: {
        type: 'column'
      },
      colors: ['blue', 'green', 'red'],
      title: {
        text: 'STATISTIQUES DES DEPENSES MENSUELLES'
      },
      xAxis: {
        categories: (this.mois),
        crosshair: true
      },
      yAxis: {
        title: {
          text: this.global.device
        }
      },
      credits: {
        enabled: false
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.1f} </b></td></tr>' ,
        footerFormat: '</table>',
        shared: true,
        useHTML: true
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0
        }
      },
      series: [{
        color: 'blue',
        name: 'Total',
        data: this.total

      }, {
        color: 'green',
        name: 'Payé',
        data: this.totalP

      }, {
        color: 'red',
        name: 'Impayée',
        data: this.totalI
      },]
    };
  }
  ngOnDestroy() {
    if (this.intervalSub) {
      clearInterval(this.intervalSub);
    }
    if (this.intervalMain) {
      clearInterval(this.intervalMain);
    }
  }
  getDayWiseTimeSeries(baseval, count, yrange) {
    let i = 0;
    while (i < count) {
      const x = baseval;
      const y = Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;

      this.data.push({x, y});
      this.lastDate = baseval;
      baseval += 86400000;
      i++;
    }
  }
  resetData() {
    this.data = this.data.slice(this.data.length - 10, this.data.length);
  }
  getNewSeries(baseval, yrange) {
    const newDate = baseval + 86400000;
    this.lastDate = newDate;
    this.data.push({
      x: newDate,
      y: Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min
    });
  }
  modal(component, type, size, center, backdrop): void {
    this.modalService.open(component, {
      ariaLabelledBy: type,
      size: size,
      centered: center,
      backdrop: backdrop
    }).result.then((result) => { }, (reason) => { });
  }
  date(date){
    var format = 'MMMM Do, YYYY [at] h:mm:ss A';
    DateHelperService.formatDatefromServer(date, format)
  }

  onApercu($event) {
    console.log('event',$event)
    $event.annee = parseInt($event.annee);
    $event.uuid =  $event.trustee
    this.type = $event.rapport ? $event.rapport : 'SYNDIC'

    
   
    if(this.type === 'SYNDIC'){
      this.getInfo($event)     
    }else if(this.type === 'DETAIL'){
      this.detail_annee = $event.annee
      let title = 'DIAGRAMME SUR LES TOTAUX POSTE TRAVAUX IMMEUBLE ET DIVERS'
      let mois = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre']
      let total = []
      let series = [
        {
          color: 'blue',
          name: 'Total',
          data: total

        },
      ]
      this.graphData(title, mois, series)
      this.invoiceCoService.getList(null, null, $event.trustee, 'VALIDE').subscribe((res) => {        
        if (res) {
          let data = []
          let invoices = res
          invoices.forEach((invoice) => {
            if (invoice.ligneBudgetaire.libelle === "Travaux immeuble et divers aléas") {
              let date = moment(invoice.date)
              let month = (date.month() + 1)
              let year = date.year()
              if (year === $event.annee) {
                const newObject = {
                  prestation: invoice?.provider?.nom + "(" + invoice?.libelle + ")",
                  janvier: (month === 1) ? invoice?.montant : '',
                  fevrier: (month === 2) ? invoice?.montant : '',
                  mars: (month === 3) ? invoice?.montant : '',
                  avril: (month === 4) ? invoice?.montant : '',
                  mai: (month === 5) ? invoice?.montant : '',
                  juin: (month === 6) ? invoice?.montant : '',
                  juillet: (month === 7) ? invoice?.montant : '',
                  aout: (month === 8) ? invoice?.montant : '',
                  septembre: (month === 9) ? invoice?.montant : '',
                  octobre: (month === 10) ? invoice?.montant : '',
                  novembre: (month === 11) ? invoice?.montant : '',
                  decembre: (month === 12) ? invoice?.montant : '',
                  total: 0,
                };

                let total = 0;

                // Iterate over the months and sum the values
                for (let i = 1; i <= 12; i++) {
                  if (month === i) {
                    total += (typeof newObject[this.getMonthName(i)]) === 'number' ? newObject[this.getMonthName(i)] : 0;
                  }
                }

                newObject.total = total; // Assign the calculated total to the 'total' attribute

                data.push(newObject);
              }
            }
          })

          this.detail_total_janvier = 0
          this.detail_total_fevrier = 0
          this.detail_total_mars = 0
          this.detail_total_avril = 0
          this.detail_total_mai = 0
          this.detail_total_juin = 0
          this.detail_total_juillet = 0
          this.detail_total_aout = 0
          this.detail_total_septembre = 0
          this.detail_total_octobre = 0
          this.detail_total_novembre = 0
          this.detail_total_decembre = 0
          data.forEach((item) => {
            this.detail_total_janvier += item?.janvier
            this.detail_total_fevrier += item?.fevrier
            this.detail_total_mars += item?.mars
            this.detail_total_avril += item?.avril
            this.detail_total_mai += item?.mai
            this.detail_total_juin += item?.juin
            this.detail_total_juillet += item?.juillet
            this.detail_total_aout += item?.aout
            this.detail_total_septembre += item?.septembre
            this.detail_total_octobre += item?.octobre
            this.detail_total_novembre += item?.novembre
            this.detail_total_decembre += item?.decembre
          })

          total = [
            this.detail_total_janvier,
            this.detail_total_fevrier,
            this.detail_total_mars,
            this.detail_total_avril,
            this.detail_total_mai,
            this.detail_total_juin,
            this.detail_total_juillet,
            this.detail_total_aout,
            this.detail_total_septembre,
            this.detail_total_octobre,
            this.detail_total_novembre,
            this.detail_total_decembre
          ]
          this.detail_total = 0
          total.forEach(item => {
            this.detail_total += parseInt(item)
          })

          let series = [
            {
              color: 'blue',
              name: 'Total',
              data: total

            },
          ]
          this.graphData(title, mois, series)

          this.detail_data = data
        }
      })

    }else if(this.type === 'CHARGES'){
      this.charges_annee = $event.annee
      this.charges_data = []
      this.charges_total_janvier = 0
      this.charges_total_fevrier = 0
      this.charges_total_mars = 0
      this.charges_total_avril = 0
      this.charges_total_mai = 0
      this.charges_total_juin = 0
      this.charges_total_juillet = 0
      this.charges_total_aout = 0
      this.charges_total_septembre = 0
      this.charges_total_octobre = 0
      this.charges_total_novembre = 0
      this.charges_total_decembre = 0
      let title = 'DIAGRAMME SUR LES CHARGES'
      let mois = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre']
      let total = []
      let series = [
        {
          color: 'blue',
          name: 'Total',
          data: total

        },
      ]
      this.graphData(title, mois, series)
      this.budgetService.getList(null, null, $event.trustee).subscribe((res) => {
        if (res) {
          let budget
          res.forEach((item) => {
            if (item.anneeEx === $event.annee) {
              budget = item
            }
          })

          if (budget) {
            let data = []
            budget.optionBudgets.forEach((option) => {
              let invoices = option.invoiceCos
              let spents = option.spents
              let total_janvier = 0
              let total_fevrier = 0
              let total_mars = 0
              let total_avril = 0
              let total_mai = 0
              let total_juin = 0
              let total_juillet = 0
              let total_aout = 0
              let total_septembre = 0
              let total_octobre = 0
              let total_novembre = 0
              let total_decembre = 0
              invoices.forEach((invoice) => {
                let date = moment(invoice.date)
                let month = (date.month() + 1)
                let year = date.year()
                if (year === $event.annee) {
                  total_janvier += (month === 1) ? invoice.montant : 0
                  total_fevrier += (month === 2) ? invoice.montant : 0
                  total_mars += (month === 3) ? invoice.montant : 0
                  total_avril += (month === 4) ? invoice.montant : 0
                  total_mai += (month === 5) ? invoice.montant : 0
                  total_juin += (month === 6) ? invoice.montant : 0
                  total_juillet += (month === 7) ? invoice.montant : 0
                  total_aout += (month === 8) ? invoice.montant : 0
                  total_septembre += (month === 9) ? invoice.montant : 0
                  total_octobre += (month === 10) ? invoice.montant : 0
                  total_novembre += (month === 11) ? invoice.montant : 0
                  total_decembre += (month === 12) ? invoice.montant : 0
                }
              })
              spents.forEach((spent) => {
                let date = moment(spent.date)
                let month = (date.month() + 1)
                let year = date.year()
                if (year === $event.annee) {
                  total_janvier += (month === 1) ? spent.montant : 0
                  total_fevrier += (month === 2) ? spent.montant : 0
                  total_mars += (month === 3) ? spent.montant : 0
                  total_avril += (month === 4) ? spent.montant : 0
                  total_mai += (month === 5) ? spent.montant : 0
                  total_juin += (month === 6) ? spent.montant : 0
                  total_juillet += (month === 7) ? spent.montant : 0
                  total_aout += (month === 8) ? spent.montant : 0
                  total_septembre += (month === 9) ? spent.montant : 0
                  total_octobre += (month === 10) ? spent.montant : 0
                  total_novembre += (month === 11) ? spent.montant : 0
                  total_decembre += (month === 12) ? spent.montant : 0
                }
              })
              const newObj = {
                prestation:  option?.typeLoad?.libelle,
                janvier: total_janvier,
                fevrier: total_fevrier,
                mars: total_mars,
                avril: total_avril,
                mai: total_mai,
                juin: total_juin,
                juillet: total_juillet,
                aout: total_aout,
                septembre: total_septembre,
                octobre: total_octobre,
                novembre: total_novembre,
                decembre: total_decembre,
                total: 0
              }

              let total = 0;

              // Iterate over the months and sum the values
              for (let i = 1; i <= 12; i++) {
                total += (typeof newObj[this.getMonthName(i)]) === 'number' ? newObj[this.getMonthName(i)] : 0;
              }

              newObj.total = total; // Assign the calculated total to the 'total' attribute

              data.push(newObj);
            })

            this.charges_total_janvier = 0
            this.charges_total_fevrier = 0
            this.charges_total_mars = 0
            this.charges_total_avril = 0
            this.charges_total_mai = 0
            this.charges_total_juin = 0
            this.charges_total_juillet = 0
            this.charges_total_aout = 0
            this.charges_total_septembre = 0
            this.charges_total_octobre = 0
            this.charges_total_novembre = 0
            this.charges_total_decembre = 0
            data.forEach((item) => {
              this.charges_total_janvier += item?.janvier
              this.charges_total_fevrier += item?.fevrier
              this.charges_total_mars += item?.mars
              this.charges_total_avril += item?.avril
              this.charges_total_mai += item?.mai
              this.charges_total_juin += item?.juin
              this.charges_total_juillet += item?.juillet
              this.charges_total_aout += item?.aout
              this.charges_total_septembre += item?.septembre
              this.charges_total_octobre += item?.octobre
              this.charges_total_novembre += item?.novembre
              this.charges_total_decembre += item?.decembre
            })

            total = [
              this.charges_total_janvier,
              this.charges_total_fevrier,
              this.charges_total_mars,
              this.charges_total_avril,
              this.charges_total_mai,
              this.charges_total_juin,
              this.charges_total_juillet,
              this.charges_total_aout,
              this.charges_total_septembre,
              this.charges_total_octobre,
              this.charges_total_novembre,
              this.charges_total_decembre
            ]
            this.charges_total = 0
            total.forEach(item => {
              this.charges_total += parseInt(item)
            })

            let series = [
              {
                color: 'blue',
                name: 'Total',
                data: total

              },
            ]
            this.graphData(title, mois, series)
            this.charges_data = data
          }
        }
      })
    }else if(this.type === 'AF_EMIS'){
      this.getStats($event.annee, $event.uuid);
      let title = 'DIAGRAMME APPELS DE CHARGE EMIS & ENCAISSES';
      let categories = ['1er Trismestre', '2e Trismestre', '3e Trismestre', '4e Trismestre'];
      let series = [
        {
          color: 'blue',
          name: 'Montant émis',
          data: [0, 0, 0, 0]
        },
        {
          color: 'green',
          name: 'Montant encaissés',
          data: [0, 0, 0, 0]
        }
      ];
      this.graphData(title, categories, series);

      //console.log($event.annee)
      /* this.ownerService.getList($event.trustee).subscribe((res) => {x
        console.log('je suis la',res)
        let data = []
        res.forEach((owner: any) => {
          let fundsapeals = owner.fundsApeals
          if (fundsapeals && fundsapeals.length > 0) {
            let totalE = 0
            let totalEnc = 0
            let totalE_t1 = 0
            let totalE_t2 = 0
            let totalE_t3 = 0
            let totalE_t4 = 0
            let totalEnc_t1 = 0
            let totalEnc_t2 = 0
            let totalEnc_t3 = 0
            let totalEnc_t4 = 0
            fundsapeals.forEach((item: any) => {
              if (item.annee === $event.annee) {
                totalE += item.montant
                let dateE =  moment(item.createdAt)
                let monthE = dateE.month()
                //console.log('item', item)
                //console.log('monthE', monthE)

                if (monthE >= 0 && monthE <= 2) {
                  totalE_t1 += item.montant
                } else if (monthE >= 3 && monthE <= 5) {
                  totalE_t2 += item.montant
                } else if (monthE >= 6 && monthE <= 8) {
                  totalE_t3 += item.montant
                } else if (monthE >= 9 && monthE <= 11) {
                  totalE_t4 += item.montant
                }

                if (item.etat === "PAYER") {
                  totalEnc += item.montant

                  let payments = item.fundsPayments
                  payments.forEach((payment) => {
                    let dateEnc =  moment(payment.createdAt)
                    let monthEnc = dateEnc.month()

                    if (monthEnc >= 0 && monthEnc <= 2) {
                      totalEnc_t1 += payment.montant
                    } else if (monthEnc >= 3 && monthEnc <= 5) {
                      totalEnc_t2 += payment.montant
                    } else if (monthEnc >= 6 && monthEnc <= 8) {
                      totalEnc_t3 += payment.montant
                    } else if (monthEnc >= 9 && monthEnc <= 11) {
                      totalEnc_t4 += payment.montant
                    }
                  })
                }
              }
            })

            data.push({
              owner: owner.nom,
              totalE: totalE,
              totalEnc: totalEnc,
              totalE_t1: totalE_t1,
              totalE_t2: totalE_t2,
              totalE_t3: totalE_t3,
              totalE_t4: totalE_t4,
              totalEnc_t1: totalEnc_t1,
              totalEnc_t2: totalEnc_t2,
              totalEnc_t3: totalEnc_t3,
              totalEnc_t4: totalEnc_t4
            })
          }
        })

        this.af_data = data
        this.af_totalE = 0
        this.af_totalEnc = 0
        this.af_totalE_t1 = 0
        this.af_totalEnc_t1 = 0
        this.af_totalE_t2 = 0
        this.af_totalEnc_t2 = 0
        this.af_totalE_t3 = 0
        this.af_totalEnc_t3 = 0
        this.af_totalE_t4 = 0
        this.af_totalEnc_t4 = 0
        data.forEach((item: any) => {
          this.af_totalE += item.totalE
          this.af_totalEnc += item.totalEnc
          this.af_totalE_t1 += item.totalE_t1
          this.af_totalEnc_t1 += item.totalEnc_t1
          this.af_totalE_t2 += item.totalE_t2
          this.af_totalEnc_t2 += item.totalEnc_t2
          this.af_totalE_t3 += item.totalE_t3
          this.af_totalEnc_t3 += item.totalEnc_t3
          this.af_totalE_t4 += item.totalE_t4
          this.af_totalEnc_t4 += item.totalEnc_t4
        })
        //console.log(this.af_totalE_t1, this.af_totalE_t2, this.af_totalE_t3, this.af_totalE_t4)
        //console.log(this.af_totalEnc_t1, this.af_totalEnc_t2, this.af_totalEnc_t3, this.af_totalEnc_t4)
        let series = [
          {
            color: 'blue',
            name: 'Montant émis',
            data: [this.af_totalE_t1, this.af_totalE_t2, this.af_totalE_t3, this.af_totalE_t4]
          },
          {
            color: 'green',
            name: 'Montant encaissés',
            data: [this.af_totalEnc_t1, this.af_totalEnc_t2, this.af_totalEnc_t3, this.af_totalEnc_t4]
          }
        ];
        this.graphData(title, categories, series);
      }) */
    }else if(this.type === 'CBRCR'){
      //console.log($event.annee)
      this.cbrcr_annee = $event.annee
      this.cbrcr_data = []
      this.cbrcr_totalBudget = 0
      this.cbrcr_totalCR = 0
      this.cbrcr_totalEcart = 0
      this.budgetService.getList(null, null, $event.trustee).subscribe((res) => {
        //console.log(res)
        if (res) {
          let budget
          res.forEach((item) => {
            if (item.anneeEx === $event.annee) {
              budget = item
            }
          })

          if (budget) {
            let data = []
            this.cbrcr_totalBudget = 0
            this.cbrcr_totalCR = 0
            this.cbrcr_totalEcart = 0
            //console.log(budget.optionBudgets)
            budget.optionBudgets.forEach((option) => {
              this.cbrcr_totalBudget += option.montantV
              let spents = option.spents
              //console.log(spents)
              let chargesR = 0
              if (spents && spents.length > 0) {
                spents.forEach((spent) => {
                  let date = moment(spent.date)
                  let year = date.year()
                  if (year === $event.annee) {
                    chargesR += spent.montant
                  }
                })
              }
              this.cbrcr_totalCR += chargesR
              data.push({
                montantP: option.montantP,
                montantV: option.montantV,
                chargesR: chargesR,
                typeLoad: {
                  // charges: option.typeLoad.charges,
                  code: option.typeLoad.code,
                  invoiceCos: option.invoiceCos,
                  libelle: option.typeLoad.libelle,
                  loadCategoryLibelle: option.typeLoad.loadCategory.libelle,
                  loadCategoryCode: option.typeLoad.loadCategory.code,
                  // quotes: option.quotes,
                  spents: option.spents,
                  // totalInvoice: option.typeLoad.totalInvoice,
                }
              })
            })
            //console.log(data)
            this.cbrcr_data = this.groupBy(data, 'loadCategoryLibelle', 'typeLoad')
            this.cbrcr_totalEcart = this.cbrcr_totalBudget - this.cbrcr_totalCR
          }
        }
      })
    }else if(this.type === 'CCREA'){
      //console.log($event.annee)
      this.ccrea_annees = []
      this.ccrea_annees.push(($event.annee - 1))
      this.ccrea_annees.push(($event.annee))
      this.ccrea_data = []
      this.ccrea_totalCR_n = 0
      this.ccrea_totalCR_n_1 = 0
      this.ccrea_totalEcart = 0
      this.budgetService.getList(null, null, $event.trustee).subscribe((res) => {
        //console.log(res)
        if (res) {
          let budget_n
          let budget_n_1
          res.forEach((item) => {
            if (item.anneeEx === $event.annee) {
              budget_n = item
            }
            if (item.anneeEx === ($event.annee - 1)) {
              budget_n_1 = item
            }
          })

          if (budget_n) {
            let data = []
            this.ccrea_totalCR_n = 0
            budget_n.optionBudgets.forEach((option) => {
              let spents = option.spents
              let chargesR = 0
              if (spents && spents.length > 0) {
                spents.forEach((spent) => {
                  let date = moment(spent.date)
                  let year = date.year()
                  if (year === $event.annee) {
                    chargesR += spent.montant
                  }
                })
              }
              this.ccrea_totalCR_n += chargesR
              data.push({
                montantP: option.montantP,
                montantV: option.montantV,
                chargesR: chargesR,
                typeLoad: {
                  // charges: option.typeLoad.charges,
                  code: option.typeLoad.code,
                  invoiceCos: option.invoiceCos,
                  libelle: option.typeLoad.libelle,
                  loadCategoryLibelle: option.typeLoad.loadCategory.libelle,
                  loadCategoryCode: option.typeLoad.loadCategory.code,
                  // quotes: option.quotes,
                  spents: option.spents,
                  // totalInvoice: option.typeLoad.totalInvoice,
                }
              })
            })
            this.ccrea_data_n = this.groupBy(data, 'loadCategoryLibelle', 'typeLoad')
          }

          if (budget_n_1) {
            let data = []
            this.ccrea_totalCR_n_1 = 0
            budget_n_1.optionBudgets.forEach((option) => {
              let spents = option.spents
              let chargesR = 0
              if (spents && spents.length > 0) {
                spents.forEach((spent) => {
                  let date = moment(spent.date)
                  let year = date.year()
                  if (year === ($event.annee - 1)) {
                    chargesR += spent.montant
                  }
                })
              }
              this.ccrea_totalCR_n_1 += chargesR
              data.push({
                montantP: option.montantP,
                montantV: option.montantV,
                chargesR: chargesR,
                typeLoad: {
                  // charges: option.typeLoad.charges,
                  code: option.typeLoad.code,
                  invoiceCos: option.invoiceCos,
                  libelle: option.typeLoad.libelle,
                  loadCategoryLibelle: option.typeLoad.loadCategory.libelle,
                  loadCategoryCode: option.typeLoad.loadCategory.code,
                  // quotes: option.quotes,
                  spents: option.spents,
                  // totalInvoice: option.typeLoad.totalInvoice,
                }
              })
            })
            this.ccrea_data_n_1 = this.groupBy(data, 'loadCategoryLibelle', 'typeLoad')
          }
          this.ccrea_totalEcart = this.ccrea_totalCR_n - this.ccrea_totalCR_n_1
          //console.log(this.groupByAttribute(this.ccrea_data_n.concat(this.ccrea_data_n_1), 'loadCategoryLibelle'))

          let all_data = []
          let concatedData = this.ccrea_data_n.concat(this.ccrea_data_n_1)
          concatedData.forEach((items) => {
            items.items.forEach((item: any) => {
              all_data.push({
                category: item.typeLoad.loadCategoryLibelle,
                libelle: item.typeLoad.libelle,
                montantV:  item.montantV,
                chargesR: item.chargesR,
                annee: item.annee
              })
            })
          })
          all_data = this.processArray(all_data)
          this.ccrea_data = this.groupByAttribute(all_data, 'category')
        }
      })
    }else if(this.type === 'CRRNC'){
      //console.log($event.annee)
      this.crrnc_data = []
      this.crrnc_owners = []
      this.crrnc_totalChargeR = 0
      this.crrnc_total_tantiemes = 0
      this.ownerService.getList($event.trustee).subscribe((res) => {
        //console.log(res)
        if (res) {
          res.forEach((owner: any) => {
            let tantieme = 0
            let homes = owner.homesCo
            homes.forEach((home: any) => {
              if (home.tantiemes && home.tantiemes.length > 0) {
                home.tantiemes.forEach((t: any) => {
                  tantieme += t.valeur
                })
              }
            })

            this.crrnc_owners.push({
              nom: owner.nom,
              tantieme: tantieme
            })
            this.crrnc_total_tantiemes += tantieme
          })
        }
      })
      this.budgetService.getList(null, null, $event.trustee).subscribe((res) => {
        //console.log(res)
        if (res) {
          let budget
          res.forEach((item) => {
            if (item.anneeEx === $event.annee) {
              budget = item
            }
          })

          if (budget) {
            let data = []
            budget.optionBudgets.forEach((option) => {
              let spents = option.spents
              let chargeR = 0
              if (spents && spents.length > 0) {
                spents.forEach((spent) => {
                  let date = moment(spent.date)
                  let year = date.year()
                  if (year === $event.annee) {
                    chargeR += spent.montant
                  }
                })
              }
              data.push({
                chargeR: chargeR,
                typeLoad: {
                  code: option.typeLoad.code,
                  libelle: option.typeLoad.libelle,
                  loadCategoryLibelle: option.typeLoad.loadCategory.libelle
                }
              })
              this.crrnc_totalChargeR += chargeR
            })
            this.crrnc_data = this.groupBy(data, 'loadCategoryLibelle', 'typeLoad')
            //console.log(this.crrnc_data)
          }
        }
      })
    }else if(this.type === 'PRODUIT_CHARGE_EXEP'){
    }else if(this.type === 'SITUATION_COPROPRIETAIRE'){
      //console.log($event.annee)
      this.sc_data = []
      this.sc_totalFundsapeals = 0
      this.sc_totalChargesR = 0
      this.sc_totalPayments = 0
      this.sc_totalChargesI = 0
      this.sc_totalSolde = 0
      this.ownerService.getList($event.trustee).subscribe((res) => {
        //console.log(res)
        if (res) {
          let data = []
          res.forEach((owner: any) => {
            let totalFundsAppeals = 0
            let totalChargesR = 0
            let fundsapeals = owner.fundsApeals
            let homes = owner.homesCo
            if (fundsapeals && fundsapeals.length > 0) {
              fundsapeals.forEach((item: any) => {
                if (item.annee === $event.annee) {
                  totalFundsAppeals += item.montant
                }
              })
            }
            if (homes && homes.length > 0) {
              homes.forEach((home: any) => {
                let spents = home.spents
                if (spents && spents.length > 0) {
                  spents.forEach((spent: any) => {
                    let spentDate = moment(spent.date)
                    let year = spentDate.year()
                    if (year === $event.annee) {
                      totalChargesR += spent.montant
                    }
                  })
                }
              })
            }

            data.push({
              owner: owner.nom,
              fundsapeals: totalFundsAppeals,
              chargesR: totalChargesR,
              payments: 0,
              chargesI: 0,
              solde: 0
            })
          })
          data.forEach((item) => {
            this.sc_totalFundsapeals += item.fundsapeals
            this.sc_totalChargesR += item.chargesR
            this.sc_totalPayments += item.payments
            this.sc_totalChargesI += item.chargesI
            this.sc_totalSolde += item.solde
          })
          this.sc_data = data
        }
      })
    }else if(this.type === 'SITUATION_CO_RESERVE_FINAN'){
      //console.log($event.annee)
      this.scrf_data = []
      this.scrf_totalFundsapeals_n = 0
      this.scrf_totalFundsapeals_n_1 = 0
      this.scrf_totalPayments_n = 0
      this.scrf_totalPayments_n_1 = 0
      this.scrf_totalSolde = 0
      this.ownerService.getList($event.trustee).subscribe((res) => {
        //console.log(res)
        if (res) {
          let date_n = moment().year($event.annee)
          let date_n_1 = moment().year(($event.annee - 1)).endOf('year')
          let current_year = moment().year()
          this.scrf_date_n = $event.annee > current_year ? (moment().year($event.annee).endOf('year')).format('DD/MM/YYYY') : date_n.format('DD/MM/YYYY')
          this.scrf_date_n_1 = date_n_1.format('DD/MM/YYYY')
          let data = []
          res.forEach((owner: any) => {
            let totalFundsAppeals_n = 0
            let totalFundsAppeals_n_1 = 0
            let totalPayments_n = 0
            let totalPayments_n_1 = 0
            let fundsapeals = owner.fundsApeals
            let fundsPayments = owner.fundsPayments
            if (fundsapeals && fundsapeals.length > 0) {
              fundsapeals.forEach((item: any) => {
                if (item.annee === $event.annee) {
                  if (item.type === "RESERVE") {
                    totalFundsAppeals_n += item.montant
                  }
                }

                if (item.annee === ($event.annee - 1)) {
                  if (item.type === "RESERVE") {
                    totalFundsAppeals_n_1 += item.montant
                  }
                }
              })
            }
            if (fundsPayments && fundsPayments.length > 0) {
              fundsPayments.forEach((item: any) => {
                if (item.annee === $event.annee) {
                  if (item.type === "RESERVE") {
                    totalPayments_n += item.montant
                  }
                }

                if (item.annee === ($event.annee - 1)) {
                  if (item.type === "RESERVE") {
                    totalPayments_n_1 += item.montant
                  }
                }
              })
            }

            data.push({
              owner: owner.nom,
              fundsapeals_n: totalFundsAppeals_n,
              fundsapeals_n_1: totalFundsAppeals_n_1,
              payments_n: totalPayments_n,
              payments_n_1: totalPayments_n_1,
              solde: (totalFundsAppeals_n_1 - totalPayments_n_1)
            })
          })
          data.forEach((item) => {
            this.scrf_totalFundsapeals_n += item.fundsapeals_n
            this.scrf_totalFundsapeals_n_1 += item.fundsapeals_n_1
            this.scrf_totalPayments_n += item.payments_n
            this.scrf_totalPayments_n_1 += item.payments_n_1
            this.scrf_totalSolde += item.solde
          })
          this.scrf_data = data
        }
      })
    }else if(this.type === 'FOND_RESERVE_FINANIERE'){
    }
  }

  getInfo($event) { 
    console.log('data',$event)
    this.syndicService.getSingle($event.uuid).subscribe((res: any) => {
      return this.syndic = res
    })
    let data = []
    this.ownerService.getList($event.trustee).subscribe((res) => {
      if (res) {
        this.syndic_owners = res
        this.tantiemeService.getList(null, $event.trustee).subscribe((res) => {
          if(res) {
            this.syndic_type_tantiemes = res
            this.syndic_data = this.generateData(this.syndic_owners, this.syndic_type_tantiemes)
          }
        })
      }
    })
  }

  getfristInfo() { 
    this.syndicService.getList(this.agency).subscribe(
      (res) => {
        this.trusteeRow = res;
        let data = {'trustee': res[0].uuid , 'uuid' : res[0].uuid }
        this.getInfo(data)
      },
      (error) => {}
    );
  }

  getStats(annee ,syndicUuid) {
    this.syndicService.getStatsData(syndicUuid, annee).subscribe((res) => {
      if(res) {
        this.stats_data = res.stats;
        this.periode = res.periode;
      }
    })
  }
  graphData(title?: string, categories?: any, series?: any) {
    this.barBasicChartOptions = {
      chart: {
        type: 'line'
      },
      colors: ['blue', 'green', 'red'],
      title: {
        text: title
      },
      xAxis: {
        categories: categories,
        crosshair: true
      },
      yAxis: {
        title: {
          text: 'XOF'
        }
      },
      credits: {
        enabled: false
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' + '<td style="padding:0"><b>{point.y:.1f} </b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0
        }
      },
      series: series
    };
  }

  groupBy(array, prop, subProp) {
    return array.reduce((groups, item) => {
      const value = item[subProp][prop];
      const group = groups.find(g => g.key === value);
      if (group) {
        group.items.push(item);
      } else {
        groups.push({ key: value, items: [item] });
      }
      return groups;
    }, []).map(group => ({ [prop]: group.key, items: group.items }));
  }

  groupByAttribute(array, attribute) {
    const groupedArray = [];

    const groups = {};

    array.forEach(function (obj) {
      const key = obj[attribute];
      if (!groups[key]) {
        groups[key] = [];
        groupedArray.push({
          groupName: key,
          data: groups[key]
        });
      }
      groups[key].push(obj);
    });

    return groupedArray;
  }

  processArray(arr) {
    const resultMap = new Map();
    const resultArray = [];

    arr.forEach(obj => {
      const { libelle, category, montantV, chargesR } = obj;

      if (resultMap.has(libelle)) {
        const existingObj = resultMap.get(libelle);

        existingObj.montantV_2 = montantV;
        existingObj.chargesR_2 = chargesR;
      } else {
        const newObj = {
          category,
          libelle,
          montantV_1: montantV,
          chargesR_1: chargesR
        };

        resultMap.set(libelle, newObj);
        resultArray.push(newObj);
      }
    });

    return resultArray;
  }

  generateData(owners, types) {
    let data = [];

    owners.forEach((owner) => {
      let ownerData = {
        nom: owner.nom,
        data: []
      };

      types.forEach((type, index) => {
        let total = 0;
        type.tantiemes.forEach((tantieme) => {
          let home = tantieme.homeCo
          if (home.owner.nom === owner.nom) {
            total += tantieme.valeur
          }
        });
        ownerData['data'].push(total);
      });

      data.push(ownerData);
    });


    return data;
  }

  getMonthName(monthNumber) {
    const months = [
      'janvier', 'fevrier', 'mars', 'avril', 'mai', 'juin',
      'juillet', 'aout', 'septembre', 'octobre', 'novembre', 'decembre'
    ];
    return months[monthNumber - 1];
  }

  getScTotalD(data) {
    let total = 0;
    if (this.type === "SITUATION_COPROPRIETAIRE") {
      data.forEach(item => {
        let mtn = ((item.chargesR + item.solde) - item.payments)
        if ( mtn < 0) {
          total += mtn;
        }
      });
    } else if (this.type === 'SITUATION_CO_RESERVE_FINAN') {
      data.forEach(item => {
        let mtn = ((item.fundsapeals_n_1 - item.payments_n_1) + item.solde)
        if ( mtn < 0) {
          total += mtn;
        }
      });
    }
    return total;
  }

  charge(periode, ownerUuid) {
    let stats: any = this.stats_data.find(elt => elt.owner.uuid == ownerUuid);
    if(stats){
      let statistique = stats.charges.find(elt => elt.periode == periode);
      return statistique;
    }
    return null;
  }
  
  getScTotalC(data) {
    let total = 0;
    if (this.type === "SITUATION_COPROPRIETAIRE") {
      data.forEach(item => {
        let mtn = ((item.chargesR + item.solde) - item.payments)
        if ( mtn > 0) {
          total += mtn;
        }
      });
    } else if (this.type === 'SITUATION_CO_RESERVE_FINAN') {
      data.forEach(item => {
        let mtn = ((item.fundsapeals_n_1 - item.payments_n_1) + item.solde)
        if ( mtn > 0) {
          total += mtn;
        }
      });
    }
    return total;
  }


}
