<div class="col-md-12" *ngIf="type === 'FAMILY'">
    <!-- <app-card [hidHeader]="true" cardClass="card-datatable"> -->
      <div class="card card-box rounded border-0">
        <div class="card-body p-3">
          <div class="table-responsive m-0">
            <table  *ngIf="etat" datatable [dtOptions]="dtOptions" class="table table-sm table-striped table-bordered nowrap table-hover">
            <thead>
              <tr>
                <th>Libellé</th>
                <th>Crée le</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of familys">
                <td class="align-middle">
                  <p class="m-0 d-inline-block align-middle font-16">
                    <span class="">{{item?.libelle}}</span>
                  </p>
                </td>
                <td>{{ item.createdAt| date: "d MMMM y 'à' h:mm:ss" : '' : 'fr-FR'}}</td>
                <td class="table-action">
                  <button *ngxPermissionsOnly="'FAMILY:SHOW'" (click)="show(item)" type="button" class="btn btn-icon btn-secondary" ngbTooltip="Détails">
                    <i class="fas fa-eye"></i>
                  </button>
                  <button *ngxPermissionsOnly="'FAMILY:EDIT'" (click)="edit(item)" type="button" class="btn btn-icon btn-primary ml-1" ngbTooltip="Modifier">
                    <i class="feather icon-edit-2"></i>
                  </button>
                  <button *ngxPermissionsOnly="'FAMILY:PRINTER:SHOW'" (click)="printer(item)" type="button" class="btn btn-icon btn-warning ml-1" ngbTooltip="Imprimer">
                    <i class="feather icon-printer"></i>
                  </button>
                  <button *ngIf="item?.isDelete" type="button" (click)="delete(item)" class="btn btn-icon btn-danger ml-1" ngbTooltip="Supprimer">
                    <i class="feather icon-trash"></i>
                  </button>
                </td>
              </tr>
            </tbody>
            </table>
          </div>
        </div>
      </div>
    <!-- </app-card> -->
  </div>
  