<div class="row help-desk" [ngClass]="this.size">
  <ng-template [ngxPermissionsOnly]="['TICKET:LIST', 'CATEGORY:LIST']">
    <app-filter class="width"
      [filter]="filter"
      [name]="true"
      [nameTitle]="nameTitle"
      [user]="true"
      [userTitle]="userTitle"
      [etat]="true"
      [type]="true"
      [dateD]="true"
      [dateF]="true"
      [create]="true"
      [min]="false"
      [max]="false"
      [ordre]="true"
      [code]="true"
      [count]="true"
      [etatRow]="etatRow"
      [typeRow]="typeRow" (typeEvent)="onChangeLoad($event)" (filterEvent)="onFilter($event)">
    </app-filter>
  </ng-template>
  <ng-template [ngxPermissionsExcept]="['TICKET:LIST', 'CATEGORY:LIST']">
    <div class="col-md-12">
      <app-no-droit [title]="'RECLAMATION'"></app-no-droit>
    </div>
  </ng-template>
    <div class="col-xl-8 col-lg-12 filter-bar">
      <ng-template [ngxPermissionsOnly]="['TICKET:NEW', 'CATEGORY:NEW']">
        <div class="card">
          <div class="card-body">
            <nav class="navbar justify-content-between p-0 align-items-center">
              <div *ngxPermissionsOnly="'TICKET:NEW'" class="btn-group btn-group-toggle text-left" ngbRadioGroup>
                <button (click)="addTicket(type)" class="btn btn-primary" ngbTooltip="Cliquez ici pour ajouter une réclamation">
                  Réclamation <i class="fas fa-ticket-alt"></i>
                </button>
              </div>
              <div *ngxPermissionsOnly="'CATEGORY:NEW'" class="btn-group btn-group-toggle text-left" ngbRadioGroup>
                <button (click)="addCategory(type)" class="btn btn-primary"
                  ngbTooltip="Cliquez ici pour ajouter une catégorie">Catégorie <i class="fas fa-th-large"></i>
                </button>
              </div>
              <div class="btn-group btn-group-toggle" ngbRadioGroup name="ticketSize" data-toggle="buttons"
                [(ngModel)]="size">
                <label ngbButtonLabel class="btn btn-primary">
                  <input ngbButton type="radio" value="sm-view" id="option1" checked> <i class="feather icon-align-justify m-0"></i>
                </label>
                <label ngbButtonLabel class="btn btn-primary">
                  <input ngbButton type="radio" value="md-view" id="option2"> <i class="feather icon-menu m-0"></i>
                </label>
                <label ngbButtonLabel class="btn btn-primary active">
                  <input ngbButton type="radio" value="large-view" id="option3"> <i class="feather icon-grid m-0"></i>
                </label>
              </div>
            </nav>
          </div>
        </div>
      </ng-template>
      <ng-template [ngxPermissionsOnly]="['TICKET:LIST']">
        <!-- LISTE DES TICKETS -->
        <div *ngIf="type === 'TICKET' && tickets" (click)="this.showView" class="ticket-block">
          <span class="badge badge-info my-2 f-14 width">
            LISTE DES TICKETS
          </span>
          <div *ngFor="let item of tickets" class="row">
            <div class="col-auto">
              <img class="media-object wid-60 img-radius" src="assets/images/avatar-default.png"
                alt="Generic placeholder image ">
            </div>
            <div class="col">
              <div class="card hd-body">
                <div class="row align-items-center">
                  <div class="col border-right pr-0">
                    <div class="card-body inner-center">
                      <div *ngIf="item.tentant!=null" class="ticket-customer font-weight-bold">{{item.tenant.nom}}</div>
                      <div *ngIf="item.owner!=null" class="ticket-customer font-weight-bold">{{item.owner.nom}}</div>
                      <div *ngIf="item.customer!=null" class="ticket-customer font-weight-bold">{{item.customer?.nom}}
                      </div>
                      <ul class="list-inline mt-2 mb-0">
                        <li class="list-inline-item">
                          <img src="assets/images/avatar-default.png" alt="" class="wid-20 rounded mr-1 img-fluid">Attribué à {{item.user?.nom}}</li>
                        <li class="list-inline-item">
                          <i class="feather icon-calendar mr-1 f-14"></i>publié {{getPastTime(item.createdAt)}}
                        </li>
                        <li class="list-inline-item">
                          <i class="feather icon-message-square mr-1 f-14"></i>{{item.chats.length}}
                        </li>
                      </ul>
                      <div class="mt-2"><span class="">Objet: </span> {{item.objet}}</div>
                      <div class="excerpt mt-4">
                        <h6>Description</h6>
                        <div [innerHTML]="item.description"></div>
                      </div>
                      <div class="mt-2">
                        <a class="mr-3 text-muted" (click)="showTicket(item)"><i class="feather icon-eye mr-1"></i>Voir</a>
                        <a class="mr-3 text-danger" (click)="delete(item)"> <i class="feather icon-trash-2 mr-1"></i>Supprimer</a>
                        <a class="text-success" (click)="assignTicket(item)"><i class="feather icon-share-2 mr-1"></i>Assigner à</a>
                      </div>
                    </div>
                  </div>
                  <div class="col-auto pl-0 right-icon">
                    <div class="card-body">
                      <ul class="list-unstyled mb-0">
                        <li>
                          <a href="javascript:" data-toggle="tooltip" data-placement="top" title="" data-original-title="tooltip on top">
                            <i class="feather icon-circle text-muted"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-auto hover-blk position-absolute shadow-sm rounded">
                    <div class="card-body p-0 pt-2">
                      <div class="img-txt">
                        <img src="assets/images/avatar-default.png" alt="" class="wid-20 rounded mr-1 img-fluid">
                        <p>{{getLastChat(item)?.user?.nom}}</p>
                      </div>
                      <p [innerHTML]="getLastChat(item)?.message"></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template ngxPermissionsExcept="TICKET:LIST"  [ngxPermissionsOnly]="['CATEGORY:LIST']">
        <div class="col-md-12">
          <app-no-droit *ngIf="type === 'TICKET'" [title]="'liste Reclamation'"></app-no-droit>
        </div>
      </ng-template>
      <ng-template [ngxPermissionsOnly]="['CATEGORY:LIST']">
        <!-- LISTE DES CATEGORIES -->
        <div class="col-md-12" *ngIf="type === 'CATEGORY' && categories && categories.length === 0">
          <span class="badge badge-info my-2 f-14 width">
            LISTE DES CATEGORIES
          </span>
          <app-no-data class="width" [title]="'Aucune catégorie trouvée'" *ngIf="type === 'CATEGORY' && categories && categories.length === 0"></app-no-data>
        </div>
      </ng-template>
      <ng-template ngxPermissionsExcept="CATEGORY:LIST">
        <div class="col-md-12">
          <app-no-droit *ngIf="type === 'CATEGORY'" [title]="'liste Categorie'"></app-no-droit>
        </div>
      </ng-template>
      <app-category-list
        [categories]="categories" *ngIf="type === 'CATEGORY' && categories && categories.length > 0" class="width">
      </app-category-list>
    </div>

  <div class="col-xl-4 col-lg-12">
    <div class="right-side">
      <div class="card mb-3 mr-4">
        <div class="card-header">
          <h5>Categories</h5>
        </div>
        <div class="card-body p-3">
          <div class="cat-list">
            <div *ngFor="let item of categories" class="border-bottom pb-3 ">
              <div class="d-inline-block">
                <img src="" alt="" class="wid-20 rounded mr-1 img-fluid">
                <a href="javascript:">{{item.libelle}}</a>
              </div>
              <div class="float-right span-content">
                <a href="javascript:" class="btn btn-default badge-danger rounded-circle mr-1" data-toggle="tooltip"
                  data-placement="top" title="" data-original-title="tooltip on top">{{countClosedTickets(item.tickets)}}
                </a>
                <a href="javascript:" class="btn btn-default badge-secondary rounded-circle mr-0 " data-toggle="tooltip"
                  data-placement="top" title="" data-original-title="tooltip on top">{{countOpenedTickets(item.tickets)}}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card mb-3 mr-4">
        <div class="card-header pt-4 pb-4">
          <h5>Commercial</h5>
        </div>
        <div class="card-body p-3">
          <div class="cat-list">
            <div *ngFor="let item of users" class="border-bottom pb-3 ">
              <div class="d-inline-block">
                <img src="{{item.photoSrc}}" alt="" class="wid-20 rounded mr-1 img-fluid">
                <a href="javascript:">{{item.nom}}</a>
              </div>
              <div class="float-right span-content">
                <a href="javascript:" class="btn btn-default badge-danger rounded-circle mr-1" data-toggle="tooltip"
                  data-placement="top" title="" data-original-title="tooltip on top">{{countClosedTickets(item.tickets)}}
                </a>
                <a href="javascript:" class="btn btn-default badge-secondary rounded-circle mr-0 " data-toggle="tooltip"
                  data-placement="top" title="" data-original-title="tooltip on top">{{countOpenedTickets(item.tickets)}}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="ticket" class="q-view" [ngClass]="showView ? 'active' : ''">
  <div class="overlay" (click)="this.showView = !this.showView"></div>
  <div class="content">
    <div class="card-body">
      <h4>
        {{ticket.objet}}
        <span [ngClass]="ticket.etat==='FERME'?'badge-danger':'badge-success'" class="badge  text-uppercase ml-2 f-12">
          {{ticket.etat}}
        </span>
      </h4>
      <div class="border-bottom pb-3 pt-3">
        <div class="row">
          <div class="col-md-7">
            <button *ngIf="ticket.etat!='FERME'" type="button" (click)="close(ticket)"
              class="btn btn-outline-success btn-sm mr-2">Fermer
              <i class="feather icon-check mr-1"></i>
            </button>
          </div>
          <div class="col-md-5 text-right">
          </div>
        </div>
      </div>
    </div>
    <div class="chat-card" #scrollMe style="height: 50%; overflow-y: auto;">
      <div style="overflow: hidden;">
        <div class="card-body">
          <div *ngFor="let item of ticket.chats">
            <div *ngIf="item.user.uuid == user.uuid" class="row m-b-20 send-chat">
              <div class="col">
                <div class="msg">
                  <p [innerHTML]="item.message" class="m-b-0"></p>
                  <img *ngFor="let image of item.folder?.files" src="{{publicUrl+'/'+image?.fullPath}}" alt=""
                    (click)="open(publicUrl+'/'+image?.fullPath)">
                </div>
                <p class="text-muted m-b-0"><i class="fa fa-clock-o m-r-10"></i>{{item.user.nom}}</p>
              </div>
              <div class="col-auto p-l-0">
                <img src="assets/images/avatar-default.png" alt="user image" class="img-radius wid-40">
              </div>
            </div>
            <div *ngIf="item.user.uuid != user.uuid" class="row m-b-20 received-chat">
              <div class="col-auto p-r-0">
                <img src="assets/images/avatar-default.png" alt="user image" class="img-radius wid-40">
              </div>
              <div class="col">
                <div class="msg">
                  <p [innerHTML]="item.message" class="m-b-0"></p>
                  <img *ngFor="let image of item.folder?.files" src="{{publicUrl+'/'+image?.fullPath}}" alt=""
                    (click)="open(publicUrl+'/'+image?.fullPath)">
                </div>
                <p class="text-muted m-b-0"><i class="fa fa-clock-o m-r-10"></i>{{item.user.nom}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="ticket.etat!='FERME'" class="px-5">
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="row mb-3">
          <div class="col-md-12">
            <app-folder-uploader #folderUploader (filesd)="files($event)"
              (filesUploaded)="setParam('folderUuid',$event)" [path]="'chat'" [etat]="'add'" [folder]="null">
            </app-folder-uploader>
          </div>
        </div>
        <div class="form-group">
          <input type="text" formControlName="message" placeholder="Message" name="task-insert" id="Project"
            class="form-control">
          <div class="form-icon">
            <button [disabled]="form.invalid" type="submit" class="btn btn-primary btn-icon">
              <i *ngIf="!loading" class="feather icon-message-circle"></i>
              <span *ngIf="loading" role="status" class="spinner-border spinner-border-sm"></span>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
