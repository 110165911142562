<div class="user-about-block row">
  <div class="col-md-2"></div>
  <div class="col-md-8 mt-4">
    <app-alert type="secondary">
      <div class="text-center item-image-box col-lg-12">
        <div class="item-image ml-2">
          <i class="fas fa-cogs" id="image-oupps"></i>
        </div>
      </div>
      <div class="text-center item-info col-lg-12">
        <div class="ad-info">
          <h3>En maintenance</h3>
          <h1>Désolé!!</h1>
        </div>
      </div>
    </app-alert>
  </div>
  <div class="col-md-2"></div>
</div>
