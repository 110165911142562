import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SyndicRoutingModule } from './syndic-routing.module';
import { SyndicAddComponent } from './syndic/syndic-add/syndic-add.component';
import { SyndicListComponent } from './syndic/syndic-list/syndic-list.component';
import { SyndicShowComponent } from './syndic/syndic-show/syndic-show.component';
import { MandateAddComponent } from './mandate/mandate-add/mandate-add.component';
import { MandateListComponent } from './mandate/mandate-list/mandate-list.component';
import { MandateShowComponent } from './mandate/mandate-show/mandate-show.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from '@theme/shared/components';
import { FileUploadModule } from '@iplab/ngx-file-upload';
import { ToastyModule } from 'ng2-toasty';
import { NgbCarouselModule, NgbDatepickerModule, NgbDropdownModule, NgbTabsetModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '@theme/shared/shared.module';
import { DataTablesModule } from 'angular-datatables';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgxPermissionsModule } from 'ngx-permissions';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { AgmCoreModule } from '@agm/core';
import { ProprietaireModule } from '@agence/proprietaire/proprietaire.module';
import { InfrastructureAddComponent } from './infrastructure/infrastructure-add/infrastructure-add.component';
import { InfrastructureListComponent } from './infrastructure/infrastructure-list/infrastructure-list.component';
import { InfrastructureShowComponent } from './infrastructure/infrastructure-show/infrastructure-show.component';
import { TextMaskModule } from 'angular2-text-mask';
import { ExtraModule } from '@agence/extra/extra.module';
import { PrestataireModule } from '@agence/prestataire/prestataire.module';
import { ChantierModule } from '@agence/chantier/chantier.module';
import { FundsApealsListComponent } from './funds-apeals/funds-apeals-list/funds-apeals-list.component';
import { FundsApealsShowComponent } from './funds-apeals/funds-apeals-show/funds-apeals-show.component';
import { FundsNoticeListComponent } from './funds-notice/funds-notice-list/funds-notice-list.component';
import { FundsNoticeShowComponent } from './funds-notice/funds-notice-show/funds-notice-show.component';
import { FundsPaymentListComponent } from './funds-payment/funds-payment-list/funds-payment-list.component';
import { FundsPaymentShowComponent } from './funds-payment/funds-payment-show/funds-payment-show.component';
import { FundsPaymentAddComponent } from './funds-payment/funds-payment-add/funds-payment-add.component';
import { BudgetModule } from '@agence/budget/budget.module';


@NgModule({
  declarations: [
    SyndicAddComponent, 
    SyndicListComponent, 
    SyndicShowComponent, 
    MandateAddComponent, 
    MandateListComponent, 
    MandateShowComponent, 
    InfrastructureAddComponent, 
    InfrastructureListComponent, 
    InfrastructureShowComponent, 
    FundsApealsListComponent, 
    FundsApealsShowComponent, 
    FundsNoticeListComponent, 
    FundsNoticeShowComponent, 
    FundsPaymentListComponent, 
    FundsPaymentShowComponent, 
    FundsPaymentAddComponent
  ],
  imports: [
    ReactiveFormsModule,
    CommonModule,    
    SharedModule,
    ExtraModule,
    ModalModule,
    SyndicRoutingModule,
    HttpClientModule,
    TextMaskModule,
    FormsModule,
    FileUploadModule,
    ToastyModule,
    NgbTabsetModule,
    NgbCarouselModule,
    DataTablesModule,
    NgbTooltipModule,
    NgbDatepickerModule,
    NgbDropdownModule,
    BsDatepickerModule.forRoot(),
    NgxPermissionsModule.forRoot(),
    NgxDocViewerModule,
    ProprietaireModule,
    PrestataireModule,
    ChantierModule,
    BudgetModule,
    AgmCoreModule.forRoot({ apiKey: 'AIzaSyCR7wdbPJRZh49cZ5lq_LCw-Eb0CFNSCdI' }),
  ],
  exports: [
    SyndicAddComponent,
    MandateAddComponent,
    MandateShowComponent,
    InfrastructureAddComponent,
    InfrastructureShowComponent,
    InfrastructureListComponent,
    // FundsApealsListComponent,
    // FundsNoticeListComponent,
    // FundsPaymentListComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],
})
export class SyndicModule { }
