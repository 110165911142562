import { SubdivisionAddComponent } from '@lotissement/subdivision/subdivision-add/subdivision-add.component';
import { SubdivisionService } from '@service/subdivision/subdivision.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subdivision } from '@model/subdivision';
import { Component, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { Islet } from '@model/islet';
import { Lot } from '@model/lot';
import { ActivatedRoute } from '@angular/router';
import { FilterService } from '@service/filter/filter.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { LotService } from '@service/lot/lot.service';
import { IsletService } from '@service/islet/islet.service';
import { Globals } from '@theme/utils/globals';
import { UploaderService } from '@service/uploader/uploader.service';

@Component({
  selector: 'app-subdivision-show',
  templateUrl: './subdivision-show.component.html',
  styleUrls: ['./subdivision-show.component.scss']
})

export class SubdivisionShowComponent implements OnInit {
  public activeTab: string = 'LOTISSEMENT';
  publicUrl = environment.publicUrl;
  userSession = Globals.user;
  mtnFiltre: Boolean = false;
  bien: boolean = false;
  global = {country: Globals.country, device: Globals.device}
  subdivision: Subdivision
  islets: Islet[]
  lot: Lot;
  lots: Lot[];
  lat = Globals.lat;
  lng = Globals.lng;
  zoom = Globals.zoom;
  icon = { url: 'assets/images/map-geo.png', scaledSize: {height: 40, width: 40}}
  type: string = 'LOTISSEMENT';
  etatRow = [
    {label: 'DISPONIBLE', value: 'DISPONIBLE'},
    {label: 'INDISPONIBLE', value: 'INDISPONIBLE'}
  ];
  typeRow = [
    {label: 'LOTISSEMENT', value: 'LOTISSEMENT'},
    {label: 'ILOT', value: 'ILOT'},
    {label: 'LOT', value: 'LOT'}
  ];
  categorieRow = [
    {label: 'RURAL', value: 'RURAL'},
    {label: 'URBAIN', value: 'URBAIN'}
  ];
  nameTitle: string = "Projet de lotissement"
  userTitle: string = "Crée par"
  bienTitle: string = "Lotissement"
  minTitle: string = "Montant MIN"
  refTitle: string = "N° Référence"
  maxTitle: string = "Montant MAX"
  etatTitle: string = "Disponibilité ?"
  categorieTitle: string = "Type de lotissement";
  file: any;

  constructor(
    private subdivisionService: SubdivisionService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private lotService: LotService,
    private filterService: FilterService,
    private isletService: IsletService,
    private uploader: UploaderService

  ) {
    this.onChangeLoad(this.type);
  }

  ngOnInit(): void {
  }

  onFilter($event) {
    this.islets = []
    this.lots = []
    $event.type = this.activeTab
    this.filterService.search($event, 'subdivision', this.subdivision.uuid).subscribe(
      res => {
      if(this.activeTab === 'ILOT'){
        return this.islets = res;
      } else if(this.activeTab === 'LOT'){
        return this.lots = res;
      }
    }, err => { })
  }
  onChangeLoad(type): void {
    this.activeTab = type;
    if(type === 'LOTISSEMENT'){
      if(!this.subdivision){
        this.subdivisionService.getSingle(this.route.snapshot.params.id).subscribe((res: any) => {
          return this.subdivision = res;
        });
      }
    } else if(type === 'ILOT'){
      this.mtnFiltre = false
      this.bienTitle = 'N° Ilot';
      this.bien = true;
      this.refTitle = "N° Référence"
      this.nameTitle = 'Lotissement'
      this.etatTitle = 'ETAT ESPACE ?'
      this.typeRow = [{label: 'ILOT', value: 'ILOT'}];
      this.categorieRow = []
      this.etatRow = [
        { label: 'ILOT DISPONIBLE', value: 'false' },
        { label: 'ESPACE VERT', value: 'true' }
      ];
      this.isletService.getList(this.subdivision.uuid).subscribe((res) => {
        return this.islets = res;
        }, error => {}
      );
    } else if (type === 'LOT') {
      this.mtnFiltre = true
      this.bienTitle = 'N° Lot';
      this.bien = true;
      this.refTitle = "N° Référence"
      this.categorieTitle = 'Type de lot'
      this.etatTitle = 'ETAT ?'
      this.typeRow = [{ label: 'LOT', value: 'LOT' }];
      this.etatRow = [
        { label: 'INDISPONIBLE', value: 'INDISPONIBLE' },
        { label: 'DISPONIBLE', value: 'DISPONIBLE' },
        { label: 'RESERVE', value: 'RESERVE' },
        { label: 'VENDU', value: 'VENDU' }
      ];
      this.categorieRow = []
      this.lotService.getList(this.subdivision.uuid).subscribe((res) => {
        return this.lots = res;
      }, error => { }
      );
    }
  }
  editPromotion(row) {
    this.subdivisionService.setSubdivision(row)
    this.subdivisionService.edit = true
    this.modal(SubdivisionAddComponent, 'modal-basic-title', 'xl', true, 'static');
  }

  printerSubdivision(row): void {
    this.subdivisionService.getPrinter('SHOW', this.userSession?.agencyKey, this.userSession?.uuid, row?.uuid);
  }
  back(){ window.history.back(); }
  delete(item) {
    Swal.fire({
      title: '',
      text: 'Voulez-vous vraiment supprimer cet enrégistrement ?',
      icon: '',
      showCancelButton: true,
      showCloseButton: true,
      cancelButtonText: 'Annuler',
      confirmButtonText: 'Supprimer <i class="feather icon-trash"></i>',
      confirmButtonColor: '#d33',
      timer: 2500,
      reverseButtons: true
    }).then((willDelete) => {
      if (willDelete.dismiss) { }
      else {
        this.subdivisionService.getDelete(item.uuid).subscribe((res: any) => {
          if (res?.status === 'success') {this.back()}
        });}
    });
  }
  updateGeo(event): void {
    const lat = event.coords.lat;
    const lng = event.coords.lng;
    this.lat = lat;
    this.lng = lng;
  }
  updateZoom(event): void {}
  modal(component, type, size, center, backdrop) {
    this.modalService.open(component, {
      ariaLabelledBy: type,
      size: size,
      centered: center,
      backdrop: backdrop
    }).result.then((result) => { }, (reason) => { });
  }
  showFile(item) {
    const fileByFolder = this.uploader.getDataFileByFolder();
    this.file = fileByFolder?.path ? this.publicUrl + '/' + fileByFolder?.path : null;
  }
  closeViewer() {
    this.file = '';
    this.uploader.setDataFileByFolder('');
  }
}
