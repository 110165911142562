import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-equipment-add',
  templateUrl: './equipment-add.component.html',
  styleUrls: ['./equipment-add.component.scss']
})
export class EquipmentAddComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
