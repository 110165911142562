<div class="row justify-content-center">
    <!-- FILTRE DE RECHERCHE -->
<!-- <div *ngIf="trusteeRow && trusteeRow.length > 0"> -->
    <app-filter class="width"
    [dateD]="dateD"
    [dateF]="dateD"
    [type]="false"
    [trustee]="true"
    [name]="name"
    [nameTitle]="nameTitle"
    [annee]="true" 
    [categorie]="false"
    [rapport]="true"
    [rapportRow]="rapportRow"
    [categorieRow]="categorieRow"
    (rapportEvent)="onApercu($event)"
    [typeRow]="typeRow" 
    (typeEvent)="onChangeLoad($event)"
    (filterEvent)="onFilter($event)">
    </app-filter>
  <!-- </div> -->
  
    

    <!-- INFORMATION SUR LES RAPPORTS-->

      <!-- NOM DU SYNDIC -->
     <div class="alert-primary m-2 col-md-12" *ngIf="rapport === true">
        <div class="col-md-12 mt-2 text-center p-2">
            <h5 class="text-black">{{syndic?.nom}}</h5>
        </div>
    </div>

    <!-- ANALYTICS -->
    <div class="col-xl-12 col-sm-12"  *ngIf="type === 'BUDGET'">
      <div class="row">       
        <!-- BUDGET TOTAL -->
        <div class="col-md-4">
          <app-card [hidHeader]="true" [isCardFooter]="true" footerClass="bg-c-green">
            <div class="row align-items-center">
              <div class="col-8">
                <h4 class="text-c-green">{{ widget?.budget?.valide|number }} {{global.device}}</h4>
                <h6 class="text-muted m-b-0">BUDGET TOTAL</h6>
              </div>
              <div class="col-4 text-right">
                <i class="fas fa-money-bill-alt f-28"></i>
              </div>
            </div>
            <div class="app-card-footer">            
            </div>
          </app-card>
        </div>

          <!-- SYNDIC  -->
        <div class="col-md-4">
          <app-card [hidHeader]="true" [isCardFooter]="true" footerClass="bg-c-yellow">
            <div class="row align-items-center">
              <div class="col-8">
                <h4 class="text-c-yellow">{{ widget?.budget?.encaisse|number }}</h4>
                <h6 class="text-muted m-b-0">TOTAL ENCAISSÉ</h6>
              </div>
              <div class="col-4 text-right">
                <i class="fas fa-money-bill-alt f-28"></i>
              </div>
            </div>
            <div class="app-card-footer">            
            </div>
          </app-card>
        </div>
  
        <!-- CHARGE TOTAL -->
        <div class="col-md-4">
          <app-card [hidHeader]="true" [isCardFooter]="true" footerClass="bg-c-red">
            <div class="row align-items-center">
              <div class="col-8">
                <h4 class="text-c-red">{{ widget?.budget?.charges|number }}  {{global.device}}</h4>
                <h6 class="text-muted m-b-0">TOTAL DES CHARGES</h6>
              </div>
              <div class="col-4 text-right">
                <i class="fas fa-money-bill-alt f-28"></i>
              </div>
            </div>
            <div class="app-card-footer">            
            </div>
          </app-card>
        </div>     
      </div>
      
    </div>

    <!-- DETAIL SUR LE SYNDIC -->
    <div *ngIf="type === 'SYNDIC'" class="col-md-12">
        <div class="row">
            <div class="col-md-6">
                <span class="badge badge-primary mb-4 f-14 width">
                    DETAIL SUR LE SYNDIC
                </span>
                <app-card [hidHeader]="true" cardClass="card-datatable">
                    <div class="col-md-12">
                        <div class="row py-2 item-condominium">
                            <div class="col-md-5">
                                <span>
                                Reférence
                                </span>
                            </div>
                            <div class="col-md-7">
                                <span class="bold">
                                {{syndic?.code}}
                                </span>
                            </div>
                        </div>
                        <div class="row py-2">
                            <div class="col-md-5">
                                <span>
                                Nom du syndic
                                </span>
                            </div>
                            <div class="col-md-7">
                                <span class="bold">
                                {{syndic?.nom}}
                                </span>
                            </div>
                        </div>
                        <div class="row py-2 item-condominium">
                            <div class="col-md-5">
                                <span>
                                Pays :
                                </span>
                            </div>
                            <div class="col-md-7">
                                <span class="bold">
                                {{syndic?.pays?.nom }}
                                </span>
                            </div>
                        </div>
                        <div class="row py-2">
                            <div class="col-md-5">
                                <span>
                                Ville :
                                </span>
                            </div>
                            <div class="col-md-7">
                                <span class="bold">
                                {{syndic?.ville }}
                                </span>
                            </div>
                        </div>
                        <div class="row py-2 item-condominium">
                            <div class="col-md-5">
                                <span>
                                Commune / Quartier :
                                </span>
                            </div>
                            <div class="col-md-7">
                                <span class="bold">
                                {{ syndic?.commune }} - {{ syndic?.quartier }}
                                </span>
                            </div>
                        </div>
                        <div class="row py-2">
                            <div class="col-md-5">
                                <span>
                                Date de création : <br />
                                </span>
                            </div>
                            <div class="col-md-7">
                                <span class="bold">
                                {{syndic?.createdAt| date: "d MMMM y 'à' H:mm:ss " : '' : 'fr-FR'}}<br />
                                </span>
                            </div>
                        </div>
                        <div class="row py-2 item-condominium">
                            <div class="col-md-5">
                                <span>
                                Créer par
                                </span>
                            </div>
                            <div class="col-md-7">
                                <span class="bold">
                                {{syndic?.create}}
                                </span>
                            </div>
                        </div>
                        <div class="row py-2">
                            <div class="col-md-5">
                                <span>
                                Date de modification : <br />
                                </span>
                            </div>
                            <div class="col-md-7">
                                <span class="bold">
                                {{syndic?.updatedAt| date: "d MMMM y 'à' H:mm:ss " : '' : 'fr-FR'}}<br />
                                </span>
                            </div>
                        </div>
                        <div class="row py-2 item-condominium">
                            <div class="col-md-5">
                                <span>
                                Modifier par
                                </span>
                            </div>
                            <div class="col-md-7">
                                <span class="bold">
                                {{syndic?.update}}
                                </span>
                            </div>
                        </div>
                    </div>
                </app-card>
            </div>
            <!-- <div class="col-md-6">
                <span class="badge badge-primary mb-4 f-14 width">
                    LOCALISATION
                </span>
                <app-card [hidHeader]="true" cardClass="card-datatable">
                    <agm-map [draggableCursor]="'default'" [mapTypeId]="'hybrid'" [latitude]="lat" [longitude]="lng" [zoom]="zoom">
                        <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>
                    </agm-map>
                </app-card>
            </div> -->
            <div class="col-md-6">
                <span class="badge badge-primary mb-4 f-14 width">
                    MILLIEME RÉPARTITION DES CHARGES
                </span>
                <app-card [hidHeader]="true" cardClass="card-datatable">
                    <perfect-scrollbar [autoPropagation]="true" class="scroll-area" style="height: 330px;">
                        <div class="table-responsive m-0" *ngIf="syndic_data && syndic_data.length > 0">
                            <table class="table table-hover text-nowrap table-bordered m-0">
                                <thead>
                                    <tr>
                                        <th class="text-center vertical-align">COPROPRIETAIRES</th>
                                        <th class="text-center vertical-align" *ngFor="let item of syndic_type_tantiemes">
                                            {{ item.libelle }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of syndic_data">
                                        <td>
                                            <span>{{item?.nom}}</span>
                                        </td>
                                        <td class="text-center bold" *ngFor="let t of item.data; let i = index;">
                                            <span>{{ t | number}}</span>
                                        </td>
                                    </tr>
                                </tbody>
                                <!-- <tfoot>
                                    <tr>
                                        <td class="text-center bold">TOTAUX</td>
                                        <td class="text-right bold" *ngFor="let item of syndic_data">
                                            {{ 0 | number}}
                                        </td>
                                    </tr>
                                </tfoot> -->
                            </table>
                        </div>
                        <app-no-data *ngIf="syndic_data && syndic_data.length === 0" [title]="'Aucune donnée trouvée'"></app-no-data>
                    </perfect-scrollbar>
                </app-card>
            </div>
        </div>
    </div>

    <!-- COMPARATIF DU BUDGET PAR RAPPORT AUX CHARGES REELLES -->
    <div *ngIf="type === 'CBRCR'" class="col-md-12">
        <div class="row mb-2">
            <div class="col-md-4">
                <app-card [hidHeader]="true" cardClass="bg-c-yellow text-white widget-visitor-card" blockClass="text-center">
                    <h2 class="text-white">{{ cbrcr_totalBudget | number }} XOF</h2>
                    <h6 class="text-white">Total budget</h6>
                    <i class="feather icon-file-text"></i>
                </app-card>
            </div>
            <div class="col-md-4">
                <app-card [hidHeader]="true" cardClass="bg-c-green text-white widget-visitor-card" blockClass="text-center">
                    <h2 class="text-white">{{ cbrcr_totalCR | number }} XOF</h2>
                    <h6 class="text-white">Total charges réelles</h6>
                    <i class="feather icon-award"></i>
                </app-card>
            </div>
            <div class="col-md-4">
                <app-card [hidHeader]="true" cardClass="bg-c-blue text-white widget-visitor-card" blockClass="text-center">
                    <h2 class="text-white">{{ cbrcr_totalEcart | number }} XOF</h2>
                    <h6 class="text-white">Total écarts</h6>
                    <i class="feather icon-file-text"></i>
                </app-card>
            </div>
        </div>
        <span class="badge badge-primary mb-4 f-14 width">
            COMPARATIF DU BUDGET PAR RAPPORT AUX CHARGES RÉELLES
            <span class="ml-2" ngbTooltip="ECARTS = CHARGES RELLES - BUDGET">
                <i class="fas fa-info-circle"></i>
            </span>
        </span>
        <app-card [hidHeader]="true" cardClass="card-datatable">
            <perfect-scrollbar [autoPropagation]="true" class="scroll-area" style="height: 450px;">
                <div class="table-responsive m-0" *ngIf="cbrcr_data && cbrcr_data.length > 0">
                    <table class="table table-hover text-nowrap table-bordered m-0">
                        <thead>
                          <tr>
                            <th class="text-center vertical-align">POSTES BUDGETAIRES</th>
                            <th class="text-center vertical-align">BUDGET</th>
                            <th class="text-center vertical-align">CHARGES REELLES</th>
                            <th class="text-center vertical-align">ECARTS</th>
                          </tr>
                        </thead>
                        <tbody>
                          <!-- <ng-container *ngFor="let type of ComparatifBudget"> -->
                          <ng-container *ngFor="let item of cbrcr_data">
                            <tr>
                              <td colspan="4" class="text-left bold">{{ item?.loadCategoryLibelle }}</td>
                            </tr>
                            <tr *ngFor="let option of item.items">
                              <td class="pl-5">{{ option?.typeLoad?.libelle }}</td>
                              <td class="text-right">{{ option?.montantV | number }}</td>
                              <td class="text-right">{{ option?.chargesR | number }}</td>
                              <td class="text-right">{{ (option?.montantV - option?.chargesR) | number }}</td>
                            </tr>
                          </ng-container>
                        </tbody>
                        <tfoot>
                          <tr>
                            <td class="text-center bold">TOTAUX</td>
                            <td class="text-right bold">{{ cbrcr_totalBudget | number }}</td>
                            <td class="text-right bold">{{ cbrcr_totalCR | number }}</td>
                            <td class="text-right bold">{{ cbrcr_totalEcart | number }}</td>
                          </tr>
                        </tfoot>
                    </table>
                </div>
                <app-no-data *ngIf="cbrcr_data && cbrcr_data.length === 0" [title]="'Aucune donnée trouvée'"></app-no-data>
            </perfect-scrollbar>
        </app-card>
    </div>

    <!-- COMPARATIF DES CHARGES REELLES D'UN EXERCICE A L'AUTRE -->
    <div *ngIf="type === 'CCREA'" class="col-md-12">
        <div class="row mb-2">
            <div class="col-md-4">
                <app-card [hidHeader]="true" cardClass="bg-c-yellow text-white widget-visitor-card" blockClass="text-center">
                    <h2 class="text-white">{{ ccrea_totalCR_n | number }} XOF</h2>
                    <h6 class="text-white">Total charges réelles année actuelle</h6>
                    <i class="feather icon-file-text"></i>
                </app-card>
            </div>
            <div class="col-md-4">
                <app-card [hidHeader]="true" cardClass="bg-c-green text-white widget-visitor-card" blockClass="text-center">
                    <h2 class="text-white">{{ ccrea_totalCR_n_1 | number }} XOF</h2>
                    <h6 class="text-white">Total charges réelles année (n - 1)</h6>
                    <i class="feather icon-award"></i>
                </app-card>
            </div>
            <div class="col-md-4">
                <app-card [hidHeader]="true" cardClass="bg-c-blue text-white widget-visitor-card" blockClass="text-center">
                    <h2 class="text-white">{{ ccrea_totalEcart | number }} XOF</h2>
                    <h6 class="text-white">Total écarts</h6>
                    <i class="feather icon-file-text"></i>
                </app-card>
            </div>
        </div>
        <span class="badge badge-primary mb-4 f-14 width">
            COMPARATIF DES CHARGES RÉELLES D'UN EXERCICE A L'AUTRE
            <span class="ml-2" ngbTooltip="ECARTS = CHARGES RELLES ANNEE ACTUELLE - CHARGES RELLES ANNEE - 1">
                <i class="fas fa-info-circle"></i>
            </span>
        </span>
        <app-card [hidHeader]="true" cardClass="card-datatable">
            <perfect-scrollbar [autoPropagation]="true" class="scroll-area" style="height: 450px;">
                <div class="table-responsive m-0" *ngIf="ccrea_data && ccrea_data.length > 0">
                    <table class="table table-hover text-nowrap table-bordered m-0">
                        <thead>
                            <tr>
                                <th class="text-center vertical-align">POSTES BUDGÉTAIRES</th>
                                <th class="text-center vertical-align" *ngFor="let item of ccrea_annees">
                                    CHARGES RÉELLES {{ item }}
                                </th>
                                <th class="text-center vertical-align">ECARTS</th>
                            </tr>
                        </thead>
                        <tbody>
                            <!-- <ng-container *ngFor="let type of ComparatifBudget"> -->
                            <ng-container *ngFor="let item of ccrea_data">
                              <tr>
                                <td colspan="4" class="text-left bold">{{ item?.groupName }}</td>
                              </tr>
                              <tr *ngFor="let option of item.data">
                                <td class="pl-5">{{ option?.libelle }}</td>
                                <td class="text-right">
                                    <span *ngIf="option.chargesR_2">{{ option?.chargesR_2 | number }}</span>
                                </td>
                                <td class="text-right">
                                    <span *ngIf="option.chargesR_1">{{ option?.chargesR_1 | number }}</span>
                                </td>
                                <td class="text-right">
                                    {{ ((option.chargesR_1 ? option.chargesR_1 : 0) - (option.chargesR_2 ? option.chargesR_2 : 0)) | number }}
                                </td>
                              </tr>
                            </ng-container>
                          </tbody>
                          <tfoot>
                            <tr>
                                <td class="text-center bold">TOTAUX</td>
                                <td class="text-right bold">{{ ccrea_totalCR_n_1 | number }}</td>
                                <td class="text-right bold">{{ ccrea_totalCR_n | number }}</td>
                                <td class="text-right bold">{{ ccrea_totalEcart | number }}</td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
                <app-no-data *ngIf="ccrea_data && ccrea_data.length === 0" [title]="'Aucune donnée trouvée'"></app-no-data>
            </perfect-scrollbar>
        </app-card>
    </div>

    <!-- APPELS DE CHARGES EMIS & ENCAISSES -->
    <div *ngIf="type === 'AF_EMIS'" class="col-md-12">
        <!-- GRAPHE -->
        <!-- <div class="mb-2">
            <lib-highcharts-angular [Highcharts]="Highcharts" [options]="barBasicChartOptions"
                style="width: 100%; height: 450px; display: block;">
            </lib-highcharts-angular>
        </div> -->
        <!-- GRAPHE END -->
        <span class="badge badge-primary mb-4 f-14 width">
            APPELS DE CHARGES ÉMIS & ENCAISSÉS
            <span class="ml-2">
                <i class="fas fa-info-circle"></i>
            </span>
        </span>
        <app-card [hidHeader]="true" cardClass="card-datatable">
            <perfect-scrollbar [autoPropagation]="true" class="scroll-area" style="height: 450px;">
                <div class="table-responsive m-0" *ngIf="stats_data && stats_data.length > 0">
                    <table class="table table-hover text-nowrap table-bordered m-0">
                        <thead>
                            <tr>
                                <th rowspan="2" class="text-center">COPROPRIETAIRES</th>
                                <th colspan="2" class="text-center">TOTAL</th>
                                <ng-container *ngFor="let p of periode">
                                  <th colspan="2" class="text-center">{{p}}</th>
                                </ng-container>
                            </tr>
                            <tr>
                                  <th class="text-center">Emis</th>
                                  <th class="text-center">Encaissé</th>
                                <ng-container *ngFor="let p of periode">
                                    <th class="text-center">Emis</th>
                                    <th class="text-center">Encaissé</th>
                                </ng-container>
                              </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let stat of stats_data">
                            <td>{{stat?.owner?.nom}}</td>
                            <td>{{charge('TOTAL', stat?.owner?.uuid)?.emis|number:'1.2-2'}}</td>
                            <td>{{charge('TOTAL', stat?.owner?.uuid)?.encaisser|number:'1.2-2'}}</td>
                            <ng-container *ngFor="let p of periode">
                              <td>{{charge(p, stat?.owner?.uuid)?.emis|number:'1.2-2'}}</td>
                              <td>{{charge(p, stat?.owner?.uuid)?.encaisser| number:'1.2-2'}}</td>
                            </ng-container>
                          </tr>
                        </tbody>
                    </table>
                </div>
                <app-no-data *ngIf="stats_data && stats_data?.length === 0" [title]="'Aucune donnée trouvée'"></app-no-data>
            </perfect-scrollbar>
        </app-card>
    </div>

    <!-- CHARGES REELLES REPARTIES PAR NATURE AUX COPROPRIETAIRES  -->
    <div *ngIf="type === 'CRRNC'" class="col-md-12">
        <span class="badge badge-primary mb-4 f-14 width">
            CHARGES RÉELLES RÉPARTIES PAR NATURE AUX COPROPRIÉTAIRES
        </span>
        <app-card [hidHeader]="true" cardClass="card-datatable">
            <perfect-scrollbar [autoPropagation]="true" class="scroll-area">
                <div class="table-responsive m-0" *ngIf="crrnc_data && crrnc_data.length > 0">
                    <table class="table table-hover text-nowrap table-bordered m-0">
                        <thead>
                            <tr>
                                <th class="text-center">POSTES BUDGETAIRES</th>
                                <th class="text-center">TOTAL</th>
                                <th *ngFor="let item of crrnc_owners" class="text-center">
                                    <span>{{ item?.nom }}</span>
                                    <br/>
                                    <span>{{ item?.tantieme }}</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                          <ng-container *ngFor="let item of crrnc_data">
                            <tr>
                              <td colspan="4" class="text-left bold">{{ item?.loadCategoryLibelle }}</td>
                            </tr>
                            <tr *ngFor="let option of item.items">
                              <td class="pl-5">{{ option?.typeLoad?.libelle }}</td>
                              <td class="text-right">{{ option?.chargeR | number }}</td>
                              <ng-container *ngIf="crrnc_owners && crrnc_owners.length > 0">
                                <td class="text-right" *ngFor="let owner of crrnc_owners">
                                    {{ ((option?.chargeR * owner?.tantieme) / crrnc_total_tantiemes) | number }}
                                </td>
                              </ng-container>
                            </tr>
                          </ng-container>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td class="bold text-center">TOTAL</td>
                                <td class="bold text-right">{{ crrnc_totalChargeR | number }}</td>
                                <td class="bold text-right" *ngFor="let owner of crrnc_owners">
                                    {{ ((crrnc_totalChargeR * owner?.tantieme) / crrnc_total_tantiemes) | number }}
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
                <app-no-data *ngIf="crrnc_data && crrnc_data.length === 0" [title]="'Aucune donnée trouvée'"></app-no-data>
            </perfect-scrollbar>
        </app-card>
    </div>

    <!-- PRODUITS & CHARGES EXEPTIONNELLES -->
    <div *ngIf="type === 'PRODUIT_CHARGE_EXEP'" class="col-md-12">
        <span class="badge badge-primary mb-4 f-14 width">
            PRODUITS EXCEPTIONNELS RÉPARTIS PAR NATURE AUX COPROPRIÉTAIRES
        </span>
        <app-card [hidHeader]="true" cardClass="card-datatable">
            <div class="table-responsive m-0">
                <table class="table table-hover text-nowrap table-bordered m-0">
                    <thead>
                        <tr>
                            <th>POSTES BUDGETAIRES</th>
                            <th>TOTAL</th>
                            <th *ngFor="let item of Millieme" class="text-center"><span>{{item?.coproprietaire}}</span><br/><span>{{item?.general}}</span></th>                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>PRODUITS GENERAUX</td>
                            <td class="text-right">
                                <span>0</span>
                                <br/>
                                <span>0</span>
                            </td>
                            <td *ngFor="let item of ProduitCharge" class="text-right"><span>{{item?.un | number}}</span><br/><span>{{item?.un | number}}</span></td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td class="text-center">TOTAUX</td>
                            <td class="text-right">
                                <span>0</span>
                            </td>
                            <td *ngFor="let item of ProduitCharge" class="text-right"><span>{{item?.un | number}}</span></td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </app-card>

        <span class="badge badge-primary mb-4 f-14 width">
            CHARGES EXCEPTIONNELLES RÉPARTIS PAR NATURE AUX COPROPRIÉTAIRES
        </span>
        <app-card [hidHeader]="true" cardClass="card-datatable">
            <div class="table-responsive m-0">
                <table class="table table-hover text-nowrap table-bordered m-0">
                    <thead>
                        <tr>
                            <th>POSTES BUDGETAIRES</th>
                            <th>TOTAL</th>
                            <th *ngFor="let item of Millieme" class="text-center"><span>{{item?.coproprietaire}}</span><br/><span>{{item?.general}}</span></th>                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>PRODUITS GENERAUX</td>
                            <td class="text-right">
                                <span>0</span>
                                <br/>
                                <span>0</span>
                            </td>
                            <td *ngFor="let item of ProduitCharge" class="text-right"><span>{{item?.un | number}}</span><br/><span>{{item?.un | number}}</span></td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td class="text-center">TOTAUX</td>
                            <td class="text-right">
                                <span>0</span>
                            </td>
                            <td *ngFor="let item of ProduitCharge" class="text-right"><span>{{item?.un | number}}</span></td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </app-card>

        <span class="badge badge-primary mb-4 f-14 width">
            PRODUITS EXCEPTIONNELLES SUR FONDS DE RÉSERVES COPROPRIÉTAIRES
        </span>
        <app-card [hidHeader]="true" cardClass="card-datatable">
            <div class="table-responsive m-0">
                <table class="table table-hover text-nowrap table-bordered m-0">
                    <thead>
                        <tr>
                            <th>POSTES BUDGETAIRES</th>
                            <th>TOTAL</th>
                            <th *ngFor="let item of Millieme" class="text-center"><span>{{item?.coproprietaire}}</span><br/><span>{{item?.general}}</span></th>                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>PRODUITS GENERAUX</td>
                            <td class="text-right">
                                <span>0</span>
                                <br/>
                                <span>0</span>
                            </td>
                            <td *ngFor="let item of ProduitCharge" class="text-right"><span>{{item?.un | number}}</span><br/><span>{{item?.un | number}}</span></td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td class="text-center">TOTAUX</td>
                            <td class="text-right">
                                <span>0</span>
                            </td>
                            <td *ngFor="let item of ProduitCharge" class="text-right"><span>{{item?.un | number}}</span></td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </app-card>
    </div>

    <!-- SITUATION DES COPROPRIETAIRES EN FIN D'ANNEE -->
    <div *ngIf="type === 'SITUATION_COPROPRIETAIRE'" class="col-md-12">
        <div class="row mb-2">
            <div class="col">
                <app-card [hidHeader]="true" cardClass="bg-c-blue text-white widget-visitor-card" blockClass="text-center">
                    <h6 class="text-white">{{ sc_totalFundsapeals | number }} XOF</h6>
                    <h6 class="text-white">Total charges appellées</h6>
                    <i class="feather icon-file-text"></i>
                </app-card>
            </div>
            <div class="col">
                <app-card [hidHeader]="true" cardClass="bg-c-yellow text-white widget-visitor-card" blockClass="text-center">
                    <h6 class="text-white">{{ sc_totalChargesR | number }} XOF</h6>
                    <h6 class="text-white">Total charges réelles</h6>
                    <i class="feather icon-award"></i>
                </app-card>
            </div>
            <div class="col">
                <app-card [hidHeader]="true" cardClass="bg-c-green text-white widget-visitor-card" blockClass="text-center">
                    <h6 class="text-white">{{ sc_totalPayments | number }} XOF</h6>
                    <h6 class="text-white">Total versements reçus</h6>
                    <i class="feather icon-file-text"></i>
                </app-card>
            </div>
            <div class="col">
                <app-card [hidHeader]="true" cardClass="bg-c-red text-red widget-visitor-card" blockClass="text-center">
                    <h6 class="text-white">{{ sc_totalChargesI | number }} XOF</h6>
                    <h6 class="text-white">Total charges imputées</h6>
                    <i class="feather icon-file-text"></i>
                </app-card>
            </div>
            <div class="col">
                <app-card [hidHeader]="true" cardClass="bg-c-blue text-white widget-visitor-card" blockClass="text-center">
                    <h6 class="text-white">{{ sc_totalSolde | number }} XOF</h6>
                    <h6 class="text-white">Total solde</h6>
                    <i class="feather icon-file-text"></i>
                </app-card>
            </div>
        </div>
        <span class="badge badge-primary mb-4 f-14 width">
            SITUATION DES COPROPRIÉTAIRES AU {{ sc_date | date: "d MMMM yyyy" : '' : 'fr-FR' | uppercase }}
        </span>
        <app-card [hidHeader]="true" cardClass="card-datatable">
            <div class="table-responsive m-0" *ngIf="sc_data && sc_data.length > 0">
                <table class="table table-hover text-nowrap table-bordered m-0">
                    <thead>
                        <tr>
                            <th rowspan="2" class="text-center">COPROPRIETAIRES</th>
                            <th rowspan="2" class="text-center">Charges appellées</th>
                            <th rowspan="2" class="text-center">Charges réelles</th>
                            <th rowspan="2" class="text-center">Versements reçus</th>
                            <th rowspan="2" class="text-center">Charges imputées</th>
                            <th rowspan="2" class="text-center">Solde</th>
                            <th colspan="2" class="text-center">Solde</th>
                        </tr>
                        <tr>
                            <th>Débiteur</th>
                            <th>Créditeur</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of sc_data">
                            <td>{{ item?.owner }}</td>
                            <td class="text-right">{{ item?.fundsapeals | number }}</td>
                            <td class="text-right">{{ item?.chargesR | number }}</td>
                            <td class="text-right">{{ item?.payments | number }}</td>
                            <td class="text-right">{{ item?.chargesI | number }}</td>
                            <td class="text-right">{{ item?.solde | number }}</td>
                            <td class="text-right">
                                <span *ngIf="(((item?.chargesR + item?.solde) - item?.payments) < 0)">
                                    {{ ((item?.chargesR + item?.solde) - item?.payments) | number }}
                                </span>
                            </td>
                            <td class="text-right">
                                <span *ngIf="(((item?.chargesR + item?.solde) - item?.payments) > 0)">
                                    {{ ((item?.chargesR + item?.solde) - item?.payments) | number }}
                                </span>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td class="text-center bold">TOTAL</td>
                            <td class="text-right bold">{{ sc_totalFundsapeals | number }}</td>
                            <td class="text-right bold">{{ sc_totalChargesR | number }}</td>
                            <td class="text-right bold">{{ sc_totalPayments | number }}</td>
                            <td class="text-right bold">{{ sc_totalChargesI | number }}</td>
                            <td class="text-right bold">{{ sc_totalSolde | number }}</td>
                            <td class="text-right bold">{{ getScTotalD(sc_data) | number }} </td>
                            <td class="text-right bold">{{ getScTotalC(sc_data) | number }}</td>
                        </tr>
                    </tfoot>
                </table>
            </div>
            <app-no-data *ngIf="sc_data && sc_data.length === 0" [title]="'Aucune donnée trouvée'"></app-no-data>
        </app-card>
    </div>

    <!-- PRODUITS & CHARGES EXEPTIONNELLES -->
    <div *ngIf="type === 'SITUATION_CO_RESERVE_FINAN'" class="col-md-12">
        <div class="row mb-2">
            <div class="col-md-4">
                <app-card [hidHeader]="true" cardClass="bg-c-yellow text-white widget-visitor-card" blockClass="text-center">
                    <h2 class="text-white">{{ scrf_totalFundsapeals_n | number }} XOF</h2>
                    <h6 class="text-white">Total appel de charge émis</h6>
                    <i class="feather icon-file-text"></i>
                </app-card>
            </div>
            <div class="col-md-4">
                <app-card [hidHeader]="true" cardClass="bg-c-green text-white widget-visitor-card" blockClass="text-center">
                    <h2 class="text-white">{{ scrf_totalPayments_n | number }} XOF</h2>
                    <h6 class="text-white">Total versements reçu</h6>
                    <i class="feather icon-award"></i>
                </app-card>
            </div>
            <div class="col-md-4">
                <app-card [hidHeader]="true" cardClass="bg-c-blue text-white widget-visitor-card" blockClass="text-center">
                    <h2 class="text-white">{{ scrf_totalSolde | number }} XOF</h2>
                    <h6 class="text-white">Total solde</h6>
                    <i class="feather icon-file-text"></i>
                </app-card>
            </div>
        </div>
        <span class="badge badge-primary mb-4 f-14 width">
            SITUATION DES COPROPRIÉTAIRES RÉSERVE FINANCIÈRE
        </span>
        <app-card [hidHeader]="true" cardClass="card-datatable">
            <div class="table-responsive m-0" *ngIf="scrf_data && scrf_data.length > 0">
                <table class="table table-hover text-nowrap table-bordered m-0">
                    <thead>
                        <tr>
                            <th rowspan="2" class="text-center">COPROPRIETAIRES</th>
                            <th rowspan="2" class="text-center">Appels de charges émis</th>
                            <th rowspan="2" class="text-center">Versements reçus</th>
                            <th rowspan="2" class="text-center">Solde au {{ scrf_date_n_1 }}</th>
                            <th colspan="2" class="text-center">Solde au {{ scrf_date_n }} </th>
                        </tr>
                        <tr>
                            <th class="text-center">Débiteur</th>
                            <th class="text-center">Créditeur</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of scrf_data">
                            <td>{{ item?.owner }}</td>
                            <td class="text-right">{{ item?.fundsapeals_n | number }}</td>
                            <td class="text-right">{{ item?.payments_n | number}}</td>
                            <td class="text-right">{{ item?.solde | number }}</td>
                            <td class="text-right">
                                <span *ngIf="((item?.fundsapeals_n_1 - item?.payments_n_1) + item?.solde) > 0">
                                    {{ ((item?.fundsapeals_n_1 - item?.payments_n_1) + item?.solde) | number }}
                                </span>
                            </td>
                            <td class="text-right">
                                <span *ngIf="((item?.fundsapeals_n_1 - item?.payments_n_1) + item?.solde) < 0">
                                    {{ ((item?.fundsapeals_n_1 - item?.payments_n_1) + item?.solde)| number }}
                                </span>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td class="text-center bold">TOTAL</td>
                            <td class="text-right bold">{{ scrf_totalFundsapeals_n | number }}</td>
                            <td class="text-right bold">{{ scrf_totalPayments_n | number }}</td>
                            <td class="text-right bold">{{ scrf_totalSolde | number }}</td>
                            <td class="text-right bold">{{ getScTotalD(sc_data) | number }} </td>
                            <td class="text-right bold">{{ getScTotalC(sc_data) | number }}</td>
                        </tr>
                    </tfoot>
                </table>
            </div>
            <app-no-data *ngIf="scrf_data && scrf_data.length === 0" [title]="'Aucune donnée trouvée'"></app-no-data>
        </app-card>
    </div>

    <!-- FONDS DE RESERVE FINANCIERE EN COMPTE -->
    <div *ngIf="type === 'FOND_RESERVE_FINANIERE'" class="col-md-12">
        <div class="row mb-2">
            <div class="col-md-6">
                <app-card [hidHeader]="true" cardClass="bg-c-yellow text-white widget-visitor-card" blockClass="text-center">
                    <h2 class="text-white">999 999 999 999 XOF</h2>
                    <h6 class="text-white">Total encaissés au titre des exercices ci-dessous</h6>
                    <i class="feather icon-file-text"></i>
                </app-card>
            </div>
            <div class="col-md-6">
                <app-card [hidHeader]="true" cardClass="bg-c-green text-white widget-visitor-card" blockClass="text-center">
                    <h2 class="text-white">999 999 999 999 XOF</h2>
                    <h6 class="text-white">Total solde restant</h6>
                    <i class="feather icon-award"></i>
                </app-card>
            </div>
        </div>
        <span class="badge badge-primary mb-4 f-14 width">
            FONDS DE RÉSERVE FINANCIÈRE EN COMPTE
        </span>
        <app-card [hidHeader]="true" cardClass="card-datatable">
            <div class="table-responsive m-0">
                <table class="table table-hover text-nowrap table-bordered m-0">
                    <thead>
                        <tr>
                            <th rowspan="2" class="text-center">COPROPRIETAIRES</th>
                            <th colspan="6" class="text-center">ENCAISSEMENTS AU TITRE DES EXERCICES</th>
                            <th rowspan="2" class="text-center">TRAVAUX DE PEINTURE REALISES</th>
                            <th rowspan="2" class="text-center">SOLDE RESTANT</th>
                        </tr>
                        <tr>
                            <th class="text-center">2018</th>
                            <th class="text-center">2019</th>
                            <th class="text-center">2020</th>
                            <th class="text-center">2021</th>
                            <th class="text-center">2022</th>
                            <th class="text-center">TOTAL</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of ReserveFinaniere">
                            <td>{{item?.coproprietaire}}</td>
                            <td class="text-right">{{item?.milleHuit}}</td>
                            <td class="text-right">{{item?.milleNeuf}}</td>
                            <td class="text-right">{{item?.milleVin}}</td>
                            <td class="text-right">{{item?.milleUn}}</td>
                            <td class="text-right">{{item?.milleDeux}}</td>
                            <td class="text-right">{{item?.total}}</td>
                            <td class="text-right">{{item?.travaux}}</td>
                            <td class="text-right">{{item?.solde}}</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td class="text-center bold">TOTAL</td>
                            <td class="text-right bold">12 376 362</td>
                            <td class="text-right bold">12 376 362</td>
                            <td class="text-right bold">12 376 362</td>
                            <td class="text-right bold">12 376 362</td>
                            <td class="text-right bold">12 376 362</td>
                            <td class="text-right bold">12 376 362</td>
                            <td class="text-right bold">12 376 362</td>
                            <td class="text-right bold">12 376 362</td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </app-card>
    </div>

    <!-- CHARGES -->
    <div *ngIf="type === 'CHARGES'" class="col-md-12">
        <!-- GRAPHE -->
        <div class="mb-2">
            <lib-highcharts-angular [Highcharts]="Highcharts" [options]="barBasicChartOptions"
                style="width: 100%; height: 450px; display: block;">
            </lib-highcharts-angular>
        </div>
        <!-- GRAPHE END -->
        <span class="badge badge-primary mb-4 f-14 width">
            CHARGES SUR {{ charges_annee }}
        </span>
        <app-card [hidHeader]="true" cardClass="card-datatable">
            <div class="table-responsive m-0" *ngIf="charges_data && charges_data.length > 0">
                <table class="table table-hover text-nowrap table-bordered m-0">
                    <thead>
                        <tr>
                            <th class="text-center">PRESTATIONS</th>
                            <th class="text-center">JANVIER</th>
                            <th class="text-center">FEVRIER</th>
                            <th class="text-center">MARS</th>
                            <th class="text-center">AVRIL</th>
                            <th class="text-center">MAI</th>
                            <th class="text-center">JUIN</th>
                            <th class="text-center">JUILLET</th>
                            <th class="text-center">AOUT</th>
                            <th class="text-center">SEPTEMBRE</th>
                            <th class="text-center">OCTOBRE</th>
                            <th class="text-center">NOVEMBRE</th>
                            <th class="text-center">DECEMBRE</th>
                            <th class="text-center">TOTAL</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of charges_data">
                            <td>{{item?.prestation}}</td>
                            <td class="text-right">{{item?.janvier | number}}</td>
                            <td class="text-right">{{item?.fevrier | number}}</td>
                            <td class="text-right">{{item?.mars | number}}</td>
                            <td class="text-right">{{item?.avril | number}}</td>
                            <td class="text-right">{{item?.mai | number}}</td>
                            <td class="text-right">{{item?.juin | number}}</td>
                            <td class="text-right">{{item?.juillet | number}}</td>
                            <td class="text-right">{{item?.aout | number}}</td>
                            <td class="text-right">{{item?.septembre | number}}</td>
                            <td class="text-right">{{item?.octobre | number}}</td>
                            <td class="text-right">{{item?.novembre | number}}</td>
                            <td class="text-right">{{item?.decembre | number}}</td>
                            <td class="text-right">{{item?.total | number}}</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td class="text-center">TOTAL</td>
                            <td class="text-right bold"> {{ charges_total_janvier | number }} </td>
                            <td class="text-right bold"> {{ charges_total_fevrier | number }} </td>
                            <td class="text-right bold"> {{ charges_total_mars | number }} </td>
                            <td class="text-right bold"> {{ charges_total_avril | number }} </td>
                            <td class="text-right bold"> {{ charges_total_mai | number }} </td>
                            <td class="text-right bold"> {{ charges_total_juin | number }} </td>
                            <td class="text-right bold"> {{ charges_total_juillet | number }} </td>
                            <td class="text-right bold"> {{ charges_total_aout | number }} </td>
                            <td class="text-right bold"> {{ charges_total_septembre | number }} </td>
                            <td class="text-right bold"> {{ charges_total_octobre | number }} </td>
                            <td class="text-right bold"> {{ charges_total_novembre | number }} </td>
                            <td class="text-right bold"> {{ charges_total_decembre | number }} </td>
                            <td class="text-right bold"> {{ charges_total | number }} </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
            <app-no-data *ngIf="charges_data && charges_data.length === 0" [title]="'Aucune donnée trouvée'"></app-no-data>
        </app-card>
    </div>

    <!-- DETAIL DU POSTE TRAVAUX IMMEUBLE ET DIVERS -->
    <div *ngIf="type === 'DETAIL'" class="col-md-12">
        <!-- GRAPHE -->
        <div class="mb-2">
            <lib-highcharts-angular [Highcharts]="Highcharts" [options]="barBasicChartOptions"
                style="width: 100%; height: 450px; display: block;">
            </lib-highcharts-angular>
        </div>
        <!-- GRAPHE END -->
        <span class="badge badge-primary mb-4 f-14 width">
            DÉTAIL DU POSTE TRAVAUX IMMEUBLE ET DIVERS ALÉAS SUR {{ detail_annee }}
        </span>
        <app-card [hidHeader]="true" cardClass="card-datatable">
            <div class="table-responsive m-0" *ngIf="detail_data && detail_data.length > 0">
                <table class="table table-hover text-nowrap table-bordered m-0">
                    <thead>
                        <tr>
                            <th class="text-center">PRESTATIONS</th>
                            <th class="text-center">JANVIER</th>
                            <th class="text-center">FEVRIER</th>
                            <th class="text-center">MARS</th>
                            <th class="text-center">AVRIL</th>
                            <th class="text-center">MAI</th>
                            <th class="text-center">JUIN</th>
                            <th class="text-center">JUILLET</th>
                            <th class="text-center">AOUT</th>
                            <th class="text-center">SEPTEMBRE</th>
                            <th class="text-center">OCTOBRE</th>
                            <th class="text-center">NOVEMBRE</th>
                            <th class="text-center">DECEMBRE</th>
                            <th class="text-center">TOTAL</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of detail_data">
                            <td>{{item?.prestation}}</td>
                            <td class="text-right">{{item?.janvier | number}}</td>
                            <td class="text-right">{{item?.fevrier | number}}</td>
                            <td class="text-right">{{item?.mars | number}}</td>
                            <td class="text-right">{{item?.avril | number}}</td>
                            <td class="text-right">{{item?.mai | number}}</td>
                            <td class="text-right">{{item?.juin | number}}</td>
                            <td class="text-right">{{item?.juillet | number}}</td>
                            <td class="text-right">{{item?.aout | number}}</td>
                            <td class="text-right">{{item?.septembre | number}}</td>
                            <td class="text-right">{{item?.octobre | number}}</td>
                            <td class="text-right">{{item?.novembre | number}}</td>
                            <td class="text-right">{{item?.decembre | number}}</td>
                            <td class="text-right">{{item?.total | number}}</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td class="text-center">TOTAL</td>
                            <td class="text-right bold"> {{ detail_total_janvier | number }} </td>
                            <td class="text-right bold"> {{ detail_total_fevrier | number }} </td>
                            <td class="text-right bold"> {{ detail_total_mars | number }} </td>
                            <td class="text-right bold"> {{ detail_total_avril | number }} </td>
                            <td class="text-right bold"> {{ detail_total_mai | number }} </td>
                            <td class="text-right bold"> {{ detail_total_juin | number }} </td>
                            <td class="text-right bold"> {{ detail_total_juillet | number }} </td>
                            <td class="text-right bold"> {{ detail_total_aout | number }} </td>
                            <td class="text-right bold"> {{ detail_total_septembre | number }} </td>
                            <td class="text-right bold"> {{ detail_total_octobre | number }} </td>
                            <td class="text-right bold"> {{ detail_total_novembre | number }} </td>
                            <td class="text-right bold"> {{ detail_total_decembre | number }} </td>
                            <td class="text-right bold"> {{ detail_total | number }} </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
            <app-no-data *ngIf="detail_data && detail_data.length === 0" [title]="'Aucune donnée trouvée'"></app-no-data>
        </app-card>
    </div>

  </div>
  