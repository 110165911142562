import { FormBuilder, FormGroup } from '@angular/forms';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { OnBoardingService } from '@theme/utils/on-boarding.service';
import { SyndicService } from '@service/syndic/syndic.service';
import { Globals } from '@theme/utils/globals';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {
  form: FormGroup
  advance: boolean = true
  cookie: string = ''
  @Input() type: boolean = false
  @Input() rapport: boolean = false  
  @Input() trustee: boolean = false
  @Input() user: boolean = false
  @Input() name: boolean = false
  @Input() bien: boolean = false
  @Input() autre: boolean = false
  @Input() etat: boolean = false
  @Input() dateD: boolean = false
  @Input() dateF: boolean = false
  @Input() create: boolean = false
  @Input() min: boolean = false
  @Input() max: boolean = false
  @Input() ordre: boolean = false
  @Input() code: boolean = false
  @Input() count: boolean = false
  @Input() categorie: boolean = false
  @Input() uuid: string = ""
  @Input() annee: boolean = false
  agency = Globals.user.agencyKey;

  @Input() etatRow
  @Input() typeRow
  @Input() rapportRow
  @Input() trusteeRow = []
  @Input() categorieRow
  @Input() countRow = [
    { label: "Tout", value: 0 },
    { label: "1", value: 1 },
    { label: "5", value: 5 },
    { label: "10", value: 10 },
    { label: "25", value: 25 },
    { label: "50", value: 50 },
    { label: "100", value: 100 },
    { label: "200", value: 200 },
    { label: "500", value: 500 }
  ]

  @Input() bienTitle: string = "Nom du bien"
  @Input() autreTitle: string = "Autre"
  @Input() nameTitle: string = "Nom et prénoms"
  @Input() userTitle: string = "Crée par"
  @Input() refTitle: string = "N° Référence"
  @Input() minTitle: string = "Montant MIN"
  @Input() maxTitle: string = "Montant MAX"
  @Input() categorieTitle: string = "Catégorie"
  @Input() etatTitle: string = "Etat"

  @Output() filterEvent = new EventEmitter<any>()
  @Output() rapportEvent = new EventEmitter<any>()
  @Output() typeEvent = new EventEmitter<any>()
  defaultAnnee: number;
  anneeRow: number[] = [];
  selectedTrustee: any;
  selectedRapport: any;

  constructor(
    private formBuild: FormBuilder,
    private syndicService: SyndicService,
    public boarding: OnBoardingService,
  ) {
    this.year()      
  }

  ngOnInit(): void {
    this.selectedRapport  = null
    if(this.rapport) {
      this.selectedRapport = this.rapportRow[0].value
    }
    this.form = this.formBuild.group({
      uuid: [null],
      type: [this.typeRow ? this.typeRow[0].value : null],
      categorie: [null],
      name: [null],
      autre: [null],
      bien: [null],
      etat: [null],
      dateD: [null],
      dateF: [null],
      ordre: ['DESC'],
      annee: [this.defaultAnnee],
      min: [null],
      max: [null],
      create: [null],
      code: [null],
      count: [10],
      user: [null],
      rapport: [this.selectedRapport],      
      trustee: [this.selectedTrustee],
    })
    if(this.trustee) {
      this.syndicService.getList(this.agency).subscribe(
        (res) => {
          this.trusteeRow = res;
          this.selectedTrustee = res[0].uuid
        },
        (error) => {}
      );
    }
  }

  public types() {
    this.f.categorie.setValue(null);
    this.f.name.setValue(null);
    this.f.autre.setValue(null);
    this.f.bien.setValue(null);
    this.f.etat.setValue(null);
    this.f.dateD.setValue(null);
    this.f.dateF.setValue(null);
    this.f.ordre.setValue('DESC');
    this.f.min.setValue(null);
    this.f.max.setValue(null);
    this.f.create.setValue(null);
    this.f.code.setValue(null);
    this.f.count.setValue(10);
    this.f.user.setValue(null);
    this.f.annee.setValue(null);
    this.f.rapport.setValue(null);    
    this.typeEvent.emit(this.form.value.type)
  }

  syndic() {
    
  }

  public filters(item) {
    if(item == 'filtre') {
      this.filterEvent.emit(this.form.value)
    }else if(item == 'rapport') {
      this.rapportEvent.emit(this.form.value)
    }
  }  

  year(){
    const startYear = 2000;
    const currentYear = 2090;
    const currentYearValue = new Date().getFullYear();

    for (let year = startYear; year <= currentYear; year++) {
      this.anneeRow.push(year);
      if (year === currentYearValue) {
        this.defaultAnnee = year;
      }
    }
  }

  get f() { return this.form.controls }
}
