
<div class="table-responsive m-0">
    <table datatable [dtOptions]="dtOptions" class="table table-striped m-0">
        <thead class="thead-light">
        <tr>
            <th>Date</th>
            <th>Pièce (Réference de l'objet source)</th>
            <th>Compte comptable</th>
            <th>Compte auxiliaire</th>
            <th>Libellé opération</th>
            <th>Mode de réglement</th>
            <th>Débit</th>
            <th>Crédit</th>
        </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of operations">
                <td>
                    <span>
                        {{ item?.dateOp | date:'dd/MM/yyyy' }}
                    </span>
                </td>
                <td>
                    <span>
                        N/A
                    </span>
                </td>
                <td>
                    <span class="badge badge-light-dark" *ngIf="item?.creditedAccount">
                        {{ item?.creditedAccount?.numero}}
                    </span>
                    <span class="badge badge-light-dark" *ngIf="item?.debitedAccount">
                        {{ item?.debitedAccount?.numero}}
                    </span>
                </td>
                <td>
                    <span class="badge badge-light-dark" *ngIf="item?.auxiliaryC">
                        {{ item?.auxiliaryC?.numero}}
                    </span>
                    <span class="badge badge-light-dark" *ngIf="item?.auxiliaryD">
                        {{ item?.auxiliaryD?.numero}}
                    </span>
                </td>
                <td>
                    <span class="title-show text-warning">
                        {{ item?.libelle }}
                    </span>
                </td>
                <td>
                    <span class="badge badge-light-primary">
                        {{ item?.mode }}
                    </span>
                </td>
                <td>
                    <span class="title-result-show text-warning" *ngIf="item?.creditedAmount && !item?.debitedAmount">
                        {{ item?.creditedAmount | number }}
                    </span>
                </td>
                <td>
                    <span class="title-result-show text-warning" *ngIf="!item?.creditedAmount && item?.debitedAmount">
                        {{ item?.debitedAmount | number }}
                    </span>
                </td>
            </tr>
        </tbody>
    </table>
</div>