import {Component, OnInit, EventEmitter, Output, Input} from '@angular/core';
import {UploaderService} from '@service/uploader/uploader.service';
import {environment} from '@env/environment';
import { Observable, throwError } from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import { ApiService } from '@theme/utils/api.service';

@Component({
  selector: 'app-image-uploader',
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.scss']
})
export class ImageUploaderComponent implements OnInit {

  publicUrl = environment.publicUrl;
  error = false;
  background = null;
  folder = null;
  data = null;
  percent = 0;
  success = false;
  downloaded = [];
  files = [];
  isUploading = false;
  private sliceSize = 1024 * 1024 * 2;
  fileId = null;
  allowedPictureType = ['image/png', 'image/jpg', 'image/jpeg', 'image/gif'];

  constructor(
    private uploader: UploaderService,
    private api: ApiService,
  ) {
  }
  @Input() photo = null;
  @Input() name = null;
  @Input() class = null;
  @Input() message = 'Selectionnez une photo';
  @Output() filesd = new EventEmitter()
  @Output() imageUploaded = new EventEmitter();

  ngOnInit(): void {
    if (this.photo && typeof this.photo === 'object') {
      if (this.photo.hasOwnProperty('fullPath')) {
        this.background = 'url(' + this.publicUrl + '/' + this.photo.fullPath + ') no-repeat center/cover';
      }
    }
  }

  uploadImage(files): void {
    if (files.length <= 0) {
      this.message = 'Selectionnez une photo';
      return;
    }
    const file = files[0];

    if (this.allowedPictureType.indexOf(file.type) === -1) {
      this.message = 'Seule les images sont autorisée';
      return;
    }

    const reader = new FileReader();
    reader.addEventListener('load', (e) => {
      this.background = 'url(' + reader.result + ') no-repeat center/cover';
    });
    reader.readAsDataURL(file);

    this.isUploading = true;
    this.error = false;
    this.success = false;

    const datas = {
      file : file,
      id: this.fileId,
      uniqId: this.uploader.generateId(),
      currentChunk: 0,
      start: 0,
      loaded: 0,
      name: this.name,
      entity: this.class,
      end: this.sliceSize,
      sent: 0,
      count: Math.ceil(file.size / this.sliceSize),
      nextSlice: null,
      xhr: null,
      percent: 0,
      refreshAttempt: 0,
    };
    this.send(datas)
  }

  send(todo) {
    todo.end = todo.start + this.sliceSize;
    if (todo.end > todo.file.size) {
      todo.end = todo.file.size;
    }

    const blob = todo.file.slice(todo.start, todo.end);
    let reader = new FileReader();
    reader.onloadend = (ev) => {
      if (ev.target.readyState !== FileReader.DONE) {
        return null;
      }
      const chunk = ev.target.result as string;
      this.filesd.emit(
        this.data = {
          todo: todo,
          chunk: chunk
        }
      );
      this.success = true;
    };
    reader.readAsDataURL(blob);
  }
  removeFile(file): void {
  }
  delete(){
    this.filesd.emit(null);
    this.background = null
    this.message = 'Selectionnez une photo';
    this.success = false
    this.isUploading = false
  }
}
