<div class="table-responsive m-0">
    <table datatable [dtOptions]="dtOptions" class="table table-striped m-0">
        <thead class="thead-light">
        <tr>
            <th>Compte comptable</th>
            <th>Débit</th>
            <th>Crédit</th>
            <th>Solde</th>
        </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of balances">
                <!-- <td>
                    <span>
                        {{item?.code}}
                    </span>
                </td>
                <td>
                    <span>
                        {{item?.libelle}}
                    </span>
                </td>
                <td>
                    <span class="badge badge-dark">
                        {{item?.type}}
                    </span>
                </td>
                <td>
                    <button type="button" (click)="show(item)" class="btn btn-icon btn-secondary" ngbTooltip="Détails">
                        <i class="fas fa-eye"></i>
                    </button>
                </td> -->
            </tr>
        </tbody>
    </table>
</div>