<div class="row justify-content-center">
  <!-- FILTRE DE RECHERCHE -->
  <div class="width btn-filter-owner">
      <app-filter class="width" [name]="name" [nameTitle]="nameTitle" [user]="true" [userTitle]="userTitle"
        [bien]="bien" [bienTitle]="bienTitle" [etat]="etat" [etatRow]="etatRow" [etatTitle]="etatTitle"
        [categorie]="categorie" [categorieRow]="categorieRow" [categorieTitle]="categorieTitle" [dateD]="true" [dateF]="true"
        [create]="true" [min]="min" [minTitle]="minTitle" [max]="max" [maxTitle]="maxTitle" [ordre]="true"
        [code]="false" [count]="true" [type]="true" [typeRow]="typeRow" (typeEvent)="onChangeLoad($event)"
        (filterEvent)="onFilter($event)">
      </app-filter>
  </div>

  <!-- BOUTONS DE GESTION -->
  <div class="col-sm-12 mb-4 btn-all-owner">
    <div class="first">
      <div class="second">
        <div class="btn-owner">
          <div *ngxPermissionsOnly="'OWNER:NEW'" class="btn-group m-1" ngbDropdown
            ngbTooltip="Cliquez ici pour ajouter un propriétaire">
            <button class="btn btn-primary" ngbDropdownToggle type="button">
              Créer un copropriétaire <i class="feather icon-user"></i>
            </button>
            <div ngbDropdownMenu>
              <a class="dropdown-item" (click)="addOwner('PARTICULIER')">PARTICULIER</a>
              <a class="dropdown-item" (click)="addOwner('ENTREPRISE')">ENTREPRISE</a>
            </div>
          </div>
        </div>
        <div class="btn-house">
          <button (click)="addCopropriete()" type="button" class="btn btn-dark m-1"
          ngbTooltip="Cliquez ici pour ajouter un lot">
          Créer un lot <i class="feather icon-home"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="btn-group float-right m-1 btn-outil-owner" ngbDropdown>
      <button class="btn btn-warning" ngbDropdownToggle type="button">
        Outils <i class="fas fa-tools"></i>
      </button>
      <div class="dropdown-menu-right" ngbDropdownMenu>
        <a *ngxPermissionsOnly="['OWNER:PRINTER:LIST']" class="dropdown-item" (click)="onPrinter()"
          ngbTooltip="Cliquez ici pour imprimer le listing">
          Imprimer <i class="feather icon-printer"></i>
        </a>
        <a class="dropdown-item" (click)="onExport()" ngbTooltip="Cliquez ici pour exporter le listing">
          Exporter <i class="fa fa-file-excel"></i>
        </a>
      </div>
    </div>
  </div>

  <!-- LISTE DES PROPRIETAIRES -->
  <div class="width list-owner">
    <ng-template [ngxPermissionsOnly]="['OWNER:LIST']">
      <div class="col-md-12" *ngIf="type === 'PROPRIETAIRE'">
        <span class="badge badge-primary mb-4 f-14 width">
          LISTE DES COPROPRIETAIRES
        </span>
        <app-no-data [title]="'Aucun copropriétaire trouvé'" *ngIf="owners && owners.length === 0"></app-no-data>
        <div class="row" *ngIf="owners && owners.length > 0">
          <div class="col-md-6" *ngFor="let item of owners">
            <app-card [hidHeader]="true" cardClass="user-card user-card-1 mt-4" blockClass="pt-0">
              <div class="user-about-block text-center" (click)="showOwner(item)">
                <div class="row align-items-end">
                  <div class="col text-left pb-3">
                    <span
                      class="badge badge-{{ item.type === 'ENTREPRISE' ? 'info': 'secondary' }}">{{item?.type}}</span>
                  </div>
                  <div class="col">
                    <img *ngIf="item?.type === 'PARTICULIER' && item?.sexe === 'Masculin'"
                      class="img-radius img-fluid wid-80 hei-80"
                      [src]="item.photoSrc ? publicUrl+'/'+item.photoSrc : 'assets/images/avatar-default.png'"
                      onerror="this.onerror=null; this.src='assets/images/avatar-default.png'" />
                    <img *ngIf="item?.type === 'PARTICULIER' && item?.sexe === 'Féminin'"
                      class="img-radius img-fluid wid-80 hei-80"
                      [src]="item.photoSrc ? publicUrl+'/'+item.photoSrc : 'assets/images/avatar-mlle.jpeg'"
                      onerror="this.onerror=null; this.src='assets/images/avatar-mlle.jpeg'" />
                    <img *ngIf="item?.type === 'ENTREPRISE' && item?.photoSrc"
                      class="img-radius img-fluid wid-80 hei-80"
                      [src]="item.photoSrc ? publicUrl+'/'+item.photoSrc : 'assets/images/avatar-default.png'"
                      onerror="this.onerror=null; this.src='assets/images/avatar-default.png'" />
                    <i *ngIf="item?.type === 'ENTREPRISE' && !item?.photoSrc" class="fas fa-building fa-5x"></i>
                  </div>
                  <div class="col text-right pb-3">
                  </div>
                </div>
              </div>
              <div class="text-center" (click)="showOwner(item)">
                <a (click)="showOwner(item)" href="javascript:">
                  <h4 class="mb-1 mt-3">{{item?.nom}}</h4>
                  <p class="mb-3 text-muted">
                    <b><i class="fas fa-qrcode"></i> {{ item.code }}</b>
                    <b class="m-4"><i class="feather icon-phone"></i> {{item?.telephone}}</b>
                  </p>
                </a>
              </div>
              <div class="text-center mt-2">
                  <span class="mb-1">
                      <b>CRÉDIT:</b> {{ item?.credit|number }} XOF, &nbsp;&nbsp;&nbsp; <b>DÉBIT:</b> {{ item?.debit|number }} XOF
                  </span><br/>
              </div>
              <div class="text-right">
                <button *ngxPermissionsOnly="'OWNER:SHOW'" (click)="showOwner(item)" type="button"
                  class="btn btn-icon btn-secondary" ngbTooltip="Détails">
                  <i class="fas fa-eye"></i>
                </button>
                <button *ngxPermissionsOnly="'OWNER:EDIT'" (click)="editOwner(item)" type="button"
                  class="btn btn-icon btn-primary ml-1" ngbTooltip="Modifier">
                  <i class="feather icon-edit-2"></i>
                </button>
                <button *ngxPermissionsOnly="'OWNER:PRINTER:SHOW'" (click)="printerOwner(item)" type="button"
                  class="btn btn-icon btn-warning ml-1" ngbTooltip="Imprimer">
                  <i class="feather icon-printer"></i>
                </button>
                <ng-template ngxPermissionsOnly="OWNER:DELETE">
                  <button *ngIf="item?.isDelete" (click)="delete(item)" type="button"
                    class="btn btn-icon btn-danger ml-1" ngbTooltip="Supprimer">
                    <i class="feather icon-trash"></i>
                  </button>
                </ng-template>
              </div>
            </app-card>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
  <ng-template ngxPermissionsExcept="OWNER:LIST" [ngxPermissionsOnly]="['OWNER:LIST']">
    <div class="col-md-12">
      <app-no-droit *ngIf="type === 'PROPRIETAIRE'" [title]="'liste des copropriétaires'"></app-no-droit>
    </div>
  </ng-template>
  <!-- LISTE DES PROPRIETAIRES -->

  <!-- LISTE DES LOTS -->
  <div class="col-md-12" *ngIf="type === 'LOT'">
    <ng-template [ngxPermissionsOnly]="['OWNER:LIST']">
      <span class="badge badge-secondary mb-4 f-14 width">
        LISTE DES LOTS
      </span>
      <app-no-data [title]="'Aucun lot trouvé'" *ngIf="coproprietes && coproprietes.length === 0"></app-no-data>
      <app-condominium-list [coproprietes]="coproprietes" *ngIf="coproprietes && coproprietes.length > 0" class="width"></app-condominium-list>
    </ng-template>
  </div>
  <ng-template ngxPermissionsExcept="OWNER:LIST" [ngxPermissionsOnly]="['OWNER:LIST']">
    <div class="col-md-12">
      <app-no-droit *ngIf="type === 'LOT'" [title]="'liste des lots'"></app-no-droit>
    </div>
  </ng-template>
  <!-- LISTE DES LOTS -->

  <!-- LISTE DES INFRASTRUCTURES -->
    <div class="col-md-12" *ngIf="type === 'INFRASTRUCTURE'">
      <ng-template [ngxPermissionsOnly]="['INFRASTRUCTURE:TRUSTEE:LIST']">
        <span class="badge badge-secondary mb-4 f-14 width">
          LISTE DES INFRASTRUCTURES
        </span>
        <app-no-data [title]="'Aucune infrastructure trouvée'" *ngIf="infrastructures && infrastructures.length === 0"></app-no-data>
        <div class="col-md-12" *ngIf="infrastructures && infrastructures.length > 0">
          <app-card [hidHeader]="true" cardClass="card-datatable">
            <div class="table-responsive m-0">
                <table datatable [dtOptions]="dtOptions" class="table table-striped m-0">
                    <thead class="thead-light">
                        <tr>
                            <th>Infrastructure</th>
                            <th>Syndic</th>
                            <th>Hauteur/Surface</th>
                            <th>Etat/Type</th>
                            <th>Crée le</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of infrastructures">
                            <td>
                                <span class="text-black">
                                    <div class="d-flex align-items-center">
                                    <div>
                                        <span class="text-black d-block">
                                            {{item?.code}}
                                        </span>
                                        <span class="text-black d-block">
                                            {{item?.nom}}
                                        </span>
                                    </div>
                                    </div>
                                </span>
                            </td>
                            <td>
                                <span class="text-black">
                                    <div class="d-flex align-items-center">
                                    <div>
                                        <span class="text-black d-block">
                                            {{item?.trustee?.code}}
                                        </span>
                                        <span class="text-black d-block">
                                            {{item?.trustee?.nom}}
                                        </span>
                                    </div>
                                    </div>
                                </span>
                            </td>
                            <td>
                                <span class="text-black">
                                    <div class="d-flex align-items-center">
                                    <div>
                                        <span class="text-black d-block">
                                            Hauteur : {{item?.hauteur | number}} m
                                        </span>
                                        <span class="text-black d-block">
                                            Superficie :{{item?.superficie | number}} m²
                                        </span>
                                    </div>
                                    </div>
                                </span>
                            </td>
                            <td>
                                <span class="text-black">
                                    <div class="d-flex align-items-center">
                                    <div>
                                        <span class="text-black d-block">
                                            Etat :
                                            <span *ngIf="item?.etat === 'EN SERVICE'" class="badge badge-success">En service</span>
                                            <span *ngIf="item?.etat === 'HORS SERVICE'" class="badge badge-danger">Hors service</span>
                                        </span>
                                        <span class="text-black d-block">
                                            Type :{{item?.type}}
                                        </span>
                                    </div>
                                    </div>
                                </span>
                            </td>
                            <td>{{item?.createdAt| date: "d MMMM y 'à' H:mm:ss " : '' : 'fr-FR'}}</td>
                            <td>
                                <button (click)="showInfrastructure(item)" type="button" class="btn btn-icon btn-secondary" ngbTooltip="Détails">
                                    <i class="fas fa-eye"></i>
                                </button>
                                <button (click)="editInfrastructure(item)" type="button"class="btn btn-icon btn-primary ml-1" ngbTooltip="Modifier">
                                    <i class="feather icon-edit-2"></i>
                                </button>
                                <button (click)="printInfrastructure(item)" type="button"class="btn btn-icon btn-warning ml-1" ngbTooltip="Imprimer">
                                    <i class="feather icon-printer"></i>
                                </button>
                                <button (click)="deleteInfrastructure(item)" type="button" class="btn btn-icon btn-danger ml-1" ngbTooltip="Supprimer">
                                    <i class="feather icon-trash"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
          </app-card>
        </div>
      </ng-template>
    </div>
    <ng-template ngxPermissionsExcept="INFRASTRUCTURE:TRUSTEE:LIST" [ngxPermissionsOnly]="['INFRASTRUCTURE:TRUSTEE:LIST']">
      <div class="col-md-12">
        <app-no-droit *ngIf="type === 'INFRASTRUCTURE'" [title]="'liste des infrastructures'"></app-no-droit>
      </div>
    </ng-template>
   <!-- LISTE DES INFRASTRUCTURES -->
</div>
