import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {clearTimeout} from 'highcharts';
import {environment} from '@env/environment';
import {EmitterService} from '@service/emitter/emitter.service';

@Component({
  selector: 'app-entity-finder',
  templateUrl: './entity-finder.component.html',
  styleUrls: ['./entity-finder.component.scss']
})
export class EntityFinderComponent implements OnInit {

  @Input() class = null;
  @Input() groups = ['default'];
  @Input() namespace = 'Client';
  @Input() required = false;
  @Input() enabled = true;
  @Input() disabledMessage = 'Désactivé';
  @Input() filterBy: any;
  @Input() label?: any;
  @Input() placeholder = 'Selectionnez un élément';
  @Output() uuid = new EventEmitter();
  @Input() disabled = false;
  @Input() params = [];

  private timeOut = null;
  private throttle = 500;
  public isTyping = false;
  public isSearching = false;
  private request = null;
  public results = [];
  @Input() selected;
  public url = environment.serverUrl;
  public publicUrl = environment.publicUrl;

  constructor(
    private http: HttpClient,
    private emitter: EmitterService,
  ) {

  }

  ngOnInit(): void {
  }

  search(value) {
    this.isTyping = true;
    if (this.timeOut) {
      if (this.request) {
        this.request.unsubscribe();
      }
      clearTimeout(this.timeOut);
    }
    if (value.trim === '') {
      this.isTyping = false;
      return;
    }
    const body = {interface: 'AGENCY', namespace: this.namespace, class: this.class, groups: this.groups, value: value, params: this.params};
    this.timeOut = setTimeout((value) => {
      this.isTyping = false;
      this.isSearching = true;
      this.emitter.disallowLoading();
      this.request = this.http.post(this.url + '/private/extra/shared/', body).subscribe((res: any) => {
        this.isSearching = false;
        if (res?.status === 'success') {
          this.results = res?.data;
        }
      }, (error: any) => {
        this.isSearching = false;
      });
    }, this.throttle);
  }

  select(entity) {
    this.results = [];
    this.selected = entity;
    this.uuid.emit(entity.uuid);
  }

  clear() {
    this.uuid.emit(null);
    this.selected = null;
    this.results = [];
  }

}
