import { Component, OnInit } from '@angular/core';
import { SettingSms } from '@model/setting-sms';
import { SettingService } from '@service/setting/setting.service';
import { SettingMail } from '@model/setting-mail';
import { Setting } from '@model/setting';
import { NgxPermissionsService } from 'ngx-permissions';
import { Equipment } from '@model/equipment';
import { SettingYear } from '@model/setting-year';
import { LoadCategoryService } from '@service/load-category/load-category.service';
import { LoadCategory } from '@model/load-category';
import { EmitterService } from '@service/emitter/emitter.service';

@Component({
  selector: 'app-setting-list',
  templateUrl: './setting-list.component.html',
  styleUrls: ['./setting-list.component.scss']
})
export class SettingListComponent implements OnInit {
  type: string = "GENERAL"
  general: Setting;
  template: SettingMail;
  sms: SettingSms;
  mail: SettingMail;
  years: SettingYear
  equipments: Equipment[] = [];
  loadCategory: LoadCategory[] = [];

  constructor(
    private settingService: SettingService,
    private loadCategoryService: LoadCategoryService,
    private emitter: EmitterService,
    private permissionsService: NgxPermissionsService
  ) {
    const permission = JSON.parse(localStorage.getItem('permission-zen')) ? JSON.parse(localStorage.getItem('permission-zen')) : [];
    this.permissionsService.loadPermissions(permission);
    this.onDisplay(this.type)
  }

  ngOnInit(): void {
    this.emitter.event.subscribe((data) => {
      if (data.action === 'CATEGORY_ADD' || data.action === 'CATEGORY_UPDATE') {
        this.onDisplay('CATEGORY');
      }
    });
  }

  onDisplay(type: string) {
    this.type = type
    if (type === 'GENERAL') {
      this.settingService.type = type
      this.settingService.getSingle().subscribe((res: any)=> { return this.general = res}, error => {})
    } else if(type === 'LENGTH_CODE_COMPTABLE'){
      console.log('type', type)
    } else if(type === 'TANTIEME'){
    }else if (type === 'CATEGORY') {
      this.loadCategoryService.getList().subscribe((res) => {return (this.loadCategory = res);},(error) => { });
    }

  }

  onChange(type: string){
    this.type = type
  }
}
