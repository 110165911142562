<div class="modal-body">
    <div class="col-md-12 text-center">
        <button class="btn btn-dark mr-1" type="button" (click)="add('ENTREPRISE')">Personne morale</button>
        <button class="btn btn-info ml-1" type="button" (click)="add('PARTICULIER')">Personne physique</button>
    </div>
</div>
<div class="modal-footer">
    <button (click)="modale.close('ferme')" type="button" class="btn btn-secondary text-center" data-dismiss="modal">
      Fermer <i class="feather icon-x-circle"></i>
    </button>
</div>