<div class="modal-header">
  <h5 class="modal-title">{{title}}</h5>
  <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close" (click)="onClose()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form autocomplete="off" [formGroup]="form" (ngSubmit)="onConfirme()">
  <div class="modal-body">
    <div class="col-md-12">
      <div class="row">
        <span class="badge badge-primary my-2 f-14 width">
          {{ type === 'ENTREPRISE' ? "DETAILS SUR L'ENTREPRISE" : "DETAILS SUR LE PARTICULIER" }}
        </span>
      </div>
      <div class="form-group">
        <div class="row mb-2">
          <div class="col-md-{{type === 'PARTICULIER' ? 6 : 4}}" *ngIf="type === 'PARTICULIER'">
            <label for="civilite">Civilité </label>
            <select (change)="onSexe()" class="form-control" id="civilite" formControlName="civilite"
              [ngClass]="{'is-invalid': submit && f.civilite.errors,'is-valid': submit && f.civilite.valid }">
              <option *ngFor="let item of civilityRow" [ngValue]="item.value">{{item.label}}</option>
            </select>
            <div class="invalid-feedback" *ngIf="submit && f.civilite.errors">
              <div *ngIf="f.civilite.errors.required">{{required.novide}}</div>
            </div>
          </div>
          <div class="col-md-{{type === 'PARTICULIER' ? 6 : 4}}">
            <label for="nom">
              {{type === 'ENTREPRISE' ? 'Raison sociale' : 'Nom et prénoms' }} <span class="asterix">*</span>
            </label>
            <input type="text" formControlName="nom" class="form-control" id="nom"
              [ngClass]="{'is-invalid': submit && f.nom.errors,'is-valid': submit && f.nom.valid}"
              [placeholder]="type === 'ENTREPRISE' ? 'Raison sociale' : 'Nom et prénoms'" (change)="onNomChange()">
            <div class="invalid-feedback" *ngIf="submit && f.nom.errors">
              <div *ngIf="f.nom.errors.required">{{required.novide}}</div>
            </div>
          </div>
          <div class="col-md-4" *ngIf="type === 'PARTICULIER'">
            <label for="sexe">Sexe </label>
            <input type="text" formControlName="sexe" class="form-control" id="sexe"
              [ngClass]="{'is-invalid': submit && f.sexe.errors,'is-valid': submit && f.sexe.valid}"
              placeholder="Ex: Masculin">
            <div class="invalid-feedback" *ngIf="submit && f.sexe.errors">
              <div *ngIf="f.sexe.errors.required">{{required.novide}}</div>
            </div>
          </div>
          <!--
            <div class="col-md-4" *ngIf="type === 'ENTREPRISE'">
              <label for="ncc">N° Compte contribuable</label>
              <input type="text" formControlName="ncc" class="form-control" id="ncc" placeholder="N° Compte contribuable">
            </div>
            <div class="col-md-4" *ngIf="type === 'ENTREPRISE'">
              <label for="nrc">N° Registre de commerce</label>
              <input type="text" formControlName="nrc" class="form-control" id="nrc"
                placeholder="N° Registre de commerce">
            </div>
          -->
          <div class="col-md-{{type === 'PARTICULIER' ? 4 : 3}}">
            <label for="telephone">
              {{type === 'ENTREPRISE' ? 'Tel. Bureau' : 'Contact' }}
            </label>
            <app-contact-mask [selected]="telephoneSelected" (value)="setContact($event, 'telephone')"
              [name]="'telephone'" [required]="true" [placeholder]="'Votre pays'"></app-contact-mask>
            <div class="invalid-feedback" *ngIf="submit && f?.telephone?.errors">
              <div *ngIf="f.telephone?.errors.required">{{required.novide}}</div>
            </div>
          </div>
          <div class="col-md-3" formGroupName="user">
            <label for="email">E-mail</label>
            <input type="text" formControlName="email" class="form-control" id="email" placeholder="E-mail"
              [ngClass]="{'is-invalid': submit && f.user?.email?.errors,'is-valid': submit && f?.user?.email?.valid}">
            <div class="invalid-feedback" *ngIf="submit && f?.user?.email?.errors">
              <div *ngIf="f.user?.email?.errors.pattern">{{required.noemail}}</div>
            </div>
          </div>
          <div class="col-md-3" *ngIf="type === 'ENTREPRISE'">
            <label for="codePostal">Boîte postale</label>
            <input type="text" formControlName="codePostal" class="form-control" id="codePostal"
              placeholder="Boîte postale">
          </div>
          <div class="col-md-{{type === 'PARTICULIER' ? 4 : 3}}">
            <label for="autorisation">Autorisation de notifier </label>
            <select class="form-control" id="autorisation" formControlName="autorisation"
              [ngClass]="{'is-invalid': submit && f.autorisation.errors,'is-valid': submit && f.autorisation.valid }">
              <option *ngFor="let item of autorisationRow" [ngValue]="item.value">{{item.label}}</option>
            </select>
            <div class="invalid-feedback" *ngIf="submit && f.autorisation.errors">
              <div *ngIf="f.autorisation.errors.required">{{required.novide}}</div>
            </div>
          </div>
          <div class="col-md-4">
            <label for="prix">Compte comptable </label>
            <app-entity-finder [namespace]="'Accounting'" [class]="'Account'" [groups]="['account']" [required]="true"
              [selected]="accountSelected" (uuid)="setAccountUuid($event)"
              [placeholder]="'Selectionez un compte comptable'">
            </app-entity-finder>
          </div>
          <div class="col-md-4">
            <label for="numero">Numéro compte auxiliaire </label>
            <input formControlName="numero" class="form-control" id="numero" [ngClass]="{
                'is-invalid': submit && f.numero.errors,
                'is-valid': submit && f.numero.valid
              }" [placeholder]="'Numéro compte auxiliaire'" />
            <div class="invalid-feedback" *ngIf="submit && f.numero.errors">
              <div *ngIf="f.numero.errors.required">{{ required.novide }}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <span class="badge badge-primary my-2 f-14 width"> QUELQUES DOCUMENTS SUPPLEMENTAIRES </span>
      </div>
      <div class="form-group">
        <div class="row mb-2">
          <div class="flex-grow-0" style="min-width: 30%">
            <app-image-uploader (filesd)="loadfile($event)" (imageUploaded)="setParam('photoUuid',$event)"
              [photo]="edit && owner ? owner.photo : null"></app-image-uploader>
          </div>
          <div class="col-md">
            <label>Pièces et documents à joindre</label>
            <app-folder-uploader (filesd)="files($event)" (filesUploaded)="setParam('folderUuid',$event)"
              [path]="'proprietaire'" [etat]="edit ? 'edit': 'add'" [folder]="edit && owner ? owner?.folder : null">
            </app-folder-uploader>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button (click)="onClose()" type="button" class="btn btn-secondary text-left" data-dismiss="modal">
      Fermer <i class="feather icon-x-circle"></i>
    </button>
    <button (click)="onReset()" type="button" class="btn btn-warning">
      Vider <i class="fas fa-broom"></i>
    </button>
    <button [disabled]="form.invalid" type="submit" class="btn btn-primary">
      Enregistrer <i class="feather icon-save"></i>
    </button>
  </div>
</form>
