import { Globals } from '@theme/utils/globals';
import { Component, OnInit } from '@angular/core';
import { FundRequest } from '@model/fund-request';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ValidatorsEnums } from '@theme/enums/validators.enums';
import { EmitterService } from '@service/emitter/emitter.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FundRequestService } from '@service/fund-request/fund-request.service';
import { TypeLoadService } from '@service/typeLoad/type-load.service';

@Component({
  selector: 'app-trustee-fund-request-disburse',
  templateUrl: './trustee-fund-request-disburse.component.html',
  styleUrls: ['./trustee-fund-request-disburse.component.scss']
})
export class TrusteeFundRequestDisburseComponent implements OnInit {
  title: string = ""
  fund: FundRequest
  form: FormGroup
  submit: boolean = false
  required = Globals.required
  lignes: any[] = []
  currentLigne: any

  constructor(
    public modal: NgbActiveModal,
    private formBuild: FormBuilder,
    private emitter: EmitterService,
    public fundRequestService: FundRequestService,
    public typeLoadService: TypeLoadService
  ) {
    this.fund = this.fundRequestService.getFundRequest()
    this.title = "Ajouter une decaisse de fond"
    this.newForm()
    this.f.fund.setValue(this.fund.uuid)
    // this.typeLoadService.getList(res.trustee.uuid).subscribe((res: any) => {
    //   console.log(res)
    //   this.lignes = res
    // })
  }

  ngOnInit(): void {
  }

  newForm() {
    this.form = this.formBuild.group({
      uuid: [null],
      id: [null],
      montant: [this.fund.montant, [Validators.required]],
      date: [null, [Validators.required]],
      fund: [null, [Validators.required]],
      ligne: [null, [Validators.required]],
    });
  }
  setLigneUuid(uuid) {
    if (uuid) {
      this.f.ligne.setValue(uuid);
    } else {
      this.f.ligne.setValue(null);
    }
  }
  onSubmit() {
    this.submit = true;
    if (!this.form.invalid) {
      const data = this.form.getRawValue();
      this.fundRequestService.disburse(data).subscribe(res => {
        if (res?.status === 'success') {
          this.modal.close('ferme');
            this.emitter.emit({action: 'FUND_DISBURSE', payload: res?.data});
        }
      });
    } else { return; }
  }
  get f() { return this.form.controls; }
}
