import { CondominiumShowComponent } from '@agence/proprietaire/condominium/condominium-show/condominium-show.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SyndicListComponent } from './syndic/syndic-list/syndic-list.component';
import { SyndicShowComponent } from './syndic/syndic-show/syndic-show.component';

const routes: Routes = [
  {path: '', component: SyndicListComponent},
  {path: "show/:id", component: SyndicShowComponent},
  { path: "copropriete/show/:id/:type", component: CondominiumShowComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SyndicRoutingModule { }
