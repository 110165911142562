<div class="modal-header">
  <h5 class="modal-title">{{ title }}</h5>
  <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
    (click)="modale.close('ferme')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="d-flex">
    <!-- COL GAUCHE -->
    <div class="flex-fill">
      <p>
        <span class="title-show">Fournisseur : </span>
        <span class="title-result-show"> {{payment?.provider?.searchableTitle}} </span>
      </p>
      <p>
        <span class="title-show">Periode : </span>
        <span class="title-result-show">établie le {{payment?.date | date:'dd/MM/yyyy'}}</span>
      </p>
      <p>
        <span class="title-show">Etat: </span>
        <span class="badge title-show" [ngClass]="{
          'badge-danger' : payment?.etat === 'INVALIDE',
          'badge-success' : payment?.etat === 'VALIDE'
          }">{{validation(payment?.etat)}}
        </span>
        <span class="ml-2 badge " [ngClass]="{
        'badge-light-success' : payment?.status === 'CONFIRMER',
        'badge-light-danger' : payment?.status === 'ATTENTE'
        }"><small>{{confirmation(payment?.status)}}</small></span>
      </p>
      <p>
        <span class="title-show">N° facture : </span>
        <span class="title-result-show">{{ payment?.invoice?.code }}</span>
      </p>
      <p>
        <span class="title-show">Type de la facture : </span>
        <span class="title-result-show">{{ payment?.invoice?.type }}</span>
      </p>
      <p>
        <span class="title-show">Libellé de la facture : </span>
        <span class="title-result-show">{{ payment?.invoice?.libelle }}</span>
      </p>
    </div>
    <!-- COL DROITE -->
    <div class="flex-fill">
      <p>
        <span class="title-show">Mode de paiement : </span>
        <span class="title-result-show">{{ payment?.mode }}</span>
      </p>
      <p>
        <span class="title-show">Effectué par : </span>
        <span class="title-result-show">{{ payment?.effectue }}</span>
      </p>
      <p>
        <span class="title-show">Tierce : </span>
        <span class="title-result-show">{{ payment?.tiers }}</span>
      </p>
      <p *ngIf="payment?.mode !== 'ESPECE'">
        <span class="title-show">Source : </span>
        <span class="title-result-show">{{ payment?.source }}</span>
      </p>
      <p>
        <span class="title-show">Crée le : </span>
        <span class="title-result-show">{{payment?.createdAt|date: "d MMMM y 'à' h:mm:ss" : '' : 'fr-FR'}}</span>
        <span class="title-show ml-5"> Par : </span>
        <span class="title-result-show">{{ payment?.create }}</span>
      </p>
      <p>
        <span class="title-show">Modifié le : </span>
        <span class="title-result-show">{{ payment?.updatedAt|date: "d MMMM y 'à' h:mm:ss" : '' : 'fr-FR'}}</span>
        <span class="title-show ml-5"> Par : </span>
        <span class="title-result-show">{{payment?.update }}</span>
      </p>
      <p>
        <span class="title-show">validé le : </span>
        <span class="title-result-show">{{ payment?.validateAt|date: "d MMMM y 'à' h:mm:ss" : '' : 'fr-FR'}}</span>
        <span class="title-show ml-5"> Par : </span>
        <span class="title-result-show">{{payment?.validate }}</span>
      </p>
    </div>
  </div>
  <div class="table-responsive">
    <table class="table table-sm table-striped table-bordered nowrap table-hover">
      <thead class="thead-light">
        <tr>
          <th>Désigantion</th>
          <th>Prix u.</th>
          <th>Qte</th>
          <th>Tva</th>
          <th>Remise</th>
          <th>Total</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of payment?.invoice?.options" >
          <td><p class="m-0 d-inline-block align-middle font-16">{{item?.libelle}}</p></td>
          <td>
            <p class="m-0 d-inline-block align-middle font-30"><span class="font-weight-blod">{{item?.prix |number}} {{global.device}}</span></p>
          </td>
          <td>{{item?.qte}}</td>
          <td>{{item?.tva}} %</td>
          <td><p class="m-0 d-inline-block align-middle font-30"><span class="font-weight-blod">{{item?.remise |number }} {{global.device}}</span></td>
          <td><p class="m-0 d-inline-block align-middle font-30"><span class="font-weight-blod">{{item?.total |number}} {{global.device}}</span></td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="row">
    <div class="col-sm-12">
      <table class="table table-responsive invoice-table invoice-total">
        <tbody>
        <tr>
          <th>TOTAL HT : </th>
          <td>{{ payment?.invoice?.montantHt | number }} {{global.device}}</td>
        </tr>
        <tr>
          <th>TOTAL REMISE : </th>
          <td>{{ payment?.invoice?.montantRemise | number }} {{global.device}}</td>
        </tr>
        <tr>
          <th>TOTAL TVA : </th>
          <td>{{ payment?.invoice?.montantTva | number }} {{global.device}}</td>
        </tr>
        <tr>
          <th>TOTAL PAYE : </th>
          <td> {{ payment?.invoice?.paye | number }} {{global.device}}</td>
        </tr>
        <tr>
          <th>TOTAL IMPAYE : </th>
          <td  class="text-danger"> {{ payment?.invoice?.impaye | number }} {{global.device}}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>

  <table class="mb-2 width">
    <tbody>
      <tr class="border-bottom-success">
        <td></td>
        <td colspan="5" class="text-right font-weight-bold"></td>
      </tr>
    </tbody>
    <tfoot>
      <tr class="border border-success">
        <td colspan="5" class="font-weight-bold font-size-default">TOTAL VERSE</td>
        <td class="font-weight-bold font-size-default text-right">{{ payment?.montant | number }} {{global.device}}</td>
      </tr>
    </tfoot>
  </table>
  
  <div class="row mb-2">
    <div class="col-md">
      <span class="badge badge-success mb-4 f-14 width">FICHE DE DOSSIER</span>
      <div class="row">
        <div [ngClass]="file ? 'col-md-4' : 'col-md-12'">
          <app-folder-uploader
            [folder]="payment?.folder" (click)="showFile(payment?.folder)"
            [etat]="'show'"
            [allowFileUpload]="false" [allowDownload]="true">
          </app-folder-uploader>
        </div>
        <div [ngClass]="file ? 'col-md-8' : ''"  *ngIf="file" >
          <div class="row">
            <div class="col-sm-12 mb-2">
              <button (click)="closeViewer()" type="button" class="btn btn-secondary m-1">
                <i class="fa fa-arrow-alt-circle-left"></i> Fermer
              </button>
            </div>
          </div>
          <ngx-doc-viewer
            [url]="file"
            viewer="url" style="width:100%;height: 64vh;">
          </ngx-doc-viewer>
          
        </div>
      </div>  
     
    </div>
  </div>
</div>
<div class="modal-footer">
  <button (click)="modale.close('ferme')" type="button" class="btn btn-secondary text-left" data-dismiss="modal">
    Fermer <i class="feather icon-x-circle"></i>
  </button>
  <ng-template ngxPermissionsOnly="PAYMENT:EDIT">
    <button *ngIf="payment?.etat === 'INVALIDE'" (click)="editPayment(payment)" type="button" class="btn btn-primary">
      Modifier <i class="feather icon-edit-2"></i>
    </button>
  </ng-template>
  <ng-template ngxPermissionsOnly="PAYMENT:PRINTER:SHOW">
    <button *ngIf="payment?.etat === 'VALIDE'" (click)="printerPayment(payment)" class="btn btn-warning">
      Imprimer <i class="feather icon-printer"></i>
    </button>
  </ng-template>
</div>
