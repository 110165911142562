import { SharedModule } from '@theme/shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TinymceModule } from 'angular2-tinymce';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TicketListComponent } from '@reclamation/ticket/ticket-list/ticket-list.component';
import { TicketRoutingModule } from '@reclamation/ticket-routing.module';
import { TicketAddComponent } from '@reclamation/ticket/ticket-add/ticket-add.component';
import { CategoryAddComponent } from './category/category-add/category-add.component';
import { CategoryListComponent } from './category/category-list/category-list.component';
import { DataTablesModule } from 'angular-datatables';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ChatAddComponent } from './chat/chat-add/chat-add.component';




@NgModule({
  declarations: [
    TicketAddComponent,
    TicketListComponent,
    CategoryAddComponent,
    CategoryListComponent,
    ChatAddComponent
  ],
  imports: [
    NgbModule,
    CommonModule,
    SharedModule,
    TinymceModule,
    DataTablesModule,
    TicketRoutingModule,
    NgxPermissionsModule.forRoot()
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ]
})
export class TicketModule { }
