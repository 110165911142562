<div class="modal-header">
  <h5 class="modal-title">{{ title }}</h5>
  <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
    (click)="modale.close('ferme')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="d-flex">
    <!-- COL GAUCHE -->
    <div class="flex-fill">
      <p>
        <span class="title-show">Référence  : </span>
        <span class="title-result-show">{{service?.code }}</span>
      </p>
      <p>
        <span class="title-show">Nom : </span>
        <span class="title-result-show"> {{ service?.nom }} </span>
      </p>
      <p>
        <span class="title-show">Responsable : </span>
        <span class="title-result-show"> {{ service?.responsable?.libelle }} </span>
      </p>
      <p>
        <span class="title-show">Direction : </span>
        <span class="title-result-show"> {{ service?.direction }} </span>
      </p>
    </div>
    <!-- COL DROITE -->
    <div class="flex-fill">
      <p>
        <span class="title-show">Description : </span>
        <span class="title-result-show"> {{ service?.description }} </span>
      </p>
      <p>
        <span class="title-show">Crée le : </span>
        <span class="title-result-show">{{service?.createdAt }}</span>
        <span class="title-show ml-5"> Par : </span>
        <span class="title-result-show">{{ service?.create }}</span>
      </p>
      <p>
        <span class="title-show">Modifié le : </span>
        <span class="title-result-show">{{ service?.updatedAt }}</span>
        <span class="title-show ml-5"> Par : </span>
        <span class="title-result-show">{{service?.update }}</span>
      </p>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button (click)="modale.close('ferme')" type="button" class="btn btn-secondary text-left" data-dismiss="modal">
    Fermer <i class="feather icon-x-circle"></i>
  </button>
  <button (click)="editService(service)" type="button" class="btn btn-primary">
    Modifier <i class="feather icon-edit-2"></i>
  </button>
  <button  (click)="printerService(service)" class="btn btn-warning">
    Imprimer <i class="feather icon-printer"></i>
  </button>
</div>
