import { Supply } from '@model/supply';
import { Treasury } from '@model/treasury';
import { Globals } from '@theme/utils/globals';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SupplyService } from '@service/supply/supply.service';
import { ValidatorsEnums } from '@theme/enums/validators.enums';
import { EmitterService } from '@service/emitter/emitter.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TreasuryService } from '@service/treasury/treasury.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-trustee-supply-add',
  templateUrl: './trustee-supply-add.component.html',
  styleUrls: ['./trustee-supply-add.component.scss']
})
export class TrusteeSupplyAddComponent implements OnInit {
  sourceTitle: string = ""
  numeroTitle: string = ""
  title: string = ""
  edit: boolean = false
  treasury: boolean = false
  supply: Supply
  form: FormGroup
  submit: boolean = false
  emetteurSelected: any;
  recepteurSelected: any;
  treasuryR: Treasury
  treasuryE: Treasury
  required = Globals.required
  modeRow = [
    { label: "ESPECE", value: "ESPECE" },
    { label: "CHEQUE", value: "CHEQUE" },
    { label: "MOBILE MONEY", value: "MOBILE MONEY" },
    { label: "VIREMENT", value: "VIREMENT" }
  ]

  constructor(
    public modal: NgbActiveModal,
    private formBuild: FormBuilder,
    public supplyService: SupplyService,
    public treasuryService: TreasuryService,
    private emitter: EmitterService
    ) {
    this.edit = this.supplyService.edit
    this.supply = this.supplyService.getSupply()
    this.title = (!this.edit) ? "Ajouter un approvisionnement" : "Modifier l'approvisionnement "+this.supply.code
    this.newForm()
    // this.setTreasury()
  }

  ngOnInit(): void {
    this.editForm()
  }
  newForm() {
    this.form = this.formBuild.group({
      uuid: [null],
      id: [null],
      mode: ['ESPECE', [Validators.required]],
      libelle: [null, [Validators.required]],
      emetteur: [null, [Validators.required]],
      recepteur: [null, [Validators.required]],
      source: [null],
      numero: [null],
      motif: [null, [Validators.required]],
      montant: [0, [Validators.required, Validators.pattern(ValidatorsEnums.number), Validators.min(0)]],
      date: [null, [Validators.required]],
      concern: ['TRUSTEE', [Validators.required]],
    });
  }
  editForm() {
    if (this.edit) {
      const data = { ...this.supplyService.getSupply() }
      this.form.patchValue(data)
    }
  }
  setTreasury(){
    if(this.supplyService.treasury){
      this.treasury = true
      this.f.emetteur.setValue(this.supplyService.treasury);
      this.treasuryService.getSingle(this.supplyService.treasury).subscribe((res) => {
        this.setCurrentTreasuryEmetteur(res)
        this.treasuryE = res;
      });
    }
  }
  setTreasuryEmetteurUuid(uuid): void {
    if (uuid) {
      this.f.emetteur.setValue(uuid);
      this.treasuryService.getSingle(this.f.emetteur.value).subscribe((res) => {
        this.treasuryE = res;
      });
    } else{
      this.f.emetteur.setValue(null);
      this.treasuryE = null;
    }
  }
  setTreasuryRecepteurUuid(uuid): void {
    if (uuid) {
      this.f.recepteur.setValue(uuid);
      this.treasuryService.getSingle(this.f.recepteur.value).subscribe((res) => {
        this.treasuryR = res;
      });
    } else{
      this.f.recepteur.setValue(null);
      this.treasuryR = null;
    }
  }
  setCurrentTreasuryEmetteur(treasury): void {
    this.setTreasuryEmetteurUuid(treasury?.uuid);
    this.emetteurSelected = {
      photoSrc: treasury?.photoSrc,
      title: treasury?.searchableTitle,
      detail: treasury?.searchableDetail
    };
  }
  setCurrentTreasuryRecepteur(treasury): void {
    this.setTreasuryRecepteurUuid(treasury?.uuid);
    this.recepteurSelected = {
      photoSrc: treasury?.photoSrc,
      title: treasury?.searchableTitle,
      detail: treasury?.searchableDetail
    };
  }
  onChangeLibelle() {
    if(this.f.mode.value === 'VIREMENT'){
      this.numeroTitle = "N° virement"
      this.sourceTitle = "Banque"
    }
    if(this.f.mode.value === 'CHEQUE'){
      this.sourceTitle = "Banque"
      this.numeroTitle = "N° cheque"
    }
    if(this.f.mode.value === 'MOBILE MONEY'){
      this.sourceTitle = "N° Téléphone"
      this.numeroTitle = "N° mobile"
    }
    this.f.source.setValue(null)
    this.f.numero.setValue(null)
  }

  checkSenderAndReceiver(uuid) {

  }

  onSubmit() {
    this.submit = true;
    if (!this.form.invalid) {
      const data = this.form.getRawValue();
      this.supplyService.add(data).subscribe(res => {
        if (res?.status === 'success') {
          this.modal.close('ferme');
          if (data?.uuid) {
            this.emitter.emit({action: 'SUPPLY_UPDATED', payload: res?.data});
          } else {
            this.emitter.emit({action: 'SUPPLY_ADD', payload: res?.data});
          }
        }
      });
    } else { return; }
  }
  onConfirme() {
    Swal.fire({
      title: '',
      text: 'Confirmez-vous l\'enregistrement ?',
      icon: 'warning',
      showCancelButton: true,
      showCloseButton: true,
      confirmButtonText: 'Confirmer <i class="fas fa-check"></i>',
      cancelButtonText: 'Annuler <i class="feather icon-x-circle"></i>',
      confirmButtonColor: '#1bc943',
      reverseButtons: true
    }).then((willDelete) => {
      if (!willDelete.dismiss) {
        this.onSubmit();
      }
    });
  }
  get f() { return this.form.controls; }
}
