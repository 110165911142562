import { Spent } from '@model/spent';
import { Treasury } from '@model/treasury';
import { ToastrService } from 'ngx-toastr';
import { Globals } from '@theme/utils/globals';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SpentService } from '@service/spent/spent.service';
import {ValidatorsEnums} from '@theme/enums/validators.enums';
import { EmitterService } from '@service/emitter/emitter.service';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TreasuryService } from '@service/treasury/treasury.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ProductService } from '@service/product/product.service';
import { SyndicService } from '@service/syndic/syndic.service';
import { HouseCoService } from '@service/syndic/house-co.service';
import { HomeCoService } from '@service/syndic/home-co.service';
import { InfrastructureService } from '@service/syndic/infrastructure.service';
import { DateHelperService } from '@theme/utils/date-helper.service';
import { TypeLoadService } from '@service/typeLoad/type-load.service';
import { PlanComptableService } from '@service/configuration/plan-comptable.service';
import { OptionBudgetService } from '@service/option-budget/option-budget.service';

@Component({
  selector: 'app-trustee-spent-add',
  templateUrl: './trustee-spent-add.component.html',
  styleUrls: ['./trustee-spent-add.component.scss']
})
export class TrusteeSpentAddComponent implements OnInit {
  title: string = ""
  edit: boolean = false
  form: FormGroup
  submit: boolean = false
  spent: Spent
  treasury: Treasury
  totalHT = 0;
  totalTva = 0;
  totalTTC = 0;
  totalRemise = 0;
  required = Globals.required;
  prioriteRow = [
    {label: 'NON PRIORITAIRE', value: 'NON'},
    {label: 'PRIORITE MOYEN', value: 'MOYEN'},
    {label: 'URGENT', value: 'URGENT'}
  ]
  lignes: any[] = []
  currentLigne: any
  currentAccount: any
  currentProduct: any
  entityFinder: boolean[] = []

  lots: any[] = []
  houses: any[] = []
  homes: any[] = []
  infrastructures: any[] = []

  selectedProducts: any[] = []
  selectedAccounts: any[] = []

  accounts: any[] = []
  products: any[] = []
  userSession = Globals.user
  editAccount: boolean = false
  selectedLot: any

  constructor(
    public modal: NgbActiveModal,
    private formBuild: FormBuilder,
    public spentService: SpentService,
    public treasuryService: TreasuryService,
    public toastr: ToastrService,
    private route: ActivatedRoute,
    private emitter: EmitterService,
    private productService: ProductService,
    private syndicService: SyndicService,
    private houseCoService: HouseCoService,
    private homeCoService: HomeCoService,
    private infrastructureService: InfrastructureService,
    private typeLoadService: TypeLoadService,
    private planComptableService: PlanComptableService,
    private optionBudgetService: OptionBudgetService
  ) {
    this.edit = this.spentService.edit
    this.spent = this.spentService.getSpent()
    this.title = (!this.edit) ? "Ajouter une dépense" : "Modifier la dépense de " + this.spent?.code
    this.treasuryService.getSingle(this.spentService.treasury).subscribe((res: any) => {
      if (res) { 
        this.treasury = res; 
        this.houseCoService.getList(res.trustee.uuid).subscribe((res: any) => {
          if (res) {
            this.houses = res
            this.houses.forEach((house) => {
              this.lots.push({
                house: {
                  uuid: null,
                  nom: null,
                },
                uuid: house.uuid,
                nom: house.nom,
                type: "HOUSE_CO"
              })

              let homes = house.homesCo
              if (homes && homes.length > 0) {
                homes.forEach((home) => {
                  this.lots.push({
                    house: {
                      uuid: house.uuid,
                      nom: house.nom,
                    },
                    uuid: home.uuid,
                    nom: house.nom + ' - ' + home.nom + ' - ' + home.owner.nom,
                    type: "HOME_CO"
                  })
                })
              }
            })
          }
          console.log(this.lots)
        })
        this.infrastructureService.getList(res.trustee.uuid).subscribe((res: any) => {
          if (res) {
            this.infrastructures = res
          }
        })
        // this.typeLoadService.getList(null, null, null, res.trustee.uuid).subscribe((res: any) => {
        //   this.lignes = res
        // })
        this.optionBudgetService.getList(res.trustee.uuid).subscribe((res: any) => {
          console.log(res)
          if (res) {
            res.forEach((option) => {
              this.lignes.push({
                uuid: option.uuid,
                libelle: option.typeLoad.searchableTitle
              })
            })
          }
        })

        this.productService.getList().subscribe((res) => {
          if (res) {
            this.products = res
          }
        })

        this.planComptableService.getList(this.userSession.agencyKey).subscribe((res) => {
          if (res) {
            res.forEach((account) => {
              if (account.etat === "ACTIF") {
                this.accounts.push(account)
              }
            })
          }
        })
      }
    });
    this.newForm()
    this.f.treasury.setValue(this.spentService.treasury)
    this.f.trustee.setValue(this.syndicService.syndic)
  }

  ngOnInit(): void {
    this.editForm();
  }

  newForm() {
    let firstOption = this.formBuild.group({
        uuid: [null],
        id: [null],
        libelle: [null, [Validators.required]],
        prix: [0, [Validators.required, Validators.pattern(ValidatorsEnums.number), Validators.min(0)]],
        qte: [1, [Validators.required, Validators.pattern(ValidatorsEnums.number), Validators.min(1)]],
        tva: [0, [Validators.required, Validators.pattern(ValidatorsEnums.number), Validators.min(0)]],
        remise: [0, [Validators.required, Validators.pattern(ValidatorsEnums.number), Validators.min(0)]],
        total: [{value: 0, disabled: true}, [Validators.required, Validators.pattern(ValidatorsEnums.number), Validators.min(0)]],
        product: [null],
        account: [null],
        auxiliary: [null]
    })
  
    this.form = this.formBuild.group({
      uuid: [null],
      id: [null],
      treasury: [null, Validators.required],
      montant: [null, Validators.required],
      date: [null, Validators.required],
      motif: [null, Validators.required],
      priorite: [null, Validators.required],
      description: [null],
      montantHt: [0],
      montantTva: [0],
      montantRemise: [0],
      options: this.edit ? this.formBuild.array([]) : this.formBuild.array([firstOption]),
      ligne: [null, Validators.required],
      trustee: [null, Validators.required],
      concern: ['TRUSTEE', Validators.required],
      houseCo: [null],
      homeCo: [null],
      infrastructure: [null]
    });
    if (!this.edit) {
      this.entityFinder.push(false)
    }
  }
  editForm() {
    if (this.edit) {
      const data = {...this.spentService.getSpent()}
      console.log(data)
      data.date = DateHelperService.fromJsonDate(data.date);
      // data.houseCo = data.houseCo ? data.houseCo.uuid : null
      // data.homeCo = data.homeCo ? data.homeCo.uuid  : null
      data.infrastructure = data.infrastructure ? data.infrastructure.uuid : null
      this.form.patchValue(data)
      // this.typeLoadService.getList(null, null, null, data.trustee.uuid).subscribe((res) => {
      //   if (res) {
      //     this.lignes = res
      //   }
      // }) 
      this.optionBudgetService.getList(data.trustee.uuid).subscribe((res: any) => {
        console.log(res)
        if (res) {
          res.forEach((option) => {
            this.lignes.push({
              uuid: option.uuid,
              libelle: option.typeLoad.searchableTitle
            })
          })
        }
      })
      this.f.houseCo.setValue(data.houseCo ? data.houseCo.uuid : null)
      this.f.homeCo.setValue(data.homeCo ? data.homeCo.uuid : null)
      this.f.ligne.setValue(data.ligne.uuid)
      data.options.forEach((option: any) => {
        // this.entityFinder.push(option.product ? true : false)
        // let product = {
        //   title: option.product ? option.product.searchableTitle : null,
        //   detail: option.product ? option.product.searchableDetail : null
        // }
        // this.selectedProducts.push(option.product ? product : null)
        // let account = {
        //   title: option.account ? option.account.searchableTitle : null,
        //   detail: option.account ? option.account.searchableDetail : null
        // }
        // this.selectedAccounts.push(option.account ? account : null)

        if (option.product == null) {
          this.products.push({ uuid: option.libelle, libelle: option.libelle, prix: option.prix, tag: true })
        }
        this.option.push(
          this.formBuild.group({
            uuid: [option.uuid],
            id: [option.id],
            libelle: [option.libelle],
            prix: [option.prix],
            qte: [option.qte],
            tva: [option.tva],
            remise: [option.remise],
            total: [option.total],
            product: [option.product ? option.product.uuid : null],
            // account: [option.account ? option.account.uuid : null],
            account: [option.product ? option.product.auxiliairy.uuid : (option.account ? option.account.uuid : null)],
          })
        )
      })
      this.onChangeTotal()
      console.log(this.form.getRawValue())
    }
  }
  addProduct(term: any) {
    return { uuid: term, libelle: term, prix: 0, tag: true }
  };
  onSelectChange(item, event: any) {
    console.log('Selected option:', event);
    if (item && event) {
      if(event.tag ){
        item.get('libelle').setValue(event.libelle)
        item.get('product').setValue(null);
        item.get('prix').setValue(event.prix);
      }else{
        item.get('product').setValue(event.uuid);
        item.get('libelle').setValue(event.libelle)
        item.get('prix').setValue(event.prix)
        this.productService.getSingle(event.uuid).subscribe((res: any) => {
          if (res) {
            this.currentProduct = res
            console.log(res)
            let account = this.currentProduct.auxiliairy ? this.currentProduct.auxiliairy.uuid : null
            item.get('account').setValue(account)
            this.editAccount = item.get('product').value && account ? true : false
          }
        })
      }
      this.onChangeTotal()
    } else {
      item.get('product').setValue(null);
      item.get('libelle').setValue(null)
      item.get('prix').setValue(null)
      item.get('account').setValue(null)
      this.currentProduct = null;
      this.onChangeTotal()
    }
  }
  onChangeTotal() {
    let totalOptionRemise = 0;
    let totalOptionHT = 0;
    let totalOptionTVA = 0;
    let totalOptionTTC = 0;
    this.option.controls.forEach(elem => {
      var remise = elem.value.remise >= 0 ? elem.value.remise : 0
      var totalHt = (elem.value.prix * elem.value.qte) - remise
      var totalTva = elem.value.tva >= 0 ? totalHt * (elem.value.tva / 100) : 0
      var totalTtc = totalHt + totalTva
      elem.get('total').setValue(totalTtc);
      totalOptionRemise += remise;
      totalOptionHT += (elem.value.qte >= 1 && (remise <= totalHt)) ? totalHt - remise : 0;
      totalOptionTVA += totalTva;
      totalOptionTTC += totalTtc
    });

    this.totalHT = totalOptionHT;
    this.totalTva = totalOptionTVA;
    this.totalRemise = totalOptionRemise;
    this.totalTTC = totalOptionHT + totalOptionTVA + totalOptionRemise
    this.f.montantHt.setValue(totalOptionHT);
    this.f.montantTva.setValue(totalOptionTVA);
    this.f.montantRemise.setValue(totalOptionRemise);
    this.f.montant.setValue(totalOptionHT + totalOptionTVA + totalOptionRemise);
  }
  addOption() {
    this.option.push(
      this.formBuild.group({
        uuid: [null],
        id: [null],
        libelle: [null, [Validators.required]],
        prix: [0, [Validators.required, Validators.pattern(ValidatorsEnums.number), Validators.min(0)]],
        qte: [1, [Validators.required, Validators.pattern(ValidatorsEnums.number), Validators.min(1)]],
        tva: [0, [Validators.required, Validators.pattern(ValidatorsEnums.number), Validators.min(0)]],
        remise: [0, [Validators.required, Validators.pattern(ValidatorsEnums.number), Validators.min(0)]],
        total: [{value: 0, disabled: true}, [Validators.required, Validators.pattern(ValidatorsEnums.number), Validators.min(0)]],
        product: [null],
        account: [null],
        // auxiliary: [null]
      })
    );
    this.entityFinder.push(false)
  }
  onDelete(row) {
    var index = this.option.controls.indexOf(row);
    this.option.controls.splice(index, 1);
    this.onChangeTotal();
  }
  setProductUuid(item, uuid) {
    if (item && uuid) {
      item.get('product').setValue(uuid);
      this.productService.getSingle(uuid).subscribe((res: any) => {
        if (res) {
          this.currentProduct = res
          console.log(res)
          let account = this.currentProduct.auxiliairy ? this.currentProduct.auxiliairy.numero : "N/A"
          item.get('libelle').setValue(this.currentProduct.libelle)
          item.get('prix').setValue(this.currentProduct.prix)
          item.get('account').setValue(account)
        }
      })
    } else {
      item.get('product').setValue(null);
      item.get('libelle').setValue(null)
      item.get('prix').setValue(null)
      item.get('account').setValue(null)
      this.currentProduct = null;
    }
  }
  setAccountUuid(item, uuid) {
    if (item && uuid) {
      item.get('account').setValue(uuid);
    } else {
      item.get('account').setValue(null);
    }
  }
  setLigneUuid(uuid) {
    if (uuid) {
      this.f.ligne.setValue(uuid);
    } else {
      this.f.ligne.setValue(null);
    }
  }
  toggleEntityFinder(item, i) {
    item.get('libelle').setValue(null)
    item.get('product').setValue(null)
    this.entityFinder[i] = !this.entityFinder[i];
  }
  getEntityFinder(i) {
    return this.entityFinder[i];
  }
  getSelectedProducts(i) {
    return this.selectedProducts[i]
  }
  getSelectedAccounts(i) {
    return this.selectedAccounts[i]
  }
  onChangeLot(data) {
    console.log(data)
    if (data.uuid) {
      if (data.type === "HOUSE_CO") {
        this.f.houseCo.setValue(data.uuid)
      }
      if (data.type === "HOME_CO") {
        this.onChangeHomeCo(data.uuid)
        this.f.houseCo.setValue(null)
        this.f.homeCo.setValue(data.uuid)
      }
    }
  }
  onChangeHouseCo(uuid) {
    if (uuid) {
      this.homeCoService.getList(this.spentService.treasury, uuid).subscribe((res) => {
        console.log(res)
        if (res) {
          this.f.homeCo.setValue(null)
          this.f.infrastructure.setValue(null)
          this.homes = res
        }
      })
    } else {
      this.f.houseCo.setValue(null)
    }
  }
  onChangeHomeCo(uuid) {
    if (uuid) {
      this.infrastructureService.getList(null, uuid).subscribe((res) => {
        console.log(res)
        if (res) {
          this.f.infrastructure.setValue(null)
          this.infrastructures = res
        }
      })
    } else {
      this.f.homeCo.setValue(null)
    }
  }
  onChangeInfrastructure(uuid) {
    if (uuid) {
      this.f.infrastructure.setValue(uuid)
    } else {
      this.f.infrastructure.setValue(null)
    }
  }
  onSubmit() {
    this.submit = true;
    if (!this.form.invalid) {
      const data = this.form.getRawValue();
        this.spentService.add(data).subscribe(res => {
          if (res?.status === 'success') {
            this.modal.close('ferme');
            if (data?.uuid) {
              this.emitter.emit({action: 'SPENT_UPDATED', payload: res?.data});
            } else {
              this.emitter.emit({action: 'SPENT_ADD', payload: res?.data});
            }
          }
        });
    } else { return; }
  }
  onConfirme() {
    Swal.fire({
      title: '',
      text: 'Confirmez-vous l\'enregistrement ?',
      icon: 'warning',
      showCancelButton: true,
      showCloseButton: true,
      confirmButtonText: 'Confirmer <i class="fas fa-check"></i>',
      cancelButtonText: 'Annuler <i class="feather icon-x-circle"></i>',
      confirmButtonColor: '#1bc943',
      reverseButtons: true
    }).then((willDelete) => {
      if (!willDelete.dismiss) {
        this.onSubmit();
      }
    });
  }
  onClose(){
    this.form.reset()
    this.modal.close('ferme');
  }
  toast(msg, title, type) {
    if (type == 'info') {
      this.toastr.info(msg, title);
    } else if (type == 'success') {
      this.toastr.success(msg, title);
    } else if (type == 'warning') {
      this.toastr.warning(msg, title);
    } else if (type == 'error') {
      this.toastr.error(msg, title);
    }
  }
  checkIt() {
    console.log(this.form.getRawValue())
  }
  get f() { return this.form.controls; }
  get option() { return this.form.get('options') as FormArray; }
}
