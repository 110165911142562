<div id="container" *ngIf="isLoading && isLoading === true">
  <div class="center">
      <div class="lds-spinner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div id="texte">
        <div class="content">{{text}}</div>
      </div>
  </div>
</div>
