<div class="row justify-content-center">
    <!-- FILTRE DE RECHERCHE -->
    <div class="width btn-filter-achat">
        <app-filter class="width"
            [name]="true"
            [nameTitle]="nameTitle"
            [user]="true"
            [userTitle]="userTitle"
            [etat]="true"
            [etatRow]="etatRow"
            [etatTitle]="etatTitle" [categorie]="true" [categorieRow]="categorieRow" [categorieTitle]="categorieTitle" [dateD]="true" [dateF]="true" [create]="true"
            [min]="true" [minTitle]="minTitle" [max]="true" [maxTitle]="maxTitle" [ordre]="true" [code]="true" [count]="true" [type]="true" [typeRow]="typeRow" (typeEvent)="onChangeLoad($event)" (filterEvent)="onFilter($event)">
        </app-filter>
    </div>


    <div class="width list-achat">
        <div *ngIf="type === 'ACHAT'" class="col-md-12">
            <span class="badge badge-primary mb-4 f-14 width">
                LISTE DES ACHATS
            </span>
            <div>
                <app-card [hidHeader]="true" cardClass="card-datatable">
                    <div class="table-responsive m-0">
                        <table datatable [dtOptions]="dtOptions" class="table table-striped m-0">
                            <thead class="thead-light">
                            <tr>
                                <th>Date</th>
                                <th>Pièce (Réf. facture)</th>
                                <th>Compte comptable</th>
                                <th>Compte auxiliaire</th>
                                <th>Libellé opération</th>
                                <th>Débit</th>
                                <th>Crédit</th>
                            </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of achats">
                                    <!-- <td>
                                        <span>
                                            {{item?.code}}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {{item?.libelle}}
                                        </span>
                                    </td>
                                    <td>
                                        <span class="badge badge-dark">
                                            {{item?.type}}
                                        </span>
                                    </td>
                                    <td>
                                        <button type="button" (click)="show(item)" class="btn btn-icon btn-secondary" ngbTooltip="Détails">
                                            <i class="fas fa-eye"></i>
                                        </button>
                                    </td> -->
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </app-card>
            </div>
        </div>
    </div>

    <div class="width list-vente">
        <div  *ngIf="type === 'VENTE'" class="col-md-12">
            <span class="badge badge-primary mb-4 f-14 width">
                LISTE DES VENTES
            </span>
            <div>
                <app-card [hidHeader]="true" cardClass="card-datatable">
                    <app-vente></app-vente>
                </app-card>
            </div>
        </div>
    </div>

    <div class="width list-tresorerie">
        <div *ngIf="type === 'TRESORERIE'" class="col-md-12">
            <span class="badge badge-primary mb-4 f-14 width">
                LISTE DES TRESORERIES
            </span>
            <div>
                <app-card [hidHeader]="true" cardClass="card-datatable">
                    <app-tresorerie *ngIf="type === 'TRESORERIE' && operationsT && operationsT.length > 0" [operations]="operationsT"></app-tresorerie>
                    <app-no-data *ngIf="type === 'TRESORERIE' && operationsT && operationsT.length === 0" [title]="'Aucune donnée trouvée'"></app-no-data>
                </app-card>
            </div>
        </div>
    </div>


    <div class="width list-frais">
        <div  *ngIf="type === 'NOTE_FRAIS'" class="col-md-12">
            <span class="badge badge-primary mb-4 f-14 width">
                LISTE DES NOTES DE FRAIS
            </span>
            <div>
                <app-card [hidHeader]="true" cardClass="card-datatable">
                    <app-note-frais></app-note-frais>
                </app-card>
            </div>
        </div>
    </div>

</div>