import { Component, Input, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { CategoryService } from '@service/category/category.service';
import { DateHelperService } from '@theme/utils/date-helper.service';
import { Globals } from '@theme/utils/globals';
import { CategoryAddComponent } from '../category-add/category-add.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EmitterService } from '@service/emitter/emitter.service';

@Component({
  selector: 'app-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.scss']
})
export class CategoryListComponent implements OnInit {

  dtOptions: any = {};
  @Input() categories = [];

  constructor(
    private categoryService: CategoryService,
    private modalService: NgbModal,
    private emitter: EmitterService) {
  }

  ngOnInit(): void {
    this.dtOptions = Globals.dataTable
    this.emitter.event.subscribe((data) => {
      if (data.action === 'CATEGORY_ADD') {
        this.appendToList(data.payload);
      }
      if (data.action === 'CATEGORY_UPDATED') {
        this.update(data.payload);
      }
    });
  }

  delete(item) {
    Swal.fire({
      title: '',
      text: 'Voulez-vous vraiment supprimer cette catégorie ?',
      icon: '',
      showCancelButton: true,
      showCloseButton: true,
      cancelButtonText: 'Annuler',
      confirmButtonText: 'Supprimer <i class="feather icon-trash"></i>',
      confirmButtonColor: '#d33',
      timer: 2500
    }).then((willDelete) => {
      if (willDelete.dismiss) {
      } else {
        this.categoryService.getDelete(item.uuid).subscribe(res => {
          if (res?.code === 200) {
            const index = this.categories.findIndex(x => x.uuid === item.uuid);
            if (index !== -1) {
              this.categories.splice(index, 1);
            }
          }
        }, error => {
        });
      }
    });
  }

  appendToList(row) {
    this.categories.unshift(row);
  }

  update(category): void {
    const index = this.categories.findIndex(x => x.uuid === category.uuid);
    if (index !== -1) {
      this.categories[index] = category;
    }
  }

  editCategory(row) {
    this.categoryService.setCategory(row);
    this.categoryService.edit = true;
    this.modal(CategoryAddComponent, 'modal-basic-title', 'md', true, 'static');
  }

  formatDate(data){
    return DateHelperService.readable(data);
  }

  modal(component, type, size, center, backdrop) {
    this.modalService.open(component, {
      ariaLabelledBy: type,
      size: size,
      centered: center,
      backdrop: backdrop
    }).result.then((result) => { }, (reason) => { });
  }

}
