<div class="app-wrapper bg-light">
    <div class="app-main">
      <div class="app-content p-0">
        <div class="app-inner-content-layout--main">
          <div class="flex-grow-1 w-100 d-flex align-items-center">
            <div class="bg-composed-wrapper--content">
              <div class="hero-wrapper bg-composed-wrapper min-vh-100">
                <div class="flex-grow-1 w-100 d-flex align-items-center">
                  <div
                    class="col-lg-6 col-md-9 px-4 mx-auto text-center text-black"
                  >
                    <img
                      src="assets/images/500.svg"
                      class="w-50 mx-auto d-block my-5 img-fluid"
                      alt="..."
                    />
  
                    <h2 class="display-1 mb-3 px-4 font-weight-bold">
                      Fonctionnalité bientôt disponible
                    </h2>
                    <h3
                      class="font-size-xxl line-height-sm font-weight-light d-block px-3 mb-3 text-black-50"
                    >
                      Ce module est en cours de développement.
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  