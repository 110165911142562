import { Globals } from '@theme/utils/globals';
import { Component, OnInit } from '@angular/core';
import { Promotion } from '@model/promotion';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PromotionService } from '@service/promotion/promotion.service';
import { PromotionAddComponent } from '../promotion-add/promotion-add.component';
import { ActivatedRoute, Router } from '@angular/router';
import { FilterService } from '@service/filter/filter.service';
import { HomeService } from '@service/home/home.service';
import { environment } from '@env/environment';
import { Home } from '@model/home';
import { UploaderService } from '@service/uploader/uploader.service';
import { TaskAddComponent } from '@agence/promotion/taks/task-add/task-add.component';
import { Worksite } from '@model/worksite';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Tasks } from '@model/tasks';

@Component({
  selector: 'app-promotion-show',
  templateUrl: './promotion-show.component.html',
  styleUrls: ['./promotion-show.component.scss']
})
export class PromotionShowComponent implements OnInit {
  public activeTab: string = 'PROMOTION';
  ilot: boolean = false;
  lot: boolean = false;
  mtnFiltre: Boolean = false;
  showTask: Boolean = false;
  promotionUuid: string = ''
  promotionLibelle: string = ''
  publicUrl = environment.publicUrl;
  global = {country: Globals.country, device: Globals.device}
  userSession = Globals.user
  promotion: Promotion
  homes: Home[]
  tasks: Tasks[]
  worksites: Worksite[]

  lat = Globals.lat;
  lng = Globals.lng;
  zoom = Globals.zoom;
  icon = { url: 'assets/images/map-geo.png', scaledSize: {height: 40, width: 40}}

  type: string = 'PROMOTION';
  etatRow = [
    {label: 'DISPONIBLE', value: 'DISPONIBLE'},
    {label: 'INDISPONIBLE', value: 'INDISPONIBLE'}
  ];
  typeRow = [
    {label: 'PROMOTION', value: 'PROMOTION'},
    {label: 'MAISON', value: 'MAISON'}
  ];
  categorieRow = [
    {label: 'RURAL', value: 'RURAL'},
    {label: 'URBAIN', value: 'URBAIN'}
  ];
  nameTitle: string = "Nom du PROMOTION"
  userTitle: string = "Crée par"
  ilotTitle: string = "N° Ilot"
  lotTitle: string = "N° Lot"
  minTitle: string = "Montant MIN"
  maxTitle: string = "Montant MAX"
  categorieTitle: string = "Type de PROMOTION"
  etatTitle: string = "Disponibilité ?"
  file: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,    
    private modalService: NgbModal,
    private homeService: HomeService,     
    private uploader: UploaderService,
    private filterService: FilterService,    
    private promotionService: PromotionService,

  ) {
    this.onChangeLoad(this.type);
  }

  ngOnInit(): void {
  }

  onFilter($event) {
    this.homes = []
    $event.type = this.activeTab
    this.filterService.search($event, 'promotion', this.promotion.uuid).subscribe(
      res => {
      if(this.activeTab === 'MAISON'){
        return this.homes = res;
      }
    }, err => { })
  }
  addTask() {
    this.promotionService.setPromotion(this.promotion)
    this.showTask = true
    this.modalService.dismissAll();
    this.modal(TaskAddComponent, 'modal-basic-title', 'xl', true, 'static');
  }
  onChangeLoad(type): void {
    this.activeTab = type;
    if(type === 'PROMOTION'){
      this.ilot = false;
      this.lot = false;
      this.mtnFiltre = false
      if(!this.promotion){
        this.promotionService.getSingle(this.route.snapshot.params.id).subscribe((res: any) => {
          return this.promotion = res;
        });
      }
    }
    if(type === 'MAISON') {
      this.typeRow = [{label: 'MAISON', value: 'MAISON'}];
      this.mtnFiltre = true
      this.ilot = true;
      this.lot = true;
      this.categorieRow = []
      this.etatRow = [
        { label: 'DISPONIBLE', value: 'DISPONIBLE' },
        { label: 'RESERVE', value: 'RESERVE' },
        { label: 'VENDU', value: 'VENDU' }
      ]
      this.homeService.getList(this.promotion.uuid).subscribe((res) => {
        return this.homes = res;
        }, error => {}
      );
    }
    if(type === 'TRAVAUX') {
      this.typeRow = [{label: 'MAISON', value: 'MAISON'}];
      this.mtnFiltre = true
      this.ilot = true;
      this.lot = true;
      this.categorieRow = []
      this.etatRow = [
        { label: 'DISPONIBLE', value: 'DISPONIBLE' },
        { label: 'RESERVE', value: 'RESERVE' },
        { label: 'VENDU', value: 'VENDU' }
      ]
      this.homeService.getList(this.promotion.uuid,null,'LIST').subscribe((res) => {
        console.log('homes',res)
        return this.homes = res;
        }, error => {}
      );
    }
  }
  editPromotion(row) {
    this.promotionService.setPromotion(row)
    this.promotionService.edit = true
    this.modal(PromotionAddComponent, 'modal-basic-title', 'xl', true, 'static');
  }
  printerPromotion(row): void {
    this.promotionService.getPrinter('SHOW', this.userSession?.agencyKey, this.userSession?.uuid, row?.uuid);
  }
  delete(item) {
    Swal.fire({
      title: '',
      text: 'Voulez-vous vraiment supprimer cet enrégistrement ?',
      icon: '',
      showCancelButton: true,
      showCloseButton: true,
      cancelButtonText: 'Annuler',
      confirmButtonText: 'Supprimer <i class="feather icon-trash"></i>',
      confirmButtonColor: '#d33',
      timer: 2500,
      reverseButtons: true
    }).then((willDelete) => {
      if (willDelete.dismiss) { }
      else {
        this.promotionService.getDelete(item.uuid).subscribe((res: any) => {
          if (res?.status === 'success') {this.back()}
        });}
    });
  }
  updateGeo(event): void {
    const lat = event.coords.lat;
    const lng = event.coords.lng;
    this.lat = lat;
    this.lng = lng;
  }
  updateZoom(event): void {}
  modal(component, type, size, center, backdrop) {
    this.modalService.open(component, {
      ariaLabelledBy: type,
      size: size,
      centered: center,
      backdrop: backdrop
    }).result.then((result) => { }, (reason) => { });
  }
  showFile(item) {
    const fileByFolder = this.uploader.getDataFileByFolder();
    this.file = fileByFolder?.path ? this.publicUrl + '/' + fileByFolder?.path : null;
  }
  back(){ window.history.back(); }
  closeViewer() {
    this.file = '';
    this.uploader.setDataFileByFolder('');
  }
}
