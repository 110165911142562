<div class="col-md-12">
    <app-card *ngIf="terminates && terminates.length > 0" [hidHeader]="true" cardClass="card-datatable">
        <div class="row">
            <div class="table-responsive">
                <table *ngIf="etat" datatable [dtOptions]="dtOptions"
                    class="table table-sm table-striped table-bordered nowrap table-hover">
                    <thead class="thead-light">
                        <tr>
                            <th>Bien</th>
                            <th>Type</th>
                            <th>Etat</th>
                            <th>Crée le</th>
                            <th *ngIf="action">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of terminates">
                            <td class="align-middle">
                                <img [src]="item?.photoSrc ? item?.photoSrc : 'assets/images/house-default.png'"
                                    alt="{{item?.libelle}}" class="rounded mr-2 ml-0" height="48" />
                                <p class="m-0 d-inline-block align-middle font-16">
                                    <span class="text-warning">{{item?.mandate?.house?.nom}}</span> <br /> Superficie
                                    :{{item?.mandate?.house?.superficie}} m² <br /> Propriétaire : {{item?.owner?.nom}}
                                </p>
                            </td>
                            <td>
                                <span *ngIf="item?.mandate?.type === 'VENTE'"
                                    class="badge badge-light-primary">Vente</span>
                                <span *ngIf="item?.mandate?.type === 'LOCATION'"
                                    class="badge badge-light-warning">Location</span>
                            </td>
                            <td>
                                <span *ngIf="item?.etat" class="badge badge-success" [ngClass]="{
                                    'badge-success' : item?.etat === 'VALIDE',
                                    'badge-warning' : item?.etat === 'INVALIDE',
                                    'badge-danger' : item?.etat === 'RESILIE'
                                    }">{{validation(item?.etat)}}</span>
                            </td>
                            <td class="align-middle">{{item?.createdAt| date: "d MMMM y 'à' h:mm:ss" : '' : 'fr-FR'}}
                            </td>
                            <td class="table-action">
                                <button (click)="showTerminateMandate(item)" type="button"
                                    class="btn btn-icon ml-1 btn-secondary" ngbTooltip="Détails"><i
                                        class="fas fa-eye"></i>
                                </button>
                                <ng-template ngxPermissionsOnly="MANDATE:VALIDATE">
                                    <button *ngIf="item?.etat === 'INVALIDE'" (click)="editTerminateMandate(item)"
                                        type="button" class="btn btn-icon btn-primary ml-1" ngbTooltip="Modifier"><i
                                            class="feather icon-edit-2"></i>
                                    </button>
                                </ng-template>
                                <button (click)="validate(item)" *ngIf="item?.etat === 'INVALIDE'" type="button"
                                    class="btn btn-icon btn-success ml-1" ngbTooltip="Valider"><i
                                        class="fas fa-check"></i>
                                </button>
                                <button (click)="printerTerminateMandate(item)" type="button"
                                    class="btn btn-icon btn-warning ml-1" ngbTooltip="Imprimer">
                                    <i class="feather icon-printer"></i>
                                </button>
                                <button *ngIf="item?.etat === 'INVALIDE'" type="button" (click)="delete(item)"
                                    class="btn btn-icon btn-danger ml-1" ngbTooltip="Supprimer">
                                    <i class="feather icon-trash"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </app-card>
</div>