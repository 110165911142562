
import { Owner } from '@model/owner';
import { House } from '@model/house';
import { Rental } from '@model/rental';
import { Mandate } from '@model/mandate';
import { Repayment } from '@model/repayment';
import { environment } from '@env/environment';
import { Globals } from '@theme/utils/globals';
import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgxPermissionsService } from 'ngx-permissions';
import { ActivatedRoute, Router } from '@angular/router';
import { OwnerService } from '@service/owner/owner.service';
import { FilterService } from '@service/filter/filter.service';
import { OwnerAddComponent } from '@proprietaire/owner/owner-add/owner-add.component';
import { UploaderService } from '@service/uploader/uploader.service';
import { FundsapealService } from '@service/syndic/fundsapeal.service';
import { FundsNoticeService } from '@service/syndic/funds-notice.service';
import { CoproprieteService } from '@service/syndic/copropriete.service';
import { FundsPaymentService } from '@service/syndic/funds-payment.service';
import { ConstructionService } from '@service/construction/construction.service';
import { MandateSyndicService } from '@service/syndic/mandate-syndic.service';
import { ConstructionAddComponent } from '@agence/chantier/construction/construction-add/construction-add.component';
import { CondominiumAddComponent } from '@agence/proprietaire/condominium/condominium-add/condominium-add.component';
import { FundsApealsShowComponent } from '@agence/syndic/funds-apeals/funds-apeals-show/funds-apeals-show.component';
import { FundsNoticeShowComponent } from '@agence/syndic/funds-notice/funds-notice-show/funds-notice-show.component';
import { FundsPaymentShowComponent } from '@agence/syndic/funds-payment/funds-payment-show/funds-payment-show.component';
import { HomeCoService } from '@service/syndic/home-co.service';
import { InfrastructureService } from '@service/syndic/infrastructure.service';
import { InfrastructureShowComponent } from '@agence/syndic/infrastructure/infrastructure-show/infrastructure-show.component';
import { InfrastructureAddComponent } from '@agence/syndic/infrastructure/infrastructure-add/infrastructure-add.component';
import { SyndicService } from '@service/syndic/syndic.service';
import { DateHelperService } from '@theme/utils/date-helper.service';


@Component({
  selector: 'app-owner-show',
  templateUrl: './owner-show.component.html',
  styleUrls: ['./owner-show.component.scss']
})
export class OwnerShowComponent implements OnInit {
  public activeTab: string = 'PROPRIETAIRE';
  publicUrl = environment.publicUrl;
  userSession = Globals.user;
  global = { country: Globals.country, device: Globals.device };
  owner: Owner = null;
  houses: House[];
  rentals: Rental[];
  mandates: Mandate[];
  repayments: Repayment[];
  fundsapeals: any[]
  fundsNotices: any[]
  fundsPayments: any[]
  coproprietes: any[];
  interventions: any[]
  mandateSyndics: any[]
  infrastructures: any[]
  appels: any[];
  avis: any[];
  travaux: any[];
  provisions: any[]
  type: string = 'PROPRIETAIRE';
  etatRow = [
    { label: 'DISPONIBLE', value: 'DISPONIBLE' },
    { label: 'VENDU', value: 'VENDU' }
  ];
  typeRow = [
    { label: 'PROPRIETAIRE', value: 'PROPRIETAIRE' },
    { label: 'BIEN', value: 'BIEN' },
    { label: 'LOCATIVE', value: 'LOCATIVE' },
    { label: 'MANDAT', value: 'MANDAT' },
    { label: 'REVERSEMENT', value: 'REVERSEMENT' }
  ];
  categorieRow = [
    { label: 'EN LOCATION', value: 'LOCATION' },
    { label: 'EN VENTE', value: 'VENTE' }
  ];
  nameTitle: string = "Nom de lot"
  userTitle: string = "Crée par"
  categorieTitle: string = "Type de lot"
  etatTitle: string = "Syndic"
  file: any;
  bien = false
  name: boolean = false
  bienTitle = ''
  modelRef: NgbModalRef
  dtOptions = {}
  view: boolean = false
  categorie = false
  etat: boolean = false

  montant: number = 0
  paye: number = 0
  reste: number = 0
  filter: any;

  constructor(
    private ownerService: OwnerService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private router: Router,
    private homeService: HomeCoService,
    private filterService: FilterService,
    private permissionsService: NgxPermissionsService,
    private uploader: UploaderService,
    private syndicService: SyndicService,
    private fundSapealService: FundsapealService,
    private coproprieteService: CoproprieteService,
    private fundsNoticeService: FundsNoticeService,
    private fundsPaymentService: FundsPaymentService,
    private constructionService: ConstructionService,
    private mandateSyndicService: MandateSyndicService,
    private fundsApealsService: FundsapealService,
    private infrastructureService: InfrastructureService
  ) {
    this.owner = this.ownerService.getOwner();
    this.onChangeLoad(false,this.type);
    const permission = JSON.parse(localStorage.getItem('permission-zen')) ? JSON.parse(localStorage.getItem('permission-zen')) : [];
    this.permissionsService.loadPermissions(permission);
    if(this.ownerService.return === 'COPROPRIETAIRE_SHOW'){
      this.activeTab = 'LOT'
      this.onChangeLoad(true, 'LOT')
      this.ownerService.return = null
    }
  }

  ngOnInit(): void {
    this.dtOptions = Globals.dataTable
    if (!this.owner) {
      this.ownerService.getSingle(this.route.snapshot.params.id).subscribe((res: any) => {
        return this.owner = res;
      });
    }
  }

  onFilter($event) {
    this.mandates = []
    this.repayments = []
    this.coproprietes = []
    this.fundsapeals = []
    this.fundsNotices = []
    this.interventions = []
    this.fundsPayments = []
    this.infrastructures = []
    this.mandateSyndics = []
    this.filterService.type = this.activeTab;
    $event.type = this.activeTab
    $event.uuid = this.route.snapshot.params.id
    $event.syndic = this.owner?.trustee?.uuid
    this.filter = null;
    if(this.activeTab === 'LOT' || this.activeTab === 'APPEL' || this.activeTab === 'MANDAT_SYNDIC' || this.activeTab === 'PROVISION' || this.activeTab === 'INFRASTRUCTURE'){
      this.filterService.search($event, 'trustee', this.owner.uuid).subscribe(
        res => {
          if(this.activeTab === 'LOT'){
            return this.coproprietes = res
          } else if(this.activeTab === 'APPEL'){
            return this.fundsapeals = res
          } else if(this.activeTab === 'AVIS'){
            return this.fundsNotices = res
          } else if(this.activeTab === 'PROVISION'){
            return this.fundsPayments = res
          } else if(this.activeTab === 'MANDAT_SYNDIC'){
            return this.mandateSyndics = res
          } else if(this.activeTab == 'INFRASTRUCTURE'){
            return this.infrastructures = res
          }
        }, err => { })
    }
    else if(this.activeTab === 'TRAVAUX'){
      this.filterService.search($event, 'owner', this.owner.uuid).subscribe(
        res => {
          if(this.activeTab === 'TRAVAUX'){
            return this.interventions = res
          }
        }, err => { })
    }
  }

  onChangeLoad(bool: boolean, type) {
    this.activeTab = type;
    this.view = bool
    if (type === 'PROPRIETAIRE') {
      if (!this.owner) {
        this.ownerService.getSingle(this.route.snapshot.params.id).subscribe((res: any) => {
          return this.owner = res;
        });
      }
    } else if(type === 'LOT'){
      this.name = false
      this.etat = true
      this.nameTitle = 'Syndic'
      this.bien = true
      this.bienTitle = 'Numéro du lot'
      this.typeRow = [
        { label: 'LOT', value: 'LOT' },
        { label: 'INFRASTRUCTURE', value: 'INFRASTRUCTURE' },
      ];
      this.categorie = true
      this.categorieTitle = "Type de lot"
      this.categorieRow = [
        { label: 'VERTICAL', value: 'VERTICAL' },
        { label: 'HORIZONTAL', value: 'HORIZONTAL' }
      ];
      this.getSyndic()
      this.homeService.getList(null, null, null, this.route.snapshot.params.id).subscribe(res => {
       return this.coproprietes = res
      }, error => {})
    } else if(type === 'APPEL'){
      this.name = false
      this.bien = true
      this.etat = false
      this.categorie = false
      this.bienTitle = 'Libellé appel de charges'
      this.typeRow = [
        { label: 'APPELS DE CHARGES', value: 'APPEL' },
      ];
      this.fundSapealService.getList(null, this.route.snapshot.params.id).subscribe((res: any) => {
        let montant = 0
        let paye = 0
        let reste = 0
        res.forEach((item: any) => {
          montant += Number(item.montant)
          paye += Number(item.payer)
          reste += Number(item.reste)
        })
        this.montant = montant
        this.paye = paye
        this.reste = reste
        return this.fundsapeals = res;
      })
    } else if(type === 'AVIS'){
      this.bien = true
      this.name = false
      this.etat = false
      this.bienTitle = 'Libellé avis d\'écheance'
      this.typeRow = [
        { label: 'AVIS D\'ECHEANCE', value: 'AVIS' },
      ];
      this.fundsNoticeService.getList(null, this.route.snapshot.params.id).subscribe((res: any) => {
        return this.fundsNotices = res
      })
    } else if(type === 'TRAVAUX'){
      this.bien = true
      this.categorie = false
      this.etat = false
      this.bienTitle = 'Libellé intervention'
      this.typeRow = [
        { label: 'INTERVENTION', value: 'INTERVENTION' },
      ];
      this.constructionService.getList(null, this.route.snapshot.params.id).subscribe((res: any) => {
        return this.interventions = res
      })
    } else if(type === 'PROVISION'){
      this.bien = true
      this.etat = false
      this.categorie = false
      this.bienTitle = 'Libellé règlement'
      this.categorie = false
      this.name = false
      this.nameTitle = 'Syndic'
      this.typeRow = [
        { label: 'RÈGLEMENT', value: 'PROVISION' },
      ];
      this.fundsPaymentService.getList(null, this.route.snapshot.params.id).subscribe((res: any) => {
        return this.fundsPayments = res
      })
    } else if(type === 'MANDAT_SYNDIC'){
      this.name = false
      this.bien = true
      this.etat = false
      this.bienTitle = 'Libellé mandat'
      this.typeRow = [
        { label: 'MANDAT', value: 'MANDAT_SYNDIC' },
      ];
      this.mandateSyndicService.getList(null, this.route.snapshot.params.id).subscribe((res: any) => {
        return this.mandateSyndics = res
      })
    } else if(type === 'INFRASTRUCTURE'){
      this.name = false
      this.bien = true
      this.etat = false
      this.bienTitle = 'Libellé infrastructure'
      this.categorie = true
      this.categorieTitle = "Type d'infrastructure"
      this.categorieRow = [
        {label: 'Ascenseur', value: 'ASCENSEUR'},
        {label: 'Partie commune', value: 'PARTIE COMMUNE'},
        {label: 'Parking', value: 'PARKING'},
        {label: 'Jardin', value: 'JARDIN'},
        {label: 'Piscine', value: 'PISCINE'},
        {label: 'Aire de jeux', value: 'AIRE DE JEUX'}
      ];
      this.infrastructureService.getList(null, null, this.route.snapshot.params.id).subscribe((res: any) => {
        return this.infrastructures = res
      })
    }
  }

  getSyndic(){
    this.syndicService.getList().subscribe((res: any) => {
      this.etatRow = []
      res.forEach(element => {
        this.etatRow.push({
          label: element?.nom , value: element?.uuid
        }
      )
      return this.etatRow
      });
    })
  }

  showHouse(row){
    this.coproprieteService.setCopropriete(row)
    this.coproprieteService.exit = 'COPROPRIETAIRE_SHOW'
    this.coproprieteService.uuidSyndic = this.route.snapshot.params.id
    this.router.navigate(['/admin/syndic/copropriete/show/' + row.uuid + '/COPROPRIETAIRE_SHOW']);
  }

  editHouse(row){
    this.coproprieteService.setCopropriete(row)
    this.coproprieteService.edit = true
    this.modal(CondominiumAddComponent, 'modal-basic-title', 'xl', true, 'static')
  }

  showConstruction(row) {
    this.constructionService.setConstruction(row);
    this.router.navigate(['/admin/intervention/show/' + row.uuid]);
  }

  printerConstruction(row): void {
    this.constructionService.getPrinter('SHOW', this.userSession?.agencyKey, this.userSession?.uuid, row?.uuid);
  }

  editConstruction(row) {
    this.constructionService.setConstruction(row);
    this.constructionService.edit = true;
    this.constructionService.type = row.type;
    this.modal(ConstructionAddComponent, 'modal-basic-title', 'lg', true, 'static');
    this.modelRef.componentInstance.type = this.constructionService.type == "SYNDIC"?  "SYNDIC":"LOCATIVE"
  }

  showFundsApeal(row){
    this.fundsApealsService.setFundSapeal(row)
    this.modal(FundsApealsShowComponent, 'modal-basic-title', 'lg', true, 'static')
  }
  showFundsNotice(row){
    this.fundsNoticeService.setFundsNotice(row)
    this.modal(FundsNoticeShowComponent, 'modal-basic-title', 'lg', true, 'static')
  }
  showFundsPayment(row){
    this.fundsPaymentService.setFundsPayment(row)
    this.modal(FundsPaymentShowComponent, 'modal-basic-title', 'lg', true, 'static')
  }

  deleteFundsApeal(item){
    Swal.fire({
      title: '',
      text: 'Voulez-vous vraiment supprimer cet enrégistrement ?',
      icon: '',
      showCancelButton: true,
      showCloseButton: true,
      cancelButtonText: 'Annuler',
      confirmButtonText: 'Supprimer <i class="feather icon-trash"></i>',
      confirmButtonColor: '#d33',
      timer: 2500,
      reverseButtons: true
    }).then((willDelete) => {
      if (willDelete.dismiss) {
      } else {
        this.fundsApealsService.getDelete(item?.uuid).subscribe((res: any) => {
          if (res?.status === 'success') {
            const index = this.fundsapeals.findIndex(x => x.id === item.id);
            if (index !== -1) { this.fundsapeals.splice(index, 1); }
            Swal.fire('', 'La suppression a été éffectuée avec succès !', 'success');
          }
        });
      }
    });
  }
  deleteFundsNotice(item){
    Swal.fire({
      title: '',
      text: 'Voulez-vous vraiment supprimer cet enrégistrement ?',
      icon: '',
      showCancelButton: true,
      showCloseButton: true,
      cancelButtonText: 'Annuler',
      confirmButtonText: 'Supprimer <i class="feather icon-trash"></i>',
      confirmButtonColor: '#d33',
      timer: 2500,
      reverseButtons: true
    }).then((willDelete) => {
      if (willDelete.dismiss) {
      } else {
        this.fundsNoticeService.getDelete(item?.uuid).subscribe((res: any) => {
          if (res?.status === 'success') {
            const index = this.fundsNotices.findIndex(x => x.id === item.id);
            if (index !== -1) { this.fundsNotices.splice(index, 1); }
            Swal.fire('', 'La suppression a été éffectuée avec succès !', 'success');
          }
        });
      }
    });
  }
  deleteFundsPayment(item){
    Swal.fire({
      title: '',
      text: 'Voulez-vous vraiment supprimer cet enrégistrement ?',
      icon: '',
      showCancelButton: true,
      showCloseButton: true,
      cancelButtonText: 'Annuler',
      confirmButtonText: 'Supprimer <i class="feather icon-trash"></i>',
      confirmButtonColor: '#d33',
      timer: 2500,
      reverseButtons: true
    }).then((willDelete) => {
      if (willDelete.dismiss) {
      } else {
        this.fundsPaymentService.getDelete(item?.uuid).subscribe((res: any) => {
          if (res?.status === 'success') {
            const index = this.fundsPayments.findIndex(x => x.id === item.id);
            if (index !== -1) { this.fundsPayments.splice(index, 1); }
            Swal.fire('', 'La suppression a été éffectuée avec succès !', 'success');
          }
        });
      }
    });
  }

  deleteHouse(item) {
    Swal.fire({
      title: '',
      text: 'Voulez-vous vraiment supprimer cet enrégistrement ?',
      icon: '',
      showCancelButton: true,
      showCloseButton: true,
      cancelButtonText: 'Annuler',
      confirmButtonText: 'Supprimer <i class="feather icon-trash"></i>',
      confirmButtonColor: '#d33',
      timer: 2500,
      reverseButtons: true
    }).then((willDelete) => {
      if (willDelete.dismiss) {
      } else {
        this.coproprieteService.getDelete(item?.uuid).subscribe((res: any) => {
          if (res?.status === 'success') {
            const index = this.coproprietes.findIndex(x => x.id === item.id);
            if (index !== -1) { this.coproprietes.splice(index, 1); }
            Swal.fire('', 'La suppression a été éffectuée avec succès !', 'success');
          }
        });
      }
    });
  }

  deleteConstruction(construction) {
    Swal.fire({
      title: '',
      text: 'Voulez-vous vraiment supprimer cet enrégistrement ?',
      icon: '',
      showCancelButton: true,
      showCloseButton: true,
      cancelButtonText: 'Annuler',
      confirmButtonText: 'Supprimer <i class="feather icon-trash"></i>',
      confirmButtonColor: '#d33',
      timer: 2500,
      reverseButtons: true
    }).then((willDelete) => {
      if (willDelete.dismiss) {
      } else {
        this.constructionService.getDelete(construction.uuid).subscribe((res: any) => {
          if (res?.code === 200) {
            const index = this.interventions.findIndex(x => x.uuid === construction.uuid);
            if (index !== -1) {
              this.interventions.splice(index, 1);
            }
            Swal.fire('', res?.message, res?.status);
          }
        }, error => {
        });
        Swal.fire('', 'Enrégistrement supprimé avec succès !', 'success');
      }
    });
  }

  deleteMandate(item){
    Swal.fire({
      title: '',
      text: 'Voulez-vous vraiment supprimer cet enrégistrement ?',
      icon: '',
      showCancelButton: true,
      showCloseButton: true,
      cancelButtonText: 'Annuler',
      confirmButtonText: 'Supprimer <i class="feather icon-trash"></i>',
      confirmButtonColor: '#d33',
      timer: 2500,
      reverseButtons: true
    }).then((willDelete) => {
      if (willDelete.dismiss) {
      } else {
        this.mandateSyndicService.getDelete(item?.uuid).subscribe((res: any) => {
          if (res?.status === 'success') {
            const index = this.mandateSyndics.findIndex(x => x.id === item.id);
            if (index !== -1) { this.mandateSyndics.splice(index, 1); }
            Swal.fire('', 'La suppression a été éffectuée avec succès !', 'success');
          }
        });
      }
    });
  }


  showInfrastructure(item){
    this.infrastructureService.setInfrastructure(item)
    this.modal(InfrastructureShowComponent, 'modal-basic-title', 'lg', true, 'static')
  }

  editInfrastructure(item){
    this.infrastructureService.setInfrastructure(item)
    this.infrastructureService.edit = true
    this.modal(InfrastructureAddComponent, 'modal-basic-title', 'xl', true, 'static')
  }

  printInfrastructure(item){}

  deleteInfrastructure(item){
    Swal.fire({
      title: '',
      text: 'Voulez-vous vraiment supprimer cet enrégistrement ?',
      icon: '',
      showCancelButton: true,
      showCloseButton: true,
      cancelButtonText: 'Annuler',
      confirmButtonText: 'Supprimer <i class="feather icon-trash"></i>',
      confirmButtonColor: '#d33',
      timer: 2500,
      reverseButtons: true
    }).then((willDelete) => {
      if (willDelete.dismiss) {
      } else {
        this.infrastructureService.getDelete(item?.uuid).subscribe((res: any) => {
          if (res?.status === 'success') {
            const index = this.infrastructures.findIndex(x => x.id === item.id);
            if (index !== -1) { this.infrastructures.splice(index, 1); }
            Swal.fire('', 'La suppression a été éffectuée avec succès !', 'success');
          }
        });
      }
    });
  }
  editOwner(row) {
    this.ownerService.setOwner(row);
    this.ownerService.edit = true;
    this.ownerService.type = row.type;
    this.modal(OwnerAddComponent, 'modal-basic-title', 'xl', true, 'static');
  }

  printerOwner(row): void {
    this.ownerService.getPrinter('SHOW', this.userSession?.agencyKey, this.userSession?.uuid, row?.uuid);
  }

  back() { this.router.navigate(['/admin/proprietaire']) }

  delete(item) {
    Swal.fire({
      title: '',
      text: 'Voulez-vous vraiment supprimer cet enrégistrement ?',
      icon: '',
      showCancelButton: true,
      showCloseButton: true,
      cancelButtonText: 'Annuler',
      confirmButtonText: 'Supprimer <i class="feather icon-trash"></i>',
      confirmButtonColor: '#d33',
      timer: 2500,
      reverseButtons: true
    }).then((willDelete) => {
      if (willDelete.dismiss) { }
      else {
        this.ownerService.getDelete(item.uuid).subscribe((res: any) => {
          if (res?.status === 'success') { this.router.navigate(['/admin/proprietaire']) }
        });
      }
    });
  }

  deleteOwner(item) {
    console.log('Lot', item);
    Swal.fire({
      title: '',
      text: 'Voulez-vous vraiment supprimer cet enrégistrement ?',
      icon: '',
      showCancelButton: true,
      showCloseButton: true,
      cancelButtonText: 'Annuler',
      confirmButtonText: 'Supprimer <i class="feather icon-trash"></i>',
      confirmButtonColor: '#d33',
      timer: 2500,
      reverseButtons: true
    }).then((willDelete) => {
      if (willDelete.dismiss) {
      } else {
        if(item.type === 'HORIZONTAL'){
          this.homeService.getDelete(item?.uuid).subscribe((res: any) => {
            if (res?.status === 'success') {
              const index = this.coproprietes.findIndex(x => x.id === item.id);
              if (index !== -1) { this.coproprietes.splice(index, 1); }
              Swal.fire('', 'La suppression a été éffectuée avec succès !', 'success');
            }
          });
        }
        else if(item.type === 'VERTICAL'){
          this.coproprieteService.getDelete(item?.uuid).subscribe((res: any) => {
            if (res?.status === 'success') {
              const index = this.coproprietes.findIndex(x => x.id === item.id);
              if (index !== -1) { this.coproprietes.splice(index, 1); }
              Swal.fire('', 'La suppression a été éffectuée avec succès !', 'success');
            }
          });
        }
      }
    });
  }

  modal(component, type, size, center, backdrop) {
    this.modalService.open(component, {
      ariaLabelledBy: type,
      size: size,
      centered: center,
      backdrop: backdrop
    }).result.then((result) => {
      if(result == 'OWNER'){
        this.ownerService.getSingle(this.route.snapshot.params.id).subscribe((res: any) => {
          return this.owner = res
        })
      }else if(result == 'COPROPRIETE'){
        this.coproprieteService.getList(null, this.route.snapshot.params.id).subscribe((res: any) => {
          return this.coproprietes = res
        })
      }else if(result == 'PROVISION'){
        this.fundsPaymentService.getList(null, this.route.snapshot.params.id, null).subscribe((res: any) => {
          return this.fundsPayments = res
        })
      }
     }, (reason) => { });
  }

  showFile(item) {
    const fileByFolder = this.uploader.getDataFileByFolder();
    this.file = fileByFolder?.path ? this.publicUrl + '/' + fileByFolder?.path : null;
  }

  closeViewer() {
    this.file = '';
    this.uploader.setDataFileByFolder('');
  }

  onSubStringLongName(str: string): any {
    if (str !== null) {
      if (str.length > 35) {
        return str.substring(0, 35) + ' ...';
      } else {
        return str;
      }
    } else {
      return '';
    }
  }

  onPrinter() {
    if (this.activeTab === 'LOT') {
      this.homeService.getPrinter('LISTE', this.userSession?.agencyKey, this.userSession?.uuid, this.filter, null);
    }
    else if (this.activeTab === 'INFRASTRUCTURE') {
      this.infrastructureService.getPrinter('LISTE', this.userSession?.agencyKey, this.userSession?.uuid, this.filter, null);
    }
    else if (this.activeTab === 'APPEL') {
      this.fundsApealsService.getPrinter('LISTE', this.userSession?.agencyKey, this.userSession?.uuid, this.filter, null, this.owner.uuid);
    }
    else if (this.activeTab === 'PROVISION') {
      this.fundsPaymentService.getPrinter('LISTE', this.userSession?.agencyKey, this.userSession?.uuid, this.filter, null);
    }
  }

  printerFundsApeal(row): void {
    this.fundsApealsService.getPrinter('SHOW', this.userSession?.agencyKey, this.userSession?.uuid, null, row.trustee.uuid, row.uuid);
  }
  timelapse(dateD, dateF): string { return DateHelperService.getTimeLapse(dateD, dateF, false, 'dmy'); }
}
