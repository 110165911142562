import {Contract} from '@model/contract';
import { Globals } from '@theme/utils/globals';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Component, Input, OnInit} from '@angular/core';
import {EmitterService} from '@service/emitter/emitter.service';
import {ContractService} from '@service/contract/contract.service';
import {DateHelperService} from '@theme/utils/date-helper.service';
import {ContractAddComponent} from '@locataire/contract/contract-add/contract-add.component';
import {ContractShowComponent} from '@locataire/contract/contract-show/contract-show.component';
import {ContractUploadComponent} from '@locataire/contract/contract-upload/contract-upload.component';


@Component({
  selector: 'app-contract-list',
  templateUrl: './contract-list.component.html',
  styleUrls: ['./contract-list.component.scss']
})
export class ContractListComponent implements OnInit {
  @Input() contracts: Contract[];
  @Input() action: boolean = true
  @Input() locataire = true;
  dtOptions: any = {};
  etat: boolean = false
  global = {country: Globals.country, device: Globals.device}
  userSession = Globals.user
  total = 0;
  paye = 0;
  impaye = 0;

  constructor(
    private modalService: NgbModal,
    private emitter: EmitterService,
    private contractService: ContractService
  ) {
  }

  ngOnInit(): void {
    this.etat = this.contracts ? true : false;
    if(this.etat){
      this.contracts.forEach(item => {
        this.total += item?.entranceInvoice?.montant
        this.paye += item?.entranceInvoice?.paye
        this.impaye += item?.entranceInvoice?.impaye
      })
    }
    this.dtOptions = Globals.dataTable;
    this.emitter.event.subscribe((data) => {
      if (data.action === 'CONTRACT_ADD') {
        this.appendToList(data.payload);
      }
      if (data.action === 'CONTRACT_UPDATED') {
        this.update(data.payload);
      }
    });
  }
  appendToList(row): void {
    this.contracts.unshift(row);
  }
  update(row): void {
    const index = this.contracts.findIndex(x => x.uuid === row.uuid);
    if (index !== -1) {
      this.contracts[index] = row;
    }
  }
  editContract(row): void {
    this.contractService.setContract(row);
    this.contractService.edit = true;
    this.modal(ContractAddComponent, 'modal-basic-title', 'xl', true, 'static');
  }
  uploadContract(row): void {
    this.contractService.setContract(row);
    this.modal(ContractUploadComponent, 'modal-basic-title', 'md', true, 'static');
  }
  showContract(row): void {
    this.contractService.setContract(row);
    this.modal(ContractShowComponent, 'modal-basic-title', 'xl', true, 'static')
  }
  printerContract(row): void {
    this.contractService.getPrinter('SHOW', this.userSession?.agencyKey, this.userSession?.uuid, row?.uuid);
  }
  activateContract(row) {
    Swal.fire({
      title: '',
      text: 'Voulez-vous vraiment valider ce contrat ?',
      icon: '',
      showCancelButton: true,
      showCloseButton: true,
      cancelButtonText: 'Annuler',
      confirmButtonText: 'Valider <i class="fas fa-check"></i>',
      confirmButtonColor: 'green',
      timer: 2500,
      reverseButtons: true
    }).then((willDelete) => {
      if (willDelete.dismiss) {
      } else {
          this.contractService.activate(row).subscribe((res) => {
          const index = this.contracts.findIndex((x) => {
            return x.uuid === res?.data.uuid;
          });
          if (index !== -1) {
            this.contracts[index] = res?.data;
          }
        });
      }
    });
  }
  delete(row) {
    Swal.fire({
      title: '',
      text: 'Voulez-vous vraiment supprimer cet enrégistrement ?',
      icon: '',
      showCancelButton: true,
      showCloseButton: true,
      cancelButtonText: 'Annuler',
      confirmButtonText: 'Supprimer <i class="feather icon-trash"></i>',
      confirmButtonColor: '#d33',
      timer: 2500,
      reverseButtons: true
    }).then((willDelete) => {
      if (willDelete.dismiss) {
      } else {
        this.contractService.getDelete(row.uuid).subscribe((res: any) => {
          const index = this.contracts.findIndex((x) => {
            return x.uuid === row.uuid;
          });
          if (index !== -1) {
            this.contracts.splice(index, 1);
          }
          Swal.fire('Contrat Supprimé', res?.message, 'success');
        });
      }
    });
  }
  modal(component, type, size, center, backdrop): void {
    this.modalService.open(component, {
      ariaLabelledBy: type,
      size: size,
      centered: center,
      backdrop: backdrop
    }).result.then((result) => {

    }, (reason) => {

    });
  }
  readableDate(date) { return DateHelperService.readable(date); }
  formatDate(date) { return DateHelperService.fromJsonDate(date); }
  timelapse(date): string { return DateHelperService.getTimeLapse(date); }
}
